import React, { useState } from 'react';
import { Modal, Spin } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the blur effect CSS
import "./SignatureModal.scss";

const SignatureModal = ({approverSig,handleCancelSigModal,openSigModal}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
    setError(true);
  };

  return (
    <Modal open={openSigModal} onCancel={handleCancelSigModal} footer={null} bodyStyle={{ height: 250 }}>
      {loading && <Spin className='signature-image-loading' />}
      {!error && (
        <div className='signature-image-container' >
          <LazyLoadImage
            src={approverSig}
            alt="Signature"
            className='signature-lazy-image'
            effect="blur"
            onLoad={handleImageLoad}
            onError={handleImageError}
            visibleByDefault={true} // Set to true to load image immediately if present in cache
          />
        </div>
      )}
      {error && <div>Error loading image</div>}
    </Modal>
  );
};

export default SignatureModal;