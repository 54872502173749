import { Button, Space, Steps } from 'antd';
import AssignSubmitterApprover from "./AssignSubmitterApprover";
import { useSelector , useDispatch} from 'react-redux';
import ReviewCreatedSurvey from "./ReviewCreatedSurvey";
import {setCurrent,setFormDataRedux} from "../../redux/slices/adminSlice";
import { setFormData } from '../../redux/actions/eWarrant';
import AdminCustomForm from '../AdminDashboardForm/AdminCustomForm';
const CreateSurveyModal = ({form}) => {
  const {eWarrentContractAddress} = useSelector((state)=> state.template);
  const {current} = useSelector((state)=>state.admin);
  const {templateTableData} = useSelector((state)=>state.template);
  const {surveyId,confirmations,approverAddrArr,submitterAddr,workflowVersion,referencedWorkflowAttestationUId,submitter,approvers} = useSelector((state)=>state.admin);
  const {isFormDataSetterLoading} = useSelector((state)=> state.contract)
  console.log("templateTableData :",templateTableData);
  const dispatch = useDispatch();
  
  const steps = [
    {
      title: 'Create Workflow Template',
      content: <AdminCustomForm form={form} type={"create"} disableForm={false} />,
    },
    {
      title: 'Assign Dataset Provider and Approver(s)',
      content: <AssignSubmitterApprover form={form}/>,
    },
    {
      title: 'Review Details',
      content: <ReviewCreatedSurvey/>,
    },
  ];

  const next = async() => {
    const value = await form.validateFields();
    dispatch(setCurrent(current + 1)); 
    console.log("value :",value);
    
    const parsedSubmitter = JSON.parse(value.submitter);
    const parsedSubmitterAddress = parsedSubmitter.address;
    const parsedSubmitterEmail = parsedSubmitter.email;
    console.log("parsed submitter address :",parsedSubmitterAddress," and email :",parsedSubmitterEmail);
    
    const parsedApprovers = value.approvers.map((approver)=>JSON.parse(approver));
    const parsedApproverArrayAddresses = parsedApprovers.map(approver => approver.address);
    const parsedApproverArrayEmails = parsedApprovers.map(approver => approver.email);
    console.log("parsed approvers address :",parsedApproverArrayAddresses," and emails :",parsedApproverArrayEmails);

    const survey = templateTableData.find(item => item.templatesVersion[item.templatesVersion?.length-1]?.name === value.surveyName);
    const surveyName = survey ? survey.templatesVersion[survey.templatesVersion?.length-1]?.name : null;
    const surveyId = survey ? survey._id : null;
    const workflowVersion = survey.templatesVersion[survey.templatesVersion?.length-1]?.versions;
    const referencedWorkflowAttestationUId = survey.templatesVersion[survey.templatesVersion?.length-1]?.attestation.attestationUId;
    dispatch(setFormDataRedux({...value,surveyName,surveyId,approverAddressArray:parsedApproverArrayAddresses,approvers:parsedApproverArrayEmails,submitter:parsedSubmitterEmail,submitterAddr:parsedSubmitterAddress,workflowVersion,referencedWorkflowAttestationUId}));
  };

  const prev = () => {
    dispatch(setCurrent(current - 1));
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const generateID = () => {
    let id = '123'; // for escaping 0 
    for (let i = 0; i < 10; i++) {
      id += Math.floor(Math.random() * 10);
    }
    return id;
  };

  const confirmDetails = async() => {
    const formId = generateID();
    console.log("formId generated :",formId);
    dispatch(setFormData({surveyId,formId,submitterAddr,approverAddrArr,confirmations,contract_address :eWarrentContractAddress,workflowVersion,referencedWorkflowAttestationUId,submitter,approvers}));
  }

  return (
      <div>
        <Steps current={current} items={items} />
        <div>{steps[current].content}</div>
        <div
          className='antd-form-button-container'
        >
          <Space>
            {current > 0 && current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={() => confirmDetails()} loading={isFormDataSetterLoading}>
                Confirm Details
              </Button>
            )}
            {current > 1 && (
              <Button
                onClick={() => prev()}
              >
                Previous
              </Button>
            )}
          </Space>
        </div>
      </div>
  );
};
export default CreateSurveyModal;