import { createSlice } from "@reduxjs/toolkit";
import { deployEWarrentForAdmin, transferEWarrentOwnership } from "../actions/eWarrantDeployer";

const eWarrantDeployerSlice = createSlice({
    name:"eWarrantDeployer",
    initialState: {
        isEWarrentDeploymentLoading:false,
        transferEWarrentOwnershipSuccess:false,
        isEWarrentTransferLoading:false
    },
    reducers:{
    },
    extraReducers:(builder)=> {
        builder
        .addCase(deployEWarrentForAdmin.pending,(state,action) => {
            state.isEWarrentDeploymentLoading = true;
        })
        .addCase(deployEWarrentForAdmin.fulfilled,(state,action) => {
            state.isEWarrentDeploymentLoading = false;
        })
        .addCase(deployEWarrentForAdmin.rejected,(state,action) => {
            state.isEWarrentDeploymentLoading = false;
        })
        .addCase(transferEWarrentOwnership.pending,(state,action) => {
            state.isEWarrentTransferLoading = true;
            state.transferEWarrentOwnershipSuccess = false;
        })
        .addCase(transferEWarrentOwnership.fulfilled,(state,action) => {
            state.isEWarrentTransferLoading = false;
            state.transferEWarrentOwnershipSuccess = action.payload;
        })
        .addCase(transferEWarrentOwnership.rejected,(state,action) => {
            state.isEWarrentTransferLoading = false;
            state.transferEWarrentOwnershipSuccess = false;
        })
    }
});
// export const {} = eWarrantDeployerSlice.actions;
export default eWarrantDeployerSlice.reducer;