import { Badge, Card, Col, Divider, Input, Pagination, Row, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import MainLayout from '../../components/MainLayout/MainLayout';
import { adminGetWorkflowsForVerifying } from '../../redux/actions/template';
import { setAssignedWorkflowCardsPerPage, setAssignedWorkflowCurrentPage } from '../../redux/slices/adminSlice';
const { Option } = Select;

const AdminAssignedSurveys = () => {
    const navigate = useNavigate();
    const {formIdsToStatusArrAdmin} = useSelector((state)=>state.contract);
    const { assignedWorkflowsCurrentPage,assignedWorkflowsCardsPerPage,assignedWorkflowsOrderBy,assignedWorkflowsSortBy } = useSelector((state)=>state.admin);
    const { isWorkflowsCreatedByAdminLoading , adminWorkflowArray , totalWorkflowsCreatedByAdminCount } = useSelector((state)=>state.template);

    const dispatch = useDispatch();
    console.log("formIdsToStatusArrAdmin ismein surveyName hona chahiye :",formIdsToStatusArrAdmin);
    const [isLoading, setIsLoading] = useState(true); // Track initial loading state

    useEffect(()=>{
      dispatch(adminGetWorkflowsForVerifying({limit:assignedWorkflowsCardsPerPage,orderBy:assignedWorkflowsOrderBy,sortBy:assignedWorkflowsSortBy,page:assignedWorkflowsCurrentPage})).then(() => {
        setIsLoading(false); // Set loading state to false after data is fetched
      });
    },[dispatch,assignedWorkflowsCurrentPage,assignedWorkflowsCardsPerPage,assignedWorkflowsOrderBy,assignedWorkflowsSortBy]);

    const toVerifyPage = (formId) => {
      navigate(`/assigned-workflow/${formId}`);
    }

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const handleSearchInputChange = (e) => {
      const query = e.target.value.toLowerCase();
      setSearchQuery(query);
      const filtered = adminWorkflowArray?.filter((item) => {
        const workflowName = item.name.toLowerCase();
        const workflowID = item.workflowID.toLowerCase();
        return (
          (workflowName.includes(query) || workflowID.includes(query))
        );
      });
      setFilteredData(filtered);
    };
    
    
  
    const handleChangePage = (page) => {
      dispatch(setAssignedWorkflowCurrentPage(page));
      setSearchQuery(''); // Reset search query when changing pages
      dispatch(adminGetWorkflowsForVerifying({limit:assignedWorkflowsCardsPerPage,orderBy:assignedWorkflowsOrderBy,sortBy:assignedWorkflowsSortBy,page:assignedWorkflowsCurrentPage}))
    };
    
    
    const handleChangeCardsPerPage = (value) => {
      dispatch(setAssignedWorkflowCardsPerPage(value));
      dispatch(setAssignedWorkflowCurrentPage(1));
      dispatch(adminGetWorkflowsForVerifying({limit:assignedWorkflowsCardsPerPage,orderBy:assignedWorkflowsOrderBy,sortBy:assignedWorkflowsSortBy,page:assignedWorkflowsCurrentPage}))
    };

    const getStatusText = (statusEnum) => {
      switch (statusEnum) {
        case 'ASSIGNED':
          return "Assigned";
        case 'SUBMITTED':
          return "Submitted";
        case 'APPROVED':
          return "Approved";
        case 'REJECTED':
          return "Rejected";
        case 'SIGNED':
          return "Signed";
        default:
          return "";
      }
    };
  
    const getStatusColor = (statusEnum) => {
      switch (statusEnum) {
        case 'ASSIGNED':
          return "orange";
        case 'SUBMITTED':
          return "blue";
        case 'APPROVED':
          return "#800080";
        case 'REJECTED':
          return "red";
        case 'SIGNED':
          return "green";
        default:
          return "black";
      }
    };

    const surveyDescription = (item) => (
      <div className='flex-center-direction-col'>
        <Row >
          <Col span={10} className='bold-font'>
            Workflow Name
          </Col>
          <Col span={2} className='bold-font'>
          : 
          </Col>
          <Col span={10}>
            {item.name && item.name.length > 15 ? item.name.substring(0, 15) + '...' : item.name}
          </Col>
        </Row>
        <Row>
            <Col span={10} className='bold-font'>
              Workflow Id
            </Col>
            <Col span={2} className='bold-font'>
            : 
            </Col>
            <Col span={10}>
              {item.workflowID}
            </Col>
        </Row>
      </div>
    );

    return(
    <MainLayout>
                    <Row justify={'space-between'} >
                      <h1>Assigned Workflows</h1>
                      <div className='flex-and-align-center' >
                            <Col>
                              <Space>
                                <Input
                                  size='large'
                                  placeholder='Search For Workflows'
                                  value={searchQuery}
                                  onChange={handleSearchInputChange}
                                />
                              </Space>
                            </Col>
                      </div>
                    </Row>
                      {searchQuery && filteredData.length === 0 && (
                        <h1 className='textalign-center'>No matching workflow found.</h1>
                      )}
                      {(!searchQuery || filteredData.length > 0) && (
                        <>
                          <Row gutter={[20,20]} className='cards-container'>
                          { isLoading ? <div className='flex-direction-col width-height-100perc'><Spinner/><h3>Loading...</h3></div>:
                            (searchQuery ? filteredData : (adminWorkflowArray)).map((item) => (
                              <Col key={item.workflowID} xs={24} sm={24} md={12} lg={8} xl={6}>
                                <Badge.Ribbon text={getStatusText(item.status)} color={getStatusColor(item.status)}>
                                  <Card
                                    cover={<div className='flex mt-3' ><img src="/assets/images/submit-survey-icon.png" alt="Survey" className='workflow-card-icon' /></div>}
                                    key={item.workflowID}
                                    className='workflow-card'
                                    hoverable
                                    onClick={() => toVerifyPage(item.workflowID)}
                                  >
                                    <Divider />
                                    {surveyDescription(item)}
                                  </Card>
                                </Badge.Ribbon>
                              </Col>
                            ))
                          }
                          </Row>
                          <Row justify='space-between' align='middle' className='mt-1'>
                                    <Col>
                                      <Select value={assignedWorkflowsCardsPerPage} onChange={handleChangeCardsPerPage}>
                                        <Option value={12}>12 per page</Option>
                                        <Option value={20}>20 per page</Option>
                                        <Option value={40}>40 per page</Option>
                                        <Option value={100}>100 per page</Option>
                                      </Select>
                                    </Col>
                                    <Col>
                                      <Pagination
                                        current={assignedWorkflowsCurrentPage}
                                        onChange={handleChangePage}
                                        pageSize={assignedWorkflowsCardsPerPage}
                                        total={totalWorkflowsCreatedByAdminCount}
                                      />
                                    </Col>
                          </Row>
                        </>
                      )}                    
    </MainLayout>
    )
 }
export default AdminAssignedSurveys;