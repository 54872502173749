import { createSlice } from "@reduxjs/toolkit";
const initialState= {
    submitWorkflowsCurrentPage:1,
    submitWorkflowsElePerPage:12,
    submitWorkflowsEleOrderBy:'createdAt',
    submitWorkflowsEleSortBy:'DESC',
    approveWorkflowsCurrentPage:1,
    approveWorkflowsElePerPage:12,
    approveWorkflowsEleOrderBy:'createdAt',
    approveWorkflowsEleSortBy:'DESC',
    viewWorkflowsCurrentPage:1,
    viewWorkflowsElePerPage:12,
    viewWorkflowsEleOrderBy:'createdAt',
    viewWorkflowsEleSortBy:'DESC',
    userTokenAccessHistoryCurrentPage:1,
    userTokenAccessHistoryElePerPage:10,
    userTokenAccessHistoryEleOrderBy:'createdAt',
    userAccessHistoryCurrentPage:1,
    userAccessHistoryElePerPage:10,
    userAccessHistoryEleOrderBy:'createdAt',
    internalUserTokenHistoryCurrentPage:1,
    internalUserTokenHistoryElePerPage:10,
    internalUserTokenHistoryEleOrderBy:'createdAt',
    internalUserAccessHistoryCurrentPage:1,
    internalUserAccessHistoryElePerPage:10,
    internalUserAccessHistoryEleOrderBy:'createdAt'
}; 
const userSlice = createSlice({
    name:"user",
    initialState: initialState,
    reducers:{
        setSubmitWorkflowsCurrentPage : (state,{payload})=>{
            state.submitWorkflowsCurrentPage = payload;
        },
        setSubmitWorkflowsElePerPage : (state,{payload})=>{
            state.submitWorkflowsElePerPage = payload;
        },
        setSubmitWorkflowsSortBy : (state,{payload})=>{
            state.submitWorkflowsEleSortBy = payload;
        },
        setApproveWorkflowsCurrentPage : (state,{payload})=>{
            state.approveWorkflowsCurrentPage = payload;
        },
        setApproveWorkflowsElePerPage : (state,{payload})=>{
            state.approveWorkflowsElePerPage = payload;
        },
        setApproveWorkflowsSortBy : (state,{payload})=>{
            state.approveWorkflowsEleSortBy = payload;
        },
        setViewWorkflowsCurrentPage : (state,{payload})=>{
            state.viewWorkflowsCurrentPage = payload;
        },
        setViewWorkflowsElePerPage : (state,{payload})=>{
            state.viewWorkflowsElePerPage = payload;
        },
        setViewWorkflowsSortBy : (state,{payload})=>{
            state.viewWorkflowsEleSortBy = payload;
        },
        setTokenAccessHistoryCurrentPage : (state,{payload})=>{
            state.userTokenAccessHistoryCurrentPage = payload;
        },
        setTokenAccessHistoryElePerPage : (state,{payload})=>{
            state.userTokenAccessHistoryElePerPage = payload;
        },
        setAccessHistoryCurrentPage : (state,{payload})=>{
            state.userAccessHistoryCurrentPage = payload;
        },
        setAccessHistoryElePerPage : (state,{payload})=>{
            state.userAccessHistoryElePerPage = payload;
        },
        setInternalUserTokenHistoryCurrentPage : (state,{payload})=>{
            state.internalUserTokenHistoryCurrentPage = payload;
        },
        setInternalUserTokenHistoryElePerPage : (state,{payload})=>{
            state.internalUserTokenHistoryElePerPage = payload;
        },
        setInternalUserAccessHistoryCurrentPage : (state,{payload})=>{
            state.internalUserAccessHistoryCurrentPage = payload;
        },
        setInternalUserAccessHistoryElePerPage : (state,{payload})=>{
            state.internalUserAccessHistoryElePerPage = payload;
        }
    },
});
export const {setSubmitWorkflowsCurrentPage,setSubmitWorkflowsElePerPage,setSubmitWorkflowsSortBy,setApproveWorkflowsCurrentPage,setApproveWorkflowsElePerPage,setApproveWorkflowsSortBy,setViewWorkflowsCurrentPage,setViewWorkflowsElePerPage,setViewWorkflowsSortBy,setTokenAccessHistoryCurrentPage,setTokenAccessHistoryElePerPage,setAccessHistoryCurrentPage,setAccessHistoryElePerPage,setInternalUserTokenHistoryCurrentPage,setInternalUserTokenHistoryElePerPage,setInternalUserAccessHistoryCurrentPage,setInternalUserAccessHistoryElePerPage} = userSlice.actions;
export default userSlice.reducer;