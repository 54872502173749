import React from 'react';
import MainLayout from '../../components/MainLayout/MainLayout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { allFormDataForAdmin } from '../../redux/actions/eWarrant';
import Spinner from '../../components/Spinner/Spinner';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { Button, Col, Row } from 'antd';
import '@react-pdf-viewer/core/lib/styles/index.css';
import CustomSkeleton from '../../components/CustomSkeleton/CustomSkeleton';
import { removeCookie } from '../../utils/cookieUtils';
import Cookies from 'js-cookie';
import { resetTokenUserData } from '../../redux/slices/erc4907ContractSlice';

const ExternalUserViewASurveyByAdmin = () => {
  const sessionCookie = Cookies.get('session');
  let { formId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const eWarrentData = {formId,contract_address: location.state.contract_address}
    dispatch(allFormDataForAdmin(eWarrentData)).then(() => {
    setIsLoading(false); // Set loading state to false after data is fetched
    })
  }, [dispatch, formId,location.state.contract_address]);

  const { viewSignedSurveyUrl } = useSelector((state) => state.contract);

  const renderLoader = () => (
    <div className='flex height-100perc'>
      <Spinner />
    </div>
  );
  const clearSessionHandler = () => {
    removeCookie('session');
    removeCookie('sessionExpiry');
    dispatch(resetTokenUserData());
    navigate('/dashboard');
  }
  
  useEffect(()=>{
    sessionCookie===undefined && navigate('/dashboard');
  },[navigate,sessionCookie]);


  return (
      <MainLayout>
                <div className='flex-between-center'>
                  <h1>View Signed Workflow</h1>
                  <Button type='primary' danger size='large' onClick={clearSessionHandler} >Clear Session</Button>
                </div>
                {isLoading ? <CustomSkeleton /> :
                  viewSignedSurveyUrl!=='' ?
                  <Row justify={'center'}>
                    <Col span={22}>
                      <div className='height-500'>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                          <Viewer fileUrl={viewSignedSurveyUrl} renderLoader={renderLoader} />
                        </Worker>
                      </div>
                    </Col>
                  </Row>
                  : <h1 className='textalign-center w-100perc'>All the required approvers haven't signed survey pdf .</h1>
                }
      </MainLayout>
  );
};

export default ExternalUserViewASurveyByAdmin;