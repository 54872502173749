import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { Link } from 'react-router-dom';

const items = [
  {
    label: <Link to="/assigned-workflows">Assigned</Link>,
    key: '0',
  },
  {
    label: <Link to="/manage-roles">Revoke Roles</Link>,
    key: '1',
  },
  {
    label: <Link to="/all-users">All Users</Link>,
    key: '2',

  },
  {
    type: 'divider',
  },
  {
    label: <Link to="/admin-dashboard">Admin Dashboard</Link>,
    key: '3',
  }
];
const LinkDropdown = () => (
  <Dropdown
    menu={{
      items,
    }}
    trigger={['click']}
  >
    {/* <a onClick={(e) => e.preventDefault()}> */}
      <Space style={{cursor:"pointer",fontSize:"20px",color:'#ffffff'}}>
        <div style={{color:'#ffffff'}}>Explore</div>
        <DownOutlined />
      </Space>
    {/* </a> */}
  </Dropdown>
);
export default LinkDropdown;
