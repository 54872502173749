import { Form , Button, Modal, Space, Spin } from 'antd'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkExternalUser } from '../../redux/actions/template';
import CheckValidTokenUserComponent from '../../components/CheckValidTokenUserComponent/CheckValidTokenUserComponent';
import { useNavigate } from 'react-router-dom';
import TokenHistoryComponent from '../../components/TokenHistoryComponent/TokenHistoryComponent';
import AccessHistoryComponent from '../../components/AccessHistoryComponent/AccessHistoryComponent';
import SignBeforeAccessingSurveysComponent from '../../components/SignBeforeAccessingSurveysComponent/SignBeforeAccessingSurveysComponent';
import { openNotificationWithIcon } from '../../utils/openNotificationWithIcon';
import { removeCookie } from '../../utils/cookieUtils';
import Cookies from 'js-cookie';
import { resetTokenUserData } from '../../redux/slices/erc4907ContractSlice';
import './UserDashboard.scss';
const UnInvitedUserDashboardUI = () => {
    const [form] = Form.useForm();
    const {externalUserSignedAccessSurveyMessage} = useSelector(state => state.erc4907Contract);
    const sessionCookie = Cookies.get('session');
    console.log("sessionCookie :",sessionCookie);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
      dispatch(resetTokenUserData());
      form.resetFields();
    };
    const [isModal1Open, setIsModal1Open] = useState(false);
    const showModal1 = () => {
      setIsModal1Open(true);
    };
    const handleOk1 = () => {
      setIsModal1Open(false);
    };
    const handleCancel1 = () => {
      setIsModal1Open(false);
    };
    const [isModal2Open, setIsModal2Open] = useState(false);
    const showModal2 = () => {
      setIsModal2Open(true);
    };
    const handleOk2 = () => {
      setIsModal2Open(false);
    };
    const handleCancel2 = () => {
      openNotificationWithIcon('warning',"Can't Access Workflows Before Signing");
      setIsModal2Open(false);
    };
    const {ERC4907TokenUser,ERC4907TokenOwner,ERC4907TokenUserExpiry,ERC4907TokenId} = useSelector(state=> state.erc4907Contract);
    const {isLoggedInUserExternalUser,isLoggedInUserExternalUserLoading} = useSelector(state => state.template);
    const navigate = useNavigate();
    const user = localStorage.getItem('user');
    const email = localStorage.getItem('email');

    const dispatch = useDispatch();
    
    useEffect(()=>{
      dispatch(checkExternalUser(email))
    },[dispatch,email]);
  
    const viewSurveysAsExternalUser = () => {
      showModal2();
    }
    useEffect(()=>{
      externalUserSignedAccessSurveyMessage===true && setIsModal2Open(false);
      externalUserSignedAccessSurveyMessage===true && setIsModalOpen(false);
    },[externalUserSignedAccessSurveyMessage,ERC4907TokenOwner,navigate,ERC4907TokenUserExpiry]);


    useEffect(()=>{
      ERC4907TokenOwner!==''&& sessionCookie==='active' && navigate('/view-workflows-externaluser',{state:{tokenOwner:ERC4907TokenOwner}});
    },[ERC4907TokenOwner,navigate,sessionCookie]);

    const clearSessionHandler = () => {
      removeCookie('session');
      removeCookie('sessionExpiry');
      dispatch(resetTokenUserData());
      window.location.reload();
    }
    const userName = localStorage.getItem('name');
    const [selectedTable, setSelectedTable] = useState('table1'); // Default selection
    const handleTableSelect = (tableName) => {
      setSelectedTable(tableName);
    };
  
    const loadedUI = <div>
                        {userName && <div className='user-dashboard-greeting-container'>
                                        <div className='user-dashboard-greeting'>Hello {userName}!</div>
                                        {isLoggedInUserExternalUser &&
                                          <div className='externaluser-action-btns flex-end-center'>
                                            <Space direction='horizontal'>
                                              {sessionCookie===undefined && <Button type='primary' size='large' onClick={showModal}>Get Access To Vault</Button>}
                                              <Button type='primary' size='large' onClick={showModal1}>History</Button>
                                              {sessionCookie==='active' && <Button type='primary' danger size='large' onClick={clearSessionHandler} >Clear Session</Button>}
                                            </Space>
                                          </div>
                                        }
                                      </div>
                        }
                        <div className='externaluser-action-btns-mobile'>
                                            <Space direction='horizontal'>
                                              {sessionCookie===undefined && <Button type='primary' size='large' onClick={showModal}>Get Access To Vault</Button>}
                                              <Button type='primary' size='large' onClick={showModal1}>History</Button>
                                              {sessionCookie==='active' && <Button type='primary' danger size='large' onClick={clearSessionHandler} >Clear Session</Button>}
                                            </Space>
                        </div>
                        {!isLoggedInUserExternalUser && <h2 className='textalign-center'>You weren't invited by any of the application Admins .</h2>}
                        <Modal maskClosable={false} footer={false} title="Get Viewing Access to User's workflows as an External User" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                            <CheckValidTokenUserComponent form={form}/>
                            { ERC4907TokenUser===user && 
                              <div>
                                <hr/>
                                <div>You are authorized to View Workflows .</div>
                                <Button type='primary' onClick={viewSurveysAsExternalUser}>View Workflows</Button>
                              </div>
                            }
                        </Modal>
                        <Modal width={'90%'} maskClosable={false} footer={false} title="External User History" open={isModal1Open} onOk={handleOk1} onCancel={handleCancel1}>
                        <Space>
                          <Button
                            type={selectedTable === 'table1' ? 'primary' : 'default'}
                            onClick={() => handleTableSelect('table1')}
                          >
                            Token History
                          </Button>
                          <Button
                            type={selectedTable === 'table2' ? 'primary' : 'default'}
                            onClick={() => handleTableSelect('table2')}
                          >
                            Access History
                          </Button>
                        </Space>
                        {selectedTable === 'table1' ? (
                            <TokenHistoryComponent />
                        ): <AccessHistoryComponent />}
                        </Modal>
                        <Modal maskClosable={false} footer={false} title="Sign Message" open={isModal2Open} onOk={handleOk2} onCancel={handleCancel2}>
                            <SignBeforeAccessingSurveysComponent tokenId={ERC4907TokenId} userExpiry={ERC4907TokenUserExpiry} tokenUser={ERC4907TokenUser} tokenOwner={ERC4907TokenOwner} />
                        </Modal>
                      </div>
    const loadingUI = <div className='flex h-70vh'><Spin/></div>
    return (
      isLoggedInUserExternalUserLoading ? loadingUI : loadedUI
    )
}

export default UnInvitedUserDashboardUI;