import { ArrowRightOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
const InvitedUserDashboardUI = () => {
    const navigate = useNavigate();
    const submitSurveyHandler = () => {
        navigate('/submit-workflows');
    }
    const approveSurveyHandler = () => {
        navigate('/approve-workflows');
    }
    const viewApprovedSurveysHandler = () => {
        navigate('/view-workflows');
    }
    const userName = localStorage.getItem('name');
    const manageAccessHandler = ()=>{
        navigate('/manage-access');
    }
    const isUserInvited = JSON.parse(localStorage.getItem('isUserInvited'));

    return (
        <>
        {userName && <div className='user-dashboard-greeting-container'><div className='user-dashboard-greeting'>Hello {userName}!</div>{isUserInvited && <Button onClick={manageAccessHandler}>Manage Access</Button>}</div>}
        <Row gutter={[0,16]} justify={'space-around'} className='userdashboard-main-row'>
            <>
            <Col xs={24} sm={18} md={18} lg={11} xl={11} className='submit-survey-card'>
                <div className='display-flex'>
                    <img src='/assets/images/submit-btn.png' alt='submit-survey-btn' className='user-dashboard-icons'/>
                    <div className='flex-and-align-center'>
                        <p className='dashboard-p-font'>Submit the information for which this address is assigned the role of submitter.</p>
                    </div>
                </div>
                <div className='flex-and-justify-right'>
                    <Button className='submit-survey-btn' size='large' onClick={submitSurveyHandler}>Submit <ArrowRightOutlined/></Button>
                </div>
            </Col>

            <Col xs={24} sm={18} md={18} lg={11} xl={11} className='approve-survey-card'>
                <div className='display-flex'>
                    <img src='/assets/images/approval-btn.png' alt='approve-survey-btn' className='user-dashboard-icons'/>
                    <div className='flex-and-align-center'>
                        <p className='dashboard-p-font'>Approve the information for which this address is assigned the role of approver.</p>
                    </div>
                </div>
                <div className='flex-and-justify-right'>
                    <Button className='approve-survey-btn' type="primary" size='large' onClick={approveSurveyHandler}>Approve <ArrowRightOutlined/></Button>
                </div>
            </Col>
            <Col xs={24} sm={18} md={18} lg={11} xl={11} className='view-approved-survey-card'>
                <div className='display-flex'>
                    <img src='/assets/images/view-approved-survey.png' alt='view-approve-survey-btn' className='user-dashboard-icons'/>
                    <div className='flex-and-align-center'>
                        <p className='dashboard-p-font'>View Approved Workflows .</p>
                    </div>
                </div>
                <div className='flex-and-justify-right'>
                    <Button className='view-approved-survey-btn' type="primary" size='large' onClick={viewApprovedSurveysHandler}>View <ArrowRightOutlined/></Button>
                </div>
            </Col>
            </> 
        </Row>  
        </>
    )
}

export default InvitedUserDashboardUI;