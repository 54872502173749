import { createSlice } from "@reduxjs/toolkit";
import {login , logout , user,getPrivateKey} from "../actions/web3Auth";

const initialState = {
    isAuthLoading:false,
    isMessage:null,
    isError:false,
    isSuccess:false,
    userAddr:"",
    isLogin:false,
    userInfo:{},
    isUserLogged:false,
    privateKey:'',
    isLogoutLoading:false,
};
const web3AuthSlice = createSlice({
    name:"web3Auth",
    initialState:initialState ,
    reducers:{
        resetWeb3AuthState: (state) => {
            return initialState;
        }
    },
    extraReducers:(builder) => {
        builder
                .addCase(login.pending,(state,action) => {
                    state.isAuthLoading = true;
                })
                .addCase(login.fulfilled,(state,action)=> {
                    state.isAuthLoading = false;
                    state.isLogin = true;
                    state.isUserLogged = true;
                })
                .addCase(login.rejected,(state,action)=> {
                    state.isAuthLoading = false;
                    state.isError = true;
                })
                .addCase(logout.pending,(state,action) => {
                    state.isLogoutLoading = true;
                })
                .addCase(logout.fulfilled,(state,action)=> {
                    state.isLogoutLoading = false;
                    state.isLogin = false;
                    state.userAddr = "";
                    state.isUserLogged = false;

                })
                .addCase(logout.rejected,(state,action)=> {
                    state.isLogoutLoading = false;
                    state.isError = true;
                })
                .addCase(user.pending,(state,action) => {
                })
                .addCase(user.fulfilled,(state,action)=> {
                    state.userInfo = {...state.userInfo,...action.payload}
                    state.isUserLogged = true;

                })
                .addCase(user.rejected,(state,action)=> {
                    state.isError = true;
                })
                .addCase(getPrivateKey.pending,(state,action) => {
                    console.log("getPrivateKey pending :");
                })
                .addCase(getPrivateKey.fulfilled,(state,action)=> {
                    state.privateKey = action.payload;
                    console.log("getPrivateKey fulfilled :");
                })
                .addCase(getPrivateKey.rejected,(state,action)=> {
                    state.isError = true;
                })
    }
});
export const {resetWeb3AuthState } = web3AuthSlice.actions;
export default web3AuthSlice.reducer;