import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Radio, Row, Select, Space, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation ,useNavigate } from 'react-router-dom';
import SurveyComments from '../../components/SurveyComments/SurveyComments';
import moment from 'moment';
import SurveyTimeline from '../../components/SurveyTimeline/SurveyTimeline';
import AttestationTimeline from '../../components/AttestationTimeline/AttestationTimeline';
import "./SubmitSurveyPage.scss";

const { Option } = Select;

const AlreadySubmittedSurvey = ({adminId,formId}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {submittedSurveyUIData} = useSelector((state)=>state.template);
  const {numConfirmationsRequired} = useSelector((state)=>state.contract);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const handleCommentCancel = () => {
	  setOpenCommentModal(false);
	};
  const showCommentModal = () => {
    setOpenCommentModal(true);
  };
  const toVerifyPage = () => {
    navigate(`/verify-workflow/${adminId}/${formId}`,{state:{adminId:location.state.adminId,contract_address: location.state.contract_address}});
  }
  const toViewSurveyPage = () => {
    navigate(`/view-workflow/${adminId}/${formId}`,{state:{adminId:location.state.adminId,contract_address: location.state.contract_address}});
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModal1Open, setIsModal1Open] = useState(false);
  const showModal1 = () => {
    setIsModal1Open(true);
  };
  const handleOk1 = () => {
    setIsModal1Open(false);
  };
  const handleCancel1 = () => {
    setIsModal1Open(false);
  };


  return (
    <>
    {Object.keys(submittedSurveyUIData).length > 0 &&
      <Row justify={'center'} className='submit-workflow-row-main'>
        <Col span={10} className='left-column'>
          <div className='left-column-title'>Title : <span>{submittedSurveyUIData.name}</span></div>
          <div className='left-column-desc'>Description : <span>{submittedSurveyUIData.description}</span></div>
          <br/>
          <Space direction="vertical" size="large">
            {
              location.pathname === `/submit-workflow/${adminId}/${formId}` && 
              <>
                <Button size='large' type='primary' className='brown-black-btn w-220 opacity-point8' onClick={showModal}>Workflow Timeline</Button>
                <Button size='large' type='primary' className='brown-black-btn w-220 opacity-point8' onClick={showModal1}>Attestation Timeline</Button>
              </>
            }
            {submittedSurveyUIData?.reason.length>0 && location.pathname === `/submit-workflow/${formId}` && <Button size='large' type="primary" className='brown-black-btn w-220 opacity-point8' onClick={showCommentModal}>View Comments</Button>
            }
            {
              submittedSurveyUIData.ApprovalSignature.length === numConfirmationsRequired && location.pathname === `/submit-workflow/${adminId}/${formId}` && <Button size='large' type="primary" className='brown-black-btn w-220 opacity-point8' onClick={()=>toVerifyPage()}>Verify Workflow</Button>
            }
            {
              submittedSurveyUIData.ApprovalSignature.length === numConfirmationsRequired && location.pathname === `/submit-workflow/${adminId}/${formId}` && <Button size='large' type="primary" className='brown-black-btn w-220 opacity-point8' onClick={()=>toViewSurveyPage()}>View Workflow</Button>
            }
          </Space>
          <Modal
            title="Approver Comments"
            open={openCommentModal}
            onCancel={handleCommentCancel}
            footer={false}
            maskClosable={false}
          >
            <SurveyComments comments={submittedSurveyUIData?.reason}/>
          </Modal>
          <Modal maskClosable={false} footer={false} title="Workflow Timeline" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}    bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
            <SurveyTimeline />
          </Modal>
          <Modal footer={false} title="Attestation Timeline" open={isModal1Open} onOk={handleOk1} onCancel={handleCancel1} bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
            <AttestationTimeline formId={formId}/>
          </Modal>
        </Col>
        <Col span={14} className='right-column survey-form-main'>
          <Form
                  id="my-form"
                  labelAlign="left"
                  layout="horizontal"
                  autoComplete="off"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  disabled={true} // submittedSurveyUIData.disabled
                >
                  {submittedSurveyUIData.fields?.map((field, index) => (

                      <Row key={index}>
                        <Col span={24}>
                          <Form.Item
                            key={index}
                            label={
                              <>
                                <span className="label-h">{field.label}</span>                            
                              </>
                            }
                            name={field.label}
                            wrapperCol={12}
                            colon={false}
                            hasFeedback
                            rules={[
                              {
                                required: field.required,
                                message: `Please Enter ${field.label} `,
                              },
                            ]}
                            initialValue={field.dataType===7 ? moment(field.value) : field.value}
                          >
                              {(field.dataType === 0 && (
                                    <Input
                                      placeholder={field.placeholder || `Enter A ${field.label}`}
                                      size="middle"
                                      width={400}
                                    />
                                  ))
                                  ||
                                  (field.dataType === 8 && (
                                    <Input
                                      placeholder={field.placeholder || `Enter A ${field.label}`}
                                      size="middle"
                                      width={400}
                                    />
                                  ))
                                   ||
                                    (field.dataType === 4 && (
                                      <Upload 
                                      >
                                        <Button icon={<UploadOutlined />}>
                                          Click to Upload
                                        </Button>
                                      </Upload>
                                    )) ||
                                    (field.dataType === 1 && (
                                      <TextArea
                                        placeholder={
                                          field.placeholder || `Enter A ${field.label}`
                                        }
                                        size="small"
                                        width={200}
                                      />
                                    )) ||
                                    (field.dataType === 9 && (
                                      <InputNumber
                                        // controls={false}
                                        disabled={true}
                                        className='w-100perc'
                                        placeholder={field.placeholder || `Enter A ${field.label}`}
                                        maxLength={10}
                                        minLength={10}
                                      />
                                    ))
                                    ||
                                    (field.dataType === 7 && (
                                      <DatePicker
                                        placeholder={`Enter ${field.label}`}
                                        className='w-100perc'
                                      ></DatePicker>
                                    )) ||
                                    (field.dataType === 3 && (
                                      <Radio.Group
                                      >
                                        {field.options.map((item, index) => (
                                          <Radio key={item} value={item}>{item}</Radio>
                                        ))}
                                      </Radio.Group>
                                    )) ||
                                    (field.dataType === 5 && (
                                      <Checkbox.Group
                                      >
                                        {field.options.map((item, index) => (
                                          <Checkbox key={item} value={item}>
                                            {item}
                                          </Checkbox>
                                        ))}
                                      </Checkbox.Group>
                                    )) ||
                                    (field.dataType === 6 && field.typeOfOptions === "Single" && (
                                      <Select placeholder={`Enter ${field.label}`} 
                                      >
                                        {field.options.map((item, index) => (
                                          <Option key={item} value={item}>
                                            {item}
                                          </Option>
                                        ))}
                                      </Select>
                                    )) ||
                                    (field.dataType === 6 && field.typeOfOptions === "Multiple" && (
                                      <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder={`Enter ${field.label}`}
                                      >
                                        {field.options.map((item, index) => (
                                          <Option key={item} value={item}>
                                            {item}
                                          </Option>
                                        ))}
                                      </Select>
                                    ))}
                          </Form.Item>
                        </Col>
                      </Row>

                  ))}
          </Form>
        </Col>                            
      </Row> 
    }
    </>
  )
}

export default AlreadySubmittedSurvey;