import { useState,useEffect } from "react";
import { Form , Card , Row , Col,Divider, Button, Menu, Popover,Modal, Select, Space, Input, Pagination} from 'antd';
import "./AdminDashboard.scss";
import {ExclamationCircleFilled , EllipsisOutlined} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { adminEmailToWalletAddress, deletetemplate, getTemplates, geteWarrentAddressByAdminId } from "../../redux/actions/template";
import { dateFormatter } from "../../utils/dateFormatter";
import { assignSurveyData, resetWorkflowCreationRelatedStates } from "../../redux/slices/adminSlice";
import { createTemplateModal, resetLatestWorkflowCreatedId, templateDrawer } from "../../redux/slices/templateSlice";
import TemplateDrawer from "../../components/TemplateTable/TemplateDrawer";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import CreateSurveyModal from "../../components/CreateSurveyModal/CreateSurveyModal";
import AssignSurveyModal from "../../components/AssignSurveyModal/AssignSurveyModal";
import Spinner from "../../components/Spinner/Spinner";
import MainLayout from "../../components/MainLayout/MainLayout";
import { setDashboardCardsPerPage,setDashboardCurrentPage } from "../../redux/slices/adminSlice";
const { confirm } = Modal;
const { Option } = Select;

const AdminDashboard = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const adminId = localStorage.getItem('adminId');
    const {isDrawerVisible,createTempModal,type,isAdminTemplatesLoading,templateCount} = useSelector(state => state.template);
    const {dashboardCurrentPage,dashboardCardsPerPage,arrayOrderBy,arraySortBy} = useSelector(state => state.admin);
    const {isFormDataSet} = useSelector(state =>state.contract);
    const {templateTableData} = useSelector(state=>state.template);
    console.log("templateTableData :",templateTableData);
    const email = localStorage.getItem('email');
    const user = localStorage.getItem('user');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {adminObjForAdmin} = useSelector((state)=> state.template);
    const [popoverVisible, setPopoverVisible] = useState({});
    const [isModal1Open, setIsModal1Open] = useState(false);
  

    useEffect(()=>{
      adminId && dispatch(getTemplates({adminId,sortBy:arraySortBy,orderBy:arrayOrderBy,page:dashboardCurrentPage,limit:dashboardCardsPerPage}));
    },[dispatch,adminId,arraySortBy,dashboardCurrentPage,dashboardCardsPerPage,arrayOrderBy]);

    useEffect(()=>{
      const data = {email:email,address:user}
      dispatch(adminEmailToWalletAddress(data));
    },[dispatch,email,user]);
    
    const handleMenuItemClick = (data,key) => {
        key==="view" &&  dispatch(templateDrawer({ record: data, visible: true }));
        key==="edit" &&  dispatch(createTemplateModal({visible: true, record: data , type: "edit"}));
        // key==="delete" && showDeleteConfirm(data);
        setPopoverVisible(false);
    };
  
    // const showDeleteConfirm = (record) => {
    //     confirm({
    //       title: "Are you sure delete this Template?",
    //       icon: <ExclamationCircleFilled />,
    //       content: "",
    //       okText: "Yes",
    //       okType: "danger",
    //       cancelText: "No",
    //       onOk() {
    //         onDelete(record);
    //       },
    //       onCancel() {},
    //     });
    // };
    
    // const onDelete = (record) => {
    //     dispatch(deletetemplate(record._id));
    // }

    const menuItemsEllipse = [
      {label:"View Workflow",key:'view'},
      {label:"Edit Workflow",key:'edit'},
      // {label:"Delete Workflow",key:'delete'}
    ];

    const optionsMenu = (data) => <Menu items={menuItemsEllipse} onClick={(e) => handleMenuItemClick(data,e.key)}/>;

    const surveyDescription = (item) => (
        <>
          <Row>
            <Col span={8} className="bold-font" >
              Name
            </Col>
            <Col span={2} className="bold-font" >
            : 
            </Col>
            <Col span={14}>
              {/* {item.name && item.name.length > 15 ? item.name.substring(0, 15) + '...' : item.name} */}
              {item.templatesVersion[item.templatesVersion.length -1]?.name && item.templatesVersion[item.templatesVersion.length -1]?.name.length > 15 ? item.templatesVersion[item.templatesVersion.length -1]?.name.substring(0, 15) + '...' : item.templatesVersion[item.templatesVersion.length -1]?.name}
            </Col>
          </Row>
          <Row>
            <Col span={8} className="bold-font" >
                Modified
            </Col>
            <Col span={2} className="bold-font" >
              : 
            </Col>
            <Col span={14}>
                {item.updatedAt ? dateFormatter(item.updatedAt) : "-"}
            </Col>
          </Row>
          <Row justify={'center'}>
          <Button className="assign-survey-btn" onClick={()=>showModal1(item.templatesVersion[item.templatesVersion?.length-1]?.name)}>Assign Workflow</Button>
          </Row>
        </>
    );

    const handleSearchInputChange = (e) => {
      const query = e.target.value.toLowerCase();
      setSearchQuery(query);
      const filtered = templateTableData?.filter((item) => {
        const surveyName = item.templatesVersion[item.templatesVersion.length-1].name.toLowerCase();
        return (
          (surveyName.includes(query)));
      });
      setFilteredData(filtered);
    };
    
    
    const handleChangePage = (page) => {
      dispatch(setDashboardCurrentPage(page));
      setSearchQuery(''); // Reset search query when changing pages
      dispatch(getTemplates({adminId,sortBy:arraySortBy,orderBy:arrayOrderBy,page:page,limit:dashboardCardsPerPage}));
    };
    
    const handleChangeCardsPerPage = (value) => {
      dispatch(setDashboardCardsPerPage(value));
      dispatch(setDashboardCurrentPage(1));
      dispatch(getTemplates({adminId,sortBy:arraySortBy,orderBy:arrayOrderBy,page:1,limit:value}));
    };
  
    const showModal = () => {
      setIsModalOpen(true);
      dispatch(geteWarrentAddressByAdminId(adminId));
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
      dispatch(resetLatestWorkflowCreatedId());
      dispatch(resetWorkflowCreationRelatedStates());
      form.resetFields();
    };
  
    const showModal1 = (surveyName) => {
      setIsModal1Open(true);
      const data = {current : 0 , surveyNameAssignSurvey : surveyName}
      dispatch(geteWarrentAddressByAdminId(adminId));
      dispatch(assignSurveyData(data));
    };
    const handleOk1 = () => {
      setIsModal1Open(false);
    };
    const handleCancel1 = () => {
      setIsModal1Open(false);
      form.resetFields();
    };
    useEffect(() => {
      isFormDataSet && handleCancel();
    }, [isFormDataSet]);
    useEffect(() => {
      isFormDataSet && handleCancel1();
    }, [isFormDataSet]);
    const handlePopoverVisibleChange = (cardId, visible) => {
      setPopoverVisible(prevState => ({
        ...prevState,
        [cardId]: visible,
      }));
    };
    
    return(
      <MainLayout>
                <Row justify={'space-between'} >
                  <h1>Created</h1>
                  <div className="flex-and-align-center">
                      <Space>
                        <Input
                          size="large"
                          placeholder='Search For Workflow Templates'
                          value={searchQuery}
                          onChange={handleSearchInputChange}
                          className="workflow-search"
                        />
                        {adminObjForAdmin.hasOwnProperty('contract_address') && <Button type="primary" size='large' className="create-workflow-btn" onClick={showModal}>Create Workflow</Button>}
                        <Modal maskClosable={false} title="Create Workflow" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className="create-workflow-modal" width={"90%"} style={{top:20}} footer={false}>
                          <CreateSurveyModal form={form}/>
                        </Modal>
                      </Space>
                  </div>
                </Row>
                {searchQuery && filteredData.length === 0 && (
                    <h1 className="textalign-center">No matching workflow template found.</h1>
                )}
                {(!searchQuery || filteredData.length > 0) && (
                  <>
                    <Row gutter={[20,20]} className="admin-dashboard-card-container" >
                        { isAdminTemplatesLoading ? <div className="flex-direction-col width-height-100perc" ><Spinner/><h3>Loading...</h3></div> :
                            (searchQuery ? filteredData : (templateTableData)).map((data)=> (
                                
                                <Col key={data._id} xs={24} sm={24} md={12} lg={8} xl={6}>
                                    <Card
                                        className="admin-assignsurvey-card"
                                        hoverable
                                        cover={<div className="flex mt-1rem" ><img src="/assets/images/submit-survey-icon.png" className="admin-dashboard-workflow-card" alt="Survey" /></div>}
                                        key={data._id}    
                                    >
                                        <div className="admin-dashboard-card-ellipse" style={{ position: 'absolute', top: 10, right: 10 ,cursor:"pointer"}}>
                                            <Popover placement="leftTop" content={() => optionsMenu(data)} open={popoverVisible[data._id]} onOpenChange={(visible) => handlePopoverVisibleChange(data._id, visible)}>
                                                <EllipsisOutlined />
                                            </Popover>
                                        </div>
                                        <Divider/>
                                        {surveyDescription(data)}
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                    <Row justify='space-between' align='middle' className="mt-1">
                        <Col>
                            <Select value={dashboardCardsPerPage} onChange={handleChangeCardsPerPage}>
                              <Option value={12}>12 per page</Option>
                              <Option value={20}>20 per page</Option>
                              <Option value={40}>40 per page</Option>
                              <Option value={100}>100 per page</Option>
                            </Select>
                        </Col>
                        <Col>
                            <Pagination
                              showSizeChanger={false}
                              hideOnSinglePage={true}
                              current={dashboardCurrentPage}
                              onChange={handleChangePage}
                              pageSize={dashboardCardsPerPage}
                              total={searchQuery ? filteredData?.length : ( templateCount )}
                            />
                        </Col>
                    </Row>
                  </>
                )}

                {
                  isModal1Open && 
                  <Modal maskClosable={false} title="Assign Workflow" open={isModal1Open} onOk={handleOk1} onCancel={handleCancel1} width={"90%"} style={{top:20}} className="create-workflow-modal" footer={false}>
                      <AssignSurveyModal form={form} />
                  </Modal>
                }
                {createTempModal && <ModalComponent type={type} createTempModal={createTempModal} />}
                {isDrawerVisible && <TemplateDrawer />}
      </MainLayout>
    )
}

export default AdminDashboard;