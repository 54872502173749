import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getExternalUserAccessSignatureHistory } from '../../redux/actions/template';
import { dateFormatter, unixEpochToIST } from '../../utils/dateFormatter';
import { Col, Pagination, Row, Select, Space, Spin, Table } from 'antd';
import "../../styles/main.scss";
import { setAccessHistoryCurrentPage, setAccessHistoryElePerPage } from '../../redux/slices/userSlice';
const {Option} = Select;

const AccessHistoryComponent = () => {
  const user = localStorage.getItem('user');
  const {userAccessSignatureHistoryArrayLoading,userAccessSignatureHistoryArray,userAccessSignatureHistoryCount} = useSelector(state => state.template);
  console.log("userAccessSignatureHistoryCount :",userAccessSignatureHistoryCount);
  const { userAccessHistoryCurrentPage,userAccessHistoryElePerPage,userAccessHistoryEleOrderBy } = useSelector((state)=>state.user);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getExternalUserAccessSignatureHistory({user,page:userAccessHistoryCurrentPage,limit:userAccessHistoryElePerPage,orderBy:userAccessHistoryEleOrderBy}));
  },[dispatch,user,userAccessHistoryCurrentPage,userAccessHistoryElePerPage,userAccessHistoryEleOrderBy]);

  const columns = [
    {
        title: 'Token Id',
        dataIndex: 'tokenId',
        key: 'tokenId',
        width:'200px',
        render: (_, item) => {
          return item.objSigned.tokenId;
        },    
    },
    {
      title: 'Accessed At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width:'200px',
      render: (_, item) => {
        return dateFormatter(item.createdAt && item.createdAt);
      },    
    },
    {
      title: 'Token Expiry',
      dataIndex: 'userExpires',
      key: 'userExpires',
      width:'200px',
      render: (_, item) => {
        return unixEpochToIST(item.objSigned.userExpiry && item.objSigned.userExpiry);
      }
    }
  ];
  
  const handleChangePage = (page) => {
    dispatch(setAccessHistoryCurrentPage(page));
    dispatch(getExternalUserAccessSignatureHistory({user,page:page,limit:userAccessHistoryElePerPage,orderBy:userAccessHistoryEleOrderBy}));
  };

  const handleChangeElePerPage = (value) => {
    dispatch(setAccessHistoryElePerPage(value));
    dispatch(setAccessHistoryCurrentPage(1));
    dispatch(getExternalUserAccessSignatureHistory({user,page:1,limit:value,orderBy:userAccessHistoryEleOrderBy}));
  };
  
  return (
    <div>
      {
        userAccessSignatureHistoryArrayLoading ? <div className='externaluser-historytable-spincontainer flex'><Spin/></div>:
        <>
          <Table pagination={false} columns={columns} dataSource={userAccessSignatureHistoryArray && userAccessSignatureHistoryArray} rowKey={(record) => record.createdAt} className='externaluser-historytablestyle'/>
          <Row justify='space-between' align='middle' className="mt-1">
            <Col>
              <Space>
                <Select value={userAccessHistoryElePerPage} onChange={handleChangeElePerPage}>
                  <Option value={10}>10 per page</Option>
                  <Option value={20}>20 per page</Option>
                  <Option value={40}>40 per page</Option>
                  <Option value={100}>100 per page</Option>
                </Select>
              </Space>
            </Col>
            <Col>
              <Pagination
                showSizeChanger={false}
                hideOnSinglePage={true}
                current={userAccessHistoryCurrentPage}
                onChange={handleChangePage}
                pageSize={userAccessHistoryElePerPage}
                total={userAccessSignatureHistoryCount} 
              />
            </Col>
          </Row>

        </>
      }
    </div>
  )

}

export default AccessHistoryComponent;