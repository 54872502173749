import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { allEWarrentDataForSuperAdmin } from '../../redux/actions/eWarrant';
import Spinner from '../../components/Spinner/Spinner';
import MainLayout from '../../components/MainLayout/MainLayout';

const AdminInfoPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const adminemail = queryParams.get('adminemail');
  const adminObj = location.state.admin;
  console.log("admin obj :",adminObj);
  const {totalSurveysCreatedByAdmin,adminSurveysArrayOfObjLoading,adminSurveysArrayOfObj} = useSelector(state => state.contract);

  const columns = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'User Address',
      dataIndex: 'address',
      key: 'address',
    },
  ];
  
  const dispatch = useDispatch();
  useEffect(()=>{
    adminObj.contract_address && dispatch(allEWarrentDataForSuperAdmin(adminObj.contract_address));
  },[dispatch,adminObj.contract_address]);

  const toSeeSurveys = () => {
    navigate(`/superadmin-dashboard/adminWorkflows?adminemail=${adminemail}`,{state:{contract_address:adminObj.contract_address,adminAddress:adminObj.address,adminId:adminObj._id}});
  }

  return (
    <MainLayout>
          <h1>Admin Details</h1>
            <>
              <Row>
                <Col span={8}>Email</Col><Col span={16}>{adminemail}</Col>
              </Row>
              <br/>
              <Row>
                <Col span={8}>EOA Address</Col><Col span={16}>{adminObj.address}</Col>
              </Row>
              <br/> 
              <Row>
                <Col span={8}>xVault Address</Col><Col span={16}>{adminObj.contract_address}</Col>
              </Row>
              <br/> 
              {/* <Row>
                <Col span={8}>Total Workflows Assigned</Col><Col span={16}>{totalSurveysCreatedByAdmin}</Col>
              </Row>
              <br /> */}
              <Button onClick={()=>toSeeSurveys()}>View Workflows</Button>
              <Table
                columns={columns}
                dataSource={adminObj.users}
                rowKey={(record) => record._id}
              />
            </>
    </MainLayout>
  );
};

export default AdminInfoPage;