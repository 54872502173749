import React, { useEffect } from 'react'
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { inviteExternalUser } from '../../redux/actions/template';
import "../../styles/main.scss";

const InviteExternalUserComponent = ({form}) => {
    const dispatch = useDispatch();
    const {isInviteExternalUserSuccess,isInviteExternalUserFailed} = useSelector(state => state.template);
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!',
        types: {
        email: '${label} is not a valid email!',
        },
    };
    const layout = {
        labelCol: {
          span: 24,
        },
        wrapperCol: {
          span: 24,
        },
    };  

    const onFinish = (values) => {
        console.log(values);
        dispatch(inviteExternalUser(values));
    };

    useEffect(()=>{
        isInviteExternalUserSuccess && form.resetFields();
    },[isInviteExternalUserSuccess,form]);

    const getValidateStatus = () => {
        if (isInviteExternalUserFailed) {
          return 'error';
        }
        return '';
    };
    return (
            <Form
                form={form}
                {...layout}
                name="nest-messages"
                onFinish={onFinish}
                labelAlign='left'
                // requiredMark={false}
                className='invitation-form'
                validateMessages={validateMessages}
                colon={false}
            >
            <Form.Item
            name='email'
            label="Email"
            required={true}
            rules={[
                {
                type: 'email',
                },
            ]}
            validateStatus={getValidateStatus()}
            >
                <Input required type='email' placeholder="Enter External User's Email Here" />
            </Form.Item>
            <Form.Item
            >
            <Button type="primary" htmlType="submit" block>
                Submit
            </Button>
            </Form.Item>
        </Form>

    )
}

export default InviteExternalUserComponent