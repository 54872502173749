import React from 'react';
import { useSelector } from "react-redux";
import { Table } from 'antd';

const ReviewCreatedSurvey = () => {
  const { surveyId, submitter, approvers, confirmations, surveyName , workflowVersion} = useSelector((state) => state.admin);

  const dataSource = [
    { key: 'surveyName', label: 'Workflow Name', value: surveyName },
    { key: 'surveyId', label: 'Workflow Id', value: surveyId },
    { key: 'workflowVersion', label: 'Workflow Version', value: workflowVersion },
    { key: 'submitter', label: 'Dataset Provider', value: submitter },
    { key: 'approvers', label: 'Dataset Approver(s)', value: (
      <span>
        {approvers.map((approver, index) => (
          <React.Fragment key={approver}>
            {approver}
            {index !== approvers.length - 1 && <br />}
          </React.Fragment>
        ))}
      </span>
    )},
    { key: 'confirmations', label: 'Confirmations', value: confirmations },
  ];

  const columns = [
    { title: 'Label', dataIndex: 'label', key: 'label' },
    { title: 'Value', dataIndex: 'value', key: 'value' },
  ];

  return (
    <div className='assign-submitter-main'>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
      />
    </div>
  );
}

export default ReviewCreatedSurvey;