import axios from "axios";
import { useEffect, useState } from "react";
import "./SubmitSurveyList.scss";
import Layout from "../Layout/Layout"
import { dateFormatter, dateTimeFormatter } from "../../utils/dateFormatter";
const PDFCodeNew = () => {
  const [formData, setFormData] = useState({});

  const getFormData = async () => {
    const res = await axios.get(
      "http://144.24.96.77:5000/api/userTemplates/templates?formId=1235068317011"
      // 1235068317011 // 24 july survey 
      // 1230450792912 // old surveys all below
      // 1236585785660
      // 1230450792912
    );
    console.log("res :", res.data.data[0]);
    setFormData(res.data.data[0]);
  };

  useEffect(() => {
    getFormData();
  }, []);

  return (
    <Layout>
    <div className="container-main">
    <div style={{overflowY:'scroll',maxHeight:'70vh'}}>
      <div className="surveyInfo">
        <div class="infoRow">
          <div style={{fontSize:'20px',fontStyle:"bold"}}>Title</div>
          <div style={{fontWeight:'light'}} className="surveyValues">{formData.name}</div>
        </div>
        <div class="infoRow">
          <div style={{fontSize:'20px',fontStyle:"bold"}}>Description</div>
          <div style={{fontWeight:'light'}} className="surveyValues">{formData.description}</div>
        </div>
        <div class="infoRow">
          <div style={{fontSize:'20px',fontStyle:"bold"}}>Form Id</div>
          <div style={{fontWeight:'light'}} className="surveyValues">{formData.formId}</div>
        </div>
      </div>
      <div className="grid-container">
        {formData.fields?.map((field, index) => (
          <div key={index} className="grid-item">
            <label className="label">Q{index+1} : {field.label}</label>
            <input className="value padded-input" value={field.value} readOnly disabled  />
          </div>
        ))}
      </div>
      <div className="approver-signatures-container">
        {
          formData.ApprovalSignature?.map((approversig,index)=>(
            <>
            <div className="each-approver-signature">
              <img src={approversig.link} alt={`approver-${index+1}-signature`} />
              <p>Name : {approversig.approver_name}</p>
              <p>Email : {approversig.approver_email}</p>
              {/* Address will be removed from the new code and Approver name , email will be added */}
              <p>Address : {approversig.approver_address}</p> 
              <p>Date : {approversig.createdAt? dateFormatter(approversig.createdAt) : ''}</p>
            </div>
            </>
          ))
        }
      </div>
      </div>

    </div>

    </Layout>
  );
};

export default PDFCodeNew;