import { Web3Auth } from "@web3auth/modal";
import {CHAIN_NAMESPACES, WEB3AUTH_NETWORK} from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
const clientId = "BI2e_4o6juGngJwQrMlP7psJwV3AlPkXW6qZILX5_QcWGhWtGF84tjHpfpmpFKBEMar1YKSOIcayP0mOwDroWSc";
// const clientId = "BD3vIBSjU-iBloIziKaBukUvWsi4UICi-Ojsfm3YJpwZvLloAoBjt8pFSVD6aRHHNZJQ-tBAz7DtwuBvd59rpEY"; // testnet network // deprecated as on 4th March 2024
// const clientId = 'BHTqd4-8jVWeFq7E2elYY6h4WlbxaIIsf4Kj78l1nE-zXyOEKo2Ayr_kEzmV8dbf04Sl_RKJE0ZOETPeMQNjNTk'; // aqua network
export const chainIds = ['0x33','0x13881'];
export const blockExplorers = ['https://apothem.xinfinscan.com','https://mumbai.polygonscan.com'];
export const rpcTargetURLs = ['https://erpc.apothem.network','https://polygon-mumbai.infura.io/v3/1b50862ccbe34a7f90bba5f38a4d08d0'];

const chainConfig = {
    chainId: chainIds[1], // Please use 0x1 for Mainnet
    rpcTarget: rpcTargetURLs[1],
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    displayName: "Matic Testnet",
    blockExplorer: blockExplorers[1],
    ticker: "MATIC",
    tickerName: "MATIC"
};
  
const privateKeyProvider = new EthereumPrivateKeyProvider({
    config: { chainConfig: chainConfig }
});
  
export const web3auth = async() => {
    try {
        const web3auth = new Web3Auth({
            clientId: clientId,
            web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
            privateKeyProvider: privateKeyProvider
        });    
        await web3auth.initModal();
    return web3auth;
    } catch(error) {
        console.log("web3auth utils web3auth main code error :",error);
    }
}