import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { approveSubmittedPDF, getFormStatus } from '../../redux/actions/eWarrant';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AlreadySubmittedSurvey from '../SubmitSurveyPage/AlreadySubmittedSurvey';
import { Button, Col, Input, Modal, Row, Space, Spin } from 'antd';
import { rejectSubmittedPDF } from '../../redux/actions/eWarrant';
import ElectronicSignature from '../ElectronicSignature/ElectronicSignature';
import SurveyComments from '../../components/SurveyComments/SurveyComments';
import SurveyTimeline from '../../components/SurveyTimeline/SurveyTimeline';
import CustomSkeleton from '../../components/CustomSkeleton/CustomSkeleton';
import "./ApproveSurveyPage.scss";
import { resetApprovedSurveyStatuses } from '../../redux/slices/eWarrantSlice';
import AttestationTimeline from '../../components/AttestationTimeline/AttestationTimeline';
import MainLayout from '../../components/MainLayout/MainLayout';

const { TextArea } = Input;

const ApproveSurveyPage = () => {

  const dispatch = useDispatch();
  let { formId } = useParams();
  const location = useLocation();

  const {approverSignedApprovedSurvey,rejectedSubmittedSurveyReceipt,approveSurveyReceiptStatus,isSurveyRejectionCommentLoading,contractData,formStatusEnum,isApprovePDFButtonLoading,isRejectSubmittedPDFLoading,numConfirmationsRequired,approverConfirmationCount,isSurveyApproved,isApprovedSurveySigned} = useSelector((state)=>state.contract);
  const [isLoading, setIsLoading] = useState(true); // Track initial loading state
  const {signatureUploadingAndPDFUploadingText,attestationTimelineData} = useSelector(state => state.template);
  useEffect(()=>{
    const eWarrentData = {formId:formId , contract_address: location.state.contract_address}
    dispatch(getFormStatus(eWarrentData)).then(() => {
      setIsLoading(false); // Set loading state to false after data is fetched
    });
  },[dispatch,formId,location.state.contract_address]);
  const {submittedSurveyUIData,isApproverSignatureUploadedSuccessful,isApproverSignatureUploading} = useSelector((state)=>state.template);
  const {loggedAccount} = contractData;
  const [commentText,setCommentText] = useState('');

  const approveSubmittedSurveyHandler = () =>{ // approve survey direct blockchain call hai !
      let eWarrentData = {formId:formId,contract_address:location.state.contract_address,dataToBeHashed:submittedSurveyUIData.fields,referencedAttestation : attestationTimelineData ? (attestationTimelineData[attestationTimelineData.length-1].location==="onchain" ? attestationTimelineData[attestationTimelineData.length-1].attestationUId  : "" ) : "",_id:location.state._id,whetherToPatchApproveWorkflowAPI:approverConfirmationCount === (numConfirmationsRequired-1)}
      dispatch(approveSubmittedPDF(eWarrentData));
  }

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    const data = {formId,"reason":commentText,"isRejected": true,contract_address:location.state.contract_address, dataToBeHashed:submittedSurveyUIData.fields ,referencedAttestation : attestationTimelineData ? (attestationTimelineData[attestationTimelineData.length-1].location==="onchain" ? attestationTimelineData[attestationTimelineData.length-1].attestationUId  : "" ) : "",_id:location.state._id};
    dispatch(rejectSubmittedPDF(data));
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const onCommentTextChange = (e) => {
    setCommentText(e.target.value);
  };

	const [openCommentModal, setOpenCommentModal] = useState(false);
  const handleCommentCancel = () => {
	  setOpenCommentModal(false);
	};
  const showCommentModal = () => {
    setOpenCommentModal(true);
  };  
  const navigate = useNavigate();

  useEffect(() => {
    if (rejectedSubmittedSurveyReceipt || approveSurveyReceiptStatus || approverSignedApprovedSurvey) {
      const navigationTimeout = setTimeout(() => {
        navigate(`/approve-workflow/${location.state.adminId}`, {
          state: { contract_address: location.state.contract_address },
        });
      }, 0);
  
      return () => {
        clearTimeout(navigationTimeout);
        dispatch(resetApprovedSurveyStatuses());
      };
    }
  }, [formId, navigate, location.state.adminId, location.state.contract_address, dispatch, approveSurveyReceiptStatus, approverSignedApprovedSurvey, rejectedSubmittedSurveyReceipt]);
  
  function checkIfAlreadySigned() {
    return submittedSurveyUIData.ApprovalSignature?.some(item => item.approver_address === loggedAccount);
  }
  const toVerifyPage = () => {
    navigate(`/verify-workflow/${location.state.adminId}/${formId}`,{state:{adminId:location.state.adminId,contract_address: location.state.contract_address}});
  }
  const [isModalxOpen, setIsModalxOpen] = useState(false);
  const showModalx = () => {
    setIsModalxOpen(true);
  };
  const handleOkx = () => {
    setIsModalxOpen(false);
  };
  const handleCancelx = () => {
    setIsModalxOpen(false);
  };
  const toViewSurveyPage = () => {
    navigate(`/view-workflow/${location.state.adminId}/${formId}`,{state:{adminId:location.state.adminId,contract_address: location.state.contract_address}});
  }
  const user = localStorage.getItem('user');
  useEffect(()=>{
    const spinner = document.getElementById('spinner-container');
    isApproverSignatureUploading===true && (spinner.style.display = 'block')
    isApproverSignatureUploadedSuccessful===true && (spinner.style.display = 'none')
  },[isApproverSignatureUploading,isApproverSignatureUploadedSuccessful]);
  const [isModalyOpen, setIsModalyOpen] = useState(false);
  const showModaly = () => {
    setIsModalyOpen(true);
  };
  const handleOky = () => {
    setIsModalyOpen(false);
  };
  const handleCancely = () => {
    setIsModalyOpen(false);
  };
  return (
    <MainLayout>
          <div className="spinner-container" id="spinner-container" style={{display:'none'}}>
            <div className='flex width-height-100perc'>
              <Spin size="large" tip={signatureUploadingAndPDFUploadingText}/>
            </div>
          </div>

        {isLoading ? <CustomSkeleton paragraph={{rows: 10}}/>:

          formStatusEnum===0 ? <h1 className='textalign-center'>Workflow is not submitted yet .</h1> 
          :  
          <>
              <Row>
                <Col xs={24} sm={24} md={24} lg={20} xl={20} className='approve-survey-content-container'>
                    <AlreadySubmittedSurvey formId={formId} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={4} xl={4} className='p-1rem'>
                  <h2>Actions :</h2>
                  <Space direction="vertical" size="large">

                    {!submittedSurveyUIData.isRejected && !isSurveyApproved && !(approverConfirmationCount === numConfirmationsRequired) && (
                      <Button
                        disabled={approverConfirmationCount === numConfirmationsRequired}
                        type="primary"
                        size='large'
                        className='green-black-btn w-220'
                        onClick={approveSubmittedSurveyHandler}
                        loading={isApprovePDFButtonLoading}
                      >
                        Approve Workflow
                      </Button>
                    )}

                    {!submittedSurveyUIData.isRejected && !isSurveyApproved && !(approverConfirmationCount === numConfirmationsRequired) && (
                      <Button
                        disabled={approverConfirmationCount === numConfirmationsRequired}
                        type="primary"
                        size='large'
                        className='red-black-btn w-220 opacity-point8'
                        onClick={showModal}
                        loading={isSurveyRejectionCommentLoading || isRejectSubmittedPDFLoading}
                      >
                        Reject Workflow
                      </Button>
                    )}
                    <Button type='primary' size='large' className='brown-black-btn w-220 opacity-point8' onClick={showModalx}>Workflow Timeline</Button>
                    <Button type='primary' size='large' className='brown-black-btn w-220 opacity-point8' onClick={showModaly}>Attestation Timeline</Button>

                    {submittedSurveyUIData.reason?.length > 0 && (
                      <Button
                        type="primary"
                        size='large'
                        className='brown-black-btn w-220 opacity-point8'
                        onClick={showCommentModal}
                      >
                        View Comments
                      </Button>
                    )}

                    {user && isSurveyApproved && !isApprovedSurveySigned && approverConfirmationCount === numConfirmationsRequired && submittedSurveyUIData.ApprovalSignature?.length !== numConfirmationsRequired && !checkIfAlreadySigned() && (
                        <ElectronicSignature approverConfirmationCount={approverConfirmationCount} _id={location.state._id} contract_address={location.state.contract_address} loggedAccount={user} formId={formId} />
                    )}

                    {submittedSurveyUIData.ApprovalSignature?.length === numConfirmationsRequired && (
                      <Button type="primary" size='large' className='brown-black-btn w-220 opacity-point8' onClick={() => toVerifyPage()}>Verify Workflow</Button>
                    )}

                    {submittedSurveyUIData.ApprovalSignature?.length === numConfirmationsRequired && (
                      <Button type="primary" size='large' className='brown-black-btn w-220 opacity-point8' onClick={() => toViewSurveyPage()}>View Workflow</Button>
                    )}

                </Space>
                  <Modal
                    title="Reason For Workflow Rejection"
                    open={open}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <TextArea
                      onChange={onCommentTextChange}
                      maxLength={250}
                      className='workflow-reject-comment-textarea'
                      placeholder="Enter reason for rejecting workflow here"
                    />
                  </Modal>

                  <Modal
                    footer={false}
                    title="Workflow Timeline"
                    open={isModalxOpen}
                    onOk={handleOkx}
                    onCancel={handleCancelx}
                    bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
                  >
                    <SurveyTimeline />
                  </Modal>
                  <Modal
                    footer={false}
                    title="Attestation Timeline"
                    open={isModalyOpen}
                    onOk={handleOky}
                    onCancel={handleCancely}
                    bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
                  >
                    <AttestationTimeline />
                  </Modal>


                  <Modal
                    title="Approver Comments"
                    open={openCommentModal}
                    onCancel={handleCommentCancel}
                    footer={false}
                    maskClosable={false}
                  >
                    <SurveyComments comments={submittedSurveyUIData?.reason} />
                  </Modal>
                </Col>
              </Row>
          </>
        }
    </MainLayout>
  )
}

export default ApproveSurveyPage;