import { createSlice } from "@reduxjs/toolkit";
const initialState= {
    superadminDashboardCurrentPage:1,
    superadminDashboardElePerPage:10,
    adminArrayEleOrderBy:'createdAt',
    adminArrayEleSortBy:'DESC',
    superadminWorkflowsByAdminCurrentPage:1,
    superadminWorkflowsByAdminElePerPage:12,
    superadminWorkflowsByAdminArrayEleOrderBy:'createdAt',
    superadminWorkflowsByAdminArrayEleSortBy:'DESC'    
}; 
const superAdminSlice = createSlice({
    name:"superAdmin",
    initialState: initialState,
    reducers:{
        setSuperAdminDashboardCurrentPage : (state,{payload})=>{
            state.superadminDashboardCurrentPage = payload;
        },
        setSuperAdminDashboardElePerPage : (state,{payload})=>{
            state.superadminDashboardElePerPage = payload;
        },
        setAdminArraySortBy : (state,{payload})=>{
            state.adminArrayEleSortBy = payload;
        },
        setSuperadminWorkflowsByAdminCurrentPage : (state,{payload})=>{
            state.superadminWorkflowsByAdminCurrentPage = payload;
        },
        setSuperadminWorkflowsByAdminElePerPage : (state,{payload})=>{
            state.superadminWorkflowsByAdminElePerPage = payload;
        },
        setSuperadminWorkflowsByAdminArrayEleSortBy : (state,{payload})=>{
            state.superadminWorkflowsByAdminArrayEleSortBy = payload;
        }
    },
});
export const {setSuperAdminDashboardCurrentPage,setSuperAdminDashboardElePerPage,setAdminArraySortBy,setSuperadminWorkflowsByAdminCurrentPage,setSuperadminWorkflowsByAdminElePerPage,setSuperadminWorkflowsByAdminArrayEleSortBy} = superAdminSlice.actions;
export default superAdminSlice.reducer;