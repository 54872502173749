import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminsOfUserByUserAddress } from '../../redux/actions/template';
import { Button, Card, Col, Row } from 'antd';
import Spinner from '../../components/Spinner/Spinner';
import { removeCookie } from '../../utils/cookieUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { resetTokenUserData } from '../../redux/slices/erc4907ContractSlice';
import MainLayout from '../../components/MainLayout/MainLayout';

const ExternalUserViewSurveys = () => {
  const {isAdminArrayObjLoading,adminArrayObj } = useSelector(state => state.template);
  const location = useLocation();
  const dispatch = useDispatch();
  const sessionCookie = Cookies.get('session');

  useEffect(()=>{
    dispatch(getAdminsOfUserByUserAddress(location.state.tokenOwner))
  },[dispatch,location.state.tokenOwner]);

  const adminDescription = (admin) => (
    <div className='flex-center-direction-col'>
      <Row>
        <Col span={8} className='bold-font'>
          Email
        </Col>
        <Col span={2} className='bold-font'>
        : 
        </Col>
        <Col span={14}>
          {admin.email}
        </Col>
      </Row>
      <Row>
        <Col span={8} className='bold-font'>
            xVault Address
        </Col>
        <Col span={2} className='bold-font'>
          : 
        </Col>
        <Col span={14}>
          {admin.contract_address.slice(0, 6)}...{admin.contract_address.slice(-4)}
        </Col>
      </Row>
    </div>
  );

  const navigate = useNavigate();
  const toViewSurveyPage = (admin) => {
    navigate(`/view-workflow-externaluser/${admin._id}`,{state:{contract_address:admin.contract_address,tokenOwner:location.state.tokenOwner}});
  }
  const clearSessionHandler = () => {
    removeCookie('session');
    removeCookie('sessionExpiry');
    dispatch(resetTokenUserData());
    navigate('/dashboard');
  }
  
  useEffect(()=>{
    sessionCookie===undefined && navigate('/dashboard');
  },[navigate,sessionCookie]);
  return (
    <MainLayout>
                  <>
                    <div className='flex-between-center'>
                      <h1>Admins Of Token Owner</h1>
                      <Button type='primary' danger size='large' onClick={clearSessionHandler} >Clear Session</Button>
                    </div>
                    <Row gutter={[20,20]}>
                    {isAdminArrayObjLoading ? <div className='flex-direction-col width-height-100perc'><Spinner/><h3>Loading...</h3></div>
                      : 
                      adminArrayObj.map((admin)=>(
                        <Col key={admin._id} xs={24} sm={24} md={12}>
                          <Card
                            cover={<div className='flex mt-1'><img src="/assets/images/admin3.png" alt="admin" className='workflow-card-icon mt-4' /></div>}
                            hoverable
                            key={admin._id}    
                            className='workflow-card' 
                            onClick={()=>toViewSurveyPage(admin)}
                          >
                           {adminDescription(admin)}
                          </Card>
                        </Col>
                      ))
                    }
                    </Row>
                  </>
    </MainLayout>

  )
}

export default ExternalUserViewSurveys;