import moment from "moment";
export const dateFormatter = (
  DDMMYYYY,
  format = "DD-MM-YYYY hh:mm A",
  tz = "Asia/Kolkata"
) => {
  return moment(new Date(DDMMYYYY)).format(format);
};
//   export const dateTimezoneFormatter = (
//     DDMMYYYY,
//     format = "DD-MM-YYYY hh:mm A",
//     tz = "Asia/Kolkata"
//   ) => {
//     return momentTimezone.tz(moment(DDMMYYYY), tz).format(format);
//   };
export const dateTimeFormatter = (
  DDMMYYYY,
  format = {
    sameDay: "[Today] hh:mm A",
    lastDay: `[Yesterday] hh:mm A`,
    lastWeek: "DD-MM-YYYY hh:mm A",
    sameElse: "DD-MM-YYYY hh:mm A",
  }
) => {
  return moment(DDMMYYYY).calendar(null, format);
};
export const downloadURL = (uri, name) => {
  let link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link)
;
  link.click();
  document.body.removeChild(link)
;
};

export const findCallNumberonMessage = (text) => {
  var urrled = /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im;
  var rx = /^[+-]?\d+$/;
  let regexTest = text.split(" ").filter((eve) => rx.test(eve) === true);
  text =
    regexTest.length && regexTest.length > 0
      ? regexTest.length > 1
        ? regexTest[0] + regexTest[1]
        : regexTest[0]
      : text;
  if (urrled.test(text)) {
    return text;
  } else {
    return undefined;
  }
};

export const findUrlOnMessage = (text) => {
  // var urlRegex =
  //   /(\b(https?|ftp|file:\/\/):[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?[^\s]*):[-A-Z0-9+&@#\u/%?=~_|!:,.;]*[-A-Z0-9+&@#\u/%=~_|])/gi;
  var urlRegex =
    /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?[^\s]*)?[-A-Z0-9+&@#\u/%?=~_|!:,.;]*[-A-Z0-9+&@#\u/%=~_|]?/gi;
  text = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  text = text.replace(/\r?\n/g, " <br/>");
  return text.replace(urlRegex, function (url) {
    let urlLink =
      url.includes("http://") || url.includes("https://")
        ? url
        : "https://" + url;
    return (
      '<a   target="_blank" rel="noopener noreferrer"  href="' +
      urlLink +
      '">' +
      url +
      "</a>"
    );
  });
};

export const unixEpochToUTC =(unixTimestamp) => {
  // Create a new Date object using the Unix timestamp in milliseconds
  const date = new Date(unixTimestamp * 1000);

  // Use the Date object's methods to extract the UTC components
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // Months are 0-indexed, so add 1
  const day = date.getUTCDate();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // Format the UTC time as a string
  const utcTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}Z`;

  return utcTime;
}

export const unixEpochToIST = (unixTimestamp) => {
  // Create a new Date object using the Unix timestamp in milliseconds
  const date = new Date(unixTimestamp * 1000);

  // Add 5 hours and 30 minutes to convert to IST
  date.setUTCHours(date.getUTCHours() + 5);
  date.setUTCMinutes(date.getUTCMinutes() + 30);

  // Use the Date object's methods to extract the IST components
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // Months are 0-indexed, so add 1
  const day = date.getUTCDate();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // Create a formatted IST time string
  const istTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  return istTime;
}

export const dateToSeconds = (dateString) => {
  const timestampInMilliseconds = Date.parse(dateString);
  if (isNaN(timestampInMilliseconds)) {
    // Invalid date string
    return null;
  }
  
  const timestampInSeconds = timestampInMilliseconds / 1000;
  return timestampInSeconds;
}

// formats a blockchain timestamp to GMT value , used in workflow timeline
export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const year = date.getUTCFullYear();
  const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
  const day = ('0' + date.getUTCDate()).slice(-2);
  const hours = ('0' + date.getUTCHours()).slice(-2);
  const minutes = ('0' + date.getUTCMinutes()).slice(-2);
  const seconds = ('0' + date.getUTCSeconds()).slice(-2);
  const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} GMT`;
  return formattedTime;
}