import { createSlice } from "@reduxjs/toolkit";
import {getTemplates,createTemplate,updateTemplate,getSurveyId,getAllSurveys,getSubmittedSurveyUI,submitCreatedSurvey,setCommentToRejectedSurvey,submitRejectedSurveyAgain,approverSignatureUpload, getEvents, inviteUser, getUserInvitedRole, getAllInvitedUsers, userEmailToWalletAddress, getAllUsersForTimeline, inviteAdmin, getAllAdmins, getAllUsersInvitedByAdmin, transferredEWarrentOwnership, getAdminsForUser, getAdminDetailsByAdminId, geteWarrentAddressByAdminId, adminEmailToWalletAddress,allExternalUsers, getAdminsOfUserByUserAddress,checkExternalUser, inviteExternalUser, getUserAccessHistory, getExternalUserAccessSignatureHistory, getUserTokenAccessHistory, getUserTokenAccessSignatureHistory, getWorkflowAttestationEvents, getAllOnboardedUsersByAdmin, deployedEWarrentForAdmin, getWorkflowsForSubmission, getWorkflowsForApproval, adminGetWorkflowsForVerifying, getWorkflowsForViewing, superadminGetWorkflowsByAdminId} from "../actions/template"
import { openNotificationWithIcon } from "../../utils/openNotificationWithIcon";
const initialState = {
  isAdminTemplatesLoading: false,
  isTemplatecreating:false,
  createTempModal: false,
  isSuccess: false,
  isError: false,
  isVisible: false,
  isMessage: "",
  data: [],
  type: "",
  details: [],
  emailDetails: [],
  isDrawerVisible:false, // changed from "" to false
  templateTableData:[],
  templateCount:0,
  templateData: {}, // changed from "" to {}
  EdittemplateData: {},
  pagination: { page: 1, limit: 10 },
  Attribute: [],
  Visible: false,
  isRadioData: [],
  isSelectData: [],
  editVisibletype: "",
  Edata: [],
  textEditor: false,
  templatePagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: -1,
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  surveyId:null,
  formUIData:{},
  allFormsCreatedByAdmin:[],
  isAllFormsCreatedByAdminLoading:false,
  isGetTemplateByIdLoading : false, // ye actually getSurveyId pe 
  isGetTemplateByIdError : false,
  isSubmittedSurveyUILoading : false,
  submittedSurveyUIData:{},
  isSurveySubmitButtonClicked:false,
  isSurveyRejectionCommentLoading:false,
  isApproverSignatureUploading:false,
  isApproverSignatureUploadedSuccessful:false,
  isSurveySubmitted:false,
  isSurveySubmitButtonClickedRejectedSurvey:false,
  isEventsLoading:false,
  timelineData:[],
  isUserInviteButtonLoading:false,
  isGetUserRoleLoading:false,
  userRoleFromAPI:null,
  isUserRoleNotPresent:false,
  userAddressFromAPI:null,
  allInvitedUsersObj:{},
  isgetAllInvitedUsersLoading:false,
  isUserWalletAddressMappedToAPI:false,
  userEmailToWalletAddressLoading:false,
  surveyTimelineLoading:false,
  onboardedUsers:[],
  signatureUploadingAndPDFUploadingText:'',
  isInviteAdminSuccess:false,
  isInviteAdminFailed:false,
  isInviteAdminLoading:false,
  isgetAllAdminsLoading:false,
  allAdminArray:[],
  adminsCount:0,
  allUsersInvitedByAdminArray:[],
  isgetAllUsersInvitedByAdminLoading:false,
  isTransferEWarrentOwnershipAPICallLoading:false,
  isEWarrentOwnershipTransferAPISuccess:false,
  usersAdminArray:[],
  isAdminArrayForUserLoading:false,
  isAdminArrayObjLoading:false,
  adminArrayObj : [],
  eWarrentContractAddress:'',
  allOnboardedUsers:[],
  adminEmailToWalletAddressLoading:false,
  adminObjForAdmin:{},
  isAllExternalUsersLoading:false,
  filteredExternalUsers:[],
  isAdminsOfUserByUserAddressLoading:false,
  userAdminIdArray:[],
  isLoggedInUserExternalUserLoading:false,
  isLoggedInUserExternalUser:false,
  isInviteExternalUserLoading:false,
  isInvitationSentToExternalUser:false,
  userAccessHistoryArrayLoading:false,
  userAccessHistoryArray:[],
  userAccessHistoryCount:0,
  userTokenHistoryArrayLoading:false,
  userTokenHistoryArray:[],
  userTokenHistoryCount:0,
  userAccessSignatureHistoryArrayLoading:false,
  userAccessSignatureHistoryArray:[],
  userAccessSignatureHistoryCount:0,
  userTokenAccessHistoryArrayLoading:false,
  userTokenAccessHistoryArray:[],
  userTokenAccessHistoryCount:0,
  userTokenAccessSignatureHistoryArrayLoading:false,
  userTokenAccessSignatureHistoryArray:[],
  userTokenAccessSignatureHistoryCount:0,
  latestWorkflowCreatedId:'',
  isWorkflowAttestationEventsLoading:'',
  attestationTimelineData:[],
  isUpdateTemplateLoading:false,
  isCreateTemplateLoading:false,
  isInviteExternalUserSuccess:false,
  isInviteExternalUserFailed:false,
  totalWorkflowsForSubmissionCount:0,
  workflowArrayForSubmission:[],
  isWorkflowsCreatedByAdminLoading:false,
  adminWorkflowArray:[],
  totalWorkflowsCreatedByAdminCount:0,
  isWorkflowsForApprovalLoading:false,
  workflowArrayForApproval:[],
  totalWorkflowsForApprovalCount:0,
  isWorkflowsForSubmissionLoading:false,
  isWorkflowsForViewingLoading:false,
  workflowArrayForViewing:[],
  totalWorkflowsForViewingCount:0,
  isSuperadminGetWorkflowsByAdminIdLoading:false,
  adminWorkflowArrayForSuperAdmin:[],
  adminWorkflowArrayForSuperAdminCount:0
};
const templateSlice = createSlice({
  name: "template",
  initialState: initialState,
  reducers: {
    
    createTemplateModal: (state, { payload }) => {
      state.createTempModal = payload.visible;
      // state.templateData = payload.record? payload.record : {};
      state.templateData = payload.record;
      state.type = payload.type;
    },

    templateDrawer: (state, { payload }) => {
      state.isDrawerVisible = payload.visible;
      state.DrawerData = payload.record;
    },

    setTemplateData: (state, { payload }) => {
      state.templateTableData = [...state.templateTableData, payload];
      state.Visible = false;
    },

    EdittemplateData:(state, { payload }) => {

      const data=state.templateTableData.map((pre)=>{
        if(pre.title===payload.title){
          return payload
        }else{
          return pre
        }
      })  
          state.templateTableData =data
          state.Visible = false;
    },

    deleteTemplate: (state,{payload}) => {
      const data=state.templateTableData.filter((pre)=>(pre.name!==payload));
      state.templateTableData =data;  
    },

    onPageChangesFilter: (state, action) => {
      (state.templatePagination = {
        ...state.templatePagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? 1 : -1,
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      })
    },

    onSearchList: (state, action) => {
      state.templatePagination = {
        ...state.templatePagination,
        search: action.payload.search || "",
      };
    },

    valueaddedonChange: (state, { payload }) => {
      console.log("values:", payload);
      const { _id, value } = payload;
      const updatedFields = state.formUIData.fields.map((field) => {
        if (field._id === _id) {
          return {
            ...field,
            value,
          };
        }
        return field;
      });
    
      state.formUIData = {
        ...state.formUIData,
        fields: updatedFields,
      };
    },  
      
    valueaddedonChange2: (state, { payload }) => {
      console.log("values :", payload);
      const { _id, value } = payload;
      const updatedFields = state.submittedSurveyUIData.fields.map((field) => {
        if (field._id === _id) {
          return {
            ...field,
            value,
          };
        }
        return field;
      });
    
      state.submittedSurveyUIData = {
        ...state.submittedSurveyUIData,
        fields: updatedFields,
      };
    },
    // array ki tarah lejara tha , api ne accept nahi kia 

    // valueaddedonChange3: (state, { payload }) => {
    //   console.log("values:", payload);
    //   const { _id, value } = payload;
    //   const updatedFields = state.formUIData.fields.map((field) => {
    //     if (field._id === _id) {
    //       let updatedValue;
    //       updatedValue = field.value? [field.value, value] : [value];
          
    //       return {
    //         ...field,
    //         value: [].concat(...updatedValue)
    //       };
    //     }
    //     return field;
    //   });
    
    //   state.formUIData = {
    //     ...state.formUIData,
    //     fields: updatedFields,
    //   };
    // },
    // valuedeletedonChange3: (state, { payload }) => {
    //   console.log("values:", payload);
    //   const { _id, value } = payload;
    //   const updatedFields = state.formUIData.fields.map((field) => {
    //     if (field._id === _id) {
    //       let updatedValue;
    //       if (Array.isArray(field.value)) {
    //         updatedValue = field.value.filter((v) => v !== value);
    //         if (updatedValue.length === 1) {
    //           updatedValue = updatedValue[0];
    //         }
    //       } else {
    //         updatedValue = null;
    //       }
          
    //       return {
    //         ...field,
    //         value: updatedValue,
    //       };
    //     }
    //     return field;
    //   });
    
    //   state.formUIData = {
    //     ...state.formUIData,
    //     fields: updatedFields,
    //   };
    // },

    valueaddedonChange3: (state, { payload }) => {
      console.log("values:", payload);
      const { _id, value } = payload;
      const updatedFields = state.formUIData.fields.map((field) => {
        if (field._id === _id) {
          let updatedValue;
          if (field.value) {
            updatedValue = `${field.value},${value}`;
          } else {
            updatedValue = value;
          }
          
          return {
            ...field,
            value: updatedValue,
          };
        }
        return field;
      });
      console.log("updated Fields :",updatedFields)
    
      state.formUIData = {
        ...state.formUIData,
        fields: updatedFields,
      };
    },
    
    valuedeletedonChange3: (state, { payload }) => {
      console.log("values:", payload);
      const { _id, value } = payload;
      const updatedFields = state.formUIData.fields.map((field) => {
        if (field._id === _id) {
          let updatedValue;
          if (field.value) {
            const values = field.value.split(",");
            updatedValue = values.filter((v) => v !== value).join(",");
          } else {
            updatedValue = null;
          }
          
          return {
            ...field,
            value: updatedValue,
          };
        }
        return field;
      });
    
      state.formUIData = {
        ...state.formUIData,
        fields: updatedFields,
      };
    },
    // valueaddedonChange4: (state, { payload }) => {
    //   function addValue(originalString, newValue) {
    //     if (typeof originalString !== "string") {
    //       throw new Error("Original string must be a string");
    //     }
      
    //     var values = originalString.split(",").map(function(value) {
    //       return value.trim();
    //     });
      
    //     if (!values.includes(newValue)) {
    //       values.push(newValue);
    //     }
      
    //     var updatedString = values.join(", ");
    //     return updatedString;
    //   }
      
      
    //   console.log("values:", payload);
    //   const { _id, value } = payload;
    //   const updatedFields = state.formUIData.fields.map((field) => {
    //     if (field._id === _id) {
    //       let updatedValue;
    //       if (field.value) {
    //         // updatedValue = `${field.value},${value}`;
    //         updatedValue = addValue(field.value, value);
    //       } else {
    //         updatedValue = `${value}`;
    //       }
          
    //       return {
    //         ...field,
    //         value: updatedValue,
    //       };
    //     }
    //     return field;
    //   });
    //   console.log("updated Fields :",updatedFields)
    
    //   state.formUIData = {
    //     ...state.formUIData,
    //     fields: updatedFields,
    //   };
    // },
    setSignatureUploadingAndPDFUploadingText : (state,{payload}) => {
      state.signatureUploadingAndPDFUploadingText = payload;
    },
    resetSignatureUploadingAndPDFUploadingText : (state,{payload}) => {
      state.signatureUploadingAndPDFUploadingText = '';
    },
    resetSurveySubmittedStatusAPI : (state,{payload}) => {
      state.isSurveySubmitted = false;
    },
    setLatestWorkflowCreatedId : (state,{payload}) => {
      state.latestWorkflowCreatedId = payload;
    },
    resetLatestWorkflowCreatedId : (state) => {
      state.latestWorkflowCreatedId = "";
    },
    resetInviteUserStates : (state) => {
      state.isUserInviteButtonLoading = false;
      state.isInviteUserSuccess = false;
      state.isInviteUserFailed = false;
    },
    resetInviteAdminStates : (state) => {
      state.isInviteAdminLoading = true;
      state.isInviteAdminSuccess = false;
      state.isInviteAdminFailed = false;
    },
    resetInviteExternalUserStates : (state) => {
      state.isInviteExternalUserLoading = true;
      state.isInviteExternalUserSuccess = false;
      state.isInviteExternalUserFailed = false;
    }
    },
  extraReducers:(builder) => {
    builder
      .addCase(getTemplates.pending, (state) => {
        state.isAdminTemplatesLoading = true;
        state.templateTableData = [];
        state.templateCount = 0;
      })
      .addCase(getTemplates.fulfilled, (state, { payload }) => {
        state.templateTableData = payload.data;
        state.templateCount = payload.Count;
        state.isAdminTemplatesLoading = false;
        // state.totalCount = payload.totalCount;
        // state.templatePagination = {
        //   ...state.templatePagination,
        //   total: payload.totalCount,
        // };
      })
      .addCase(getTemplates.rejected, (state, { payload }) => {
        state.isAdminTemplatesLoading = false;

      })
      .addCase(createTemplate.pending, (state) => {
        state.isCreateTemplateLoading = true;
      })
      .addCase(createTemplate.fulfilled, (state, { payload }) => {
        state.isCreateTemplateLoading = false;
        state.createTempModal=false
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(createTemplate.rejected, (state, { payload }) => {
        state.isCreateTemplateLoading = false;
      })
      .addCase(updateTemplate.pending, (state) => {
        state.isUpdateTemplateLoading = true;
      })
      .addCase(updateTemplate.fulfilled, (state, { payload }) => {
        state.isUpdateTemplateLoading = false;
        state.createTempModal=false
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(updateTemplate.rejected, (state, { payload }) => {
        state.isUpdateTemplateLoading = false;
      })
      .addCase(getSurveyId.pending, (state) => {
        console.log("getSurveyId pending")
        state.isGetTemplateByIdLoading = true;
      })
      .addCase(getSurveyId.fulfilled, (state, { payload }) => {
        state.isGetTemplateByIdLoading = false;
        state.surveyId = payload.surveyId;
        state.formUIData = payload.template.templatesVersion[0];
      })
      .addCase(getSurveyId.rejected, (state, { payload }) => {
        state.isGetTemplateByIdLoading = false;
        state.isGetTemplateByIdError = true;
      })
      // .addCase(getTemplateById.pending, (state) => {
      //   console.log("getTemplateById pending");
      // })
      // .addCase(getTemplateById.fulfilled, (state, { payload }) => {
      //   state.formUIData = payload;
      // })
      // .addCase(getTemplateById.rejected, (state, { payload }) => {
      // })
      .addCase(getAllSurveys.pending, (state) => {
        console.log("getTemplateById pending")
        state.isAllFormsCreatedByAdminLoading = true;
      })
      .addCase(getAllSurveys.fulfilled, (state, { payload }) => {
        state.allFormsCreatedByAdmin = payload;
        state.isAllFormsCreatedByAdminLoading = false;
      })
      .addCase(getAllSurveys.rejected, (state, { payload }) => {
        openNotificationWithIcon('error','getAllSurveys errror')
        state.isAllFormsCreatedByAdminLoading = false;
      })
      .addCase(getSubmittedSurveyUI.pending, (state) => {
        state.isSubmittedSurveyUILoading = true;
        state.submittedSurveyUIData = {};
      })
      .addCase(getSubmittedSurveyUI.fulfilled, (state, { payload }) => {
        state.submittedSurveyUIData = payload;
        state.isSubmittedSurveyUILoading = false;
      })
      .addCase(getSubmittedSurveyUI.rejected, (state, { payload }) => {
        openNotificationWithIcon('error','getSubmittedSurveyUI errror');
        state.isSubmittedSurveyUILoading = false;
      })
      .addCase(submitCreatedSurvey.pending, (state) => {
        state.isSurveySubmitButtonClicked = true;
      })
      .addCase(submitCreatedSurvey.fulfilled, (state, { payload }) => {
        state.isSurveySubmitButtonClicked = false;
        state.isSurveySubmitted = payload;
      })
      .addCase(submitCreatedSurvey.rejected, (state, { payload }) => {
        openNotificationWithIcon('error','submitCreatedSurvey errror');
        state.isSurveySubmitButtonClicked = false;
      })
      .addCase(setCommentToRejectedSurvey.pending, (state) => {
        state.isSurveyRejectionCommentLoading = true;
      })
      .addCase(setCommentToRejectedSurvey.fulfilled, (state, { payload }) => {
        state.isSurveyRejectionCommentLoading = false;
      })
      .addCase(setCommentToRejectedSurvey.rejected, (state, { payload }) => {
        state.isSurveyRejectionCommentLoading = false;
      })
      .addCase(submitRejectedSurveyAgain.pending, (state) => {
        state.isSurveySubmitButtonClickedRejectedSurvey = true;
      })
      .addCase(submitRejectedSurveyAgain.fulfilled, (state, { payload }) => {
        // state.submittedSurveyUIData = payload;
        state.isSurveySubmitted = payload;
        state.isSurveySubmitButtonClickedRejectedSurvey = false;
      })
      .addCase(submitRejectedSurveyAgain.rejected, (state, { payload }) => {
        state.isSurveySubmitButtonClickedRejectedSurvey = false;
      })
      .addCase(approverSignatureUpload.pending, (state) => {
        state.isApproverSignatureUploading = true;
      })
      .addCase(approverSignatureUpload.fulfilled, (state, { payload }) => {
        state.isApproverSignatureUploading = false;
        state.isApproverSignatureUploadedSuccessful = payload;
      })
      .addCase(approverSignatureUpload.rejected, (state, { payload }) => {
        state.isApproverSignatureUploading = false;
      })
      .addCase(getEvents.pending, (state,{payload}) => {
        state.isEventsLoading = true;
        state.timelineData = [];
      })
      .addCase(getEvents.fulfilled, (state, { payload }) => {
        state.isEventsLoading = false;
        state.timelineData = payload;
      })
      .addCase(getEvents.rejected, (state, { payload }) => {
        state.isEventsLoading = false;
      })
      .addCase(inviteUser.pending, (state,{payload}) => {
        state.isUserInviteButtonLoading = true;
        state.isInviteUserSuccess = false;
        state.isInviteUserFailed = false;
      })
      .addCase(inviteUser.fulfilled, (state, { payload }) => {
        state.isUserInviteButtonLoading = false;
        state.isInviteUserSuccess = payload===200 ? true : false;
        state.isInviteUserFailed = payload!==200 ? true : false;
      })
      .addCase(inviteUser.rejected, (state, { payload }) => {
        state.isUserInviteButtonLoading = false;
        state.isInviteUserSuccess = false;
        state.isInviteUserFailed = false;
      })
      .addCase(getUserInvitedRole.pending, (state,{payload}) => {
        state.isGetUserRoleLoading = true;
        state.isUserRoleNotPresent = false;
      })
      .addCase(getUserInvitedRole.fulfilled, (state, { payload }) => {
        state.isGetUserRoleLoading = false;
        console.log("payload of getUserInvitedRole :",payload);
        state.userRoleFromAPI = payload.role;
        state.userAddressFromAPI = payload.address;
      })
      .addCase(getUserInvitedRole.rejected, (state, { payload }) => {
        console.log("getUserInvitedRole error ")
        state.isGetUserRoleLoading = false;
        state.isUserRoleNotPresent = true;
      })
      .addCase(getAllInvitedUsers.pending, (state,{payload}) => {
        state.isgetAllInvitedUsersLoading = true;
      })
      .addCase(getAllInvitedUsers.fulfilled, (state, { payload }) => {
        state.isgetAllInvitedUsersLoading = false;
        state.allInvitedUsersObj = payload;
      })
      .addCase(getAllInvitedUsers.rejected, (state, { payload }) => {
        state.isgetAllInvitedUsersLoading = false;
      })
      .addCase(userEmailToWalletAddress.pending, (state,{payload}) => {
        state.userEmailToWalletAddressLoading = true;
        state.isUserWalletAddressMappedToAPI = false;
      })
      .addCase(userEmailToWalletAddress.fulfilled, (state, { payload }) => {
        state.userEmailToWalletAddressLoading = false;
        state.isUserWalletAddressMappedToAPI = true;
      })
      .addCase(userEmailToWalletAddress.rejected, (state, { payload }) => {
        state.userEmailToWalletAddressLoading = true;
      })
      .addCase(getAllUsersForTimeline.pending, (state,{payload}) => {
        state.surveyTimelineLoading = true;
        state.onboardedUsers = [];
      })
      .addCase(getAllUsersForTimeline.fulfilled, (state, { payload }) => {
        state.surveyTimelineLoading = false;
        state.onboardedUsers = payload;
      })
      .addCase(getAllUsersForTimeline.rejected, (state, { payload }) => {
        state.surveyTimelineLoading = false;
      })
      .addCase(inviteAdmin.pending, (state,{payload}) => {
        state.isInviteAdminLoading = true;
        state.isInviteAdminSuccess = false;
        state.isInviteAdminFailed = false;
      })
      .addCase(inviteAdmin.fulfilled, (state, { payload }) => {
        state.isInviteAdminLoading = false;
        state.isInviteAdminSuccess = payload===200?true:false;
        state.isInviteAdminFailed = payload!==200 ? true : false;
      })
      .addCase(inviteAdmin.rejected, (state, { payload }) => {
        state.isInviteAdminLoading = false;
        state.isInviteAdminFailed = true;
        state.isInviteAdminSuccess = false;
      })
      .addCase(getAllAdmins.pending, (state,{payload}) => {
        state.isgetAllAdminsLoading = true;
        state.allAdminArray = [];
        state.adminsCount = 0;
      })
      .addCase(getAllAdmins.fulfilled, (state, { payload }) => {
        state.isgetAllAdminsLoading = false;
        state.allAdminArray = payload.adminArray;
        state.adminsCount = payload.totalAdmins;
      })
      .addCase(getAllAdmins.rejected, (state, { payload }) => {
        state.isgetAllAdminsLoading = false;
      })
      .addCase(getAllUsersInvitedByAdmin.pending, (state,{payload}) => {
        state.isgetAllUsersInvitedByAdminLoading = true;
        state.allUsersInvitedByAdminArray = [];
        // state.allOnboardedUsers = [];
      })
      .addCase(getAllUsersInvitedByAdmin.fulfilled, (state, { payload }) => {
        state.isgetAllUsersInvitedByAdminLoading = false;
        state.allUsersInvitedByAdminArray = payload.allInvitedUsers;
        // state.allOnboardedUsers = payload.onboardedUsers;
      })
      .addCase(getAllUsersInvitedByAdmin.rejected, (state, { payload }) => {
        state.isgetAllUsersInvitedByAdminLoading = false;
      })
      .addCase(transferredEWarrentOwnership.pending, (state,{payload}) => {
        state.isTransferEWarrentOwnershipAPICallLoading = true;
        state.isEWarrentOwnershipTransferAPISuccess = false;
      })
      .addCase(transferredEWarrentOwnership.fulfilled, (state, { payload }) => {
        state.isTransferEWarrentOwnershipAPICallLoading = false;
        state.isEWarrentOwnershipTransferAPISuccess = payload===200 ?true:false;
      })
      .addCase(transferredEWarrentOwnership.rejected, (state, { payload }) => {
        state.isTransferEWarrentOwnershipAPICallLoading = false;
        state.isEWarrentOwnershipTransferAPISuccess = false;
      })
      .addCase(getAdminsForUser.pending, (state,{payload}) => {
        state.usersAdminArray = [];
        state.isAdminArrayForUserLoading = true;
      })
      .addCase(getAdminsForUser.fulfilled, (state, { payload }) => {
        state.isAdminArrayForUserLoading = false;
        state.usersAdminArray = payload;
      })
      .addCase(getAdminsForUser.rejected, (state, { payload }) => {
        state.isAdminArrayForUserLoading = false;
      })
      .addCase(getAdminDetailsByAdminId.pending, (state,{payload}) => {
        state.isAdminArrayObjLoading = true;
        state.adminArrayObj = [];
      })
      .addCase(getAdminDetailsByAdminId.fulfilled, (state, { payload }) => {
        state.isAdminArrayObjLoading = false;
        state.adminArrayObj = payload;
      })
      .addCase(getAdminDetailsByAdminId.rejected, (state, { payload }) => {
        state.isAdminArrayObjLoading = false;
      })
      .addCase(geteWarrentAddressByAdminId.pending, (state,{payload}) => {
        state.eWarrentContractAddress = '';
      })
      .addCase(geteWarrentAddressByAdminId.fulfilled, (state, { payload }) => {
        state.eWarrentContractAddress = payload;
      })
      .addCase(geteWarrentAddressByAdminId.rejected, (state, { payload }) => {
      })
      .addCase(adminEmailToWalletAddress.pending, (state,{payload}) => {
        state.adminEmailToWalletAddressLoading = true;
        state.adminObjForAdmin = {};
      })
      .addCase(adminEmailToWalletAddress.fulfilled, (state, { payload }) => {
        state.adminEmailToWalletAddressLoading = false;
        state.adminObjForAdmin = payload;
      })
      .addCase(adminEmailToWalletAddress.rejected, (state, { payload }) => {
        state.adminEmailToWalletAddressLoading = false;
      })
      .addCase(allExternalUsers.pending, (state,{payload}) => {
        state.isAllExternalUsersLoading = true;
        state.filteredExternalUsers = [];
      })
      .addCase(allExternalUsers.fulfilled, (state, { payload }) => {
        state.isAllExternalUsersLoading = false;
        state.filteredExternalUsers = payload;  
      })
      .addCase(allExternalUsers.rejected, (state, { payload }) => {
        state.isAllExternalUsersLoading = false;
      })
      .addCase(getAdminsOfUserByUserAddress.pending, (state,{payload}) => {
        state.isAdminsOfUserByUserAddressLoading = true;
        state.userAdminIdArray = []
      })
      .addCase(getAdminsOfUserByUserAddress.fulfilled, (state, { payload }) => {
        state.isAdminsOfUserByUserAddressLoading = false;
        state.userAdminIdArray = payload.adminArray;
      })
      .addCase(getAdminsOfUserByUserAddress.rejected, (state, { payload }) => {
        state.isAdminsOfUserByUserAddressLoading = false;
      })
      .addCase(checkExternalUser.pending, (state,{payload}) => {
        state.isLoggedInUserExternalUserLoading = true;
        state.isLoggedInUserExternalUser = false;
      })
      .addCase(checkExternalUser.fulfilled, (state, { payload }) => {
        state.isLoggedInUserExternalUserLoading = false;
        state.isLoggedInUserExternalUser = payload;
      })
      .addCase(checkExternalUser.rejected, (state, { payload }) => {
        state.isLoggedInUserExternalUserLoading = false;
      })
      .addCase(inviteExternalUser.pending, (state,{payload}) => {
        state.isInviteExternalUserLoading = true;
        state.isInviteExternalUserSuccess = false;
        state.isInviteExternalUserFailed = false;
      })
      .addCase(inviteExternalUser.fulfilled, (state, { payload }) => {
        state.isInviteExternalUserLoading = false;
        state.isInviteExternalUserSuccess = payload===200 ? true : false;
        state.isInviteExternalUserFailed = payload!==200 ? true : false;
      })
      .addCase(inviteExternalUser.rejected, (state) => {
        state.isInviteExternalUserLoading = false;
        state.isInviteExternalUserSuccess = false;
        state.isInviteExternalUserFailed = true;
      })
      .addCase(getUserAccessHistory.pending, (state,{payload}) => {
        state.userTokenHistoryArrayLoading = true;
        state.userTokenHistoryArray = [];
        state.userTokenHistoryCount = 0;
      })
      .addCase(getUserAccessHistory.fulfilled, (state, { payload }) => {
        state.userTokenHistoryArrayLoading = false;
        state.userTokenHistoryArray = payload.userTokenHistoryArray;
        state.userTokenHistoryCount = payload.userTokenHistoryCount;
      })
      .addCase(getUserAccessHistory.rejected, (state, { payload }) => {
        state.userTokenHistoryArrayLoading = false;
      })
      .addCase(getExternalUserAccessSignatureHistory.pending, (state,{payload}) => {
        state.userAccessSignatureHistoryArrayLoading = true;
        state.userAccessSignatureHistoryArray = [];
        state.userAccessSignatureHistoryCount = 0;
      })
      .addCase(getExternalUserAccessSignatureHistory.fulfilled, (state, { payload }) => {
        state.userAccessSignatureHistoryArrayLoading = false;
        state.userAccessSignatureHistoryArray = payload.userAccessHistoryArray;
        state.userAccessSignatureHistoryCount = payload.userAccessHistoryCount;
      })
      .addCase(getExternalUserAccessSignatureHistory.rejected, (state, { payload }) => {
        state.userAccessSignatureHistoryArrayLoading = false;
      })
      .addCase(getUserTokenAccessHistory.pending, (state,{payload}) => {
        state.userTokenAccessHistoryArrayLoading = true;
        state.userTokenAccessHistoryArray = [];
        state.userTokenAccessHistoryCount = 0;
      })
      .addCase(getUserTokenAccessHistory.fulfilled, (state, { payload }) => {
        state.userTokenAccessHistoryArrayLoading = false;
        state.userTokenAccessHistoryArray = payload.userTokenAccessHistoryArray;
        state.userTokenAccessHistoryCount = payload.userTokenAccessHistoryCount;
      })
      .addCase(getUserTokenAccessHistory.rejected, (state, { payload }) => {
        state.userTokenAccessHistoryArrayLoading = false;
      })
      .addCase(getUserTokenAccessSignatureHistory.pending, (state,{payload}) => {
        state.userTokenAccessSignatureHistoryArrayLoading = true;
        state.userTokenAccessSignatureHistoryArray = [];
        state.userTokenAccessSignatureHistoryCount = 0;
      })
      .addCase(getUserTokenAccessSignatureHistory.fulfilled, (state, { payload }) => {
        state.userTokenAccessSignatureHistoryArrayLoading = false;
        state.userTokenAccessSignatureHistoryArray = payload.userTokenAccessSignatureHistoryArray;
        state.userTokenAccessSignatureHistoryCount = payload.userTokenAccessSignatureHistoryCount;
      })
      .addCase(getUserTokenAccessSignatureHistory.rejected, (state, { payload }) => {
        state.userTokenAccessSignatureHistoryArrayLoading = false;
      })
      .addCase(getWorkflowAttestationEvents.pending, (state,{payload}) => {
        state.isWorkflowAttestationEventsLoading = true;
        state.attestationTimelineData = [];
      })
      .addCase(getWorkflowAttestationEvents.fulfilled, (state, { payload }) => {
        state.isWorkflowAttestationEventsLoading = false;
        state.attestationTimelineData = payload;
      })
      .addCase(getWorkflowAttestationEvents.rejected, (state, { payload }) => {
        state.isWorkflowAttestationEventsLoading = false;
      })
      .addCase(getAllOnboardedUsersByAdmin.pending, (state) => {
        state.allOnboardedUsers = [];
      })
      .addCase(getAllOnboardedUsersByAdmin.fulfilled, (state, { payload }) => {
        state.allOnboardedUsers = payload;
      })
      .addCase(getAllOnboardedUsersByAdmin.rejected, (state, { payload }) => {
      })
      .addCase(deployedEWarrentForAdmin.pending, (state) => {
        state.xvaultDeploymentSuccess = false;
      })
      .addCase(deployedEWarrentForAdmin.fulfilled, (state, { payload }) => {
        state.xvaultDeploymentSuccess = payload === 200 ? true : false;
      })
      .addCase(deployedEWarrentForAdmin.rejected, (state, { payload }) => {
        state.xvaultDeploymentSuccess = false;
      })
      .addCase(getWorkflowsForSubmission.pending, (state) => {
        state.isWorkflowsForSubmissionLoading = true;
        state.workflowArrayForSubmission = [];
        state.totalWorkflowsForSubmissionCount = 0;
      })
      .addCase(getWorkflowsForSubmission.fulfilled, (state, { payload }) => {
        state.isWorkflowsForSubmissionLoading = false;
        state.workflowArrayForSubmission = payload.workflowArray;
        state.totalWorkflowsForSubmissionCount = payload.totalWorkflowsForSubmission;
      })
      .addCase(getWorkflowsForSubmission.rejected, (state, { payload }) => {
        state.isWorkflowsForSubmissionLoading = false;
      })
      .addCase(getWorkflowsForApproval.pending, (state) => {
        state.isWorkflowsForApprovalLoading = true;
        state.workflowArrayForApproval = [];
        state.totalWorkflowsForApprovalCount = 0;
      })
      .addCase(getWorkflowsForApproval.fulfilled, (state, { payload }) => {
        state.isWorkflowsForApprovalLoading = false;
        state.workflowArrayForApproval = payload.workflowArray;
        state.totalWorkflowsForApprovalCount = payload.totalWorkflowsForApproval;
      })
      .addCase(getWorkflowsForApproval.rejected, (state, { payload }) => {
        state.isWorkflowsForApprovalLoading = false;
      })
      .addCase(adminGetWorkflowsForVerifying.pending, (state) => {
        state.isWorkflowsCreatedByAdminLoading = true;
        state.adminWorkflowArray = [];
        state.totalWorkflowsCreatedByAdminCount = 0;
      })
      .addCase(adminGetWorkflowsForVerifying.fulfilled, (state, { payload }) => {
        state.isWorkflowsCreatedByAdminLoading = false;
        state.adminWorkflowArray = payload.workflowArray;
        state.totalWorkflowsCreatedByAdminCount = payload.totalWorkflowsCreatedByAdmin;
      })
      .addCase(adminGetWorkflowsForVerifying.rejected, (state, { payload }) => {
        state.isWorkflowsCreatedByAdminLoading = false;
      })
      .addCase(getWorkflowsForViewing.pending, (state) => {
        state.isWorkflowsForViewingLoading = true;
        state.workflowArrayForViewing = [];
        state.totalWorkflowsForViewingCount = 0;
      })
      .addCase(getWorkflowsForViewing.fulfilled, (state, { payload }) => {
        state.isWorkflowsForViewingLoading = false;
        state.workflowArrayForViewing = payload.workflowArray;
        state.totalWorkflowsForViewingCount = payload.totalWorkflowsForViewing;
      })
      .addCase(getWorkflowsForViewing.rejected, (state, { payload }) => {
        state.isWorkflowsForViewingLoading = false;
      })
      .addCase(superadminGetWorkflowsByAdminId.pending, (state) => {
        state.isSuperadminGetWorkflowsByAdminIdLoading = true;
        state.adminWorkflowArrayForSuperAdmin = [];
        state.adminWorkflowArrayForSuperAdminCount = 0;
      })
      .addCase(superadminGetWorkflowsByAdminId.fulfilled, (state, { payload }) => {
        state.isSuperadminGetWorkflowsByAdminIdLoading = false;
        state.adminWorkflowArrayForSuperAdmin = payload.workflowArray;
        state.adminWorkflowArrayForSuperAdminCount = payload.totalWorkflowsCreatedByAdmin;
      })
      .addCase(superadminGetWorkflowsByAdminId.rejected, (state, { payload }) => {
        state.isSuperadminGetWorkflowsByAdminIdLoading = false;
      })
  },
});
export const { createTemplateModal,templateDrawer, setTemplateData,EdittemplateData,onPageChangesFilter,onSearchList,deleteTemplate,valueaddedonChange,valueaddedonChange2,valueaddedonChange3,valuedeletedonChange3,valueaddedonChange4,setSignatureUploadingAndPDFUploadingText,resetSignatureUploadingAndPDFUploadingText,resetSurveySubmittedStatusAPI,setLatestWorkflowCreatedId,resetLatestWorkflowCreatedId,resetInviteUserStates,resetInviteAdminStates,resetInviteExternalUserStates} = templateSlice.actions;
export default templateSlice.reducer;
