import { Badge, Button, Spin, Table, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../styles/main.scss";
import { dateFormatter, dateTimeFormatter } from "../../utils/dateFormatter";
import { CopyOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";

const UserList = () => {
  const { isgetAllUsersInvitedByAdminLoading, allUsersInvitedByAdminArray } = useSelector(
    (state) => state.template
  );
  console.log("allUsersInvitedByAdminArray :",allUsersInvitedByAdminArray)
  const [isUserAddressCopying, setIsUserAddressCopying] = useState(false);

  const handleCopyAdminAddress = (address) => {
    setIsUserAddressCopying(true);
    copy(address);
    setTimeout(() => setIsUserAddressCopying(false), 1000); // Reset copying state after 1 second
    message.success('User Address copied to clipboard!');
  };


  const columns = [
    {
      title: "User Email",
      dataIndex: "email",
      key: "email",
      width:"200px"
    },
    {
      title: "User Status",
      dataIndex: "status",
      key: "status",
      width:"150px",
      render: (_, item) => {
        if (item.address) {
            return <Badge text="Onboarded" color="green" className="flex-start-center" />;
        } else if (!item.address) {
          return <Badge text="Invited" color="orange" className="flex-start-center" />;
        } 
      },
    },
    {
      title: "User Wallet Address",
      dataIndex: "address",
      key: "address",
      width:"200px",
      render: (_, item) => {
        if (item.address) {
            return  <>{item.address.slice(0, 6)}...{item.address.slice(-4)}<CopyOutlined onClick={() => handleCopyAdminAddress(item.address)}/></>;
        } else if (!item.address) {
          return <>-</>;
        } 
      }
      // render: (_, item) => {
      //   if (item.address) {
      //       return  <div className="textalign-center w-100perc">{item.address.slice(0, 6)}...{item.address.slice(-4)}<CopyOutlined onClick={() => handleCopyAdminAddress(item.address)}/></div>;
      //   } else if (!item.address) {
      //     return <div className="textalign-center w-100perc">-</div>;
      //   } 
      // }
    },
    {
      title: "Invited on",
      dataIndex: "createdAt",
      key: "createdAt",
      width:"200px",
      render: (_, item) => {
        if (item.createdAt) {
            return dateFormatter(item.createdAt);
        } else if (!item.createdAt) {
          return '-';
        } 
      }
    },
    {
      title: "Onboarded On",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width:"200px",
      render: (_, item) => {
        if (item.createdAt!==item.updatedAt) {
            return dateFormatter(item.updatedAt);
        } else if (item.createdAt===item.updatedAt) {
          return '-';
        } 
      }
    }
  ];
  const calculateContainerHeight = () => {
    if (window.innerWidth > 1400) {
      return 400;
    } else if (window.innerWidth > 980) {
      return 360;
    } else if (window.innerWidth > 680) {
      return 350;
    } else {
      return 320;
    }
  };

  const [containerHeight, setContainerHeight] = useState(calculateContainerHeight());

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(calculateContainerHeight());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    isgetAllUsersInvitedByAdminLoading ? (
      <div
        className="flex-direction-col width-100perc"
        style={{
          height: containerHeight
        }}
      >
        <Spin />
        <h3>Loading...</h3>
      </div>
    ) : (
      <>
        <Table
          dataSource={allUsersInvitedByAdminArray && allUsersInvitedByAdminArray}
          columns={columns}
          rowKey={(record) => record._id}
          pagination={false}
          scroll={{ y: containerHeight,x:'auto' }}
        />
      </>
    )
  );
};

export default UserList;