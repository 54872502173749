import React, { useState } from 'react'
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import CryptoJS from "crypto-js";
import { openNotificationWithIcon } from '../../utils/openNotificationWithIcon';

const { Dragger } = Upload;

const AuthenticatePDF = ({storedHash}) => {
    const [defaultFileList, setDefaultFileList] = useState([]);

    const getPDFHash = async options => {
        const { onSuccess, onError, file, onProgress } = options;
    
        try {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async function () {
              const file_result = this.result; // this == reader, get the loaded file "result"
              const file_wordArr = CryptoJS.lib.WordArray.create(file_result); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
              const sha256_hash = CryptoJS.SHA256(file_wordArr); //calculate SHA1 hash
              const Hash = sha256_hash.toString(); //output result
              const comparableHash = "0x"+Hash;
              console.log("hash returned :",comparableHash);
              storedHash===comparableHash ? openNotificationWithIcon("success","PDF is authentic","PDF hash was found to be correct .") : openNotificationWithIcon("error","PDF is tampered!!!","PDF hash is not matching . Contact Admin!");
            }
        
            onSuccess("Ok");
          } catch (err) {
            console.log("Eroor: ", err);
            onError({ err });
          }
      };

      const handleOnChange = ({ file, fileList, event }) => {
        //Using Hooks to update the state to the current filelist
        setDefaultFileList(fileList);
      };
    
    return (
        <Dragger customRequest={getPDFHash} onChange={handleOnChange} defaultFileList={defaultFileList}>

        <p className="ant-upload-drag-icon">
        <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Upload workflow pdf here to check for its authenticity .
        </p>
    </Dragger>
    )
}

export default AuthenticatePDF;