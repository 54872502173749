import React from 'react';
import { Col, Row, Timeline } from 'antd';
import { useSelector } from 'react-redux';
import Spinner from '../Spinner/Spinner';
import { LinkOutlined } from '@ant-design/icons';

const AttestationTimeline = () => {
  
  const {attestationTimelineData,isWorkflowAttestationEventsLoading} = useSelector(state => state.template);
  return(
    <>
    {
      isWorkflowAttestationEventsLoading ? 
      <div className='flex height-250'><Spinner/></div> :
      <Timeline>
        {attestationTimelineData?.map((attestation, index) => 
                <Timeline.Item key={index}>
                <h3>workflow {attestation.attestation_type } &nbsp; <a target='_blank' rel="noreferrer" href={`https://polygon-mumbai.easscan.org/attestation/view/${attestation.attestationUId}`} ><LinkOutlined /></a></h3>
                <Row>
                    <Col span={10}>Attestation Type</Col> <Col span={2}>:</Col> <Col span={10}>{attestation.attestation_type}</Col>
                </Row>
                <br/>
                <Row>
                    <Col span={10}>Attestation Location</Col> <Col span={2}>:</Col> <Col span={10}>{attestation.location}</Col>
                </Row>
                <br/>
                </Timeline.Item>
            )}
        </Timeline>
    }
    </>
    );
};
export default AttestationTimeline;
