import { Modal , Form} from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createTemplateModal } from '../../redux/slices/templateSlice';
import AdminCustomForm from '../AdminDashboardForm/AdminCustomForm';

const ModalComponent = ({type,createTempModal}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const capitalize = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    
    return (
        <Modal
        title={`${type && capitalize(type)} Workflow Template`}
        centered
        open={createTempModal}
        // onOk={() => setModal1Open(false)}
        onCancel={() => {
            dispatch(createTemplateModal({
                type: "",
                visible: false,
                record: { name: "", fields: [""] },
            }));
            form.resetFields();
        }
        }
        width="1000px"
        maskClosable={false}
        // style={{margin:"0 auto"}}
        footer={false}
        >
            {type==="create" && <AdminCustomForm form={form} type={type} />}
            {type==="edit" && <AdminCustomForm form={form} type={type}  />}
        </Modal>
    )
}

export default ModalComponent