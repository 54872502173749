import React, { useDebugValue, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, Card, Col, Divider, Input, Pagination, Row, Select, Space } from 'antd';
import MainLayout from '../../components/MainLayout/MainLayout';import { useDispatch, useSelector } from 'react-redux';
import { superadminGetWorkflowsByAdminId } from '../../redux/actions/template';
import { setSuperadminWorkflowsByAdminCurrentPage, setSuperadminWorkflowsByAdminElePerPage } from '../../redux/slices/superAdminSlice';
import Spinner from '../../components/Spinner/Spinner';
const {Option} = Select; 

const AdminSurveysForSuperAdmin = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { superadminWorkflowsByAdminCurrentPage,superadminWorkflowsByAdminElePerPage,superadminWorkflowsByAdminArrayEleOrderBy,superadminWorkflowsByAdminArrayEleSortBy } = useSelector((state)=>state.superAdmin);
  const {isSuperadminGetWorkflowsByAdminIdLoading,adminWorkflowArrayForSuperAdmin,adminWorkflowArrayForSuperAdminCount} = useSelector(state => state.template);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(()=>{
    dispatch(superadminGetWorkflowsByAdminId({adminId:location.state.adminId,limit:superadminWorkflowsByAdminElePerPage,orderBy:superadminWorkflowsByAdminArrayEleOrderBy,sortBy:superadminWorkflowsByAdminArrayEleSortBy,page:superadminWorkflowsByAdminCurrentPage}))
  },[dispatch,location.state.adminId,superadminWorkflowsByAdminElePerPage,superadminWorkflowsByAdminArrayEleOrderBy,superadminWorkflowsByAdminArrayEleSortBy,superadminWorkflowsByAdminCurrentPage]);

  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = adminWorkflowArrayForSuperAdmin?.filter((item) => {
      const surveyName = item.name.toLowerCase();
      const formId = item.workflowID.toLowerCase();
      return (
        (surveyName.includes(query) || formId.includes(query))
      );
    });
    setFilteredData(filtered);
  };
  
  
  const handleChangePage = (page) => {
    dispatch(setSuperadminWorkflowsByAdminCurrentPage(page));
    setSearchQuery(''); // Reset search query when changing pages
    dispatch(superadminGetWorkflowsByAdminId({adminId:location.state.adminId,limit:superadminWorkflowsByAdminElePerPage,orderBy:superadminWorkflowsByAdminArrayEleOrderBy,sortBy:superadminWorkflowsByAdminArrayEleSortBy,page:page}))
  };
  
  const handleChangeCardsPerPage = (value) => {
    dispatch(setSuperadminWorkflowsByAdminElePerPage(value));
    dispatch(setSuperadminWorkflowsByAdminCurrentPage(1));
    dispatch(superadminGetWorkflowsByAdminId({adminId:location.state.adminId,limit:value,orderBy:superadminWorkflowsByAdminArrayEleOrderBy,sortBy:superadminWorkflowsByAdminArrayEleSortBy,page:1}))
  };

  const getStatusText = (statusEnum) => {
    switch (statusEnum) {
      case 'ASSIGNED':
        return "Assigned";
      case 'SUBMITTED':
        return "Submitted";
      case 'APPROVED':
        return "Approved";
      case 'REJECTED':
        return "Rejected";
      case 'SIGNED':
        return "Signed";
      default:
        return "";
    }
  };

  const getStatusColor = (statusEnum) => {
    switch (statusEnum) {
      case 'ASSIGNED':
        return "orange";
      case 'SUBMITTED':
        return "blue";
      case 'APPROVED':
        return "#800080";
      case 'REJECTED':
        return "red";
      case 'SIGNED':
        return "green";
      default:
        return "black";
    }
  };

  const surveyDescription = (item) => (
    <div className='flex-center-direction-col'>
      <Row>
        <Col span={10} className="bold-font" >
          Workflow Name
        </Col>
        <Col span={2} className="bold-font" >
        : 
        </Col>
        <Col span={12}>
          {item.name && item.name.length > 15 ? item.name.substring(0, 15) + '...' : item.name}
        </Col>
      </Row>
      <Row>
        <Col span={10} className="bold-font" >
            Workflow Id
          </Col>
          <Col span={2} className="bold-font" >
          : 
          </Col>
          <Col span={12}>
            {item.workflowID}
          </Col>
      </Row>
    </div>
  );
  
  const navigate = useNavigate();
  const toVerifyPage = (workflowID) => {
    navigate(`/superadmin-dashboard/adminWorkflow?workflowId=${workflowID}`,{state:{contract_address:location.state.contract_address}});
  }

  return (
    <MainLayout>
        <Row justify={'space-between'} >
                      <h1>Assigned Workflows</h1>
                      <div className='flex-and-align-center'>
                            <Col>
                              <Space>
                                <Input
                                  size='large'
                                  placeholder='Search For Workflows'
                                  value={searchQuery}
                                  onChange={handleSearchInputChange}
                                />
                              </Space>
                            </Col>
                      </div>
                    </Row>
                      {searchQuery && filteredData.length === 0 && (
                        <h1 className='textalign-center'>No matching workflows found.</h1>
                      )}
                      {(!searchQuery || filteredData.length > 0) && (
                        <>
                          <Row gutter={[20,20]} className='admin-dashboard-card-container'>
                          {
                            isSuperadminGetWorkflowsByAdminIdLoading ? <div className='flex-direction-col width-height-100perc'><Spinner/><h3>Loading...</h3></div> :
                            (searchQuery ? filteredData : (adminWorkflowArrayForSuperAdmin)).map((item) => (
                              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                                <Badge.Ribbon text={getStatusText(item.status)} color={getStatusColor(item.status)}>
                                  <Card
                                    cover={<div className='flex mt-3'><img src="/assets/images/submit-survey-icon.png" className='workflow-card-icon' alt="Survey" /></div>}
                                    key={item.workflowID}
                                    className='workflow-card'
                                    hoverable
                                    onClick={() => toVerifyPage(item.workflowID)}
                                  >
                                    <Divider />
                                    {surveyDescription(item)}
                                  </Card>
                                </Badge.Ribbon>
                              </Col>
                            )) 
                          }
                          </Row>
                          <Row justify='space-between' align='middle' className='mt-1'>
                            <Col>
                              <Select value={superadminWorkflowsByAdminElePerPage} onChange={handleChangeCardsPerPage}>
                                <Option value={12}>12 per page</Option>
                                <Option value={20}>20 per page</Option>
                                <Option value={40}>40 per page</Option>
                                <Option value={100}>100 per page</Option>
                              </Select>
                            </Col>
                            <Col>
                              <Pagination
                                current={superadminWorkflowsByAdminCurrentPage}
                                onChange={handleChangePage}
                                pageSize={superadminWorkflowsByAdminElePerPage}
                                total={adminWorkflowArrayForSuperAdminCount}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
    </MainLayout>
  )
}

export default AdminSurveysForSuperAdmin