import { Badge, Card, Col, Divider, Input, Pagination, Row, Select, Space} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/MainLayout/MainLayout';
import "./SubmitSurvey.scss";
import { getWorkflowsForSubmission } from '../../../redux/actions/template';
import { setSubmitWorkflowsCurrentPage, setSubmitWorkflowsElePerPage } from '../../../redux/slices/userSlice';

const { Option } = Select;

const SubmitSurvey = () => {
  let { adminId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { totalWorkflowsForSubmissionCount , isWorkflowsForSubmissionLoading , workflowArrayForSubmission } = useSelector((state) => state.template);
  const { submitWorkflowsCurrentPage,submitWorkflowsElePerPage,submitWorkflowsEleOrderBy,submitWorkflowsEleSortBy } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true); // Track initial loading state

  useEffect(() => {
    dispatch(getWorkflowsForSubmission({limit:submitWorkflowsElePerPage,orderBy:submitWorkflowsEleOrderBy,sortBy:submitWorkflowsEleSortBy,page:submitWorkflowsCurrentPage,contract_address:location.state.contract_address})).then(() => {
      setIsLoading(false); // Set loading state to false after data is fetched
    });
  }, [dispatch,submitWorkflowsCurrentPage,submitWorkflowsElePerPage,submitWorkflowsEleOrderBy,submitWorkflowsEleSortBy,location.state.contract_address]);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = workflowArrayForSubmission && workflowArrayForSubmission?.filter((item) => {
      const workflowName = item.name.toLowerCase();
      const workflowID = item.workflowID.toLowerCase();
      return (
        (workflowName.includes(query) || workflowID.includes(query))
      );
    });
    setFilteredData(filtered);
  };
  
  const handleChangePage = (page) => {
    dispatch(setSubmitWorkflowsCurrentPage(page));
    setSearchQuery(''); // Reset search query when changing pages
    dispatch(getWorkflowsForSubmission({limit:submitWorkflowsElePerPage,orderBy:submitWorkflowsEleOrderBy,sortBy:submitWorkflowsEleSortBy,page:page,contract_address:location.state.contract_address}))
  };
  
  const handleChangeCardsPerPage = (value) => {
    dispatch(setSubmitWorkflowsElePerPage(value));
    dispatch(setSubmitWorkflowsCurrentPage(1));
    dispatch(getWorkflowsForSubmission({limit:value,orderBy:submitWorkflowsEleOrderBy,sortBy:submitWorkflowsEleSortBy,page:1,contract_address:location.state.contract_address}))
  };
  
  const surveyDescription = (item) => (
    <div className='flex-center-direction-col'>
      <Row >
        <Col span={10} className='bold-font'>
          Workflow Name
        </Col>
        <Col span={2} className='bold-font'>
        : 
        </Col>
        <Col span={10}>
          {item.name && item.name.length > 15 ? item.name.substring(0, 15) + '...' : item.name}
        </Col>
      </Row>
      <Row>
        <Col span={10} className='bold-font'>
            Workflow Id
          </Col>
          <Col span={2} className='bold-font'>
          : 
          </Col>
          <Col span={10}>
            {item.workflowID}
          </Col>
      </Row>
    </div>
  );

  const toSubmitterPage = ({workflowID,_id}) => {
    navigate(`/submit-workflow/${adminId}/${workflowID}`,{state:{contract_address:location.state.contract_address,adminId,_id}});
  };

  const getStatusText = (statusEnum) => {
    switch (statusEnum) {
      case 'ASSIGNED':
        return "Assigned";
      case 'SUBMITTED':
        return "Submitted";
      case 'APPROVED':
        return "Approved";
      case 'REJECTED':
        return "Rejected";
      case 'SIGNED':
        return "Signed";
      default:
        return "";
    }
  };

  const getStatusColor = (statusEnum) => {
    switch (statusEnum) {
      case 'ASSIGNED':
        return "orange";
      case 'SUBMITTED':
        return "blue";
      case 'APPROVED':
        return "#800080";
      case 'REJECTED':
        return "red";
      case 'SIGNED':
        return "green";
      default:
        return "black";
    }
  };
  
  return (
      <MainLayout>
          <Row justify={'space-between'}>
            <h1>Submit Workflows</h1>
            <div className='flex-and-align-center'>
                  <Col>
                    <Space>
                      <Input
                        size='large'
                        placeholder='Search For Workflows'
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                      />
                    </Space>
                  </Col>
            </div>
          </Row>
            {searchQuery && filteredData?.length === 0 && (
              <h1 className='textalign-center'>No matching workflows found.</h1>
            )}
            {(!searchQuery || filteredData.length > 0) && (
              <>
              <Row gutter={[20,20]} className='cards-container'>       
              { isWorkflowsForSubmissionLoading ? <div className='flex-direction-col width-height-100perc'><Spinner/><h3>Loading...</h3></div> :
                (searchQuery ? filteredData : workflowArrayForSubmission).map((item) => (
                  <Col xs={24} sm={24} md={12} lg={8} xl={6} key={item.workflowID}>
                    <Badge.Ribbon text={getStatusText(item.status)} color={getStatusColor(item.status)}>
                      <Card
                        cover={<div className='flex mt-3'><img src="/assets/images/submit-survey-icon.png" alt="Survey" className='workflow-card-icon'/></div>}
                        key={item.workflowID}
                        className='workflow-card'
                        hoverable
                        onClick={() => toSubmitterPage({workflowID:item.workflowID,_id:item._id})}
                      >
                        <Divider />
                        {surveyDescription(item)}
                      </Card>
                    </Badge.Ribbon>
                  </Col>
                ))
              }
              </Row>
              <Row justify='space-between' align='middle' className='mt-1'>
                <Col>
                  <Select value={submitWorkflowsElePerPage} onChange={handleChangeCardsPerPage}>
                    <Option value={12}>12 per page</Option>
                    <Option value={20}>20 per page</Option>
                    <Option value={40}>40 per page</Option>
                    <Option value={100}>100 per page</Option>
                  </Select>
                </Col>
                <Col>
                  <Pagination
                    showSizeChanger={false}
                    hideOnSinglePage={true}
                    current={submitWorkflowsCurrentPage}
                    onChange={handleChangePage}
                    pageSize={submitWorkflowsElePerPage}
                    total={totalWorkflowsForSubmissionCount}
                  />
                </Col>
              </Row>
              </>
            )}
      </MainLayout>
    )
};

export default SubmitSurvey;