import { createAsyncThunk } from "@reduxjs/toolkit"; 
import { web3auth } from "../../utils/Web3Auth";
import Web3 from "web3";
import RPC from "../../web3RPC";
import { openNotificationWithIcon } from "../../utils/openNotificationWithIcon";
import { getAllSurveys, getEvents, getSubmittedSurveyUI, getSurveyId, getWorkflowAttestationEvents, mapSurveyIdToFormId, saveEvent, setCommentToRejectedSurvey, submitCreatedSurvey, submitRejectedSurveyAgain, updateWorkflowStatusAPI } from "./template";
import axios from "axios";
import { resetFormDataRedux, setCurrent } from "../slices/adminSlice";
import {contractABI,contractAddress} from "../../utils/contractABIAndAddress";
import { resetSignatureUploadingAndPDFUploadingText, setSignatureUploadingAndPDFUploadingText } from "../slices/templateSlice";
import { transferEWarrentOwnership } from "./eWarrantDeployer";
import { onchainAttestWorkflowApproval, onchainAttestWorkflowAssignment, onchainAttestWorkflowSignature, onchainAttestWorkflowSubmission } from "./attestations";
import { generateBytes32Hash, generateBytes32HashOfString } from "../../utils/attestationUtils";

const BACKENDURL='https://backend.xvault.thenftbrewery.com';

const commonCalls = async()=> {
	try {
    const Web3Auth = await web3auth(); // function for this 
    const web3authProvider = await Web3Auth.connect();
    const web3 = new Web3(web3authProvider);       
    const fromAddress = (await web3.eth.getAccounts())[0];
    const myContract = new web3.eth.Contract(contractABI,contractAddress);
	const balanceInWei = await web3.eth.getBalance(fromAddress);
	const balance = web3.utils.fromWei(balanceInWei, 'ether');
	// const rpc = new RPC(Web3Auth.provider);
	// const privateKey = await rpc.getPrivateKey(); // private key log hogi
	// console.log("logged user private key : ",privateKey);

    return {myContract,fromAddress,web3,balance};
	}
	catch(error){
		console.log("commonCalls error :",error);
	}
};

export const getEthBalance = createAsyncThunk('contract/getEthBalance',async(_,thunkAPI)=>{
	try{
	const {balance} = await commonCalls();
	return balance;
	} catch(error) {
		console.log("getEthBalance action error :",error)
	}
});

// ok
export const getContractData = createAsyncThunk("contract/getContractData",async(data,thunkAPI)=> {
	try {
		const {myContract,fromAddress,web3,balance} = await commonCalls();
		const userEmail = localStorage.getItem('email');
		const rpc = new RPC(web3);
		const loggedAccount = await rpc.getAccounts();
		console.log("LoggedAccount :",loggedAccount);
		const contractAdmin = await myContract.methods.admin().call();
		console.log("eth balance :",balance)
		// thunkAPI.dispatch(getBalanceOfUser({myContract,fromAddress}));
		const isLoggedUserContractAdmin = fromAddress === contractAdmin;
		const data = {email:userEmail,address:loggedAccount};
		// isLoggedUserContractAdmin===false && thunkAPI.dispatch(getUserInvitedRole(data));
		return {contractAdmin,loggedAccount,isLoggedUserContractAdmin,balance};
	}
	catch(error){
		console.log("getFormCount error :",error);
	}
});

// looks ok , needs testing
export const submitPDF = createAsyncThunk("contract/submitPDF",async(data,thunkAPI) =>{
		try{
			const {web3,fromAddress} = await commonCalls();
			const myContract = new web3.eth.Contract(contractABI,data.contract_address);
			const receipt = await myContract.methods.submitPDF(data.formId).send({from:fromAddress});
			console.log("submitPDF receipt : ",receipt);
			// thunkAPI.dispatch(getBalanceOfUser({myContract,fromAddress}));
			const eventData = {formId:data.formId,obj:{PDFSubmitted	: receipt.events.PDFSubmitted}};
			receipt.status===true && await thunkAPI.dispatch(saveEvent(eventData));
			receipt.status===true && await thunkAPI.dispatch(submitCreatedSurvey(data));
			console.log("before sorting array :",data.fields);			
			const sortedArray = data.fields?.map(obj => {
				const sortedObject = {label:obj.label , value :obj.dataType===9 ? obj.value.toString() : obj.value};
				return sortedObject;
			});
			console.log("after sorting array :",sortedArray);			

			const encodedFieldDataToBytes32 = await generateBytes32Hash(sortedArray);
			console.log("encodeFieldDataToBytes32 value :",encodedFieldDataToBytes32);
			const attestationData = {workflowUId:data.formId,workflowContentHash:encodedFieldDataToBytes32,role:1,referencedAttestationUId : data.referencedAttestation};
			await thunkAPI.dispatch(onchainAttestWorkflowSubmission(attestationData));
			thunkAPI.dispatch(getEthBalance());
      		return receipt.status;
		} catch(error) {
			openNotificationWithIcon('error',`Blockchain Error : Survey Submission Unsuccessful`);
			console.log("submitPDF eWarrant action error :",error);
		}
});

export const submitRejectedPDF = createAsyncThunk("contract/submitRejectedPDF",async(data,thunkAPI) =>{
	try{
		const {web3,fromAddress} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,data.contract_address);
		const receipt = await myContract.methods.submitPDF(data.formId).send({from:fromAddress});
		console.log("submitPDF receipt : ",receipt);
		// thunkAPI.dispatch(getBalanceOfUser({myContract,fromAddress}));
		const eventData = {formId:data.formId,obj:{PDFSubmitted	: receipt.events.PDFSubmitted}};
		receipt.status===true && await thunkAPI.dispatch(saveEvent(eventData));
		receipt.status===true && await thunkAPI.dispatch(submitRejectedSurveyAgain(data));
		console.log("before sorting array :",data.fields);			
		const sortedArray = data.fields?.map(obj => {
			const sortedObject = {label:obj.label , value :obj.dataType===9 ? obj.value.toString() : obj.value};
			return sortedObject;
		});
		console.log("after sorting array :",sortedArray);			

		const encodedFieldDataToBytes32 = await generateBytes32Hash(sortedArray);
		console.log("encodeFieldDataToBytes32 value :",encodedFieldDataToBytes32);
		const attestationData = {workflowUId:data.formId,workflowContentHash:encodedFieldDataToBytes32,role:1,referencedAttestationUId : data.referencedAttestation};
		await thunkAPI.dispatch(onchainAttestWorkflowSubmission(attestationData));
		thunkAPI.dispatch(getEthBalance());
		return receipt.status;
	} catch(error) {
		openNotificationWithIcon('error',`Blockchain Error : Survey ReSubmission Unsuccessful`);
		console.log("submitRejectedPDF eWarrant action error :",error);
	}
});

// looks ok , needs testing ( this function is called when submitter needs to get his url)
export const getPDFUrl = createAsyncThunk("contract/getPDFUrl",async({formId},thunkAPI) => {
	try{
		const {myContract,fromAddress} = await commonCalls();
		const submitterInfo = await myContract.methods.formIdToFormDataAdmin(formId).submitterInfo(fromAddress).call();
		console.log("submitter Info corresponding to formId logged :",submitterInfo); // submitter info contains , submitterPDFHash and submitterPDFUrl
		return submitterInfo.submitterPDFUrl;
	} catch(error) {
		console.log("getPDFUrl function error :",error);
	}
});

// ok
export const setFormData = createAsyncThunk("contract/setFormData",async({surveyId,formId,submitterAddr,approverAddrArr,confirmations,contract_address,workflowVersion,referencedWorkflowAttestationUId,submitter,approvers},thunkAPI) => {
	try {
		const {web3,fromAddress} = await commonCalls();
		console.log('inside setFormData')
		console.log("fromAddress :",fromAddress);
		console.log("formId from params :",formId);
		console.log("submitter from params :",submitterAddr);
		console.log("approvers array from params :",approverAddrArr);
		console.log("approverConfirmations :",confirmations);
		console.log("contract address :",contract_address);
		const myContract = new web3.eth.Contract(contractABI,contract_address);
		console.log("checking if contract is initialized :",myContract);
		const receipt = await myContract.methods.setFormData(formId,submitterAddr,approverAddrArr,confirmations).send({from:fromAddress});
		console.log("receipt of ewarrent setFormData method :",receipt);
		receipt.status === true && await thunkAPI.dispatch(mapSurveyIdToFormId({surveyId,formId,version:workflowVersion,datasetProvider:submitter,datasetApprovers:approvers}));
		const eventData = {formId:formId,obj:{SetFormData : receipt.events.SetFormData}};
		receipt.status === true && await thunkAPI.dispatch(saveEvent(eventData));
		const workflowTIdHash = await generateBytes32HashOfString(surveyId);
		const attestationData = {workflowUId:formId , workflowTIdHash:workflowTIdHash , submitter:submitterAddr , approvers:approverAddrArr , confirmationsRequired:confirmations , version:workflowVersion,role:0,referencedWorkflowAttestationUId:referencedWorkflowAttestationUId};
		await thunkAPI.dispatch(onchainAttestWorkflowAssignment(attestationData));
		thunkAPI.dispatch(getEthBalance());
		thunkAPI.dispatch(resetFormDataRedux());
		thunkAPI.dispatch(setCurrent(0));
		return receipt.status; // returns true when successfully written to the blockchain state
	} catch(error) {
		openNotificationWithIcon('error','Blockchain Error : Something went wrong . Try again!');
		console.log("setFormData calling error :",error);
	}
})

// ok
export const grantRoleFunc = createAsyncThunk("contract/grantRoleFunc",async({formId,address,role},thunkAPI)=>{
  try{
    const {myContract,fromAddress} = await commonCalls();
	console.log("formId from params grantRoleFunc : ",formId);
    console.log("address from params grantRoleFunc : ",address);
    console.log("role from params grantRoleFunc : ",role);
    const receipt = await myContract.methods.grantRole(formId,address,role).send({from:fromAddress});
	thunkAPI.dispatch(getEthBalance());
    // console.log("grantRoleFunc receipt : ",receipt);
    console.log("grantRoleFunc receipt status : ",receipt.status);
    return receipt.status; // returns true when successfully written to the blockchain state
  }
  catch(error) {
    console.log("grantRoleFunc calling error :",error);
  }
})

// ok
export const revokeRoleFunc = createAsyncThunk("contract/revokeRoleFunc",async({formId,address,role},thunkAPI)=>{
  try{
    const {myContract,fromAddress} = await commonCalls();
	console.log("formId from params revokeRoleFunc : ",formId);
    console.log("address from params revokeRoleFunc: ",address);
    console.log("role from params revokeRoleFunc: ",role);
    const receipt = await myContract.methods.revokeRole(formId,address,role).send({from:fromAddress});
    console.log("revokeRole receipt status :",receipt.status);
	receipt.status === true && openNotificationWithIcon('success',`Revoked ${role} from ${address} for formId ${formId}`)
	thunkAPI.dispatch(getEthBalance());
    return receipt.status; // returns true when successfully written to the blockchain state
  }
  catch(error) {
	openNotificationWithIcon('error','Revoke Role Function calling error .');
    console.log("revokeRoleFunc calling error :",error);
  }
})

// ok
export const getPDFDataApprover = createAsyncThunk("contract/getPDFDataApprover",async(data,thunkAPI) => {
	try{
		console.log("getPDFData approver started");
		const Web3Auth = await web3auth(); // function for this 
		const web3authProvider = await Web3Auth.connect();
		const web3 = new Web3(web3authProvider);       
		const myContract = new web3.eth.Contract(contractABI,contractAddress);	
		const formIdToFormData = await myContract.methods.formIdToFormDataAdmin(data).call();  
		console.log("formIdToFormData :",formIdToFormData);
		const pdfSubmitter = formIdToFormData.submitter;
		console.log("pdfSubmitter :",pdfSubmitter);
		const pdfSubmitterInfo = await myContract.methods.submitterInfo(data,pdfSubmitter).call();
		console.log("pdfSubmitterInfo corresponding to submitter address :",pdfSubmitterInfo);
		const {submitterPDFUrl,submitterPDFHash} = pdfSubmitterInfo;
		const pdfURLForApprover = submitterPDFUrl;
		const pdfHashForApprover = submitterPDFHash;
		if(!Web3Auth) {
			console.log("web3auth not initialized yet")
			return;
		}
		const rpc = new RPC(Web3Auth.provider);
		const privateKey = await rpc.getPrivateKey(); // private key log hogi
		console.log("logged user private key : ",privateKey);
		const pdfData = {pdfURLForApprover,pdfHashForApprover,privateKey};
		return pdfData;
	} catch(error) {
		console.log("getPDFDataApprover function error :",error);
	}
});

// ok // before 16 may wala
// export const approveSubmittedPDF = createAsyncThunk("contract/approveSubmittedPDF",async({formId,signature,pdfUrl,pdfHash},thunkAPI) => {
// 	try{
// 		const {myContract,fromAddress} = await commonCalls();
// 		let pdfURLBackup;
// 		pdfURLBackup = pdfUrl==="" ?  '' : pdfUrl;
// 		let pdfHashBackup;
// 		pdfHashBackup = pdfHash===""? '0x0000000000000000000000000000000000000000000000000000000000000000':pdfHash;
// 		const checkdata = {formId,signature,pdfURLBackup,pdfHashBackup};
// 		console.log("checkdata before sending to blockchain :",checkdata);
// 		const pdfSignatureReceipt = await myContract.methods.approveSubmittedPDF(formId,signature,pdfURLBackup,pdfHashBackup).send({from:fromAddress});
// 		thunkAPI.dispatch(getBalanceOfUser({myContract,fromAddress}));
// 		pdfSignatureReceipt.status === true && openNotificationWithIcon("success","Approved Successfully");
// 		return pdfSignatureReceipt.status;
// 	} catch(error) {
// 		console.log("approverSignPDF function error :",error);
// 		openNotificationWithIcon("error","Error in approving submitted pdf");
// 	}
// })

// ok
export const approveSubmittedPDF = createAsyncThunk("contract/approveSubmittedPDF",async({formId,contract_address,dataToBeHashed,referencedAttestation,_id,whetherToPatchApproveWorkflowAPI},thunkAPI) => {
	try{
		console.log("approveSubmittedPDF called")
		const {web3,fromAddress} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,contract_address);
		const pdfSignatureReceipt = await myContract.methods.approveSubmittedPDF(formId).send({from:fromAddress});
		const eventData = {formId:formId,obj:{PDFApproved : pdfSignatureReceipt.events.PDFApproved}};
		pdfSignatureReceipt.status===true && thunkAPI.dispatch(saveEvent(eventData));
		const statusData = {ID:_id,Status:"APPROVED"};
		pdfSignatureReceipt.status===true && whetherToPatchApproveWorkflowAPI && await thunkAPI.dispatch(updateWorkflowStatusAPI(statusData));
		console.log("data hashed array logged before sorting :",dataToBeHashed);
		const sortedArray = dataToBeHashed?.map(obj => {
			const sortedObject = {label:obj.label, value : obj.value};
			return sortedObject;
		});
		console.log("after sorting array :",sortedArray);			
		const encodedFieldDataToBytes32 = await generateBytes32Hash(sortedArray);
		console.log("encodeFieldDataToBytes32 value :",encodedFieldDataToBytes32);
		const attestationData = {workflowUId:formId,workflowContentHash:encodedFieldDataToBytes32,action:"approved",role:2,referencedAttestationUId : referencedAttestation,attestation_type:"approval"};
		await thunkAPI.dispatch(onchainAttestWorkflowApproval(attestationData));
		thunkAPI.dispatch(getEthBalance());
		return pdfSignatureReceipt.status;
	} catch(error) {
		console.log("approverSignPDF function error :",error);
		openNotificationWithIcon("error","Blockchain Error : Error in approving submitted pdf");
	}
})

// ok
export const rejectSubmittedPDF = createAsyncThunk("contract/rejectSubmittedPDF",async(data,thunkAPI) => {
	try{
		const {web3,fromAddress} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,data.contract_address);
		const rejectSubmittedPDFReceipt = await myContract.methods.rejectSubmittedPDF(data.formId).send({from:fromAddress});
		const sortedArray = data.dataToBeHashed?.map(obj => {
			const sortedKeys = Object.keys(obj).sort();
			const sortedObject = {};
			sortedKeys.forEach(key => {
			sortedObject[key] = obj[key];
			});
			return sortedObject;
		});
		console.log("sorted data logged after a function call :",sortedArray);
		const encodedFieldDataToBytes32 = await generateBytes32Hash(sortedArray);
		console.log("encodeFieldDataToBytes32 value :",encodedFieldDataToBytes32);
		console.log("rejectSubmittedPDFReceipt receipt : ",rejectSubmittedPDFReceipt);
		rejectSubmittedPDFReceipt.status===true && await thunkAPI.dispatch(setCommentToRejectedSurvey(data));
		const eventData = {formId:data.formId,obj:{PDFRejected : rejectSubmittedPDFReceipt.events.PDFRejected}};
		rejectSubmittedPDFReceipt.status===true && await thunkAPI.dispatch(saveEvent(eventData));
		const statusData = {ID:data._id,Status:"REJECTED"};
		rejectSubmittedPDFReceipt.status===true && await thunkAPI.dispatch(updateWorkflowStatusAPI(statusData));
		const attestationData = {workflowUId:data.formId,workflowContentHash:encodedFieldDataToBytes32,action:"rejected",role:2,referencedAttestationUId : data.referencedAttestation,attestation_type:"rejection"};
		await thunkAPI.dispatch(onchainAttestWorkflowApproval(attestationData));
		// thunkAPI.dispatch(getBalanceOfUser({myContract,fromAddress}));
		thunkAPI.dispatch(getEthBalance());
		return rejectSubmittedPDFReceipt.status;
	} catch(error) {
		openNotificationWithIcon('error','Error when rejecting Submitted PDF');
		console.log("rejectSubmittedPDFReceipt function error :",error);
	}
})

// ok
export const formIdToStatusObjectAsAdmin = createAsyncThunk("contract/formIdToStatusObjectAsAdmin",async(data,thunkAPI)=>{
	try{
		let res = await axios.get(`${BACKENDURL}/api/role/getAdminByid?id=${data}`);
		console.log("admin details of this user :",res.data.data[0]);
		const contract_address = res.data.data[0].contract_address;
		console.log("formIdToStatusObjectAsAdmin action ke andar : ");
		const {web3,fromAddress} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,contract_address);
		const createdFormIdArray = await myContract.methods.getAdminAllFormIds().call({from:fromAddress});
		console.log("created formId Array by admin :",createdFormIdArray);
		const promisesFormStatusAsAdmin = Array.from({length: createdFormIdArray.length}, async (_, i) => {
			const id = createdFormIdArray[i]; // formID // created
			const formStatus = await myContract.methods.getFormStatusEnum(id).call();
			const formIdToFormDataAdmin = await myContract.methods.formIdToFormDataAdmin(id).call();
			const submitter = formIdToFormDataAdmin.submitter;
			const approvers = await myContract.methods.getApprovers(id).call();
			let surveyName = await axios.get(`${BACKENDURL}/api/survey/getSurveyName/?formId=${id}`).then((res)=>res.data.workflowName);
			// const surveyName = res.data.data[0].template.name;
			// console.log("surveyName each one:",surveyName)
			switch(formStatus) {
				case "0" : {
							const retObj = {
								formId:id,
								formStatus: "Assigned To Submitter",
								submitter,
								approvers,
								surveyName,
								statusEnum:formStatus

							}
							return retObj;
				}
				case "1" : {
					const retObj = {
						formId:id,
						formStatus: "Assigned To Approver(s)",
						submitter,
						approvers,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "2" : {
					const retObj = {
						formId:id,
						formStatus: "Approved By Approver(s)",
						submitter,
						approvers,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "3" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Approver(s)",
						submitter,
						approvers,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "4" : {
					const retObj = {
						formId:id,
						formStatus: "Verified By Admin",
						submitter,
						approvers,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "5" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Admin",
						submitter,
						approvers,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				default : return "Error!";
			}
		});
		const formStatusArrayAsAdmin = await Promise.all(promisesFormStatusAsAdmin);
		thunkAPI.dispatch(getAllSurveys());
		console.log("form status array of objects as admin :",formStatusArrayAsAdmin);
		return {formStatusArrayAsAdmin,contract_address};
	} catch(error) {
		console.log("formStatusAsApprover error",error);
	}
});

// ok // 8 aug checked
export const formIdSubmitterData = createAsyncThunk("contract/formIdSubmitterData",async(data,thunkAPI)=>{
	try{
		const {web3} = await commonCalls();
		const user = localStorage.getItem('user');
		const myContract = new web3.eth.Contract(contractABI,data); // contract address from params
		const countTotalFormsAsSubmitter = await myContract.methods.countTotalFormsAsSubmitter(user).call();
		console.log("total forms for this address was assigned role submitter are :",countTotalFormsAsSubmitter);
		const formIdArrayForSubmitter = await myContract.methods.getFormIdsBySubmitter().call({from:user});
		console.log("getFormIdsBySubmitter array :",formIdArrayForSubmitter);

		const promisesFormStatusAsSubmitter = Array.from({length: countTotalFormsAsSubmitter}, async (_, i) => {
			const id = formIdArrayForSubmitter[i];
			const formIdToFormData = await myContract.methods.formIdToFormDataAdmin(id).call();
			const formStatus = formIdToFormData.statusEnum;
			const numConfirmationsRequired = formIdToFormData.numConfirmationsRequired;
			const approverConfirmationCount = formIdToFormData.approverConfirmationCount;
			// new api to be integrated here ( assigned to Kartik )
			let surveyName = await axios.get(`${BACKENDURL}/api/survey/getSurveyName/?formId=${id}`).then((res)=>res.data.workflowName);

			switch(formStatus) {
				case "0" : {
							const retObj = {
								formId:id,
								formStatus: "Assigned To Submitter",
								confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
								currentConfirmations:approverConfirmationCount,
								surveyName,
								statusEnum:formStatus
							}
							return retObj;
				}
				case "1" : {
					const retObj = {
						formId:id,
						formStatus: "Assigned To Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "2" : {
					const retObj = {
						formId:id,
						formStatus: "Approved By Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "3" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus // enum value of formStatus
					}
					return retObj;
				}
				case "4" : {
					const retObj = {
						formId:id,
						formStatus: "Verified By Admin",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus // enum value of formStatus
					}
					return retObj;
				}
				case "5" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Admin",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus // enum value of formStatus
					}
					return retObj;
				}
				default : return "Error!";
			}
		});
		const formIdToSubmitterInfo = await Promise.all(promisesFormStatusAsSubmitter);
		console.log("form id se submitter UI tak :",formIdToSubmitterInfo);
		return formIdToSubmitterInfo;
	} catch(error) {
		console.log("formStatusAsSubmitter error",error);
	}
});

// ok
export const formIdApproverData = createAsyncThunk("contract/formIdApproverData",async(data,thunkAPI)=>{
	try{
		const user = localStorage.getItem('user');
		const {web3} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,data); // contract address from params
		const countTotalFormsAsApprover = await myContract.methods.countTotalFormsAsApprover(user).call();
		console.log("total forms for this address was assigned role approver are :",countTotalFormsAsApprover);
		const formIdArrayForApprover = await myContract.methods.getFormIdsByApprover().call({from:user});
		console.log("getFormIdsByApprover array :",formIdArrayForApprover);
		const promisesFormStatusAsApprover = Array.from({length: countTotalFormsAsApprover}, async (_, i) => {
			const id = formIdArrayForApprover[i];
			console.log("fid :",id);
			const formIdToFormData = await myContract.methods.formIdToFormDataAdmin(id).call();
			const formStatus = formIdToFormData.statusEnum;
			const numConfirmationsRequired = formIdToFormData.numConfirmationsRequired;
			const approverConfirmationCount = formIdToFormData.approverConfirmationCount;
			let surveyName = await axios.get(`${BACKENDURL}/api/survey/getSurveyName/?formId=${id}`).then((res)=>res.data.workflowName);

			switch(formStatus) {
				case "0" : {
							const retObj = {
								formId:id,
								formStatus: "Assigned To Submitter",
								confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
								currentConfirmations:approverConfirmationCount,
								surveyName,
								statusEnum:formStatus
							}
							return retObj;
				}
				case "1" : {
					const retObj = {
						formId:id,
						formStatus: "Assigned To Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "2" : {
					const retObj = {
						formId:id,
						formStatus: "Approved By Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "3" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "4" : {
					const retObj = {
						formId:id,
						formStatus: "Verified By Admin",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "5" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Admin",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				default : return "Error!";
			}
		});
		const formIdToApproverInfo = await Promise.all(promisesFormStatusAsApprover);
		console.log("form id se approver UI tak :",formIdToApproverInfo);
		return formIdToApproverInfo;
	} catch(error) {
		console.log("formIdApproverData error",error);
	}
});

// ok
export const verifyPDF = createAsyncThunk("contract/verifyPDF",async(data,thunkAPI) =>{ 
	try{
		const {myContract,fromAddress} = await commonCalls();
		console.log("fromAddress verifyPDF should be admin :",fromAddress);
		const verifyPDFReceipt = await myContract.methods.verifyPDF(data).send({from:fromAddress});
		console.log("VerifyPDFReceipt.status :",verifyPDFReceipt.status);
		console.log("verifyPDFReceipt :",verifyPDFReceipt);
		verifyPDFReceipt.status === true && openNotificationWithIcon("success","Survey Verification Successful");
		// thunkAPI.dispatch(getBalanceOfUser({myContract,fromAddress}));
		return verifyPDFReceipt.status; // true,false
	} catch(error) {
		openNotificationWithIcon("error","PDF Verification Unsuccessful");
		console.log("verifyPDF error ",error);
	}
})

// ok // not ok as on 11 may 
export const allFormDataForAdmin = createAsyncThunk("contract/allFormDataForAdmin",async(data,thunkAPI)=>{
	try{
		const {web3} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,data.contract_address);
		const formDataForAdmin = await myContract.methods.formIdToFormDataAdmin(data.formId).call();
		console.log("formDataForAdmin action : ",formDataForAdmin);
		return formDataForAdmin;
	} catch(error) {
		console.log("allFormDataForAdmin error :",error);
	}
});

export const getFormApprover = createAsyncThunk("contract/getFormApprover",async(data,thunkAPI) => {
	try{
		const {myContract,fromAddress,web3} = await commonCalls();
		const formApprover = await myContract.methods.formIdToApprover(data).call();
		console.log("Form Submitter : ",formApprover);
		return formApprover;
	} catch(error) {
		console.log("getFormApprover function error :",error);
	}
});

export const getFormSubmitter = createAsyncThunk("contract/getFormSubmitter",async(data,thunkAPI) => {
	try{
		const {myContract,fromAddress,web3} = await commonCalls();
		const formSubmitter = await myContract.methods.formIdToSubmitter(data).call();
		console.log("Form Submitter: ",formSubmitter);
		return formSubmitter;
	} catch(error) {
		console.log("getFormSubmitter function error :",error);
	}
});

export const submitterAddressToPDFHash = createAsyncThunk("contract/submitterAddressToPDFHash",async({approverSelectedFormId,submitter},thunkAPI) => {
	try{
		const {myContract,fromAddress,web3} = await commonCalls();
		const submitterAddrToHash = await myContract.methods.submitterAddressToPDFHash(approverSelectedFormId,submitter).call();
		console.log("Form Submitter: ",submitterAddrToHash);
		return submitterAddrToHash;
	} catch(error) {
		console.log("getFormSubmitter function error :",error);
	}
});

export const submitSignedPDF = createAsyncThunk("contract/submitSignedPDF",async({image,formId,hash},thunkAPI) => {
  try {
	const {myContract,fromAddress,web3} = await commonCalls();
    const signedPDFReceipt = await myContract.methods.submitSignedPDF(formId,hash,image).send({from:fromAddress});
    console.log("signedPDFReceipt.status :",signedPDFReceipt.status);
	// thunkAPI.dispatch(getBalanceOfUser({myContract,fromAddress}));
    return signedPDFReceipt.status;
  } catch(error) {
    console.log("submitSignedPDF error :",error);
  }
});

export const getAdminAllFormIds = createAsyncThunk("contract/getAdminAllFormIds",async(_,thunkAPI) => {
	try {
	  const {myContract,fromAddress} = await commonCalls();
	  const allCreatedForms = await myContract.methods.getAdminAllFormIds().call({from : fromAddress});
	  console.log("allCreatedForms :",allCreatedForms);
	  return allCreatedForms;
	} catch(error) {
	  console.log("submitSignedPDF error :",error);
	}
});

export const checkLoggedUserToBeAdmin = createAsyncThunk("contract/checkLoggedUserToBeAdmin",async(_,thunkAPI)=>{
	try {
		const {myContract,fromAddress} = await commonCalls();
		const contractAdmin = await myContract.methods.admin().call();

		return fromAddress === contractAdmin;
	} catch(error) {
		console.log("checkLoggedUserToBeAdmin error :",error);
	}
});

export const getBalanceOfUser = createAsyncThunk("contract/getBalanceOfUser",async({myContract,fromAddress},thunkAPI) => {
	try {
		console.log("inside getBalanceOfUser action")
		// const {myContract,fromAddress} = await commonCalls();
		const balance = await myContract.methods.balanceOf(fromAddress).call();
		console.log("user balance :",balance);
		return balance;

	} catch(error) {
		console.log("getBalanceOfUser error :",error);
	}
});

export const getFormStatus = createAsyncThunk("contract/getFormStatus",async({formId,contract_address},thunkAPI)=> {
	try {
		const user = localStorage.getItem('user');
		const {web3} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,contract_address);
		const formDataForAdmin = await myContract.methods.formIdToFormDataAdmin(formId).call();
		const numConfirmationsRequired = Number(formDataForAdmin.numConfirmationsRequired);
		const approverConfirmationCount = Number(formDataForAdmin.approverConfirmationCount);
		const totalApprovers = Number(formDataForAdmin.totalApprovers);
		const formStatusEnum  = formDataForAdmin.statusEnum;
		const submitterSurveyUrl = formDataForAdmin.submitterPDFUrl;
		const submitterSurveyHash = formDataForAdmin.submitterPDFHash;
		const approverSurveyUrl = formDataForAdmin.approvedPDFUrl;
		const approverSurveyHash = formDataForAdmin.approvedPDFHash;
		console.log("formStatusEnum :",formStatusEnum);
		const formStatusEnumToNum = Number(formStatusEnum); // type string converted to number
		formStatusEnumToNum===0 && thunkAPI.dispatch(getSurveyId(formId)); // isi se form UI banta hai
		formStatusEnumToNum>0 && thunkAPI.dispatch(getSubmittedSurveyUI(formId)); // submit karne ke baad ye
		const isSurveyApproved = await myContract.methods.isApproved(formId,user).call();
		const surveyRejector = await myContract.methods.submittedSurveyRejected(formId).call();
		const isApprovedSurveySigned = await myContract.methods.isSignedApprovedPDF(formId,user).call();
		thunkAPI.dispatch(getEvents(formId)); // for workflow timeline
		thunkAPI.dispatch(getWorkflowAttestationEvents(formId)); // for attestation timeline
		return {formStatusEnumToNum,approverConfirmationCount,numConfirmationsRequired,totalApprovers,submitterSurveyUrl,submitterSurveyHash,approverSurveyUrl,approverSurveyHash,isSurveyApproved,surveyRejector,isApprovedSurveySigned};
	} catch(error) {
		console.log("getFormStatusEnumAndSubmittedPDFUrl error :",error);
	}
});

export const approverGetsData = createAsyncThunk("contract/approverGetsData",async(_,thunkAPI)=>{
	try {
		console.log("approverGetsData ke andar")
		const Web3Auth = await web3auth(); // function for this 
		if(!Web3Auth) {
			console.log("web3auth not initialized yet")
			return;
		}
		const rpc = new RPC(Web3Auth.provider);
		const privateKey = await rpc.getPrivateKey(); // private key log hogi
		console.log("logged user private key : ",privateKey);


	} catch(error) {
		console.log("approverGetsData error :",error);
	}
});

export const rejectApprovedSurvey = createAsyncThunk('contract/rejectApprovedSurvey',async(data,thunkAPI)=> {
	try {
		const {myContract,fromAddress} = await commonCalls();
		const rejectApprovedPDF = await myContract.methods.rejectApprovedPDF(data).send({from:fromAddress});
		rejectApprovedPDF.status === true && openNotificationWithIcon('success','Approved survey rejected successfully');
		return rejectApprovedPDF.status;
	} catch(error) {
		openNotificationWithIcon('error','Reject Approved survey error');
		console.log("rejectApprovedSurvey error :",error);
	}
});

export const approverSignApprovedSurvey = createAsyncThunk('contract/approverSignApprovedSurvey',async({formId,image,hash,contract_address,signatureBlob,referencedAttestationUId,_id,approverConfirmationCount},thunkAPI)=> {
	try {
		thunkAPI.dispatch(setSignatureUploadingAndPDFUploadingText('Talking to Blockchain ...'));
		const {web3,fromAddress} = await commonCalls();
		console.log("contract address from params :",contract_address);
		console.table("formId,signature,pdfUrl,pdfHash log karra hun",formId,image,hash);
		const myContract = new web3.eth.Contract(contractABI,contract_address);
		const workflowIdToData = await myContract.methods.formIdToFormDataAdmin(formId).call();
		const approverSignatureCount = Number(workflowIdToData.approverSignatureCount);
		const approverSignApprovedSurveyCalled = await myContract.methods.approverSignApprovedSurvey(formId,image,hash).send({from:fromAddress});
		// approverSignApprovedSurveyCalled.status === true && openNotificationWithIcon('success','Workflow Signed Successfully');
		const eventData = {formId:formId,obj:{PDFSigned : approverSignApprovedSurveyCalled.events.PDFSigned}};
		approverSignApprovedSurveyCalled.status===true && await thunkAPI.dispatch(saveEvent(eventData));
		const statusData = {ID:_id,Status:"SIGNED"};
		approverSignatureCount===(approverConfirmationCount-1) && await thunkAPI.dispatch(updateWorkflowStatusAPI(statusData));
		approverSignApprovedSurveyCalled.status === true && thunkAPI.dispatch(resetSignatureUploadingAndPDFUploadingText());
		const signatureAttestationData = {workflowUId : formId,signatureBlob : signatureBlob , referencedAttestationUId : referencedAttestationUId,action:"signed",role:2,attestation_type:"signature"};
		console.log("signature blob logged :",signatureAttestationData.signatureBlob);
		await thunkAPI.dispatch(onchainAttestWorkflowSignature(signatureAttestationData))
		thunkAPI.dispatch(getEthBalance());
		return approverSignApprovedSurveyCalled.status;
	} catch(error) {
		openNotificationWithIcon('error','Blockchain Error : approverSignApprovedSurvey error');
		console.log("approverSignApprovedSurvey error :",error);
	}
});

export const getApprovedPDFHash = createAsyncThunk('contract/getApprovedPDFHash',async(data,thunkAPI)=> {
	try {
		const {web3} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,data.contract_address);
		const formIdToFormData = await myContract.methods.formIdToFormDataAdmin(data.formId).call();
		const formStatus = Number(formIdToFormData.statusEnum);
		const submitter = formIdToFormData.submitter;
		console.log("formIdToFormData :",formIdToFormData);
		let statusString;

		switch (formStatus) {
		  case 0: {
			statusString = "Assigned To Submitter";
			break;
		  }
		  case 1: {
			statusString = "Assigned To Approver(s)";
			break;
		  }
		  case 2: {
			statusString = "Approved By Approver(s)";
			break;
		  }
		  case 3: {
			statusString = "Rejected By Approver(s)";
			break;
		  }
		  case 4: {
			statusString = "Signed By Approver(s)";
			break;
		  }
		  default:
			statusString = "something went wrong!";
			break;
		}
	
		const ifSignedReturnObj = async() => {
			const result = await axios.get(`${BACKENDURL}/api/userTemplates/templates?formId=${data.formId}`);
			console.log("result :",result.data.data[0]);
			const surveyName = result.data?.data[0].name;
			const surveyDescription = result.data?.data[0].description;
			const approverArray = result.data?.data[0].ApprovalSignature;
			const approvedPDFHash = formIdToFormData.approvedPdfHash;
			const approvedPDFUrl = formIdToFormData.approvedPDFUrl;
			const retObj = {statusString ,submitter,formStatus, surveyName,surveyDescription,approvedPDFHash,approvedPDFUrl,approverArray};
			console.log("retObj :",retObj);
			return retObj;
		}
		const ifNotSignedReturnObj = async()=> {
			const result = await axios.get(`${BACKENDURL}/api/survey/getSurvey/?formId=${data.formId}`);
			const approvers = await myContract.methods.getApprovers(data.formId).call();
			const templateObj = result.data.data[0].template.templatesVersion[0];
			console.log("template obj :",templateObj);
			const surveyName = templateObj.name;
			const surveyDescription = templateObj.description;
			const retObj = {statusString ,submitter,formStatus,surveyName,surveyDescription,approvers};
			return retObj;
		}
		const retObj = formIdToFormData.statusEnum==='4' ? ifSignedReturnObj() : ifNotSignedReturnObj();
		return retObj;
	} catch(error) {
		openNotificationWithIcon('error','getApprovedPDFHash error');
		console.log("getApprovedPDFHash error :",error);
	}
})


export const getApprovedSurveys = createAsyncThunk('contract/getApprovedSurveys',async(data,thunkAPI)=> {
	try{
		const {web3,fromAddress} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,data);
		const countTotalFormsAsSubmitter = await myContract.methods.countTotalFormsAsSubmitter(fromAddress).call();
		console.log("total forms for this address was assigned role submitter are :",countTotalFormsAsSubmitter);
		const formIdArrayForSubmitter = await myContract.methods.getFormIdsBySubmitter().call({from:fromAddress});
		console.log("formIdArrayForSubmitter array :",formIdArrayForSubmitter);

		const promisesFormStatusAsSubmitter = Array.from({length: countTotalFormsAsSubmitter}, async (_, i) => {
			const id = formIdArrayForSubmitter[i];
			// const id = await myContract.methods.submitterAddressToFormIdsAssigned(fromAddress, i).call();
			const formIdToFormData = await myContract.methods.formIdToFormDataAdmin(id).call();
			const formStatus = formIdToFormData.statusEnum;
			const numConfirmationsRequired = formIdToFormData.numConfirmationsRequired;
			const approverConfirmationCount = formIdToFormData.approverConfirmationCount;
			let surveyName = await axios.get(`${BACKENDURL}/api/survey/getSurvey/?formId=${id}`).then((res)=>res.data.data[0].template.name);

			switch(formStatus) {
				case "0" : {
							const retObj = {
								formId:id,
								formStatus: "Assigned To Submitter",
								confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
								currentConfirmations:approverConfirmationCount,
								surveyName,
								statusEnum:formStatus
							}
							return retObj;
				}
				case "1" : {
					const retObj = {
						formId:id,
						formStatus: "Assigned To Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "2" : {
					const retObj = {
						formId:id,
						formStatus: "Approved By Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "3" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus // enum value of formStatus
					}
					return retObj;
				}
				case "4" : {
					const retObj = {
						formId:id,
						formStatus: "Verified By Admin",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus // enum value of formStatus
					}
					return retObj;
				}
				case "5" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Admin",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus // enum value of formStatus
					}
					return retObj;
				}
				default : return "Error!";
			}
		});
		const formIdToSubmitterInfo = await Promise.all(promisesFormStatusAsSubmitter);
		console.log("form id se submitter UI tak :",formIdToSubmitterInfo);

		const countTotalFormsAsApprover = await myContract.methods.countTotalFormsAsApprover(fromAddress).call();
		console.log("total forms for this address was assigned role approver are :",countTotalFormsAsApprover);
		const formIdArrayForApprover = await myContract.methods.getFormIdsByApprover().call({from:fromAddress});
		console.log("getFormIdsByApprover array :",formIdArrayForApprover);

		const promisesFormStatusAsApprover = Array.from({length: countTotalFormsAsApprover}, async (_, i) => {
			const id = formIdArrayForApprover[i];
			// const id = await myContract.methods.approverAddressToFormIdsAssigned(fromAddress, i).call();
			const formIdToFormData = await myContract.methods.formIdToFormDataAdmin(id).call();
			const formStatus = formIdToFormData.statusEnum;
			const numConfirmationsRequired = formIdToFormData.numConfirmationsRequired;
			const approverConfirmationCount = formIdToFormData.approverConfirmationCount;
			let surveyName = await axios.get(`${BACKENDURL}/api/survey/getSurvey/?formId=${id}`).then((res)=>res.data.data[0].template.name);

			switch(formStatus) {
				case "0" : {
							const retObj = {
								formId:id,
								formStatus: "Assigned To Submitter",
								confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
								currentConfirmations:approverConfirmationCount,
								surveyName,
								statusEnum:formStatus
							}
							return retObj;
				}
				case "1" : {
					const retObj = {
						formId:id,
						formStatus: "Assigned To Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "2" : {
					const retObj = {
						formId:id,
						formStatus: "Approved By Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "3" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "4" : {
					const retObj = {
						formId:id,
						formStatus: "Verified By Admin",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "5" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Admin",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				default : return "Error!";
			}
		});
		const formIdToApproverInfo = await Promise.all(promisesFormStatusAsApprover);
		// const finalArray = [];

		// formIdToSubmitterInfo.forEach((submitterObj) => {
		// 	const existingObj = finalArray.find((obj) => obj.formId === submitterObj.formId);
		// 	if (!existingObj) {
		// 		finalArray.push(submitterObj);
		// 	}
		// });

		// formIdToApproverInfo.forEach((approverObj) => {
		// 	const existingObj = finalArray.find((obj) => obj.formId === approverObj.formId);
		// 	if (!existingObj) {
		// 		finalArray.push(approverObj);
		// 	}
		// });

		// console.log("final array :",finalArray);
		// return finalArray;
		const finalArray = [];

		formIdToSubmitterInfo.forEach((submitterObj) => {
		if (submitterObj.statusEnum === "4") {
			const existingObj = finalArray.find((obj) => obj.formId === submitterObj.formId);
			if (!existingObj) {
			finalArray.push(submitterObj);
			}
		}
		});

		formIdToApproverInfo.forEach((approverObj) => {
		if (approverObj.statusEnum === "4") {
			const existingObj = finalArray.find((obj) => obj.formId === approverObj.formId);
			if (!existingObj) {
			finalArray.push(approverObj);
			}
		}
		});

		console.log("final array:", finalArray);
		return finalArray;

	} catch(error) {
		console.log("getApprovedSurveys error",error);
	}
})

export const allEWarrentDataForSuperAdmin = createAsyncThunk("contract/allEWarrentDataForSuperAdmin",async(data,thunkAPI)=>{
	try{
		const {web3,fromAddress} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,data);
		const createdFormIdArray = await myContract.methods.getAdminAllFormIds().call({from:fromAddress});
		console.log("created formId Array by admin :",createdFormIdArray);
		const totalSurveysCreated = Number(createdFormIdArray.length);
		const promisesFormStatusAsAdmin = Array.from({length: createdFormIdArray.length}, async (_, i) => {
			const id = createdFormIdArray[i];
			const formStatus = await myContract.methods.getFormStatusEnum(id).call();
			const formIdToFormDataAdmin = await myContract.methods.formIdToFormDataAdmin(id).call();
			const submitter = formIdToFormDataAdmin.submitter;
			const approvers = await myContract.methods.getApprovers(id).call();
			let surveyName = await axios.get(`${BACKENDURL}/api/survey/getSurveyName/?formId=${id}`).then((res)=>res.data.workflowName);
			switch(formStatus) {
				case "0" : {
							const retObj = {
								formId:id,
								formStatus: "Assigned To Submitter",
								submitter,
								approvers,
								surveyName,
								statusEnum:formStatus

							}
							return retObj;
				}
				case "1" : {
					const retObj = {
						formId:id,
						formStatus: "Assigned To Approver(s)",
						submitter,
						approvers,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "2" : {
					const retObj = {
						formId:id,
						formStatus: "Approved By Approver(s)",
						submitter,
						approvers,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "3" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Approver(s)",
						submitter,
						approvers,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "4" : {
					const retObj = {
						formId:id,
						formStatus: "Verified By Admin",
						submitter,
						approvers,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "5" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Admin",
						submitter,
						approvers,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				default : return "Error!";
			}
		});
		const formStatusArrayAsAdmin = await Promise.all(promisesFormStatusAsAdmin);
		// thunkAPI.dispatch(getAllSurveys());
		console.log("form status array of objects of admin for superAdmin :",formStatusArrayAsAdmin);
		return {formStatusArrayAsAdmin,totalSurveysCreated};
	} catch(error) {
		console.log("allEWarrentDataForSuperAdmin error",error);
	}
});

export const transferEWarrentContractOwnership = createAsyncThunk('contract/transferEWarrentContractOwnership',async(data,thunkAPI)=> {
	try {
		const {web3,fromAddress} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,data.contract_address);
		const transferOwnerShipReceipt = await myContract.methods.transferOwnership(data.new_admin.address).send({from:fromAddress});
		console.log("transferOwnerShipReceipt direct call receipt :",transferOwnerShipReceipt);
		// transferOwnerShipReceipt.status===true && thunkAPI.dispatch(transferEWarrentOwnership(data));
		await thunkAPI.dispatch(transferEWarrentOwnership(data));
		return transferOwnerShipReceipt.status;
	} catch(error) {
		openNotificationWithIcon('error','Reject Approved survey error');
		console.log("rejectApprovedSurvey error :",error);
	}
});

//  optimized , but contains some error ( parsing related )
// export const getApprovedSurveys = createAsyncThunk('contract/getApprovedSurveys', async (_, thunkAPI) => {
// 	try {
// 	  const { myContract, fromAddress } = await commonCalls();
// 	  const countTotalFormsAsSubmitter = await myContract.methods.countTotalFormsAsSubmitter(fromAddress).call();
// 	  console.log("total forms for this address assigned the role of submitter:", countTotalFormsAsSubmitter);
  
// 	  const promisesFormStatusAsSubmitter = Array.from({ length: countTotalFormsAsSubmitter }, async (_, i) => {
// 		const id = await myContract.methods.submitterAddressToFormIdsAssigned(fromAddress, i).call();
// 		const formIdToFormData = await myContract.methods.formIdToFormDataAdmin(id).call();
// 		const formStatus = formIdToFormData.statusEnum;
// 		const numConfirmationsRequired = formIdToFormData.numConfirmationsRequired;
// 		const approverConfirmationCount = formIdToFormData.approverConfirmationCount;
// 		const surveyName = axios
// 		  .get(`${BACKENDURL}/api/survey/getSurvey/?formId=${id}`)
// 		  .then((res) => res.data.data[0].template.name);
  
// 		switch (formStatus) {
// 		  case "0": {
// 			const retObj = {
// 			  formId: id,
// 			  formStatus: "Assigned To Submitter",
// 			  confirmationsRequired: Number(numConfirmationsRequired) - Number(approverConfirmationCount),
// 			  currentConfirmations: approverConfirmationCount,
// 			  surveyName,
// 			  statusEnum: formStatus,
// 			};
// 			return retObj;
// 		  }
// 		  case "1": {
// 			const retObj = {
// 			  formId: id,
// 			  formStatus: "Assigned To Approver(s)",
// 			  confirmationsRequired: Number(numConfirmationsRequired) - Number(approverConfirmationCount),
// 			  currentConfirmations: approverConfirmationCount,
// 			  surveyName,
// 			  statusEnum: formStatus,
// 			};
// 			return retObj;
// 		  }
// 		  case "2": {
// 			const retObj = {
// 			  formId: id,
// 			  formStatus: "Approved By Approver(s)",
// 			  confirmationsRequired: Number(numConfirmationsRequired) - Number(approverConfirmationCount),
// 			  currentConfirmations: approverConfirmationCount,
// 			  surveyName,
// 			  statusEnum: formStatus,
// 			};
// 			return retObj;
// 		  }
// 		  case "3": {
// 			const retObj = {
// 			  formId: id,
// 			  formStatus: "Rejected By Approver(s)",
// 			  confirmationsRequired: Number(numConfirmationsRequired) - Number(approverConfirmationCount),
// 			  currentConfirmations: approverConfirmationCount,
// 			  surveyName,
// 			  statusEnum: formStatus, // enum value of formStatus
// 			};
// 			return retObj;
// 		  }
// 		  case "4": {
// 			const retObj = {
// 			  formId: id,
// 			  formStatus: "Verified By Admin",
// 			  confirmationsRequired: Number(numConfirmationsRequired) - Number(approverConfirmationCount),
// 			  currentConfirmations: approverConfirmationCount,
// 			  surveyName,
// 			  statusEnum: formStatus, // enum value of formStatus
// 			};
// 			return retObj;
// 		  }
// 		  case "5": {
// 			const retObj = {
// 			  formId: id,
// 			  formStatus: "Rejected By Admin",
// 			  confirmationsRequired: Number(numConfirmationsRequired) - Number(approverConfirmationCount),
// 			  currentConfirmations: approverConfirmationCount,
// 			  surveyName,
// 			  statusEnum: formStatus, // enum value of formStatus
// 			};
// 			return retObj;
// 		  }
// 		  default:
// 			return "Error!";
// 		}
// 	  });
  
// 	  const formIdToSubmitterInfo = await Promise.all(promisesFormStatusAsSubmitter);
// 	  console.log("form id to submitter UI:", formIdToSubmitterInfo);
  
// 	  const countTotalFormsAsApprover = await myContract.methods.countTotalFormsAsApprover(fromAddress).call();
// 	  console.log("total forms for this address assigned the role of approver:", countTotalFormsAsApprover);
  
// 	  const promisesFormStatusAsApprover = Array.from({ length: countTotalFormsAsApprover }, async (_, i) => {
// 		const id = await myContract.methods.approverAddressToFormIdsAssigned(fromAddress, i).call();
// 		const formIdToFormData = await myContract.methods.formIdToFormDataAdmin(id).call();
// 		const formStatus = formIdToFormData.statusEnum;
// 		const numConfirmationsRequired = formIdToFormData.numConfirmationsRequired;
// 		const approverConfirmationCount = formIdToFormData.approverConfirmationCount;
// 		const surveyName = axios
// 		  .get(`${BACKENDURL}/api/survey/getSurvey/?formId=${id}`)
// 		  .then((res) => res.data.data[0].template.name);
  
// 		switch (formStatus) {
// 		  case "0": {
// 			const retObj = {
// 			  formId: id,
// 			  formStatus: "Assigned To Submitter",
// 			  confirmationsRequired: Number(numConfirmationsRequired) - Number(approverConfirmationCount),
// 			  currentConfirmations: approverConfirmationCount,
// 			  surveyName,
// 			  statusEnum: formStatus,
// 			};
// 			return retObj;
// 		  }
// 		  case "1": {
// 			const retObj = {
// 			  formId: id,
// 			  formStatus: "Assigned To Approver(s)",
// 			  confirmationsRequired: Number(numConfirmationsRequired) - Number(approverConfirmationCount),
// 			  currentConfirmations: approverConfirmationCount,
// 			  surveyName,
// 			  statusEnum: formStatus,
// 			};
// 			return retObj;
// 		  }
// 		  case "2": {
// 			const retObj = {
// 			  formId: id,
// 			  formStatus: "Approved By Approver(s)",
// 			  confirmationsRequired: Number(numConfirmationsRequired) - Number(approverConfirmationCount),
// 			  currentConfirmations: approverConfirmationCount,
// 			  surveyName,
// 			  statusEnum: formStatus,
// 			};
// 			return retObj;
// 		  }
// 		  case "3": {
// 			const retObj = {
// 			  formId: id,
// 			  formStatus: "Rejected By Approver(s)",
// 			  confirmationsRequired: Number(numConfirmationsRequired) - Number(approverConfirmationCount),
// 			  currentConfirmations: approverConfirmationCount,
// 			  surveyName,
// 			  statusEnum: formStatus,
// 			};
// 			return retObj;
// 		  }
// 		  case "4": {
// 			const retObj = {
// 			  formId: id,
// 			  formStatus: "Verified By Admin",
// 			  confirmationsRequired: Number(numConfirmationsRequired) - Number(approverConfirmationCount),
// 			  currentConfirmations: approverConfirmationCount,
// 			  surveyName,
// 			  statusEnum: formStatus,
// 			};
// 			return retObj;
// 		  }
// 		  case "5": {
// 			const retObj = {
// 			  formId: id,
// 			  formStatus: "Rejected By Admin",
// 			  confirmationsRequired: Number(numConfirmationsRequired) - Number(approverConfirmationCount),
// 			  currentConfirmations: approverConfirmationCount,
// 			  surveyName,
// 			  statusEnum: formStatus,
// 			};
// 			return retObj;
// 		  }
// 		  default:
// 			return "Error!";
// 		}
// 	  });
  
// 	  const formIdToApproverInfo = await Promise.all(promisesFormStatusAsApprover);
// 	  const finalArray = [];
  
// 	  formIdToSubmitterInfo.forEach((submitterObj) => {
// 		const existingObj = finalArray.find((obj) => obj.formId === submitterObj.formId);
// 		if (!existingObj) {
// 		  finalArray.push(submitterObj);
// 		}
// 	  });
  
// 	  formIdToApproverInfo.forEach((approverObj) => {
// 		const existingObj = finalArray.find((obj) => obj.formId === approverObj.formId);
// 		if (!existingObj) {
// 		  finalArray.push(approverObj);
// 		}
// 	  });
  
// 	  console.log("final array:", finalArray);
// 	  return finalArray;
// 	} catch (error) {
// 	  console.log("getApprovedSurveys error", error);
// 	}
//   });
  


export const getApprovedSurveysForExternalUser = createAsyncThunk('contract/getApprovedSurveysForExternalUser',async(data,thunkAPI)=> {
	try{
		const {web3} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,data.contractAddress);
		const countTotalFormsAsSubmitter = await myContract.methods.countTotalFormsAsSubmitter(data.userAddress).call();
		console.log("total forms for this address was assigned role submitter are :",countTotalFormsAsSubmitter);
		const formIdArrayForSubmitter = await myContract.methods.getFormIdsBySubmitter().call({from:data.userAddress});
		console.log("formIdArrayForSubmitter array :",formIdArrayForSubmitter);

		const promisesFormStatusAsSubmitter = Array.from({length: countTotalFormsAsSubmitter}, async (_, i) => {
			const id = formIdArrayForSubmitter[i];
			// const id = await myContract.methods.submitterAddressToFormIdsAssigned(fromAddress, i).call();
			const formIdToFormData = await myContract.methods.formIdToFormDataAdmin(id).call();
			const formStatus = formIdToFormData.statusEnum;
			const numConfirmationsRequired = formIdToFormData.numConfirmationsRequired;
			const approverConfirmationCount = formIdToFormData.approverConfirmationCount;
			let surveyName = await axios.get(`${BACKENDURL}/api/survey/getSurvey/?formId=${id}`).then((res)=>res.data.data[0].template.name);

			switch(formStatus) {
				case "0" : {
							const retObj = {
								formId:id,
								formStatus: "Assigned To Submitter",
								confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
								currentConfirmations:approverConfirmationCount,
								surveyName,
								statusEnum:formStatus
							}
							return retObj;
				}
				case "1" : {
					const retObj = {
						formId:id,
						formStatus: "Assigned To Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "2" : {
					const retObj = {
						formId:id,
						formStatus: "Approved By Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "3" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus // enum value of formStatus
					}
					return retObj;
				}
				case "4" : {
					const retObj = {
						formId:id,
						formStatus: "Verified By Admin",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus // enum value of formStatus
					}
					return retObj;
				}
				case "5" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Admin",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus // enum value of formStatus
					}
					return retObj;
				}
				default : return "Error!";
			}
		});
		const formIdToSubmitterInfo = await Promise.all(promisesFormStatusAsSubmitter);
		console.log("form id se submitter UI tak :",formIdToSubmitterInfo);

		const countTotalFormsAsApprover = await myContract.methods.countTotalFormsAsApprover(data.userAddress).call();
		console.log("total forms for this address was assigned role approver are :",countTotalFormsAsApprover);
		const formIdArrayForApprover = await myContract.methods.getFormIdsByApprover().call({from:data.userAddress});
		console.log("getFormIdsByApprover array :",formIdArrayForApprover);

		const promisesFormStatusAsApprover = Array.from({length: countTotalFormsAsApprover}, async (_, i) => {
			const id = formIdArrayForApprover[i];
			const formIdToFormData = await myContract.methods.formIdToFormDataAdmin(id).call();
			const formStatus = formIdToFormData.statusEnum;
			const numConfirmationsRequired = formIdToFormData.numConfirmationsRequired;
			const approverConfirmationCount = formIdToFormData.approverConfirmationCount;
			let surveyName = await axios.get(`${BACKENDURL}/api/survey/getSurvey/?formId=${id}`).then((res)=>res.data.data[0].template.name);

			switch(formStatus) {
				case "0" : {
							const retObj = {
								formId:id,
								formStatus: "Assigned To Submitter",
								confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
								currentConfirmations:approverConfirmationCount,
								surveyName,
								statusEnum:formStatus
							}
							return retObj;
				}
				case "1" : {
					const retObj = {
						formId:id,
						formStatus: "Assigned To Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "2" : {
					const retObj = {
						formId:id,
						formStatus: "Approved By Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "3" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Approver(s)",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "4" : {
					const retObj = {
						formId:id,
						formStatus: "Verified By Admin",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				case "5" : {
					const retObj = {
						formId:id,
						formStatus: "Rejected By Admin",
						confirmationsRequired:Number(numConfirmationsRequired) - Number(approverConfirmationCount),
						currentConfirmations:approverConfirmationCount,
						surveyName,
						statusEnum:formStatus
					}
					return retObj;
				}
				default : return "Error!";
			}
		});
		const formIdToApproverInfo = await Promise.all(promisesFormStatusAsApprover);
		const finalArray = [];

		formIdToSubmitterInfo.forEach((submitterObj) => {
		if (submitterObj.statusEnum === "4") {
			const existingObj = finalArray.find((obj) => obj.formId === submitterObj.formId);
			if (!existingObj) {
			finalArray.push(submitterObj);
			}
		}
		});

		formIdToApproverInfo.forEach((approverObj) => {
		if (approverObj.statusEnum === "4") {
			const existingObj = finalArray.find((obj) => obj.formId === approverObj.formId);
			if (!existingObj) {
			finalArray.push(approverObj);
			}
		}
		});

		console.log("final array:", finalArray);
		return finalArray;

	} catch(error) {
		console.log("getApprovedSurveys error",error);
	}
})

export const getApprovedPDFHashForExternalUser = createAsyncThunk('contract/getApprovedPDFHashForExternalUser',async(data,thunkAPI)=> {
	try {
		const {web3} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,data.contract_address);
		const formIdToFormData = await myContract.methods.formIdToFormDataAdmin(data.formId).call();
		const formStatus = Number(formIdToFormData.statusEnum);
		const submitter = formIdToFormData.submitter;
		console.log("formIdToFormData :",formIdToFormData);
		let statusString;

		switch (formStatus) {
		  case 0: {
			statusString = "Assigned To Submitter";
			break;
		  }
		  case 1: {
			statusString = "Assigned To Approver(s)";
			break;
		  }
		  case 2: {
			statusString = "Approved By Approver(s)";
			break;
		  }
		  case 3: {
			statusString = "Rejected By Approver(s)";
			break;
		  }
		  case 4: {
			statusString = "Signed By Approver(s)";
			break;
		  }
		  default:
			statusString = "something went wrong!";
			break;
		}
	
		const ifSignedReturnObj = async() => {
			const result = await axios.get(`${BACKENDURL}/api/userTemplates/templates?formId=${data.formId}`);
			console.log("result :",result.data.data[0]);
			const surveyName = result.data?.data[0].name;
			const surveyDescription = result.data?.data[0].description;
			const approverArray = result.data?.data[0].ApprovalSignature;
			const approvedPDFHash = formIdToFormData.approvedPdfHash;
			const approvedPDFUrl = formIdToFormData.approvedPDFUrl;
			const retObj = {statusString ,submitter,formStatus, surveyName,surveyDescription,approvedPDFHash,approvedPDFUrl,approverArray};
			console.log("retObj :",retObj);
			return retObj;
		}
		const ifNotSignedReturnObj = async()=> {
			const result = await axios.get(`${BACKENDURL}/api/survey/getSurvey/?formId=${data.formId}`);
			const approvers = await myContract.methods.getApprovers(data.formId).call();
			const templateObj = result.data.data[0].template;
			console.log("template obj :",templateObj);
			const surveyName = templateObj.name;
			const surveyDescription = templateObj.description;
			const retObj = {statusString ,submitter,formStatus,surveyName,surveyDescription,approvers};
			return retObj;
		}
		const retObj = formIdToFormData.statusEnum==='4' ? ifSignedReturnObj() : ifNotSignedReturnObj();
		return retObj;
	} catch(error) {
		openNotificationWithIcon('error','getApprovedPDFHash error');
		console.log("getApprovedPDFHash error :",error);
	}
});


export const getApprovedPDFHashForAdmin = createAsyncThunk('contract/getApprovedPDFHashForAdmin',async(data,thunkAPI)=> {
	try {
		const admin_id = localStorage.getItem('adminId');
		let contractAddressAPIRes = await axios.get(`${BACKENDURL}/api/role/getAdminByid?id=${admin_id}`);
		const contract_address = contractAddressAPIRes.data.data[0].contract_address;
		const {web3} = await commonCalls();
		const myContract = new web3.eth.Contract(contractABI,contract_address);
		const formIdToFormData = await myContract.methods.formIdToFormDataAdmin(data.formId).call();
		const formStatus = Number(formIdToFormData.statusEnum);
		const submitter = formIdToFormData.submitter;
		console.log("formIdToFormData :",formIdToFormData);
		let statusString;

		switch (formStatus) {
		  case 0: {
			statusString = "Assigned To Submitter";
			break;
		  }
		  case 1: {
			statusString = "Assigned To Approver(s)";
			break;
		  }
		  case 2: {
			statusString = "Approved By Approver(s)";
			break;
		  }
		  case 3: {
			statusString = "Rejected By Approver(s)";
			break;
		  }
		  case 4: {
			statusString = "Signed By Approver(s)";
			break;
		  }
		  default:
			statusString = "something went wrong!";
			break;
		}
	
		const ifSignedReturnObj = async() => {
			const result = await axios.get(`${BACKENDURL}/api/userTemplates/templates?formId=${data.formId}`);
			console.log("result :",result.data.data[0]);
			const surveyName = result.data?.data[0].name;
			const surveyDescription = result.data?.data[0].description;
			const approverArray = result.data?.data[0].ApprovalSignature;
			const approvedPDFHash = formIdToFormData.approvedPdfHash;
			const approvedPDFUrl = formIdToFormData.approvedPDFUrl;
			const retObj = {statusString ,submitter,formStatus, surveyName,surveyDescription,approvedPDFHash,approvedPDFUrl,approverArray};
			console.log("retObj :",retObj);
			return retObj;
		}
		const ifNotSignedReturnObj = async()=> {
			const result = await axios.get(`${BACKENDURL}/api/survey/getSurvey/?formId=${data.formId}`);
			const approvers = await myContract.methods.getApprovers(data.formId).call();
			const templateObj = result.data.data[0].template.templatesVersion[0];
			console.log("template obj :",templateObj);
			const surveyName = templateObj.name;
			const surveyDescription = templateObj.description;
			const retObj = {statusString ,submitter,formStatus,surveyName,surveyDescription,approvers};
			return retObj;
		}
		const retObj = formIdToFormData.statusEnum==='4' ? ifSignedReturnObj() : ifNotSignedReturnObj();
		return retObj;
	} catch(error) {
		openNotificationWithIcon('error','getApprovedPDFHash error');
		console.log("getApprovedPDFHash error :",error);
	}
})