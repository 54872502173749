import Cookies from 'js-cookie';

export const isSessionActive = () => {
  const sessionCookie = Cookies.get('session');
  return sessionCookie === 'active';
};

export const removeCookie = (cookieName) => {
  Cookies.remove(cookieName);
};
