import { Button, Form, Input, Select } from 'antd';
import React , {useEffect} from 'react';
import { getAdminAllFormIds, revokeRoleFunc } from '../../redux/actions/eWarrant';
import {useDispatch, useSelector} from "react-redux";
import { getSurveyId } from '../../redux/actions/template';
import "../../styles/main.scss";
const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};
const AdminRevokeRoleForm = ({setOpen2}) => {
  const [form] = Form.useForm();
  const formRef = React.useRef(null);
  const dispatch = useDispatch();
  const {createdFormIdArray,isRevokeRoleLoading} = useSelector((state)=>state.contract);
  const {surveyId} = useSelector((state)=>state.template);
  console.log("createdFormIdArray :",createdFormIdArray);
  const onFinish = (values) => {
    console.log("admin revokeroles values logged :",values);
    const data = {"formId":values.formId,"address":values.address,"role":values.role.toLowerCase()}
    console.log("data to be sent to blockchain to revoke a role :",data);
    dispatch(revokeRoleFunc(data));
  };

  const walletAddressRegex = /^0x[a-fA-F0-9]{40}$/;
  useEffect(()=>{
    dispatch(getAdminAllFormIds());
  },[dispatch]);

  return (
    <Form
      form={form}
      {...layout}
      ref={formRef}
      name="control-ref"
      onFinish={onFinish}
      style={{
        maxWidth: 600,
      }}
    >
    
      <Form.Item
        name="address"
        label="Wallet Address"
        required={false}
        rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input user's address",
                    },
                    {
                      pattern: walletAddressRegex,
                      message: 'Please enter a valid Ethereum wallet address'
                    }
        ]}

      >
        <Input />
      </Form.Item>
      <Form.Item
        name="role"
        label="Role"
        required={false}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select a option and change input text above"
          allowClear
        >
          <Option value="submitter" >Submitter</Option>
          <Option value="approver" >Approver</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="formId"
        label="Form Id"
        required={false}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select Form Id"
          onChange={(value) => {
            form.setFieldsValue({
              'selectedFormId': value,
            });
            dispatch(getSurveyId(value));
          }}
        >
          {
            createdFormIdArray?.map((formId,index)=><Option name={formId} value={formId} key={index} />)
          }
        </Select>        
      </Form.Item>
      {((form.getFieldValue('selectedFormId'))) && 
          <Form.Item 
            name="surveyId"
            label="Associated Survey Id"
          >
            <Input readOnly value={surveyId} placeholder={surveyId}/>
          </Form.Item>}
      
      <Form.Item {...tailLayout}>
        <div className='antd-form-button-container'>
          <Button type="primary" htmlType="submit" loading={isRevokeRoleLoading}>
            Submit
          </Button>
        </div>
      </Form.Item>

    </Form>
  );
};
export default AdminRevokeRoleForm;