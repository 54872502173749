import React, { useEffect, useState } from 'react';
import { Button,Col,Form, Modal, Pagination, Row, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import InviteUserComponent from '../../components/InviteUserComponent/InviteUserComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsersInvitedByAdmin } from '../../redux/actions/template';
import UserList from '../../components/UserList/UserList';
import { resetInviteUserStates } from '../../redux/slices/templateSlice';
import MainLayout from '../../components/MainLayout/MainLayout';
const {Option }= Select;

const AllUsers = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy,setSortBy] = useState('DESC');
  const [buttonText, setButtonText] = useState('Sort By Ascending');
  const [orderBy,setOrderBy] = useState('createdAt');
  const [currentPage, setCurrentPage] = useState(1); // page parameter of api
  const [elePerPage, setElePerPage] = useState(10); // limit parameter of api 
  const handleChangePage = (page) => {
    setCurrentPage(page);
    dispatch(getAllUsersInvitedByAdmin({page:page,orderBy:orderBy,sortBy:sortBy,limit:elePerPage}))
  };

  const handleChangeElePerPage = (value) => {
    setElePerPage(value);
    setCurrentPage(1);
    dispatch(getAllUsersInvitedByAdmin({page:1,orderBy:orderBy,sortBy:sortBy,limit:value}))
  };

  const {isInviteUserSuccess} = useSelector(state=>state.template);
  const { allUsersInvitedByAdminArray } = useSelector(
    (state) => state.template
  );
  console.log("allUsersInvitedByAdminArray.length :",allUsersInvitedByAdminArray.length)

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(resetInviteUserStates());
    form.resetFields();
  };
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getAllUsersInvitedByAdmin({page:currentPage,orderBy:orderBy,sortBy:sortBy,limit:elePerPage}));
  },[dispatch,elePerPage,orderBy,sortBy,currentPage]);

  useEffect(()=>{
    isInviteUserSuccess && dispatch(getAllUsersInvitedByAdmin({page:currentPage,orderBy:orderBy,sortBy:sortBy,limit:elePerPage}));
  },[dispatch,isInviteUserSuccess,currentPage,elePerPage,orderBy,sortBy]);

  useEffect(()=>{
    isInviteUserSuccess===true && handleCancel();
  },[isInviteUserSuccess]);

  const sortByAscending = () => {
    const newSortBy = sortBy === 'ASC' ? 'DESC' : 'ASC';
    setSortBy(newSortBy);
    dispatch(getAllUsersInvitedByAdmin({page:currentPage,orderBy:orderBy,sortBy:'ASC',limit:elePerPage}));
    const newButtonText = newSortBy === 'ASC' ? 'Sort By Descending' : 'Sort By Ascending';
    setButtonText(newButtonText);
  }
  return(
    <MainLayout>
          <div className='flex-between-center'>
            <h1>All Users</h1>
            <Button type='primary' onClick={showModal} icon={<PlusOutlined />}>Invite User</Button>
            <Modal title="Invite Users to xVault" open={isModalOpen} onCancel={handleCancel} footer={false} maskClosable={false}>
              <InviteUserComponent form={form} />
            </Modal>
          </div>
          <UserList/>
          <Row justify='space-between' align='middle' className="mt-1">
            <Col>
              <Space>
                <Select value={elePerPage} onChange={handleChangeElePerPage}>
                  <Option value={10}>10 per page</Option>
                  <Option value={20}>20 per page</Option>
                  <Option value={40}>40 per page</Option>
                  <Option value={100}>100 per page</Option>
                </Select>
                {/* <Button style={{backgroundColor :sortBy==='ASC' ? 'lightgreen':''}} onClick={sortByAscending}>{buttonText}</Button> */}
              </Space>
            </Col>
            <Col>
              <Pagination
                showSizeChanger={false}
                hideOnSinglePage={true}
                current={currentPage}
                onChange={handleChangePage}
                pageSize={elePerPage}
                total={allUsersInvitedByAdminArray && allUsersInvitedByAdminArray.length} // error in api , count is returning 0 , to be fixed by Kartik
              />
            </Col>
          </Row>

    </MainLayout>
  )
}
export default AllUsers;
