import React, { useState, useEffect } from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { dateToSeconds } from '../../utils/dateFormatter';
import { removeCookie } from '../../utils/cookieUtils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetTokenUserData } from '../../redux/slices/erc4907ContractSlice';
import './SessionTimer.scss';

const SessionTimer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionCookieExpiry = Cookies.get('sessionExpiry');  
  const currentDate = new Date();
  const currentDateInSeconds = dateToSeconds(currentDate);
  const sessionCookieExpiryInSeconds = dateToSeconds(sessionCookieExpiry);
  const sessionTimeInSeconds = sessionCookieExpiryInSeconds - currentDateInSeconds;
  const [remainingTime, setRemainingTime] = useState(sessionTimeInSeconds);

  useEffect(() => {
    let timer;

    if (remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevRemainingTime) => prevRemainingTime - 1);
      }, 1000); // Update every second
    } else if (remainingTime === 0) {
      clearInterval(timer);
    }

    return () => clearInterval(timer); // Cleanup the timer on unmount
  }, [remainingTime]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  useEffect(() => {
    const clearSessionHandler = () => {
      removeCookie('session');
      removeCookie('sessionExpiry');
      dispatch(resetTokenUserData());
      navigate('/dashboard');
    }
    if (remainingTime === 0) {
      clearSessionHandler();
    }
  }, [remainingTime, sessionTimeInSeconds,dispatch,navigate]);

  return (
    <div className="stopwatch-container">
      <div className="clock-icon-container">
        <ClockCircleOutlined className='session-clock-icon'/>
      </div>
      <p className="remaining-time">{formatTime(remainingTime)}</p>
    </div>
  );
}

export default SessionTimer;