import { createSlice } from "@reduxjs/toolkit";
import { onchainAttestWorkflowApproval, onchainAttestWorkflowAssignment, onchainAttestWorkflowSubmission, onchainAttestWorkflowTemplate } from "../actions/attestations";

const attestationsSlice = createSlice({
    name:"attestations",
    initialState: {
        isAttestingWorkflowTemplate:false,
        attestationUID : '',
        isAttestingWorkflowAssignment : false,
        isAttestingWorkflowSubmission : false
    },
    reducers:{
    },
    extraReducers:(builder) => {
        builder.addCase(onchainAttestWorkflowTemplate.pending,(state,action) => {
            state.isAttestingWorkflowTemplate = true;
            state.attestationUID = '';
        })
        builder.addCase(onchainAttestWorkflowTemplate.fulfilled,(state,{payload}) => {
            state.isAttestingWorkflowTemplate = false;
            state.attestationUID = payload;
        })
        builder.addCase(onchainAttestWorkflowTemplate.rejected,(state,action) => {
            state.isAttestingWorkflowTemplate = false;
        })
        builder.addCase(onchainAttestWorkflowAssignment.pending,(state,action) => {
            state.isAttestingWorkflowAssignment = true;
            state.attestationUID = '';
        })
        builder.addCase(onchainAttestWorkflowAssignment.fulfilled,(state,{payload}) => {
            state.isAttestingWorkflowAssignment = false;
            state.attestationUID = payload;
        })
        builder.addCase(onchainAttestWorkflowAssignment.rejected,(state,action) => {
            state.isAttestingWorkflowAssignment = false;
        })
        builder.addCase(onchainAttestWorkflowSubmission.pending,(state) => {
            state.isAttestingWorkflowSubmission = true;
            state.isWorkflowSubmissionAttested = false;
        })
        builder.addCase(onchainAttestWorkflowSubmission.fulfilled,(state,{payload}) => {
            state.isAttestingWorkflowSubmission = false;
            console.log("payload :",payload);
            state.isWorkflowSubmissionAttested = payload;
        })
        builder.addCase(onchainAttestWorkflowSubmission.rejected,(state) => {
            state.isAttestingWorkflowSubmission = false;
        })
        builder.addCase(onchainAttestWorkflowApproval.pending,(state) => {
            state.isAttestingWorkflowApproval = true;
            state.isWorkflowApprovalAttested = false;
        })
        builder.addCase(onchainAttestWorkflowApproval.fulfilled,(state,{payload}) => {
            state.isAttestingWorkflowApproval = false;
            state.isWorkflowApprovalAttested = payload;
        })
        builder.addCase(onchainAttestWorkflowApproval.rejected,(state) => {
            state.isAttestingWorkflowApproval = false;
        })
    }
});
// export const {} = attestationsSlice.actions;
export default attestationsSlice.reducer;