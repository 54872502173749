import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { openNotificationWithIcon } from '../../utils/openNotificationWithIcon';

export const setSessionCookie = createAsyncThunk("session/setSessionCookie",async(data,thunkAPI)=> {
    try {
        console.log("expiry time in epoch :",data) // expiry time in unix time
        const expiresIn = new Date( data *1000);
        console.log("expiresIn :",expiresIn);
        Cookies.set('session', 'active', { expires: expiresIn });
        Cookies.set('sessionExpiry',`${expiresIn}`, { expires: expiresIn });
    } catch(error) {
      openNotificationWithIcon('error',error.response.data.message);
    }
});