import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { allFormDataForAdmin } from '../../redux/actions/eWarrant';
import Spinner from '../../components/Spinner/Spinner';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { Button, Col, Row } from 'antd';
import '@react-pdf-viewer/core/lib/styles/index.css';
import CustomSkeleton from '../../components/CustomSkeleton/CustomSkeleton';
import MainLayout from '../../components/MainLayout/MainLayout';
import "./ViewApprovedSurveyPage.scss";

const ViewApprovedSurveyPage = () => {
  let { formId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const eWarrentData = {formId,contract_address: location.state.contract_address}
    dispatch(allFormDataForAdmin(eWarrentData)).then(() => {
    setIsLoading(false); // Set loading state to false after data is fetched
    })
  }, [dispatch, formId,location.state.contract_address]);

  const { viewSignedSurveyUrl } = useSelector((state) => state.contract);

  const renderLoader = () => (
    <div className='flex height-100perc '>
      <Spinner />
    </div>
  );

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = viewSignedSurveyUrl;
    link.target = '_blank';
    link.download = 'approved_survey.pdf';
    link.click();
  };
 
  const toVerifyPage = () => {
    navigate(`/verify-workflow/${location.state.adminId}/${formId}`,{state:{adminId:location.state.adminId,contract_address: location.state.contract_address}});
  }
  return (
      <MainLayout>
                <h1>View Approved Workflows</h1>
                {isLoading ? <CustomSkeleton /> :
                  viewSignedSurveyUrl!=='' ?
                  <Row justify={'center'}>
                    <Col xs={24} sm={24} md={24} lg={18} xl={20}>
                      <div className='height-500'>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                          <Viewer fileUrl={viewSignedSurveyUrl} renderLoader={renderLoader} />
                        </Worker>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={4}>
                      <div className='ml-2'>
                        <Button type="primary" size='large' className='brown-black-btn w-220' onClick={handleDownload}>Download PDF</Button>
                        <br/>
                        <br/>
                        <Button type="primary" size='large' className='brown-black-btn w-220' onClick={()=>toVerifyPage()}>Verify Workflow</Button>
                      </div>
                    </Col>
                  </Row>
                  : <h1 className='textalign-center w-100perc'>All the required approvers haven't signed survey pdf .</h1>
                }
      </MainLayout>
  );
};

export default ViewApprovedSurveyPage;

