import { createAsyncThunk } from "@reduxjs/toolkit"; 
import Web3 from "web3";
import RPC from "../../web3RPC";
import { openNotificationWithIcon } from "../../utils/openNotificationWithIcon";
import axios from "axios";
import { EWarrentDeployerAddress , EWarrentDeployerABI } from '../../utils/EWarrentDeployerABIAndAddress';
import { web3auth } from "../../utils/Web3Auth";
import { deployedEWarrentForAdmin, transferredEWarrentOwnership } from "./template";

const commonCalls = async()=> {
	try {
    const Web3Auth = await web3auth(); // function for this 
    const web3authProvider = await Web3Auth.connect();
    const web3 = new Web3(web3authProvider);       
    const fromAddress = (await web3.eth.getAccounts())[0];
    const eWarrentDeployerContract = new web3.eth.Contract(EWarrentDeployerABI,EWarrentDeployerAddress);
	const balanceInWei = await web3.eth.getBalance(fromAddress);
	const balance = web3.utils.fromWei(balanceInWei, 'ether');
    return {eWarrentDeployerContract,fromAddress,web3,balance};
	}
	catch(error){
		console.log("commonCalls error in eWarrantDeployer action :",error);
	}
};

export const deployEWarrentForAdmin = createAsyncThunk("deployerContract/deployEWarrentForAdmin",async(data,thunkAPI) =>{
    try{
        const numberOfTokens = 100000;
        const adminAddress = data.address;
        const adminEmail = data.email;
        const adminRole = data.role;
        console.table("admin info :",adminAddress,adminEmail,adminRole);
        const {eWarrentDeployerContract,fromAddress} = await commonCalls();
        const inviteAdminReceipt = await eWarrentDeployerContract.methods.inviteAdmin(adminAddress,numberOfTokens).send({from:fromAddress});
        const deployedEWarrentContract = inviteAdminReceipt.events.AdminInvited.returnValues.deployedContract;
        console.log("deployedEWarrentContract Address :",deployedEWarrentContract);
        const apiData = {email:adminEmail,address:adminAddress,role:adminRole,contract_address:deployedEWarrentContract};
        await thunkAPI.dispatch(deployedEWarrentForAdmin(apiData));
        console.log("inviteAdminReceipt :",inviteAdminReceipt);
    } catch(error) {
        console.log("deployEWarrentForAdmin action error :",error);
    }
});

export const transferEWarrentOwnership = createAsyncThunk("deployerContract/transferEWarrentOwnership",async(data,thunkAPI) =>{
    try{
        const {eWarrentDeployerContract,fromAddress} = await commonCalls();
        const transferEWarrentOwnershipReceipt = await eWarrentDeployerContract.methods.eWarrentOwnershipTransferred(data.admin.address,data.new_admin.address).send({from:fromAddress});
        const EWarrantContractAddress = transferEWarrentOwnershipReceipt.events.EWarrantOwnershipTransferred.returnValues.ewarrantContractAddress;
        const adminAPIDataToUpdateContractAddress = {email:data.admin.email,address:data.admin.address,role:"admin",contract_address:"0x0000000000000000000000000000000000000000"};
        const newAdminAPIDataToUpdateContractAddress = {email:data.new_admin.email,address:data.new_admin.address,role:"admin",contract_address:EWarrantContractAddress};
        const arrayEWarrantAdminAndNewAdmin = [adminAPIDataToUpdateContractAddress,newAdminAPIDataToUpdateContractAddress];
        // if(transferEWarrentOwnershipReceipt.status===true) {
        //     thunkAPI.dispatch(transferredEWarrentOwnership(arrayEWarrantAdminAndNewAdmin))
        // }
        await thunkAPI.dispatch(transferredEWarrentOwnership(arrayEWarrantAdminAndNewAdmin))
        console.log("transferEWarrentOwnershipReceipt eWarrentDEployer contract wala receipt :",transferEWarrentOwnershipReceipt);
        return transferEWarrentOwnershipReceipt.status;
    } catch(error) {
        console.log("transferEWarrentOwnership action error :",error);
    }
});