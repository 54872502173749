import {  Outlet, useNavigate } from "react-router-dom";
import { Button, Result } from "antd";

const SuperAdminRoutes = ({ children }) => {
  const navigate = useNavigate();

  function Navi() {
    navigate("/");
  }
  const isSuperAdmin = JSON.parse(localStorage.getItem('isSuperAdmin'));

  return (
    isSuperAdmin ? <Outlet /> :(
            <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
                <Button onClick={Navi} type="primary">
                Back Home
                </Button>
            }
            />

    )
  )
    };
export default SuperAdminRoutes;