import { CopyOutlined, UserOutlined } from '@ant-design/icons';
import {Avatar, Col, Popover, Row , message } from "antd";
import { useEffect, useMemo, useState } from 'react';
import {Button} from "antd";
import { useNavigate } from 'react-router-dom';
import { useDispatch , useSelector } from "react-redux";
import { logout } from "../../redux/actions/web3Auth";
import Spinner from "../Spinner/Spinner";
import copy from 'copy-to-clipboard';
import "./Header.scss";

const CustomUserAvatar = () => {
    const {userEthBalance,ethBalanceLoading} =  useSelector((state)=>state.contract);
    const {isLogoutLoading} = useSelector((state)=>state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoutUser = ()=> {
      dispatch(logout()).then(()=>{
        navigate('/');
      });
    }
    const [isCopying, setIsCopying] = useState(false);

    const handleCopyAddress = (address) => {
      setIsCopying(true);
      copy(address);
      setTimeout(() => setIsCopying(false), 1000); // Reset copying state after 1 second
      message.success('Address copied to clipboard!');
    };

    const showUserModal  = () => {
        console.log("user icon clicked!")
    }
    const userEmail  = localStorage.getItem('email');
    const isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
    const isSuperAdmin = JSON.parse(localStorage.getItem('isSuperAdmin'));
    const user = localStorage.getItem('user');
    const content = (
      <div className='userdata-popover'>
          {ethBalanceLoading ? <div className='flex popover-height-onspin'><Spinner/></div>:
          <>
          <span>User Details</span>
          <hr/>
          <Col>
          <Row><Col span={6}>Email</Col> <Col span={2}>:</Col> <Col span={16}>{userEmail}</Col></Row>
          <Row><Col span={6}>Account</Col> <Col span={2}>:</Col> <Col span={16} onClick={() => handleCopyAddress(user)} ><div><span>{user && <>{user.slice(0, 6)}...{user.slice(-4)}&nbsp;<CopyOutlined /></>}</span></div></Col></Row>
          <Row><Col span={6}>Balance</Col> <Col span={2}>:</Col> <Col span={16}>{userEthBalance}</Col></Row>
          </Col>
          <hr/>
          <Button  type='primary' className='logout-btn' onClick={logoutUser}  loading={isLogoutLoading}>Log Out</Button></>}
      </div>
    );
    
    const [showArrow, setShowArrow] = useState(true);
    const [arrowAtCenter, setArrowAtCenter] = useState(false);
    const mergedArrow = useMemo(() => {
      if (arrowAtCenter)
        return {
          arrowPointAtCenter: true,
        };
      return showArrow;
    }, [showArrow, arrowAtCenter]);

    const getPlacement = () => {
      return window.innerWidth > 600 ? 'leftTop' : 'bottomRight';
    };
  
    const [placement, setPlacement] = useState(getPlacement);

    useEffect(() => {
      const handleResize = () => {
        setPlacement(getPlacement);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    return(
      <div className='flex-between-center'>
      {
        isSuperAdmin ? <h3><span className='user-role' >Super Admin &nbsp;</span></h3>: 
        isAdmin ? <h3><span className='user-role' >Admin &nbsp;</span></h3> :
        !isAdmin && <h3><span className='user-role' >User &nbsp;</span></h3> 
      }
      
      <div>
        <Popover placement='leftTop' content={content} arrow={mergedArrow} trigger="click">
            <Avatar shape="square" icon={<UserOutlined />}  onClick={showUserModal} className="user-avatar" />
        </Popover>
      </div>
      </div>
    )
}
export default CustomUserAvatar;