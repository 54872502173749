import { createSlice } from "@reduxjs/toolkit";
import { } from "../actions/session";

const sessionSlice = createSlice({
    name:'session',
    initialState:{
    },
    reducers:{
    },
    extraReducers:(builder)=>{
    }
})
export const { activateSession, deactivateSession } = sessionSlice.actions;
export default sessionSlice.reducer;