import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminsForUser } from '../../redux/actions/template';
import { Card, Col, Row } from 'antd';
import Spinner from '../../components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../components/MainLayout/MainLayout';

const SubmitSurveys = () => {
  const {isAdminArrayForUserLoading,adminArrayObj } = useSelector(state => state.template);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getAdminsForUser());
  },[dispatch]);
  const adminDescription = (admin) => (
    <div className='flex-center-direction-col'>
      <Row >
        <Col span={8} className='bold-font'>
          Email
        </Col>
        <Col span={2} className='bold-font'>
        : 
        </Col>
        <Col span={14}>
          {admin.email}
        </Col>
      </Row>
      <Row>
        <Col span={8} className='bold-font'>
            xVault Address
        </Col>
        <Col span={2} className='bold-font'>
          : 
        </Col>
        <Col span={14}>
            {admin.contract_address.slice(0, 6)}...{admin.contract_address.slice(-4)}
        </Col>
      </Row>
    </div>
  );

  const navigate = useNavigate();
  const toSubmitSurveyPage = (admin) => {
    navigate(`/submit-workflow/${admin._id}`,{state:{contract_address:admin.contract_address}});
  }

  return (
    <MainLayout>
                  <>
                    <h1>Following Admins have assigned you workflows</h1>
                    <Row gutter={[20,20]}>
                    {isAdminArrayForUserLoading ? <div className='width-height-100perc flex-direction-col'><Spinner/><h3>Loading...</h3></div>
                      : 
                      adminArrayObj.map((admin)=>(
                        <Col xs={24} sm={24} md={12}>
                          <Card
                            className='workflow-card' 
                            cover={<div className='flex mt-1'><img src="/assets/images/admin3.png" alt="admin" className='workflow-card-icon mt-4' /></div>}
                            hoverable
                            key={admin._id}    
                            onClick={()=>toSubmitSurveyPage(admin)}
                          >
                           {adminDescription(admin)}
                          </Card>
                        </Col>
                      ))
                    }
                    </Row>
                  </>
    </MainLayout>

  )
}

export default SubmitSurveys;