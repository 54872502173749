import React from "react";

import { Tooltip, message } from "antd";
// import CustomButton from "../../../globals/components/button";
import { InfoCircleOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Size = ReactQuill.Quill.import("attributors/style/size");
Size.whitelist = [
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
  "30px",
  false,
];
ReactQuill.Quill.register(Size, true);
const editorStyles = {
  fontSize: {
    "10px": 10,
    "12px": "12px",
    "14px": "14px",
    "16px": "16px",
    "18px": "18px",
    "20px": "20px",
    "22px": "22px",
    "24px": "24px",
    "26px": "26px",
    "28px": "28px",
    "30px": "30px",
  },
};

const Editor = ({ value, onChange, placeholder }) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block", "formula"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      // [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],

      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],

      [{ align: [] }],
      [{ size: [] }],

      ["link", "image", "video"],
      // [
      //   {
      //     size: [
      //       "10px",
      //       "12px",
      //       "14px",
      //       "16px",
      //       "18px",
      //       "20px",
      //       "22px",
      //       "24px",
      //       "26px",
      //       "28px",
      //       "30px",
      //       false,
      //     ],
      //   },
      // ],

      ["clean"],
      ["undo", "redo"],
    ],
    clipboard: {
      matchVisual: false,
    },
    history: {
      delay: 2000,
      maxStack: 500,
      userOnly: true,
    },
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "align",
    "indent",
    // "direction",
    "list",
    "bullet",
    "script",
    "blockquote",
    "code-block",
    "link",
    "image",
    "video",
    "size",
     "clean",
  ];

  const handleChange = (html) => {
    onChange(html);
  };
  const handleSave = () => {
    // Save the editor content and title to the database
    message.success("Content saved successfully!");
  };
  return (
    <>
      <ReactQuill
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        style={editorStyles}
        // direction='rtl'
        theme="snow"
      />
      {/* <div style={{ marginTop: "20px" }}>
        <Tooltip title="Save" placement="bottom">
          <CustomButton
            type="primary"
            shape="round"
            size="large"
            onClick={handleSave}
          >
            Save
          </CustomButton>
        </Tooltip>
        <Tooltip title="Preview" placement="bottom">
          <CustomButton
            type="ghost"
            shape="round"
            size="large"
            icon={<InfoCircleOutlined />}
          >
            Preview
          </CustomButton>
        </Tooltip>
      </div> */}
    </>
  );
};
export default Editor;
