import { createAsyncThunk } from "@reduxjs/toolkit"; 
import { web3auth } from "../../utils/Web3Auth";
import Web3 from "web3";
import { EAS , SchemaEncoder , createOffchainURL} from "@ethereum-attestation-service/eas-sdk";
import { workflowApproverSchemaUID, workflowAssignmentSchemaUID, workflowSignatureSchemaUID, workflowSubmissionSchemaUID, workflowTemplateEditSchemaUID, workflowTemplateSchemaUID } from "../../utils/schemaUUID";
import {EASContractAddress,EASContractABI} from '../../utils/EASABIAndAddress.js';
import {ethers} from 'ethers';
import RPC from "../../web3RPC";
import { openNotificationWithIcon } from "../../utils/openNotificationWithIcon.jsx";
import { addWorkflowAssignmentAttestation, addWorkflowAttestation, addWorkflowCreationAttestation } from "./template.js";

const ZERO_BYTES32  = '0x0000000000000000000000000000000000000000000000000000000000000000';

const commonCalls = async()=> {
	try {
    const Web3Auth = await web3auth();
    const web3authProvider = await Web3Auth.connect();
    const web3 = new Web3(web3authProvider);       
    const fromAddress = (await web3.eth.getAccounts())[0];
	const balanceInWei = await web3.eth.getBalance(fromAddress);
	const balance = web3.utils.fromWei(balanceInWei, 'ether');
    const EASContract = new web3.eth.Contract(EASContractABI,EASContractAddress);
    return {EASContract,web3authProvider,Web3Auth,fromAddress,web3,balance};
	}
	catch(error){
		console.log("commonCalls error :",error);
	}
};

export const onchainAttestWorkflowTemplate = createAsyncThunk("attestations/onchainAttestWorkflowTemplate",async(data,thunkAPI)=> {
	try {   
        console.log("required attestation data :",data);
        const {EASContract,fromAddress} = await commonCalls();
        const schemaEncoder = new SchemaEncoder("bytes32 workflowTIdHash , bytes32 contentHash , uint64 version , uint8 role");
        const encodedData = schemaEncoder.encodeData([{name:'workflowTIdHash',value:data.workflowTIdHash,type:'bytes32'},{name:'contentHash',value:data.contentHash,type:'bytes32'},{name:'version',value:data.version,type:'uint64'},{name:'role',value:data.role,type:'uint8'}]);
        const attestationReceipt = await EASContract.methods.attest({
            schema : workflowTemplateSchemaUID,
            data: {
                recipient:fromAddress,
                expirationTime:0,
                revocable:false,
                refUID:ZERO_BYTES32,
                data:encodedData,
                value:0
            }
        }).send({from:fromAddress});
        const attestationUID = attestationReceipt.events.Attested.returnValues.uid;
        console.log("uid of attestation :",attestationUID);
        const workflowCreationAttestationData = {location:'onchain',attestationUId:attestationUID,id:data.templateId,version:data.version,action:"Created",sortBy:data.sortBy,orderBy:data.orderBy,page:data.page,limit:data.limit};
        thunkAPI.dispatch(addWorkflowCreationAttestation(workflowCreationAttestationData))
        // a new patch api to be created for appending this attestation to the workflow template object ( similar to saveEvents api )
        // working flow 
        // admin creates a workflow template 
        // _id of this newly created template to be returned  ( by Kartik , this api should be modified first )
        // after onchain attestation is made and we get attestationUId back , we take it and send it to backend along with the _id of the template , it gets appended the template object
        //  data object to be sent to backend is below :
        //  attestation : {
        //  templateId : _id, // search using this 
        //  location:"onchain",
        //  attestationUId : "attestion unique id here"
        // }
        return attestationUID;
    }
	catch(error){
		console.log("onchainAttestWorkflowTemplate error :",error);
	}
});

export const offchainAttestWorkflowTemplate = createAsyncThunk("attestations/offchainAttestWorkflowTemplate",async(data,thunkAPI)=> {
	try {   
        let provider = new ethers.JsonRpcProvider('https://polygon-mumbai.infura.io/v3/1b50862ccbe34a7f90bba5f38a4d08d0');
        // const blockNum = await provider.getBlockNumber();
        // console.log("block number :",blockNum);    
        const {fromAddress} = await commonCalls();
        const web3Auth = await web3auth();
        if(!web3Auth) {
            return;
        }
        const rpc = new RPC(web3Auth.provider);
        const privateKey = await rpc.getPrivateKey();
        const wallet = new ethers.Wallet(privateKey,provider);
        const signer = wallet.connect(provider);
        const currentDate = new Date();
        const unixTimestamp = Math.floor(currentDate.getTime() / 1000);  
        const eas = new EAS(EASContractAddress);
        eas.connect(signer);
        const offchain = await eas.getOffchain(); 
        const schemaEncoder = new SchemaEncoder("string name , string description , bytes32 hash , uint64 createdAt");
        const encodedData = schemaEncoder.encodeData([{name:'name',value:data.workflowName,type:'string'},{name:'description',value:data.workflowDescription,type:'string'},{name:'hash',value:data.hash,type:'bytes32'},{name:'createdAt',value:data.workflowCreatedAt,type:'uint64'}]);
        const offchainAttestation = await offchain.signOffchainAttestation({
            recipient: fromAddress,
            expirationTime: 0,
            time: unixTimestamp,
            revocable: false,
            version: 1,
            nonce: 0,
            schema: workflowTemplateSchemaUID,
            refUID: ZERO_BYTES32,
            data: encodedData,
        }, signer);
        const attestation = {sig : offchainAttestation , signer: fromAddress};
        const encodedURL = createOffchainURL(attestation); // encoded url , append this to absolute url to be able to see attestation
        const attestationURL = `https://polygon-mumbai.easscan.org`+encodedURL;
        return attestationURL;
        // window.open(attestationURL);
        // function replacer(key, value) {
        //     if (typeof value === 'bigint') {
        //       return value.toString();
        //     }
        //     return value;
        // }     
        // var jsonString = JSON.stringify(offchainAttestation,replacer);
    }
	catch(error){
		console.log("offchainAttestWorkflowTemplate error :",error);
	}
});

export const onchainAttestWorkflowAssignment = createAsyncThunk("attestations/onchainAttestWorkflowAssignment",async(data,thunkAPI)=> {
	try {   
        const {EASContract,fromAddress} = await commonCalls();
        const schemaEncoder = new SchemaEncoder("uint48 workflowUId , bytes32 workflowTIdHash , uint64 version , address datasetProvider , address[] datasetApprovers , uint8 confirmationsRequired , uint8 role");
        const encodedData = schemaEncoder.encodeData([{name:'workflowUId',value:data.workflowUId,type:'uint48'},{name:'workflowTIdHash',value:data.workflowTIdHash,type:'bytes32'},{name:'version',value:data.version,type:'uint64'},{name:'datasetProvider',value:data.submitter,type:'address'},{name:'datasetApprovers',value:data.approvers,type:'address[]'},{name:'confirmationsRequired',value:data.confirmationsRequired,type:'uint8'},{name:'role',value:data.role,type:'uint8'}]);
        const attestationReceipt = await EASContract.methods.attest({
            schema : workflowAssignmentSchemaUID,
            data: {
                recipient:fromAddress,
                expirationTime:0,
                revocable:false,
                refUID:data.referencedWorkflowAttestationUId,
                data:encodedData,
                value:0
            }
        }).send({from:fromAddress});
        const attestationUID = attestationReceipt.events.Attested.returnValues.uid;
        console.log("uid of onchainAttestWorkflowAssignment attestation :",attestationUID);
        const workflowAssignmenAtttestationData = {location:'onchain',attestationUId:attestationUID,formId:data.workflowUId,attestation_type:"assignment",action:"assigned"};
        thunkAPI.dispatch(addWorkflowAttestation(workflowAssignmenAtttestationData));// add the newly created attestation to db
        return attestationUID;
    }
	catch(error){
		console.log("onchainAttestWorkflowAssignment error :",error);
	}
});

export const onchainAttestWorkflowSubmission = createAsyncThunk("attestations/onchainAttestWorkflowSubmission",async(data,thunkAPI)=> {
	try {   
        const {EASContract,fromAddress} = await commonCalls();
        const schemaEncoder = new SchemaEncoder("uint48 workflowUId , bytes32 workflowContentHash , uint8 role");
        const encodedData = schemaEncoder.encodeData([{name:'workflowUId',value:data.workflowUId,type:'uint48'},{name:'workflowContentHash',value:data.workflowContentHash,type:'bytes32'},{name:'role',value:data.role,type:'uint8'}]);
        const attestationReceipt = await EASContract.methods.attest({
            schema : workflowSubmissionSchemaUID,
            data: {
                recipient:fromAddress,
                expirationTime:0,
                revocable:false,
                refUID:data.referencedAttestationUId!==""? data.referencedAttestationUId : ZERO_BYTES32,
                data:encodedData,
                value:0
            }
        }).send({from:fromAddress});
        const attestationUID = attestationReceipt.events.Attested.returnValues.uid;
        console.log("uid of onchainAttestWorkflowSubmission attestation :",attestationUID);
        const workflowAssignmenAtttestationData = {location:'onchain',attestationUId:attestationUID,formId:data.workflowUId,attestation_type:"submission",action:"submitted"};
        thunkAPI.dispatch(addWorkflowAttestation(workflowAssignmenAtttestationData));// add the newly created attestation to db

        // openNotificationWithIcon('success','Workflow Submitted Successfully');
        // a new api to be created for saving workflowId related attestations ( similar to saveEvents api )
        return attestationReceipt.status;
    }
	catch(error){
		console.log("onchainAttestWorkflowSubmission error :",error);
	}
});

export const onchainAttestWorkflowApproval = createAsyncThunk("attestations/onchainAttestWorkflowApproval",async(data,thunkAPI)=> {
	try {   
        const {EASContract,fromAddress} = await commonCalls();
        const schemaEncoder = new SchemaEncoder("uint48 workflowUId , bytes32 workflowContentHash , string action , uint8 role");
        const encodedData = schemaEncoder.encodeData([{name:'workflowUId',value:data.workflowUId,type:'uint48'},{name:'workflowContentHash',value:data.workflowContentHash,type:'bytes32'},{name:'action',value:data.action,type:'string'},{name:'role',value:data.role,type:'uint8'}]);
        const attestationReceipt = await EASContract.methods.attest({
            schema : workflowApproverSchemaUID,
            data: {
                recipient:fromAddress,
                expirationTime:0,
                revocable:false,
                refUID:data.referencedAttestationUId!==""? data.referencedAttestationUId : ZERO_BYTES32,
                data:encodedData,
                value:0
            }
        }).send({from:fromAddress});
        const attestationUID = attestationReceipt.events.Attested.returnValues.uid;
        console.log("uid of onchainAttestWorkflowApproval attestation :",attestationUID);
        const workflowApprovalAtttestationData = {location:'onchain',attestationUId:attestationUID,formId:data.workflowUId,attestation_type:data.attestation_type,action:data.action};
        thunkAPI.dispatch(addWorkflowAttestation(workflowApprovalAtttestationData));// add the newly created attestation to db
        // openNotificationWithIcon('success','Workflow Approved Successfully');
        // a new api to be created for saving workflowId related attestations ( similar to saveEvents api )
        return attestationReceipt.status;
    }
	catch(error){
		console.log("onchainAttestWorkflowSubmission error :",error);
	}
});

export const onchainAttestWorkflowSignature = createAsyncThunk("attestations/onchainAttestWorkflowSignature",async(data,thunkAPI)=> {
	try {   
        const {EASContract,fromAddress} = await commonCalls();
        const schemaEncoder = new SchemaEncoder("uint48 workflowUId ,string action , bytes32 signatureBlobHash , uint8 role ");
        const encodedData = schemaEncoder.encodeData([{name:'workflowUId',value:data.workflowUId,type:'uint48'},{name:'action',value:data.action,type:'string'},{name:'signatureBlobHash',value:data.signatureBlob,type:'bytes32'},{name:'role',value:data.role,type:'uint8'}]);
        const attestationReceipt = await EASContract.methods.attest({
            schema : workflowSignatureSchemaUID,
            data: {
                recipient:fromAddress,
                expirationTime:0,
                revocable:false,
                refUID:data.referencedAttestationUId!==""? data.referencedAttestationUId : ZERO_BYTES32,
                data:encodedData,
                value:0
            }
        }).send({from:fromAddress});
        const attestationUID = attestationReceipt.events.Attested.returnValues.uid;
        console.log("uid of onchainAttestWorkflowSignature attestation :",attestationUID);
        // openNotificationWithIcon('success','Workflow Signed Successfully');
        const workflowSignedAtttestationData = {location:'onchain',attestationUId:attestationUID,formId:data.workflowUId,attestation_type:data.attestation_type,action:data.action};
        thunkAPI.dispatch(addWorkflowAttestation(workflowSignedAtttestationData))

        // a new api to be created for saving workflowId related attestations ( similar to saveEvents api )
        return attestationReceipt.status;
    }
	catch(error){
		console.log("onchainAttestWorkflowSignature error :",error);
	}
});

export const onchainAttestWorkflowTemplateEdit = createAsyncThunk("attestations/onchainAttestWorkflowTemplateEdit",async(data,thunkAPI)=> {
	try {   
        console.log("required attestation data :",data);
        const {EASContract,fromAddress} = await commonCalls();
        const schemaEncoder = new SchemaEncoder("bytes32 workflowTIdHash , bytes32 contentHash , uint64 version , uint8 role");
        const encodedData = schemaEncoder.encodeData([{name:'workflowTIdHash',value:data.workflowTIdHash,type:'bytes32'},{name:'contentHash',value:data.contentHash,type:'bytes32'},{name:'version',value:data.version,type:'uint64'},{name:'role',value:data.role,type:'uint8'}]);
        const attestationReceipt = await EASContract.methods.attest({
            schema : workflowTemplateSchemaUID,
            data: {
                recipient:fromAddress,
                expirationTime:0,
                revocable:false,
                refUID:data.referencedAttestation,
                data:encodedData,
                value:0
            }
        }).send({from:fromAddress});
        const attestationUID = attestationReceipt.events.Attested.returnValues.uid;
        console.log("uid of attestation :",attestationUID);
        const workflowCreationAttestationData = {location:'onchain',attestationUId:attestationUID,id:data.templateId,version:data.version,action:"Updated",sortBy:data.sortBy,orderBy:data.orderBy,page:data.page,limit:data.limit};
        thunkAPI.dispatch(addWorkflowCreationAttestation(workflowCreationAttestationData))
        return attestationUID;
    }
	catch(error){
		console.log("onchainAttestWorkflowTemplate error :",error);
	}
});