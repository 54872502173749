import { Button,Form, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import { transferEWarrentOwnership } from '../../redux/actions/eWarrantDeployer'; // pehle eWarrantDeployer contract mein transfer ka logic tha , ab XVault contract mein hardcoded hai transfer logic
import { transferEWarrentContractOwnership } from '../../redux/actions/eWarrant';

const TransferEWarrantOwnership = ({form,allAdminArray}) => {
    // const { isEWarrentTransferLoading } = useSelector(state => state.eWarrantDeployer);
    const {isTransferEWarrentContractOwnershipLoading} = useSelector(state => state.contract);
    const {isEWarrentOwnershipTransferAPISuccess} = useSelector(state => state.template);
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!',
        types: {
        email: '${label} is not a valid email!',
        },
    };

    const layout = {
        labelCol: {
          span: 24,
        },
        wrapperCol: {
          span: 24,
        },
    };
    const adminsWithContract = allAdminArray?.filter((admin) => admin.hasOwnProperty("contract_address") && admin.contract_address!=="0x0000000000000000000000000000000000000000");
    const adminsWithoutContract = allAdminArray?.filter((admin)=>admin.hasOwnProperty("address") && !admin.hasOwnProperty("contract_address"))

    const [selectedAdmin, setSelectedAdmin] = useState(null);

    const handleSelectChange = (value) => {
      const selectedAdminObject = adminsWithContract.find((ele) => ele.address === value);
      const objToSend = {email:selectedAdminObject.email,address:selectedAdminObject.address,contract_address:selectedAdminObject.contract_address};
      setSelectedAdmin(objToSend);
    };

    
    const [selectedNewAdmin, setSelectedNewAdmin] = useState(null);

    const handleSelect1Change = (value) => {
      const selectedAdminObject = adminsWithoutContract.find((ele) => ele.address === value);
      const objToSend = {email:selectedAdminObject.email,address:selectedAdminObject.address};
      setSelectedNewAdmin(objToSend);
    };
  
    const dispatch = useDispatch();

    useEffect(()=>{
        isEWarrentOwnershipTransferAPISuccess && form.resetFields();
    },[isEWarrentOwnershipTransferAPISuccess,form]);

    return (
            <Form
                form={form}
                {...layout}
                name="nest-messages"
                onFinish={()=>{
                    const objToDispatch = {admin:selectedAdmin,new_admin:selectedNewAdmin,contract_address:selectedAdmin.contract_address}
                    dispatch(transferEWarrentContractOwnership(objToDispatch));
                    // form.resetFields();
                }}
                className='invitation-form'
                validateMessages={validateMessages}
                labelAlign='left'
                colon={false}
            >

                <Form.Item label="Admin" required={true} name="admin" rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: "Please select Admin to be removed",
                    },
                ]} colon={false}>
                    <Select
                        showSearch
                        placeholder="Select Admin's Email"
                        onChange={handleSelectChange}
                    >
                        {adminsWithContract.map((ele) => (
                            <Select.Option key={ele._id} value={ele.address}>
                                {ele.email}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="New Admin" required={true} name="new-admin" rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: "Please Select New Admin",
                    },
                ]} colon={false}>
                    <Select
                        showSearch
                        placeholder="Select New Admin's Email"
                        onChange={handleSelect1Change}
                    >
                        {adminsWithoutContract.map((ele) => (
                            <Select.Option key={ele._id} value={ele.address}>
                                {ele.email}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                >
                    <Button type="primary" htmlType="submit" loading={isTransferEWarrentContractOwnershipLoading} block>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
    )
}

export default TransferEWarrantOwnership;