import React, { useEffect, useState } from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { Form, InputNumber, Modal, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Col, DatePicker, Input, Radio, Row, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { resetSurveySubmissionStatus } from "../../redux/slices/eWarrantSlice";
import { getFormStatus, submitPDF } from '../../redux/actions/eWarrant';
import AlreadySubmittedSurvey from './AlreadySubmittedSurvey';
import {resetSurveySubmittedStatusAPI, valueaddedonChange, valueaddedonChange3, valuedeletedonChange3} from "../../redux/slices/templateSlice";
import RejectedSurvey from './RejectedSurvey';
import SurveyTimeline from '../../components/SurveyTimeline/SurveyTimeline';
import CustomSkeleton from '../../components/CustomSkeleton/CustomSkeleton'
import AttestationTimeline from '../../components/AttestationTimeline/AttestationTimeline';
import MainLayout from '../../components/MainLayout/MainLayout';
import "./SubmitSurveyPage.scss";

const { Option } = Select;

const SubmitSurveyPage = () => {
  const [form] = Form.useForm();
  let { formId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {formUIData,isSurveySubmitButtonClicked,isSurveySubmitted,attestationTimelineData} = useSelector((state)=>state.template);
  const {submissionReceiptStatus,isPdfSubmitLoading,formStatusEnum} =  useSelector((state)=>state.contract);
  const {isAttestingWorkflowSubmission,isWorkflowSubmissionAttested} = useSelector(state => state.attestations);
  const [isLoading, setIsLoading] = useState(true); // Track initial loading state

  useEffect(()=>{
    const eWarrentData = {formId:formId , contract_address: location.state.contract_address}
    dispatch(getFormStatus(eWarrentData)).then(() => {
      setIsLoading(false); // Set loading state to false after data is fetched
    });
  },[dispatch,formId,location.state.contract_address]);
  
  useEffect(() => {  
    if (isWorkflowSubmissionAttested && isSurveySubmitted && submissionReceiptStatus) {
      const navigationTimeout = setTimeout(() => {
        navigate(`/submit-workflow/${location.state.adminId}`, {
          state: { contract_address: location.state.contract_address },
        });
      }, 0);
  
      return () => {
        clearTimeout(navigationTimeout);  
        dispatch(resetSurveySubmissionStatus());
        dispatch(resetSurveySubmittedStatusAPI());
      };
    }
  }, [formId, navigate, submissionReceiptStatus, isSurveySubmitted, location.state.adminId, location.state.contract_address, isWorkflowSubmissionAttested, dispatch]);
  
  const onFinish = (values) => {
    console.log("values logged at the time of workflow submission :",values);
    const data = {
      formId:formId,
      name:formUIData.name,
      description:formUIData.description,
      fields:formUIData.fields,
      contract_address: location.state.contract_address,
      referencedAttestation : attestationTimelineData ? (attestationTimelineData[attestationTimelineData.length-1].location==="onchain" ? attestationTimelineData[attestationTimelineData.length-1].attestationUId  : "" ) : "",
      _id:location.state._id
      // disabled:true
    }
    console.log("requiredData to be sent to submitPDF action :",data);
    dispatch(submitPDF(data));
  };

  const [currentPage, setCurrentPage] = useState(0);
  const fieldsPerPage = 5;
  const totalPages = Math.ceil(formUIData.fields?.length / fieldsPerPage);
  const handleNext = async() => {
    await form.validateFields();
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handleBack = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const startIndex = currentPage * fieldsPerPage;
  const endIndex = startIndex + fieldsPerPage;
  const pageFields = formUIData.fields?.slice(startIndex, endIndex);
  const handleCheckboxChange = (e,id) =>{
    if (e.target.checked) {
      dispatch(valueaddedonChange3({_id:id,value:e.target.value}));
    }
    else if(!e.target.checked){
      dispatch(valuedeletedonChange3({_id:id,value:e.target.value}));
    }
  }
  const validateEmail = (_, value) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid email address.'));
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModal1Open, setIsModal1Open] = useState(false);
  const showModal1 = () => {
    setIsModal1Open(true);
  };
  const handleOk1 = () => {
    setIsModal1Open(false);
  };
  const handleCancel1 = () => {
    setIsModal1Open(false);
  };

  return (
    <MainLayout>
        {isLoading ? <CustomSkeleton paragraph={{rows: 10}}/>:
          formStatusEnum===0 ?
            Object.keys(formUIData).length > 0 && 
            <Row justify={'center'} className='submit-workflow-row-main'>
              <Col xs={24} sm={24} md={24} lg={24} xl={10} className='left-column'>
                      <div className='left-column-title'>Title : <span>{formUIData.name}</span></div>
                      <div className='left-column-desc'>Description : <span>{formUIData.description}</span></div>
                      <br/>
                      <Space direction="vertical" size="large">
                        <Button type='primary' className='brown-black-btn w-220 opacity-point8' onClick={showModal}>Workflow Timeline</Button>
                        <Button type='primary' className='brown-black-btn w-220 opacity-point8' onClick={showModal1}>Attestation Timeline</Button>
                      </Space>
                      <Modal footer={false} title="Workflow Timeline" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                        <SurveyTimeline formId={formId}/>
                      </Modal>
                      <Modal footer={false} title="Attestation Timeline" open={isModal1Open} onOk={handleOk1} onCancel={handleCancel1} bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                        <AttestationTimeline formId={formId}/>
                      </Modal>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={14} className='right-column survey-form-main'>
                      <Form
                        form={form}
                        id="my-form"
                        onFinish={onFinish}
                        labelAlign="left"
                        layout="horizontal"
                        autoComplete="off"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        {pageFields.map((field, index) => (
                            <Row key={index}>
                              <Col key={index} span={24}>
                                <Form.Item
                                  label={
                                    <>
                                      <span className="label-h">{field.label}</span>                            
                                    </>
                                  }
                                  name={field.label}
                                  wrapperCol={12}
                                  colon={false}
                                  hasFeedback
                                  rules={
                                    field.dataType === 8
                                      ? [
                                          {
                                            required: field.required,
                                            message: `Please Enter ${field.label} `,
                                          },
                                          { validator: validateEmail }
                                        ]
                                      : [
                                          {
                                            required: field.required,
                                            message: `Please Enter ${field.label} `,
                                          },
                                        ]
                                  }
                                >
                                  {(field.dataType === 0 && (
                                    <Input
                                      placeholder={field.placeholder || `Enter A ${field.label}`}
                                      onChange={(e)=>dispatch(valueaddedonChange({_id:field._id,value:e.target.value}))}
                                      size="middle"
                                      width={400}
                                    />
                                  ))
                                  ||
                                  (field.dataType === 8 && (
                                    <Input
                                      placeholder={field.placeholder || `Enter A ${field.label}`}
                                      onChange={(e)=>dispatch(valueaddedonChange({_id:field._id,value:e.target.value}))}
                                      size="middle"
                                      width={400}
                                    />
                                  ))
                                   ||
                                    (field.dataType === 4 && (
                                      <Upload 
                                      onChange={(e)=>dispatch(valueaddedonChange({_id:field._id,value:e.file}))}
                                      >
                                        <Button icon={<UploadOutlined />}>
                                          Click to Upload
                                        </Button>
                                      </Upload>
                                    )) ||
                                    (field.dataType === 1 && (
                                      <TextArea
                                        placeholder={
                                          field.placeholder || `Enter A ${field.label}`
                                        }
                                      onChange={(e)=>dispatch(valueaddedonChange({_id:field._id,value:e.target.value}))}
                                        size="small"
                                        width={200}
                                      />
                                    )) ||
                                    (field.dataType === 9 && (
                                      <InputNumber
                                        onChange={(value)=>dispatch(valueaddedonChange({_id:field._id,value:value}))}
                                        controls={false}
                                        className='w-100perc'
                                        placeholder={field.placeholder || `Enter A ${field.label}`}
                                        maxLength={10}
                                        minLength={10}
                                      />
                                    ))
                                    ||
                                    (field.dataType === 7 && (
                                      <DatePicker
                                        // format="YYYY/MM/DD HH:mm:ss"
                                        placeholder={`Enter ${field.label}`}
                                        className='w-100perc'
                                        onChange={(value)=>dispatch(valueaddedonChange({_id:field._id,value:value}))}
                                      ></DatePicker>
                                    )) ||
                                    (field.dataType === 3 && (
                                      <Radio.Group
                                      onChange={(e)=>dispatch(valueaddedonChange({_id:field._id,value:e.target.value}))}
                                      >
                                        {field.options.map((item, index) => (
                                          <Radio key={index} value={item}>{item}</Radio>
                                        ))}
                                      </Radio.Group>
                                    )) ||
                                    (field.dataType === 5 && (
                                      <Checkbox.Group
                                      >
                                        {field.options.map((item, index) => (
                                          <Checkbox key={item} value={item}
                                            onChange={(e) =>handleCheckboxChange(e,field._id)}
                                          >
                                            {item}
                                          </Checkbox>
                                        ))}
                                      </Checkbox.Group>
                                    )) ||
                                    (field.dataType === 6 && field.typeOfOptions === "Single" && (
                                      <Select placeholder={`Enter ${field.label}`} 
                                        onChange={(value)=>dispatch(valueaddedonChange({_id:field._id,value:value}))}
                                      >
                                        {field.options.map((item, index) => (
                                          <Option key={index} value={item}>
                                            {item}
                                          </Option>
                                        ))}
                                      </Select>
                                    )) ||
                                    (field.dataType === 6 && field.typeOfOptions === "Multiple" && (
                                      <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder={`Enter ${field.label}`}
                                        onChange={(value)=>dispatch(valueaddedonChange({_id:field._id,value:value}))}
                                        // onDeselect={(value)=>dispatch(valuedeletedonChange({_id:field._id,value:value}))}
                                      >
                                        {field.options.map((item, index) => (
                                          <Option key={index} value={item}>
                                            {item}
                                          </Option>
                                        ))}
                                      </Select>
                                    ))
                                    }
                                </Form.Item>
                              </Col>
                              {/* <Col span={1}></Col>                      */}
                            </Row>

                        ))}
                        <Form.Item>
                          {currentPage > 0 && (
                            <Button type="primary" onClick={handleBack} className='mr-1'>
                              Back
                            </Button>
                          )}
                          {currentPage < totalPages - 1 && (
                            <Button type="primary" onClick={handleNext} className='mr-1'>
                              Next
                            </Button>
                          )}
                          {currentPage === totalPages - 1 && (
                            <Button type="primary" htmlType="submit" loading={ isPdfSubmitLoading || isSurveySubmitButtonClicked || isAttestingWorkflowSubmission}>
                              Submit
                            </Button>
                          )}
                        </Form.Item>
                      </Form>
              </Col>
            </Row>
          :(formStatusEnum===1||formStatusEnum===2||formStatusEnum===4||formStatusEnum===5 ? <AlreadySubmittedSurvey adminId={location.state.adminId} formId={formId}/>
          :formStatusEnum===3 && <RejectedSurvey contract_address={location.state.contract_address} formId={formId} _id={location.state._id}/>)
        }
    </MainLayout>
  )
}

export default SubmitSurveyPage;