import { createSlice } from "@reduxjs/toolkit";
import {getContractData,submitPDF,getPDFUrl,setFormData,grantRoleFunc,revokeRoleFunc,approveSubmittedPDF,getFormApprover,getFormSubmitter,getPDFDataApprover,verifyPDF,formIdToStatusObjectAsAdmin,allFormDataForAdmin,submitSignedPDF,formIdSubmitterData,formIdApproverData,rejectSubmittedPDF,getAdminAllFormIds,checkLoggedUserToBeAdmin,getBalanceOfUser,getFormStatus,rejectApprovedSurvey,approverSignApprovedSurvey,getApprovedPDFHash,getApprovedSurveys, submitRejectedPDF, getEthBalance, allEWarrentDataForSuperAdmin, getApprovedSurveysForExternalUser, transferEWarrentContractOwnership, getApprovedPDFHashForAdmin} from "../actions/eWarrant";

const eWarrantSlice = createSlice({
    name:"eWarrant",
    initialState: {
        isWarrantLoading:false,
        isFormDataSetterLoading:false,
        isFormDataSet:false,
        isMessage:null,
        isError:false,
        isSuccess:false,
        contractData:{}, // object hai 
        pdfUrl:'', 
        pdfHash:"",
        privateKey:'',
        isPdfSubmitLoading:false,
        submissionReceiptStatus:false, // receipt returned on submitting pdf
        initializeweb3Auth:false,
        web3Auth:{},
        isPDFURLLoading:false,
        signatureStatus:false,
        approverSelectedFormId:null,
        submitterOfFormId:"",
        submitter:'', // submitter of formId selected
        approver:'',  // approver of formId selected
        pdfDataApprover:{},
        formIdsToStatusArrSubmitter : [],
        formIdsToSubmitterInfo:[],
        isFormIdsToStatusArrLoadingSubmitter:false,
        formIdsToStatusArrApprover : [],
        formIdsToApproverInfo:[],
        isFormIdsToStatusArrLoadingApprover:false,
        isVerifyPDFLoading:false,
        isPDFVerifiedByAdmin:false,
        formIdsToStatusArrAdmin : [],
        isFormIdsToStatusArrLoadingAdmin:false,
        isFormDataForAdminLoading:false,
        completeFormDataForAdmin:{},
        signedPDFUploading:false,
        isSignedPDFLoading:false,
        signedPDFUploadStatus:false,
        isApprovePDFButtonLoading:false,
        isRejectSubmittedPDFLoading:false,
        isRevokeRoleLoading:false,
        userTokenBalance:null,
        userTokenBalanceLoading:false,
        isLoggedUserAdminHai:false,
        isLoggedUserAdminLoading:false,
        formStatusEnum:null,
        isFormStatusEnumLoading:false,
        numConfirmationsRequired:null,
        approverConfirmationCount:null,
        totalApprovers:null,
        isSurveySuccessfullyRejected:false,
        isRejectApprovedPDFLoading:false,
        submitterSurveyUrl:'',
        submitterSurveyHash:'',
        approverSurveyUrl:'',
        approverSurveyHash:'',
        approveSurveyReceiptStatus:false, // receipt returned on approving submitted pdf
        rejectedSubmittedSurveyReceipt:false,
        approverSignApprovedSurveyLoading:false,
        approverSignedApprovedSurvey:false,
        verifySurveyUIObj:{},
        isApprovedPDFHashLoading : false,
        isGetApprovedSurveysLoading : false,
        approvedSurveys:[],
        isPDFResubmitLoading:false,
        ethBalanceLoading:false,
        userEthBalance:'',
        viewSignedSurveyUrl:'',
        isSurveyApproved:false,
        surveyRejector:'',
        isApprovedSurveySigned:false,
        contractAddressForAdmin:'',
        adminSurveysArrayOfObjLoading:false,
        adminSurveysArrayOfObj:[],
        totalSurveysCreatedByAdmin:0,
        isGetApprovedSurveysForExternalUserLoading:false,
        approvedSurveysForExternalUser:[],
        isTransferEWarrentContractOwnershipLoading:false,
        isEWarrentOwnershipTransferred:false,
        workflowStatusArray :[],
        isApprovedPDFHashForAdminLoading:false,
        assignedWorkflowUIObj:[]
    },
    reducers:{
        setWeb3Auth:(state,action) =>{
            state.web3Auth = action.payload
        },
        setPDFHash:(state,action)=>{
            state.pdfHash = action.payload;
        }, 
        setApproverSelectedFormId:(state,action)=>{
            state.approverSelectedFormId = action.payload;
        },
        resetSurveySubmissionStatus : (state,{payload}) => {
            state.submissionReceiptStatus = false;
        },
        resetApprovedSurveyStatuses : (state,{payload}) => {
            state.rejectedSubmittedSurveyReceipt = false;
            state.approveSurveyReceiptStatus = false;
            state.approverSignedApprovedSurvey = false;
        }
    },
    extraReducers:(builder)=> {
        builder
            .addCase(getContractData.pending,(state,action) => {
                    state.isWarrantLoading = true;
                })
            .addCase(getContractData.fulfilled,(state,action)=> {
                    state.isWarrantLoading = false;
                    // console.log("action.payload :",action.payload)
                    state.contractData = action.payload;
                })
            .addCase(getContractData.rejected,(state,action)=> {
                    state.isWarrantLoading = false;
                    state.isError = true;
                    console.log("errror eWarrantSlice");
                })
            .addCase(submitPDF.pending,(state,action) => {
                    // state.isWarrantLoading = true;
                    state.isPdfSubmitLoading = true;
                    state.submissionReceiptStatus=false;
                })
            .addCase(submitPDF.fulfilled,(state,action)=> {
                    // state.isWarrantLoading = false;
                    state.isPdfSubmitLoading = false;
                    console.log("payload submit pdf action :",action.payload);
                    state.submissionReceiptStatus = action.payload;
                    // console.log("action.payload submitpdf :",action.payload.status);
                })
            .addCase(submitPDF.rejected,(state,action)=> {
                    // state.isWarrantLoading = false;
                    state.isPdfSubmitLoading = false;
                    state.isError = true;
                }) 
            .addCase(submitRejectedPDF.pending,(state,action) => {
                    state.isPDFResubmitLoading = true;
                })
            .addCase(submitRejectedPDF.fulfilled,(state,action)=> {
                    state.isPDFResubmitLoading = false;
                    state.submissionReceiptStatus = action.payload;
                })
            .addCase(submitRejectedPDF.rejected,(state,action)=> {
                    state.isPDFResubmitLoading = false;
                    state.isError = true;
                }) 
            .addCase(getPDFUrl.pending,(state,action) => {
                    // state.isWarrantLoading = true;
                    console.log("getPDFUrl pending :");
                })
            .addCase(getPDFUrl.fulfilled,(state,action)=> {
                    // state.isWarrantLoading = false;
                    state.pdfUrl = action.payload;
                    console.log("getPDFUrl fulfilled :");
                })
            .addCase(getPDFUrl.rejected,(state,action)=> {
                    // state.isWarrantLoading = false;
                    state.isError = true;
                })
            .addCase(grantRoleFunc.pending,(state,action) => {
                    console.log("grantRoleFunc pending state")
                })
            .addCase(grantRoleFunc.fulfilled,(state,action)=> {
                    console.log("grantRoleFunc fulfilled state")
                })
            .addCase(grantRoleFunc.rejected,(state,action)=> {
                    console.log("grantRoleFunc rejected state")
                })
            .addCase(revokeRoleFunc.pending,(state,action) => {
                    console.log("revokeRoleFunc pending state");
                    state.isRevokeRoleLoading = true;
                })
            .addCase(revokeRoleFunc.fulfilled,(state,action)=> {
                    console.log("revokeRoleFunc fulfilled state");
                    state.isRevokeRoleLoading = false;
                })
            .addCase(revokeRoleFunc.rejected,(state,action)=> {
                    state.isRevokeRoleLoading = false;
                })
            .addCase(approveSubmittedPDF.pending,(state,action) => {
                state.isApprovePDFButtonLoading = true;
                })
            .addCase(approveSubmittedPDF.fulfilled,(state,action)=> {
                state.isApprovePDFButtonLoading = false;
                state.signatureStatus = true;
                state.approveSurveyReceiptStatus = action.payload;
                })
            .addCase(approveSubmittedPDF.rejected,(state,action)=> {
                state.isApprovePDFButtonLoading = false;
                })
            .addCase(getFormApprover.pending,(state,action) => {
                })
            .addCase(getFormApprover.fulfilled,(state,action)=> {
                state.approver = action.payload;
                })
            .addCase(getFormApprover.rejected,(state,action)=> {
                })
            .addCase(getFormSubmitter.pending,(state,action) => {
                })
            .addCase(getFormSubmitter.fulfilled,(state,action)=> {
                state.submitter = action.payload;
                })
            .addCase(getFormSubmitter.rejected,(state,action)=> {
                })
            .addCase(setFormData.pending,(state,action) => {
                console.log("setting form data ...")
                state.isFormDataSetterLoading = true;
                state.isFormDataSet = false;
                })
            .addCase(setFormData.fulfilled,(state,action)=> {
                console.log("form data set successfully .")
                console.log("action.payload setFormData :",action.payload);
                state.isFormDataSet = action.payload;
                state.isFormDataSetterLoading = false;
            })
            .addCase(setFormData.rejected,(state,action)=> {
                console.log("form data not able to set !")
                state.isFormDataSetterLoading = false;
                })
            .addCase(getPDFDataApprover.pending,(state,action) => {
                console.log("getPDFDataApprover pending .")
                state.isPDFURLLoading = true;
                })
            .addCase(getPDFDataApprover.fulfilled,(state,action)=> {
                state.pdfDataApprover = action.payload;
                state.isPDFURLLoading = false;
                console.log("getPDFDataApprover fulfilled");
                })
            .addCase(getPDFDataApprover.rejected,(state,action)=> {
                console.log("getPDFDataApprover error");
                state.isPDFURLLoading = false;
                })
            .addCase(verifyPDF.pending,(state,action) => {
                        console.log("verifyPDF pending .")
                        state.isVerifyPDFLoading = true;
                    })
            .addCase(verifyPDF.fulfilled,(state,action)=> {
                        console.log("verifyPDF fulfilled");
                        state.isVerifyPDFLoading = false;
                        state.isPDFVerifiedByAdmin = action.payload; // action.payload returns true if verified successfully , otherwise false 
                        console.log("action.payload verifyPDF :",action.payload);
                    
                    })
            .addCase(verifyPDF.rejected,(state,action)=> {
                        state.isVerifyPDFLoading = false;
                        console.log("verifyPDF error");
                    })
            .addCase(formIdToStatusObjectAsAdmin.pending,(state,action) => {
                        console.log("formIdToStatusObjectAsAdmin pending .")
                        state.isFormIdsToStatusArrLoadingAdmin = true;
                        state.contractAddressForAdmin = '';
                        state.formIdsToStatusArrAdmin = []
                    })
            .addCase(formIdToStatusObjectAsAdmin.fulfilled,(state,action)=> {
                        console.log("formIdToStatusObjectAsAdmin fulfilled");
                        state.formIdsToStatusArrAdmin = action.payload.formStatusArrayAsAdmin; // action.payload returns true if verified successfully , otherwise false 
                        state.contractAddressForAdmin = action.payload.contract_address;
                        state.isFormIdsToStatusArrLoadingAdmin = false;
                    })
            .addCase(formIdToStatusObjectAsAdmin.rejected,(state,action)=> {
                        console.log("formIdToStatusObjectAsAdmin error");
                        state.isFormIdsToStatusArrLoadingAdmin = false;
                    })
            .addCase(allFormDataForAdmin.pending,(state,action) => {
                        console.log("pdfURLsForAdmin pending .")
                        state.viewSignedSurveyUrl = '';
                        state.isFormDataForAdminLoading = true;
                    })
            .addCase(allFormDataForAdmin.fulfilled,(state,action)=> {
                        console.log("pdfURLsForAdmin fulfilled");
                        state.isFormDataForAdminLoading = false;
                        state.viewSignedSurveyUrl = action.payload.approvedPDFUrl;
                        state.completeFormDataForAdmin = action.payload;
                    })
            .addCase(allFormDataForAdmin.rejected,(state,action)=> {
                        console.log("pdfURLsForAdmin error");
                        state.isFormDataForAdminLoading = false;
                    })
            .addCase(submitSignedPDF.pending,(state,action) => {
                        console.log("submitSignedPDF pending .")
                        state.signedPDFUploading = true;
                        state.isSignedPDFLoading = true;
                    })
            .addCase(submitSignedPDF.fulfilled,(state,action)=> {
                        console.log("submitSignedPDF fulfilled");
                        state.isSignedPDFLoading = false;
                        state.signedPDFUploading = false;
                        state.signedPDFUploadStatus = true;
                    })
            .addCase(submitSignedPDF.rejected,(state,action)=> {
                        state.isSignedPDFLoading = false;
                        console.log("submitSignedPDF error");
                        state.signedPDFUploading = false;
                    })
            .addCase(formIdSubmitterData.pending,(state,action) => {
                        state.isFormIdsToStatusArrLoadingSubmitter = true;
                        state.formIdsToSubmitterInfo = [];
                    })
            .addCase(formIdSubmitterData.fulfilled,(state,action)=> {
                        state.formIdsToSubmitterInfo = action.payload;
                        state.isFormIdsToStatusArrLoadingSubmitter = false;
                    })
            .addCase(formIdSubmitterData.rejected,(state,action)=> {
                        state.isFormIdsToStatusArrLoadingSubmitter = false;
                    })
            .addCase(formIdApproverData.pending,(state,action) => {
                        state.isFormIdsToStatusArrLoadingApprover = true;
                    })
            .addCase(formIdApproverData.fulfilled,(state,action)=> {
                        state.formIdsToApproverInfo = action.payload;
                        state.isFormIdsToStatusArrLoadingApprover = false;
                    })
            .addCase(formIdApproverData.rejected,(state,action)=> {
                        state.isFormIdsToStatusArrLoadingApprover = false;
                    })
            .addCase(rejectSubmittedPDF.pending,(state,action) => {
                        state.isRejectSubmittedPDFLoading = true;
                    })
            .addCase(rejectSubmittedPDF.fulfilled,(state,action)=> {
                        state.isRejectSubmittedPDFLoading = false;
                        state.rejectedSubmittedSurveyReceipt = action.payload;
                    })
            .addCase(rejectSubmittedPDF.rejected,(state,action)=> {
                    })
            .addCase(getAdminAllFormIds.pending,(state,action) => {
                    })
            .addCase(getAdminAllFormIds.fulfilled,(state,action)=> {
                    state.createdFormIdArray = action.payload;
                    })
            .addCase(getAdminAllFormIds.rejected,(state,action)=> {
                    })
            .addCase(checkLoggedUserToBeAdmin.pending,(state,action) => {
                    state.isLoggedUserAdminLoading = true;
                    })
            .addCase(checkLoggedUserToBeAdmin.fulfilled,(state,action)=> {
                    state.isLoggedUserAdminHai = action.payload;
                    state.isLoggedUserAdminLoading = false;
                    })
            .addCase(checkLoggedUserToBeAdmin.rejected,(state,action)=> {
                    state.isLoggedUserAdminLoading = false;
                    })
            .addCase(getBalanceOfUser.pending,(state,action) => {
                    state.userTokenBalanceLoading = true;
                    })
            .addCase(getBalanceOfUser.fulfilled,(state,action)=> {
                    state.userTokenBalance = action.payload;
                    state.userTokenBalanceLoading = false;
                    })
            .addCase(getBalanceOfUser.rejected,(state,action)=> {
                    state.userTokenBalanceLoading = false;
                    })
            .addCase(getFormStatus.pending,(state,action) => {
                    state.isFormStatusEnumLoading = true;
                    })
            .addCase(getFormStatus.fulfilled,(state,{payload})=> {
                    state.formStatusEnum = payload.formStatusEnumToNum;
                    state.numConfirmationsRequired = payload.numConfirmationsRequired;
                    state.approverConfirmationCount = payload.approverConfirmationCount;
                    state.totalApprovers = payload.totalApprovers;
                    state.submitterSurveyUrl = payload.submitterSurveyUrl;
                    state.submitterSurveyHash = payload.submitterSurveyHash;
                    state.approverSurveyUrl = payload.approverSurveyUrl;
                    state.approverSurveyHash = payload.approverSurveyHash;
                    state.isSurveyApproved = payload.isSurveyApproved;
                    state.surveyRejector = payload.surveyRejector;
                    state.isApprovedSurveySigned = payload.isApprovedSurveySigned;
                    state.isFormStatusEnumLoading = false;
                    })
            .addCase(getFormStatus.rejected,(state,action)=> {
                    state.isFormStatusEnumLoading = false;
                    })
            .addCase(rejectApprovedSurvey.pending,(state,action) => {
                    state.isRejectApprovedPDFLoading = true;
                    })
            .addCase(rejectApprovedSurvey.fulfilled,(state,{payload})=> {
                    state.isSurveySuccessfullyRejected = payload;
                    state.isRejectApprovedPDFLoading = false;
                    })
            .addCase(rejectApprovedSurvey.rejected,(state,action)=> {
                    state.isRejectApprovedPDFLoading = false;
                    })
            .addCase(approverSignApprovedSurvey.pending,(state,action) => {
                        state.approverSignApprovedSurveyLoading = true;
                    })
            .addCase(approverSignApprovedSurvey.fulfilled,(state,{payload})=> {
                        state.approverSignedApprovedSurvey = payload;
                        state.approverSignApprovedSurveyLoading = false;
                    })
            .addCase(approverSignApprovedSurvey.rejected,(state,action)=> {
                        state.approverSignApprovedSurveyLoading = false;
                    })
            .addCase(getApprovedPDFHash.pending,(state,action) => {
                        state.isApprovedPDFHashLoading = true;
                    })
            .addCase(getApprovedPDFHash.fulfilled,(state,{payload})=> {
                        state.verifySurveyUIObj = payload;
                        state.isApprovedPDFHashLoading = false;
                    })
            .addCase(getApprovedPDFHash.rejected,(state,action)=> {
                        state.isApprovedPDFHashLoading = false;
                    })
            .addCase(getApprovedPDFHashForAdmin.pending,(state,action) => {
                        state.isApprovedPDFHashForAdminLoading = true;
                        state.assignedWorkflowUIObj = [];
                    })
            .addCase(getApprovedPDFHashForAdmin.fulfilled,(state,{payload})=> {
                        state.assignedWorkflowUIObj = payload;
                        state.isApprovedPDFHashForAdminLoading = false;
                    })
            .addCase(getApprovedPDFHashForAdmin.rejected,(state,action)=> {
                        state.isApprovedPDFHashForAdminLoading = false;
                    })
            .addCase(getApprovedSurveys.pending,(state,action) => {
                        state.isGetApprovedSurveysLoading = true;
                        state.approvedSurveys = [];
                    })
            .addCase(getApprovedSurveys.fulfilled,(state,{payload})=> {
                        state.approvedSurveys = payload;
                        state.isGetApprovedSurveysLoading = false;
                    })
            .addCase(getApprovedSurveys.rejected,(state,action)=> {
                        state.isGetApprovedSurveysLoading = false;
                    })
            .addCase(getEthBalance.pending,(state,action) => {
                        state.ethBalanceLoading = true ;
                    })
            .addCase(getEthBalance.fulfilled,(state,{payload})=> {
                        state.userEthBalance = payload;
                        state.ethBalanceLoading = false;
                    })
            .addCase(getEthBalance.rejected,(state,action)=> {
                        state.ethBalanceLoading = false;
                    })
            .addCase(allEWarrentDataForSuperAdmin.pending,(state,action) => {
                state.adminSurveysArrayOfObjLoading = true ;
                state.adminSurveysArrayOfObj = [];
                state.totalSurveysCreatedByAdmin = 0;
            })
            .addCase(allEWarrentDataForSuperAdmin.fulfilled,(state,{payload})=> {
                state.adminSurveysArrayOfObjLoading = false ;
                state.adminSurveysArrayOfObj = payload.formStatusArrayAsAdmin;
                state.totalSurveysCreatedByAdmin = payload.totalSurveysCreated;
            })
            .addCase(allEWarrentDataForSuperAdmin.rejected,(state,action)=> {
                state.adminSurveysArrayOfObjLoading = false ;
            })
            .addCase(getApprovedSurveysForExternalUser.pending,(state,action) => {
                state.isGetApprovedSurveysForExternalUserLoading = true;
                state.approvedSurveysForExternalUser = []
            })
            .addCase(getApprovedSurveysForExternalUser.fulfilled,(state,{payload})=> {
                state.isGetApprovedSurveysForExternalUserLoading = false;
                state.approvedSurveysForExternalUser = payload;
            })
            .addCase(getApprovedSurveysForExternalUser.rejected,(state,action)=> {
                state.isGetApprovedSurveysForExternalUserLoading = false;
            })
            .addCase(transferEWarrentContractOwnership.pending,(state,{payload}) => {
                state.isTransferEWarrentContractOwnershipLoading = true;
                state.isEWarrentOwnershipTransferred = false;
            })
            .addCase(transferEWarrentContractOwnership.fulfilled,(state,{payload})=> {
                state.isTransferEWarrentContractOwnershipLoading = false;
                state.isEWarrentOwnershipTransferred = payload;
            })
            .addCase(transferEWarrentContractOwnership.rejected,(state,{payload})=> {
                state.isTransferEWarrentContractOwnershipLoading = false;
            })
    }
});

export const {setWeb3Auth,setPDFHash,setApproverSelectedFormId,resetSurveySubmissionStatus,resetApprovedSurveyStatuses} = eWarrantSlice.actions;
export default eWarrantSlice.reducer;