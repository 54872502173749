import React, { useEffect } from 'react'
import Spinner from '../../components/Spinner/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { getApprovedSurveysForExternalUser } from '../../redux/actions/eWarrant';
import { useState } from 'react';
import { Badge, Button, Card, Col, Divider, Input, Pagination, Row, Select, Space } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { removeCookie } from '../../utils/cookieUtils';
import Cookies from 'js-cookie';
import { resetTokenUserData } from '../../redux/slices/erc4907ContractSlice';
import MainLayout from '../../components/MainLayout/MainLayout';
const { Option } = Select;

const ExternalUserViewSurveysByAdmin = () => {
  const {approvedSurveysForExternalUser} = useSelector((state)=>state.contract);
  const sessionCookie = Cookies.get('session');
  let { adminId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Track initial loading state

  useEffect(()=> {
    const data = {contractAddress:location.state.contract_address,userAddress:location.state.tokenOwner};
    dispatch(getApprovedSurveysForExternalUser(data)).then(() => {
        setIsLoading(false);
    });
  },[dispatch,location.state.contract_address,location.state.tokenOwner]);

  const surveyDescription = (item) => (
    <div className='flex-center-direction-col'>
      <Row >
        <Col span={10} className='bold-font'>
          Workflow Name
        </Col>
        <Col span={2} className='bold-font'>
        : 
        </Col>
        <Col span={10}>
          {item.surveyName && item.surveyName.length > 15 ? item.surveyName.substring(0, 15) + '...' : item.surveyName}
        </Col>
      </Row>
      <Row>
        <Col span={10} className='bold-font'>
            Workflow Id
          </Col>
          <Col span={2} className='bold-font'>
          : 
          </Col>
          <Col span={10}>
            {item.formId}
          </Col>
      </Row>
    </div>
  );

  const toViewApprovedSurvey = (formId) => {
    navigate(`/view-workflow-externaluser/${adminId}/${formId}`,{state:{contract_address:location.state.contract_address,adminId,userAddress:location.state.userAddress}});
  };

  const getStatusText = (statusEnum) => {
    switch (statusEnum) {
      case '0':
        return "Assigned";
      case '1':
        return "Submitted";
      case '2':
        return "Approved";
      case '3':
        return "Rejected";
      case '4':
        return "Signed";
      default:
        return "";
    }
  };

  const getStatusColor = (statusEnum) => {
    switch (statusEnum) {
      case '0':
        return "orange";
      case '1':
        return "blue";
      case '2':
        return "#800080";
      case '3':
        return "red";
      case '4':
        return "green";
      default:
        return "black";
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(12);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = approvedSurveysForExternalUser?.filter((item) => {
      const surveyName = item.surveyName.toLowerCase();
      const formId = item.formId.toLowerCase();
      return (
        (surveyName.includes(query) || formId.includes(query)) &&
        (filterValue === '' || item.statusEnum === filterValue) // Apply the filter condition
      );
    });
    setFilteredData(filtered);
  };
  
  
  const handleChangePage = (page) => {
    setCurrentPage(page);
    setSearchQuery(''); // Reset search query when changing pages
  };
  
  const handleChangeCardsPerPage = (value) => {
    setCardsPerPage(value);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * cardsPerPage;
  const paginatedData = approvedSurveysForExternalUser?.slice(startIndex, startIndex + cardsPerPage);
  console.log("approved surveys :",approvedSurveysForExternalUser);
  const clearSessionHandler = () => {
    removeCookie('session');
    removeCookie('sessionExpiry');
    dispatch(resetTokenUserData());
    navigate('/dashboard');
  }
  
  useEffect(()=>{
    sessionCookie===undefined && navigate('/dashboard');
  },[navigate,sessionCookie]);

  return (
    
    <MainLayout>
            <Row justify={'space-between'} >
              <h1>View Signed Workflows</h1>
              <Space align='center'>
                <Input
                  size='large'
                  placeholder='Search For Workflows'
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  />
                <Button type='primary' danger size='large' onClick={clearSessionHandler} >Clear Session</Button>
              </Space>
            </Row>

              {searchQuery && filteredData.length === 0 && (
                <h1 className='textalign-center'>No matching workflows found.</h1>
              )}
              {(!searchQuery || filteredData.length > 0) && (
              <>
                <Row gutter={[20,20]} className='cards-container'>
                  {isLoading ? <div className='flex-direction-col width-height-100perc'><Spinner/><h3>Loading...</h3></div> :
                  approvedSurveysForExternalUser.length>0 ?
                  (searchQuery ? filteredData : paginatedData).map((item) => (
                    <Col key={item.formId} sm={6}>
                      <Badge.Ribbon text={getStatusText(item.statusEnum)} color={getStatusColor(item.statusEnum)}>
                        <Card
                          cover={<div className='flex mt-1'><img src="/assets/images/submit-survey-icon.png" alt="Survey" className='workflow-card-icon'/></div>}
                          key={item.formId}
                          className='workflow-card'
                          hoverable
                          onClick={() => toViewApprovedSurvey(item.formId)}
                        >
                          <Divider />
                          {surveyDescription(item)}
                        </Card>
                      </Badge.Ribbon>
                    </Col>
                  ))
                  : <h1 style={{textAlign:'center',width:'100%'}}>No Workflow Signed Yet</h1>
                  }
                </Row>
                <Row justify='space-between' align='middle' style={{ marginBottom: '0.5rem' }}>
                <Col>
                  <Select value={cardsPerPage} onChange={handleChangeCardsPerPage}>
                    <Option value={12}>12 per page</Option>
                    <Option value={20}>20 per page</Option>
                    <Option value={40}>40 per page</Option>
                    <Option value={100}>100 per page</Option>
                  </Select>
                </Col>
                <Col>
                  <Pagination
                            current={currentPage}
                            onChange={handleChangePage}
                            pageSize={cardsPerPage}
                            total={searchQuery ? filteredData.length : approvedSurveysForExternalUser.length}
                  />
                </Col>
                </Row>
              </>
              )}
    </MainLayout>
  )
}

export default ExternalUserViewSurveysByAdmin;