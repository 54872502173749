import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getUserTokenAccessSignatureHistory } from '../../redux/actions/template';
import { dateFormatter, unixEpochToIST } from '../../utils/dateFormatter';
import { Col, Pagination, Row, Select, Space, Spin, Table } from 'antd';
import { setInternalUserAccessHistoryCurrentPage, setInternalUserAccessHistoryElePerPage } from '../../redux/slices/userSlice';
const {Option} = Select;

const UserTokenAccessHistoryComponent = () => {
  const user = localStorage.getItem('user');
  const {userTokenAccessSignatureHistoryArrayLoading,userTokenAccessSignatureHistoryArray,userTokenAccessSignatureHistoryCount} = useSelector(state => state.template);
  const { internalUserAccessHistoryCurrentPage,internalUserAccessHistoryElePerPage,internalUserAccessHistoryEleOrderBy } = useSelector((state)=>state.user);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getUserTokenAccessSignatureHistory({user,page:internalUserAccessHistoryCurrentPage,limit:internalUserAccessHistoryElePerPage,orderBy:internalUserAccessHistoryEleOrderBy}));
  },[dispatch,user,internalUserAccessHistoryCurrentPage,internalUserAccessHistoryElePerPage,internalUserAccessHistoryEleOrderBy]);
  const handleChangePage = (page) => {
    dispatch(setInternalUserAccessHistoryCurrentPage(page));
    dispatch(getUserTokenAccessSignatureHistory({user,page:page,limit:internalUserAccessHistoryElePerPage,orderBy:internalUserAccessHistoryEleOrderBy}));
  };

  const handleChangeElePerPage = (value) => {
    dispatch(setInternalUserAccessHistoryElePerPage(value));
    dispatch(setInternalUserAccessHistoryCurrentPage(1));
    dispatch(getUserTokenAccessSignatureHistory({user,page:1,limit:value,orderBy:internalUserAccessHistoryEleOrderBy}));
  };
  const columns = [
    {
        title: 'Token Id',
        dataIndex: 'tokenId',
        key: 'tokenId',
        width:'200px',
        render: (_, item) => {
          return item.objSigned.tokenId;
        },    
    },
    {
      title: 'Accessed At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width:'200px',
      render: (_, item) => {
        return dateFormatter(item.createdAt && item.createdAt);
      },    
    },
    {
      title: 'Token Expiry',
      dataIndex: 'userExpires',
      key: 'userExpires',
      width:'200px',
      render: (_, item) => {
        return unixEpochToIST(item.objSigned.userExpiry && item.objSigned.userExpiry);
      }
    }
  ];
  
  return (
    <div>
      {
        userTokenAccessSignatureHistoryArrayLoading ? <div className='flex height-400'><Spin/></div>:
        <>
          <Table pagination={false} columns={columns} dataSource={userTokenAccessSignatureHistoryArray && userTokenAccessSignatureHistoryArray} rowKey={(record) => record.transactionHash} className='externaluser-tokenhistorytable'/>
          <Row justify='space-between' align='middle' className="mt-1">
            <Col>
              <Space>
                <Select value={internalUserAccessHistoryElePerPage} onChange={handleChangeElePerPage}>
                  <Option value={10}>10 per page</Option>
                  <Option value={20}>20 per page</Option>
                  <Option value={40}>40 per page</Option>
                  <Option value={100}>100 per page</Option>
                </Select>
              </Space>
            </Col>
            <Col>
              <Pagination
                showSizeChanger={false}
                hideOnSinglePage={true}
                current={internalUserAccessHistoryCurrentPage}
                onChange={handleChangePage}
                pageSize={internalUserAccessHistoryElePerPage}
                total={userTokenAccessSignatureHistoryCount} 
              />
            </Col>
          </Row>
        </>
      }
    </div>
  )

}

export default UserTokenAccessHistoryComponent;