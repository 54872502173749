import React , {useState} from 'react'
import {Row,Col,Button,Modal} from "antd";
import AdminRevokeRoleForm from '../../components/AdminRevokeRoleForm/AdminRevokeRoleForm';
import {UsergroupDeleteOutlined} from "@ant-design/icons";
import MainLayout from "../../components/MainLayout/MainLayout";
import "../AdminDashboard/AdminDashboard.scss";

const AdminManageRolesPage = () => {

    const [open2, setOpen2] = useState(false);
    const [confirmLoading2, setConfirmLoading2] = useState(false);
    const showModal2 = () => {
      setOpen2(true);
    };
    const handleOk2 = () => {
      setConfirmLoading2(true);
      setTimeout(() => {
        setOpen2(false);
        setConfirmLoading2(false);
      }, 2000);
    };
    const handleCancel2 = () => {
      console.log('Clicked cancel button');
      setOpen2(false);
    };

  
  return (
    <MainLayout>
            <Row>
              <Col className="admin-dashboard-card manage-role-card" >
                            <>
                              <h1>Revoke Roles <span><UsergroupDeleteOutlined /></span></h1>
                              <p className='font-18px'>Admin can revoke role of a user whenever he wants to . After the role is revoked , the workflowId is removed from user's list of workflows that the workflowId belongs to and he loses access to that particular workflow .</p>
                              <div className='flex-end-center mb-2'>
                              <Button className='admin-dashboard-manage-role-btn' size='large' onClick={showModal2} >Revoke Roles</Button>
                              </div>
                              <Modal
                                    title="Revoke Role"
                                    open={open2}
                                    onOk={handleOk2}
                                    confirmLoading={confirmLoading2}
                                    onCancel={handleCancel2}
                                    maskClosable={false}
                                    footer={false}
                                >
                                    <AdminRevokeRoleForm setOpen2={setOpen2}/>
                              </Modal>
                            </>
              </Col>
            </Row>
    </MainLayout>
  )
}

export default AdminManageRolesPage;