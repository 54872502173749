import { Button, Form, Input } from 'antd';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { inviteAdmin } from '../../redux/actions/template';
import "../../styles/main.scss";

const InviteAdminComponent = ({form}) => {
    const dispatch = useDispatch();
    const {isInviteAdminSuccess,isInviteAdminFailed} = useSelector(state=>state.template);
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!',
        types: {
        email: '${label} is not a valid email!',
        },
    };
    const onFinish = (values) => {
        console.log("logged value :",values);
        dispatch(inviteAdmin(values));
    };
    const layout = {
        labelCol: {
          span: 24,
        },
        wrapperCol: {
          span: 24,
        },
    };  

    
    useEffect(()=>{
        isInviteAdminSuccess && form.resetFields();
    },[isInviteAdminSuccess,form]);

    // useEffect(()=>{
    //     isInviteAdminFailed && form.resetFields();
    // },[isInviteAdminFailed,form]);

    const getValidateStatus = () => {
        if (isInviteAdminFailed) {
          return 'error';
        }
        return '';
      };
    
    // const getHelpMessage = () => {
    //     if (isInviteAdminFailed) {
    //       return 'Error in API call. Please try again.';
    //     }
    //     return '';
    // };
    
    return (
            <Form
                form={form}
                {...layout}
                name="nest-messages"
                onFinish={onFinish}
                className='invitation-form'
                validateMessages={validateMessages}
                labelAlign='left'
                colon={false}
            >
                <Form.Item
                name={'email'}
                required={true}
                label="Email"
                rules={[
                    {
                    type: 'email',
                    },
                ]}
                validateStatus={getValidateStatus()}
                // help={getHelpMessage()}
                >
                <Input type='email' placeholder="Enter email to invite as admin" required />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
    )
}

export default InviteAdminComponent;