import React from 'react'
import { Button, Col, Modal, Row, Space, Spin, Table , Form} from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getERC4907ContractUserData, mintNFTERC4907 } from '../../redux/actions/erc4907Contract'
import { HistoryOutlined, PlusOutlined } from '@ant-design/icons'
import { useState } from 'react'
import InviteExternalUserComponent from '../../components/InviteExternalUserComponent/InviteExternalUserComponent'
import SetTokenUserComponent from '../../components/SetTokenUserComponent/SetTokenUserComponent'
import { allExternalUsers } from '../../redux/actions/template'
import UserTokenHistoryComponent from '../../components/UserTokenHistoryComponent/UserTokenHistoryComponent'
import UserTokenAccessHistoryComponent from '../../components/UserTokenAccessHistoryComponent/UserTokenAccessHistoryComponent'
import { resetInviteExternalUserStates } from '../../redux/slices/templateSlice'
import MainLayout from '../../components/MainLayout/MainLayout'

const ManageAccess = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const {isERC4907ContractUserDataLoading,tokenIdsToRespectiveUsers,isMintERC4907Loading,isERC4907NFTMinted,isERC4907UserSetSuccess} = useSelector((state)=>state.erc4907Contract);
    const {filteredExternalUsers,isAllExternalUsersLoading,isInviteExternalUserLoading,isInviteExternalUserSuccess,isInviteExternalUserFailed} = useSelector(state => state.template);
    useEffect(()=>{
      dispatch(getERC4907ContractUserData())
    },[dispatch]);
    useEffect(()=>{
		  dispatch(allExternalUsers());
    },[dispatch]);
    const calculateContainerHeight = () => {
        if (window.innerWidth > 1400) {
          return 420;
        } else if (window.innerWidth > 980) {
          return 360;
        } else if (window.innerWidth > 680) {
          return 350;
        } else {
          return 320;
        }
    };
    
    const [containerHeight, setContainerHeight] = useState(calculateContainerHeight());
    
    useEffect(() => {
        const handleResize = () => {
          setContainerHeight(calculateContainerHeight());
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [isModal2Open, setIsModal2Open] = useState(false);
    const [tokenId,setTokenId]=useState(null);
    const showModal2 = (item) => {
      setIsModal2Open(true);
      setTokenId(item.tokenId);
    };
    const handleCancel2= () => {
      setIsModal2Open(false);
    };

    const columns = [
            {
                title: 'Token Id',
                dataIndex: 'tokenId',
                key: 'tokenId'
            },
            {
                title: 'Token User',
                dataIndex: 'tokenUser',
                key: 'tokenUser'
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (_,item) => (
                  <>
                  <Button
                    key={item.tokenId}
                    onClick={() => showModal2(item)}
                  >
                    Set User
                  </Button>
                  </>
                )
            }
    ];
    
    const externalUsersColumns = [
      {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          width:'40%'
      },
      {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
          width:'60%',
          render: (_, item) => {
            if (item.address) {
                return item.address;
            } else if (!item.address) {
              return '-';
            } 
          }
          }
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
  
    const mintERC4907NFTHandler = () => {
        dispatch(mintNFTERC4907());
    }

    useEffect(()=>{
        isERC4907NFTMinted===true && dispatch(getERC4907ContractUserData());
        isERC4907NFTMinted===true && handleCancel();
    },[dispatch,isERC4907NFTMinted]);

    useEffect(()=>{
      isInviteExternalUserSuccess===true && dispatch(allExternalUsers());
      isInviteExternalUserSuccess===true && handleCancel1();
    },[dispatch,isInviteExternalUserSuccess])
    
    const [isModal1Open, setIsModal1Open] = useState(false);
    const showModal1 = () => {
      setIsModal1Open(true);
    };
    const handleCancel1 = () => {
      setIsModal1Open(false);
      form.resetFields()
      dispatch(resetInviteExternalUserStates());
    };

    const paginationSettings = {
      pageSize: 5
    };
  
    useEffect(()=>{
      isERC4907UserSetSuccess===true && dispatch(getERC4907ContractUserData());
      isERC4907UserSetSuccess===true && handleCancel2()
    },[isERC4907UserSetSuccess,dispatch]);

    const [isModal3Open, setIsModal3Open] = useState(false);
    const showModal3 = () => {
      setIsModal3Open(true);
    };
    const handleCancel3 = () => {
      setIsModal3Open(false);
    };
    const [selectedTable, setSelectedTable] = useState('table1'); // Default selection
    const handleTableSelect = (tableName) => {
      setSelectedTable(tableName);
    };

    return (
        <MainLayout>
                    <div>
                        <div className='flex-between-center'>
                            <h1>Manage External User Access</h1>
                            <Space>
                              <Button type='primary' size='large' icon={<PlusOutlined/>} onClick={showModal1}> Invite External User</Button>
                              <Button type='primary' size='large' icon={<PlusOutlined/>} onClick={showModal}> Mint Access Token</Button>
                              <Button type='primary' size='large' icon={<HistoryOutlined />} onClick={showModal3}> History</Button>
                            </Space>
                        </div>
                        {isERC4907ContractUserDataLoading || isAllExternalUsersLoading ? (
                        <div
                          className='flex-direction-col width-height-100perc'
                        >
                            <Spin />
                            <h3>Loading...</h3>
                        </div>
                        ) : (
                        <Row gutter={[12,12]}>
                          <Col sm={24} lg={24} xl={12}>
                            <Table caption={'Tokens And Respective Users'} columns={columns} dataSource={tokenIdsToRespectiveUsers} rowKey={(record) => record.tokenId} pagination={paginationSettings} />
                          </Col>
                          <Col sm={24} lg={24} xl={12}>
                            <Table caption={'External Users'} columns={externalUsersColumns} dataSource={filteredExternalUsers} rowKey={(record) => record.email} pagination={paginationSettings} />  
                          </Col>
                        </Row>
                        )
                        }
                        <Modal title="Mint Access Token" open={isModalOpen} onCancel={handleCancel} footer={false} maskClosable={false}>
                            <p>By Clicking 'Confirm Mint' , a new NFT will be minted to your address which you can use for granting access to an external user . </p>
                            <Button type='primary' loading={isMintERC4907Loading} onClick={mintERC4907NFTHandler}>Confirm Mint</Button>
                        </Modal>
                        <Modal title="Invite External Users to xVault" open={isModal1Open} onCancel={handleCancel1} footer={false} maskClosable={false}>
                          <InviteExternalUserComponent form={form}/>
                        </Modal>
                        <Modal title={`Set User for Token ${tokenId}`} open={isModal2Open} onCancel={handleCancel2} footer={false} maskClosable={false}>
                          <SetTokenUserComponent tokenId={tokenId}/>
                        </Modal>
                        <Modal width={'90%'} title={`External User History`} open={isModal3Open} onCancel={handleCancel3} footer={false} maskClosable={false}>
                          <Space>
                            <Button
                              type={selectedTable === 'table1' ? 'primary' : 'default'}
                              onClick={() => handleTableSelect('table1')}
                            >
                              Token History
                            </Button>
                            <Button
                              type={selectedTable === 'table2' ? 'primary' : 'default'}
                              onClick={() => handleTableSelect('table2')}
                            >
                              Access History
                            </Button>
                          </Space>
                        {selectedTable === 'table1' ? (
                          <UserTokenHistoryComponent/>
                        ): <UserTokenAccessHistoryComponent />}
                        </Modal>
                    </div>
        </MainLayout>
    )
}

export default ManageAccess;