import { Button, Form, Input,Select,Row,Col,Radio,Checkbox, Divider } from 'antd';
import myObj from './templateInputTypes';
import { PlusOutlined , DeleteOutlined}  from '@ant-design/icons';
import { useSelector,useDispatch } from 'react-redux';
import "./AdminCustomForm.scss";
import { createTemplate,updateTemplate } from '../../redux/actions/template';
import { useEffect } from 'react';
const {Option} = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 16, offset: 8 },
    sm: { span: 16, offset: 8 },
  },
};
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */



const AdminCustomForm = ({form,type,disableForm}) => {
  
  const dispatch = useDispatch();
  const {templateData,isSuccess,isCreateTemplateLoading,isUpdateTemplateLoading} = useSelector((state)=>state.template);
  const {isAttestingWorkflowTemplate} = useSelector((state)=>state.attestations);
  const latestWorkflowVersionObj = type==="edit" ?templateData?.templatesVersion[templateData.templatesVersion?.length-1]:{};
  console.log("latestWorkflowVersionObj ",latestWorkflowVersionObj);
  const latestWorkflowVersionData = {_id:templateData._id,...latestWorkflowVersionObj};
  console.log("latestWorkflowVersionData :",latestWorkflowVersionData);
  console.log("templateData :",templateData);
  console.log("form :",form);
  console.log("myObj :",myObj.templateFeildTypes);
  const {current,dashboardCurrentPage,dashboardCardsPerPage,arrayOrderBy,arraySortBy} = useSelector(state => state.admin);

  const onFinish = async (values) => {
    console.log("clicked submit on edit workflow");
    console.log("logged :",latestWorkflowVersionData)
    const id = templateData?._id; // _id of workflow template
    console.log("values object to be sent to backend :",values);
    type === "create" && dispatch(createTemplate({...values,current,sortBy:arraySortBy,orderBy:arrayOrderBy,page:dashboardCurrentPage,limit:dashboardCardsPerPage}));
    const currentLatestVersion = type === "edit" ?  latestWorkflowVersionData.versions : 0;
    const referencedAttestation = type === "edit" ?  latestWorkflowVersionData.attestation.attestationUId : "";
    type === "edit" && dispatch(updateTemplate({...values,id,currentLatestVersion,referencedAttestation,sortBy:arraySortBy,orderBy:arrayOrderBy,page:dashboardCurrentPage,limit:dashboardCardsPerPage}));
  };
  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess, form]);

  return (
    <div>
      <Divider></Divider>
      <Form
        {...layout}
        form={form}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
        disabled={disableForm ? disableForm : false }
        labelAlign='left'
        // initialValues={templateData}
      >
        <div className='form-static-content parent_grouping'>

          <div className="extra_chile_group_text_font footer_less">
                  Basic Details&nbsp;
          </div>
          <div className="extra_no_child">
            <Row>
              <Col span={18}>
                <Form.Item
                  name={'name'}
                  initialValue={type==="edit" ? latestWorkflowVersionData?.name : ""}
                  label="Workflow Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={'description'}
                  initialValue={type==="edit" ? latestWorkflowVersionData?.description : ""}
                  label="Workflow Description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>

        <div className='form-dynamic-content parent_grouping'>

          <div className="extra_chile_group_text_font footer_less">
            Fields Details&nbsp;
          </div>
          <div className="extra_no_child">
            <Form.List name="fields" initialValue={type==="edit" ? latestWorkflowVersionData?.fields ? latestWorkflowVersionData?.fields : [""]:[""]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key}>
                      <Row>
                        <Col span={18}>
                          <Form.Item
                            {...restField}
                            name={[name, 'label']}
                            label="Enter field name :"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={4}>
                          <Form.Item>                      
                            {
                              fields.length>1 ?
                              <Button onClick={()=>remove(name)} icon={<DeleteOutlined style={{color:"red"}}/>}/>:""
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                      
                        <Row>
                          <Col span={6}></Col>
                          <Col span={4}> 
                            <Form.Item {...restField} name={[name,'required']} valuePropName='checked'>
                              <Checkbox >required</Checkbox>
                            </Form.Item>
                          </Col>

                          <Col span={4}> 
                            <Form.Item {...restField} name={[name,'infoRequired']} valuePropName='checked'>
                                <Checkbox onChange={(value)=>{
                                  form.setFieldsValue({
                                    [`${name}.selectedOption1`]:value.target.checked
                                  })
                                }}>info</Checkbox>
                            </Form.Item>
                          </Col>

                          <Col span={4}> 
                            <Form.Item {...restField} name={[name,'placeholderRequired']} valuePropName='checked'>
                              <Checkbox onChange={(value)=>{
                                  form.setFieldsValue({
                                    [`${name}.selectedOption2`]:value.target.checked
                                  })
                                }}>placeholder</Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row> 
                          <Col span={18}>
                            {
                              (form.getFieldValue(`${name}.selectedOption1`) === true || form.getFieldValue([
                                "fields",
                                name,
                                "infoRequired",
                              ]) === true) && (
                                <Form.Item {...restField} name={[name,'info']} label='Input Info'>
                                  <Input />
                                </Form.Item>
                              )
                            }
                          </Col>
                          <Col span={18}>
                            {
                              (form.getFieldValue(`${name}.selectedOption2`) === true || form.getFieldValue([
                                "fields",
                                name,
                                "placeholderRequired",
                              ]) === true ) && (
                                <Form.Item {...restField} name={[name,'placeholder']} label='Input Placeholder'>
                                  <Input />
                                </Form.Item>
                              )
                            }
                          </Col>
                          <Col span={18}>
                                <Form.Item
                                  {...restField}
                                  name={[name, `dataType`]}
                                  label={ "Type of value"}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder={"Value Type"}
                                    onChange={(value) => {
                                      form.setFieldsValue({
                                        [`${name}.selectedOption3`]: value,
                                      });
                                    }}
                                  >
                                    {myObj.templateFeildTypes.map((rt) => (
                                      <Option key={rt.key} value={rt.key}>{rt.value}</Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                          </Col>

                        </Row>
                                    
                        {((form.getFieldValue(`${name}.selectedOption3`) ===
                            3) ||
                              form.getFieldValue([
                                "fields",
                                name,
                                "dataType",
                              ]) === 3)&& (
                            <Row>
                              <Col span={18}>
                                {/* <Form.Item {...restField}> */}
                                  <Form.List
                                    initialValue={[""]}
                                    name={[name, "options"]}
                                  >
                                    {(
                                      subFields,
                                      { add: subAdd, remove: subRemove }
                                    ) => (
                                      <>
                                        {subFields.map((fiel, index) => (
                                          <Form.Item
                                            {...(index === 0
                                              ? layout
                                              : formItemLayoutWithOutLabel)}
                                            label={
                                              index === 0
                                                ?"options"
                                                : ""
                                            }
                                            required={false}
                                            key={fiel.key}
                                          >
                                            <Form.Item
                                              {...fiel}
                                              validateTrigger={[
                                                "onChange",
                                                "onBlur",
                                              ]}
                                              rules={[
                                                {
                                                  required: true,
                                                  whitespace: true,
                                                  message: "option_rule",
                                                },
                                              ]}
                                              noStyle
                                            >
                                              <Input
                                                placeholder={"option_name"}
                                                style={{ width: "80%" }}
                                              />
                                            </Form.Item>

                                            {subFields.length > 1 ? (
                                              <Button
                                                style={{
                                                  marginLeft: "8px",
                                                  marginBottom: "15px",
                                                  color: "blue",
                                                  backgroundColor: "red",
                                                }}
                                                icon={
                                                  <DeleteOutlined
                                                    className="dynamic-delete-button"
                                                    style={{
                                                      margin: "0 2px",
                                                      color: "#fff",
                                                    }}
                                                  />
                                                }
                                                onClick={() => {
                                                  subRemove(fiel.name);
                                                }}
                                              ></Button>
                                            ) : null}

                                            {index === subFields.length - 1 && (
                                              <Button
                                                type="dashed"
                                                onClick={() => {
                                                  subAdd();
                                                }}
                                                icon={
                                                  <PlusOutlined
                                                    style={{ color: "blue" }}
                                                  />
                                                }
                                                style={{
                                                  marginLeft: "10px",
                                                  backgroundColor: "whitesmoke",
                                                }}
                                              ></Button>
                                            )}
                                          </Form.Item>
                                        ))}
                                      </>
                                    )}
                                  </Form.List>
                                {/* </Form.Item> */}
                              </Col>
                            </Row>
                          )}
                          {(form.getFieldValue(`${name}.selectedOption3`) === 5 ||
                              form.getFieldValue([
                                "fields",
                                name,
                                "dataType",
                              ]) === 5) && (
                            <Row>
                              <Col span={18}>
                                {/* <Form.Item {...restField}> */}
                                  <Form.List
                                    initialValue={[""]}
                                    name={[name, "options"]}
                                  >
                                    {(
                                      subFields,
                                      { add: subAdd, remove: subRemove }
                                    ) => (
                                      <>
                                        {subFields.map((fiel, index) => (
                                          <Form.Item
                                            {...(index === 0
                                              ? layout
                                              : formItemLayoutWithOutLabel)}
                                            label={
                                              index === 0
                                                ?  "options" 
                                                : ""
                                            }
                                            required={false}
                                            key={fiel.key}
                                          >
                                            <Form.Item
                                              {...fiel}
                                              validateTrigger={[
                                                "onChange",
                                                "onBlur",
                                              ]}
                                              rules={[
                                                {
                                                  required: true,
                                                  whitespace: true,
                                                  message:  "option_rule",
                                                },
                                              ]}
                                              noStyle
                                            >
                                              <Input
                                                placeholder={"option_name"}
                                                style={{ width: "80%" }}
                                              />
                                            </Form.Item>

                                            {subFields.length > 1 ? (
                                              <Button
                                                style={{
                                                  marginLeft: "8px",
                                                  marginBottom: "15px",
                                                  color: "blue",
                                                  backgroundColor: "red",
                                                }}
                                                icon={
                                                  <DeleteOutlined
                                                    className="dynamic-delete-button"
                                                    style={{
                                                      margin: "0 2px",
                                                      color: "#fff",
                                                    }}
                                                  />
                                                }
                                                onClick={() => {
                                                  subRemove(fiel.name);
                                                }}
                                              ></Button>
                                            ) : null}

                                            {index === subFields.length - 1 && (
                                              <Button
                                                type="dashed"
                                                onClick={() => {
                                                  subAdd();
                                                }}
                                                icon={
                                                  <PlusOutlined
                                                    style={{ color: "blue" }}
                                                  />
                                                }
                                                style={{
                                                  marginLeft: "10px",
                                                  backgroundColor: "whitesmoke",
                                                }}
                                              ></Button>
                                            )}
                                          </Form.Item>
                                        ))}
                                      </>
                                    )}
                                  </Form.List>
                                {/* </Form.Item> */}
                              </Col>
                            </Row>
                          )}
                          {(form.getFieldValue(`${name}.selectedOption3`) === 6 ||
                              form.getFieldValue([
                                "fields",
                                name,
                                "dataType",
                              ]) === 6) && (
                            <>
                              <Row>
                                <Col span={18}>
                                <Form.Item
                                  name={[name, `typeOfOptions`]}
                                  label={ "select type"}
                                  key={key}
                                >
                                  <Radio.Group>
                                    <Radio value="Single">
                                      {"single" }
                                    </Radio>
                                    <Radio value="Multiple">
                                      {"multiple"}
                                    </Radio>
                                  </Radio.Group>
                                </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                              <Col span={18}>
                                {/* <Form.Item {...restField}> */}
                                  <Form.List
                                    initialValue={[""]}
                                    name={[name, "options"]}
                                  >
                                    {(
                                      subFields,
                                      { add: subAdd, remove: subRemove }
                                    ) => (
                                      <>
                                        {subFields.map((fiel, index) => (
                                          <Form.Item
                                            {...(index === 0
                                              ? layout
                                              : formItemLayoutWithOutLabel)}
                                            label={
                                              index === 0
                                                ?  "options" 
                                                : ""
                                            }
                                            required={false}
                                            key={fiel.key}
                                          >
                                            <Form.Item
                                              {...fiel}
                                              validateTrigger={[
                                                "onChange",
                                                "onBlur",
                                              ]}
                                              rules={[
                                                {
                                                  required: true,
                                                  whitespace: true,
                                                  message: "options_rule",
                                                },
                                              ]}
                                              noStyle
                                            >
                                              <Input
                                                placeholder={"option_name"}
                                                style={{ width: "80%" }}
                                              />
                                            </Form.Item>

                                            {subFields.length > 1 ? (
                                              <Button
                                                style={{
                                                  marginLeft: "8px",
                                                  marginBottom: "15px",
                                                  color: "blue",
                                                  backgroundColor: "red",
                                                }}
                                                icon={
                                                  <DeleteOutlined
                                                    className="dynamic-delete-button"
                                                    style={{
                                                      margin: "0 2px",
                                                      color: "#fff",
                                                    }}
                                                  />
                                                }
                                                onClick={() => {
                                                  subRemove(fiel.name);
                                                }}
                                              ></Button>
                                            ) : null}

                                            {index === subFields.length - 1 && (
                                              <Button
                                                type="dashed"
                                                onClick={() => {
                                                  subAdd();
                                                }}
                                                icon={
                                                  <PlusOutlined
                                                    style={{ color: "blue" }}
                                                  />
                                                }
                                                style={{
                                                  marginLeft: "10px",
                                                  backgroundColor: "whitesmoke",
                                                }}
                                              ></Button>
                                            )}
                                          </Form.Item>
                                        ))}
                                      </>
                                    )}
                                  </Form.List>
                                {/* </Form.Item> */}
                              </Col>
                              </Row>
                            </>
                          )}
                    
                    </div>
                  ))}
                  <Divider></Divider>
                  <div className='add-field-btn-container'>
                    <Form.Item>
                      <Button className='add-field-btn' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add field
                      </Button>
                    </Form.Item>
                  </div>
                </>
              )}
            </Form.List>
          </div>
        </div>
        <div className='submit-btn-create-survey'>
        <Form.Item
          // wrapperCol={{
          //   ...layout.wrapperCol,
          //   offset: 8,
          // }}
        >
          <Button type="primary" htmlType="submit" loading={isCreateTemplateLoading || isUpdateTemplateLoading}>
            Submit
          </Button>
        </Form.Item>
        </div>
      </Form>
    </div>)
};
export default AdminCustomForm;