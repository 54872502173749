const myObj = {
    templateFeildTypes: [
      { key: 0, value: "Text (Abc...)" }, // sahi
      { key: 1, value: "Textarea" }, // sahi lagra
      // { key: 2, value: "heading" }, // heading feature removed
      { key: 3, value: "Radio" }, // sahi
      // { key: 4, value: "Upload" }, // sahi (upload ka endpoint banao)
      { key: 5, value: "Checkbox" }, // sahi
      { key: 6, value: "Dropdown" }, // drawer side se toh sahi hai , create karte time kuch issue hai 
      { key: 7, value: "Datepicker" }, // sahi
      { key: 8, value: "Email" }, // sahi
      { key: 9, value: "Phone Number" }, // sahi
      // { key: 10, value: "Text Editor" }, // sahi kardiya
      // { key: 11, value: "Signature" }, // sahi , clear signature functionality to be added 
    ],
};
  
export default myObj;