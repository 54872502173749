import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router';
import { useSelector , useDispatch} from "react-redux";
import { getApprovedPDFHashForAdmin} from "../../redux/actions/eWarrant";
import { Button } from 'antd';
import AuthenticatePDF from '../../components/AuthenticatePDF/AuthenticatePDF';
import { DownloadOutlined } from '@ant-design/icons';
import { dateTimeFormatter } from '../../utils/dateFormatter';
import CustomSkeleton from '../../components/CustomSkeleton/CustomSkeleton';
import MainLayout from '../../components/MainLayout/MainLayout';

const AssignedWorkflowPage = () => {

  let { formId } = useParams();
  const dispatch = useDispatch();
  const {assignedWorkflowUIObj} = useSelector((state)=>state.contract);
  const [isLoading, setIsLoading] = useState(true); // Track initial loading state
  console.log("assignedWorkflowUIObj :",assignedWorkflowUIObj);
  useEffect(()=>{
    const eWarrentData = {formId};
    dispatch(getApprovedPDFHashForAdmin(eWarrentData)).then(() => {
      setIsLoading(false); // Set loading state to false after data is fetched
    });
  },[dispatch,formId])
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = assignedWorkflowUIObj.approvedPDFUrl;
    link.target = '_blank';
    link.download = 'approved_survey.pdf';
    link.click();
  };

  return (
    <>  
      {
        <MainLayout>
              <div className='flex-between-center'>
                <h1>Workflow Details</h1>
                {assignedWorkflowUIObj.formStatus===4 && <Button icon={<DownloadOutlined />}  type="primary" size='large' className='green-black-btn' onClick={handleDownload}>Download PDF</Button>}
              </div>
              { isLoading ? <CustomSkeleton /> :
              <>
              <div className="workflow-info">
                <div className="workflow-info-row">
                  <div className='workflow-info-key'>Title</div>
                  <div className='workflow-info-value'>{assignedWorkflowUIObj.surveyName}</div>
                </div>
                <div className="workflow-info-row">
                  <div className='workflow-info-key'>Description</div>
                  <div className='workflow-info-value'>{assignedWorkflowUIObj.surveyDescription}</div>
                </div>
                <div className="workflow-info-row">
                  <div className='workflow-info-key'>Workflow Id</div>
                  <div className='workflow-info-value'>{formId}</div>
                </div>
                <div className="workflow-info-row">
                <div className='workflow-info-key'>Workflow Status</div>
                <div className='workflow-info-value'>{assignedWorkflowUIObj.statusString}</div>
              </div>
              <div className="workflow-info-row">
                <div className='workflow-info-key'>Dataset Provider</div>
                <div className='workflow-info-value'>{assignedWorkflowUIObj.submitter}</div>
              </div>
              {assignedWorkflowUIObj.formStatus!==4 && 
              <div className="workflow-info-row">
                <div className='workflow-info-key'>Dataset Approver(s)</div>
                <div className='workflow-info-value'>
                  <div className='flex-direction-col-only'>
                    {
                        assignedWorkflowUIObj.approvers?.map((approver)=>(
                        <div key={approver}>{approver}</div>
                      ))
                    }
                  </div>
                </div>
              </div>
              }
              {assignedWorkflowUIObj.formStatus===4 && 
              <div className="workflow-info-row">
                  <div className='workflow-info-key'>Approved By</div>
                  <div className='workflow-info-value'>
                    <div className='flex-direction-col-only'>
                      {
                        assignedWorkflowUIObj.approverArray?.map((approver)=>(
                          <div>{approver.approver_address} at {dateTimeFormatter(approver.createdAt)}</div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              }
              </div>

              {assignedWorkflowUIObj.formStatus===4 && <div>
                <AuthenticatePDF storedHash={assignedWorkflowUIObj.approvedPDFHash} />
              </div>}
              </>
              }                     
        </MainLayout>
      }
    
    </>
  )
}

export default AssignedWorkflowPage;