import React, { useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Col, Row, Timeline } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllUsersForTimeline } from '../../redux/actions/template';
import Spinner from '../Spinner/Spinner';
import SignatureModal from './SignatureModal';
import { LinkOutlined } from '@ant-design/icons';
import {blockExplorers} from '../../utils/Web3Auth';
import {formatTimestamp} from '../../utils/dateFormatter';

const SurveyTimeline = () => {

  const {timelineData} = useSelector(state => state.template);
  const {submittedSurveyUIData} = useSelector((state)=>state.template);
  const ApproverSignatures = submittedSurveyUIData?.ApprovalSignature;
  console.log("timelineData :",timelineData);
  const events = timelineData.events;

  const renderSignatureImage = (approverAddress) => {
    const approverSigArr = ApproverSignatures.filter((appr)=>appr.approver_address === approverAddress); // correct approver signature url
    console.log("approver signature filtered :",approverSig);
    setOpenSigModal(true);
    setApproverSig(approverSigArr[0].link);
  }
  const [openSigModal,setOpenSigModal] = useState(false);
  const [approverSig,setApproverSig] = useState('');

  const {onboardedUsers,surveyTimelineLoading} = useSelector(state => state.template);
  console.log("allOnboardedUsers :",onboardedUsers); // this is array of invited user objects , each object contains information about the invited user

  function findUserByAddress(address) {
    return onboardedUsers?.find(user => user.address === address);
  }

  // Function to update the timeline items with corresponding email values
  function updateTimelineWithEmails(address) {
    const user = findUserByAddress(address);
    if (user) {
        return user.email
    } else {
        return address; // if no user found with address , return the address as it is 
    }
  }
  
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getAllUsersForTimeline());
  },[dispatch]);

  return(
    <>
    {
      surveyTimelineLoading ? 
      <div className='flex height-250'><Spinner/></div> :
      <Timeline>
      
      {events?.map((event, index) => {
        const eventName = Object.keys(event)[0];
        const eventData = event[eventName];
        
        console.log("eventData :",eventData);
        console.log("eventName :",eventName);
        switch(eventName) {
          case "SetFormData" : return (
            <Timeline.Item key={index}>
            <h3>Workflow Assigned &nbsp; <a target='_blank' rel="noreferrer" href={`${blockExplorers[1]}/tx/${eventData.transactionHash}`} ><LinkOutlined /></a></h3>
            <Row>
            <Col span={6}>Dataset Provider</Col> <Col span={2}>:</Col> <Col span={16}>{updateTimelineWithEmails(eventData.returnValues.submitter)}</Col>
            </Row>
            <br/>
            <Row>
            <Col span={6}>Dataset Approver(s)</Col><Col span={2}>:</Col><Col span={16}>
              {eventData.returnValues.approvers.map((appr, index) => (
                <span key={index}>
                  {updateTimelineWithEmails(appr)}
                  {index !== eventData.returnValues.approvers.length - 1 && <br />}
                </span>
              ))}
              </Col>
            </Row>
            <br/>
            <Row><Col span={6}>Date </Col> <Col span={2}>:</Col> <Col span={16}>{formatTimestamp(eventData.returnValues.timestamp)}</Col></Row>
            </Timeline.Item>
          )
          case "PDFSubmitted" : return (
            <Timeline.Item key={index}>
            <h3>Workflow Submitted &nbsp; <a target='_blank' rel="noreferrer" href={`${blockExplorers[1]}/tx/${eventData.transactionHash}`}  ><LinkOutlined /></a></h3>
            <Row>
              <Col span={6}>Submitted By</Col><Col span={2}> :</Col><Col span={16}> {updateTimelineWithEmails(eventData.returnValues.submitter)}</Col>
            </Row>
            <br/>
            <Row>
              <Col span={6}>Date</Col><Col span={2}> :</Col> <Col span={16}>{formatTimestamp(eventData.returnValues.timestamp)}</Col>
            </Row>
            </Timeline.Item>
          )
          case "PDFRejected" : return (
            <Timeline.Item key={index}>
            <h3>Workflow Rejected &nbsp; <a target='_blank' rel="noreferrer" href={`${blockExplorers[1]}/tx/${eventData.transactionHash}`} ><LinkOutlined /></a></h3>
            <Row>
              <Col span={6}>Rejected By</Col><Col span={2}> :</Col> <Col span={16}>{updateTimelineWithEmails(eventData.returnValues.approver)}</Col>
            </Row>
            <br/>
            <Row>
              <Col span={6}>Date</Col><Col span={2}> :</Col> <Col span={16}>{formatTimestamp(eventData.returnValues.timestamp)}</Col>
            </Row>
            </Timeline.Item>
          )
          case "PDFApproved" : return (
            <Timeline.Item key={index}>
            <h3>Workflow Approved &nbsp; <a target='_blank' rel="noreferrer" href={`${blockExplorers[1]}/tx/${eventData.transactionHash}`}  ><LinkOutlined /></a></h3>
            <Row>
              <Col span={6}>Approved By</Col><Col span={2}> :</Col><Col span={16}>{updateTimelineWithEmails(eventData.returnValues.approver)}</Col>
            </Row>
            <br/>
            <Row>
              <Col span={6}>Date</Col><Col span={2}> :</Col><Col span={16}>{formatTimestamp(eventData.returnValues.timestamp)}</Col>
            </Row>
            </Timeline.Item>
          )
          case "PDFSigned" : return (
            <Timeline.Item key={index}>
            <h3>Workflow Signed &nbsp; <a target='_blank' rel="noreferrer" href={`${blockExplorers[1]}/tx/${eventData.transactionHash}`} ><LinkOutlined /></a></h3>
            <Row>
              <Col span={6}>Signed By</Col><Col span={2}> :</Col><Col span={16}> {updateTimelineWithEmails(eventData.returnValues.approver)}&nbsp;<EyeOutlined onClick={()=>renderSignatureImage(eventData.returnValues.approver)} /></Col>
            </Row>
            <br/>
            <Row>
              <Col span={6}>Date</Col><Col span={2}> :</Col><Col span={16}>{formatTimestamp(eventData.returnValues.timestamp)}</Col>
            </Row>
            </Timeline.Item>
          )
          default : return ""                       
        }
      })}
    </Timeline>

    }
      {openSigModal && <SignatureModal approverSig={approverSig} openSigModal={openSigModal} handleCancelSigModal={()=>setOpenSigModal(false)} />}
    </>
    );
};
export default SurveyTimeline;
