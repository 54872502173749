import React, { useEffect } from 'react'
import { Button, Form, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setUserERC4907 } from '../../redux/actions/erc4907Contract';
import "../../styles/main.scss";
const SetTokenUserComponent = ({tokenId}) => {
    const {filteredExternalUsers} = useSelector((state)=> state.template);
    const {isERC4907SetUserLoading,isERC4907UserSetSuccess} = useSelector((state)=> state.erc4907Contract);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!',
        types: {
        email: '${label} is not a valid email!',
        },
    };
    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
    };  
    const onFinish = (values) => {
        console.log(values);
        const parsedExternalUser = JSON.parse(values.email);
        console.log("parsed external user :",parsedExternalUser);
        const user = parsedExternalUser.address;
        const userEmail = parsedExternalUser.email;
        const reqData = {user,tokenId,userEmail};
        dispatch(setUserERC4907(reqData));
    };


    useEffect(() => {
      if (isERC4907UserSetSuccess) {
        form.resetFields();
      }
    }, [isERC4907UserSetSuccess, form]);
    const onChange = (value) => {
        console.log(`selected ${value}`);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
      
    console.log("filteredExternalUsers :",filteredExternalUsers);
    const onboardedExternalUsers = filteredExternalUsers?.filter(obj => obj.address !== undefined);
    console.log("onboarded external users :",onboardedExternalUsers);
    return (
            <Form
                form={form}
                {...layout}
                name="nest-messages"
                onFinish={onFinish}
                labelAlign='left'
                // requiredMark={false}
                className='invitation-form'                
                validateMessages={validateMessages}
                colon={false}
            >
            <Form.Item
            name='email'
            label="Email"
            required={true}
            // rules={[
            //     {
            //     type: 'email',
            //     },
            // ]}
            >
                <Select
                    showSearch
                    placeholder="Select an External User"
                    onChange={onChange}
                    onSearch={onSearch}
                >
                    {onboardedExternalUsers?.map((obj) => (
                        <Select.Option key={obj.address} value={JSON.stringify({ email: obj.email, address: obj.address })}>
                            {obj.email}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                labelCol={24}
                wrapperCol={24}
            >
                <div className='antd-form-button-container'>
                    <Button type="primary" htmlType="submit" loading={isERC4907SetUserLoading}>
                        Submit
                    </Button>
                </div>
            </Form.Item>
        </Form>

    )
}

export default SetTokenUserComponent;