import React from 'react'
import { Button, Form, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { userOfERC4907Token } from '../../redux/actions/erc4907Contract';

const CheckValidTokenUserComponent = ({form}) => {
    const {isUserOfERC4907TokenLoading} = useSelector(state => state.erc4907Contract);
    console.log("isUserOfERC4907TokenLoading :",isUserOfERC4907TokenLoading)
    const dispatch = useDispatch();
    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
    };  
    const onFinish = (values) => {
        console.log(values);
        dispatch(userOfERC4907Token(values.tokenId));
    };   

    // const getValidateStatus = () => {
    //     if (isAccessTokenAuthorizationFailed) {
    //       return 'error';
    //     }
    //     return '';
    //   };

    return (
            <Form
                form={form}
                {...layout}
                name="nest-messages"
                onFinish={onFinish}
                labelAlign='left'
                requiredMark={false}
                style={{
                maxWidth: 600,
                }}
                colon={false}
            >
                <Form.Item
                name="tokenId"
                label="Token Id"
                // validateStatus={()=>getValidateStatus()}
                >
                    <InputNumber required style={{width:'100%'}} controls={false} placeholder="Enter TokenId here to get access to workflows" />
                </Form.Item>
                <Form.Item
                labelCol={24}
                wrapperCol={24}
                // wrapperCol={{
                //     ...layout.wrapperCol,
                //     offset: 18,
                // }}
                >
                    <div className='antd-form-button-container'>
                        <Button type="primary" htmlType="submit" loading={isUserOfERC4907TokenLoading} >
                            Submit
                        </Button>
                    </div>
                </Form.Item>
        </Form>

    )
}

export default CheckValidTokenUserComponent;