import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import "./UserDashboard.scss";
import { useEffect } from 'react';
import { invitedUserEmailToAddress } from '../../redux/actions/template';
import InvitedUserDashboardUI from './InvitedUserDashboardUI';
import UnInvitedUserDashboardUI from './UnInvitedUserDashboardUI';
import MainLayout from '../../components/MainLayout/MainLayout';
const UserDashboard = () => {
  const {userRoleFromAPI,userAddressFromAPI} = useSelector((state)=>state.template);
  console.log("userAddressFromAPI of a user that has not been invited yet :",userAddressFromAPI);
  console.log("userRoleFromAPI :",userRoleFromAPI);
  const dispatch = useDispatch();
  const userEmail = localStorage.getItem('email');
  const user = localStorage.getItem('user');
  const isUserInvited = JSON.parse(localStorage.getItem('isUserInvited'));
  console.log("isUserInvited :",isUserInvited);

  useEffect(()=> {
    const data = {address:user,email:userEmail}
     isUserInvited && dispatch(invitedUserEmailToAddress(data));
  },[dispatch,user,userEmail,isUserInvited]);

  return (
    <MainLayout>
      { isUserInvited &&
        <InvitedUserDashboardUI />
      }
      {
        !isUserInvited && <UnInvitedUserDashboardUI />
      }
    </MainLayout>
  )
}

export default UserDashboard;