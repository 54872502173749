import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserTokenAccessHistory } from '../../redux/actions/template';
import { Col, Pagination, Row, Select, Space, Spin, Table } from 'antd';
import { unixEpochToIST } from '../../utils/dateFormatter';
import { setInternalUserTokenHistoryCurrentPage, setInternalUserTokenHistoryElePerPage } from '../../redux/slices/userSlice';
const {Option} = Select;

const UserTokenHistoryComponent = () => {
  const user = localStorage.getItem('user');
  const {userTokenAccessHistoryArrayLoading,userTokenAccessHistoryArray,userTokenAccessHistoryCount} = useSelector(state => state.template);
  const { internalUserTokenHistoryCurrentPage,internalUserTokenHistoryElePerPage,internalUserTokenHistoryEleOrderBy } = useSelector((state)=>state.user);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getUserTokenAccessHistory({user,page:internalUserTokenHistoryCurrentPage,limit:internalUserTokenHistoryElePerPage,orderBy:internalUserTokenHistoryEleOrderBy}));
  },[dispatch,user,internalUserTokenHistoryCurrentPage,internalUserTokenHistoryElePerPage,internalUserTokenHistoryEleOrderBy]);

  const handleChangePage = (page) => {
    dispatch(setInternalUserTokenHistoryCurrentPage(page));
    dispatch(getUserTokenAccessHistory({user,page:page,limit:internalUserTokenHistoryElePerPage,orderBy:internalUserTokenHistoryEleOrderBy}));
  };

  const handleChangeElePerPage = (value) => {
    dispatch(setInternalUserTokenHistoryElePerPage(value));
    dispatch(setInternalUserTokenHistoryCurrentPage(1));
    dispatch(getUserTokenAccessHistory({user,page:1,limit:value,orderBy:internalUserTokenHistoryEleOrderBy}));
  };

  const columns = [
    {
        title: 'Token Id',
        dataIndex: 'tokenId',
        key: 'tokenId',
        width:'200px'
    },
    {
      title: 'Token User',
      dataIndex: 'tokenUser',
      key: 'tokenUser'
    },
    {
      title: 'Assigned At',
      dataIndex: 'userSetAt',
      key: 'userSetAt',
      width:'200px',
      render: (_, item) => {
        return unixEpochToIST(item.userSetAt && item.userSetAt);
      },    
    },
    {
      title: 'Token Expiry',
      dataIndex: 'userExpires',
      key: 'userExpires',
      width:'200px',
      render: (_, item) => {
        return unixEpochToIST(item.userExpires && item.userExpires);
      }
    },
    {
      title: 'Transaction Hash',
      dataIndex: 'transactionHash',
      key: 'transactionHash',
      render:(transactionHash) => {
        return <span><a href={`https://mumbai.polygonscan.com/tx/${transactionHash}`} target='_blank' rel='noreferrer'>{transactionHash}</a></span>
      },
    }
  ];

  return (
    <div>
      {
        userTokenAccessHistoryArrayLoading ? <div className='flex height-400'><Spin/></div>:
        <>
          <Table pagination={false} columns={columns} dataSource={userTokenAccessHistoryArray && userTokenAccessHistoryArray} rowKey={(record) => record.transactionHash} className='externaluser-tokenhistorytable' />
          <Row justify='space-between' align='middle' className="mt-1">
            <Col>
              <Space>
                <Select value={internalUserTokenHistoryElePerPage} onChange={handleChangeElePerPage}>
                  <Option value={10}>10 per page</Option>
                  <Option value={20}>20 per page</Option>
                  <Option value={40}>40 per page</Option>
                  <Option value={100}>100 per page</Option>
                </Select>
              </Space>
            </Col>
            <Col>
              <Pagination
                showSizeChanger={false}
                hideOnSinglePage={true}
                current={internalUserTokenHistoryCurrentPage}
                onChange={handleChangePage}
                pageSize={internalUserTokenHistoryElePerPage}
                total={userTokenAccessHistoryCount} 
              />
            </Col>
          </Row>

        </>
      }
    </div>
  )
}

export default UserTokenHistoryComponent;