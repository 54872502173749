import React from 'react';
import { List } from 'antd';
import {dateTimeFormatter} from '../../utils/dateFormatter'

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    itemLayout="horizontal"
    renderItem={(item, index) => (
      <List.Item>
        <List.Item.Meta
          title={item.message}
          description={item.createdAt?dateTimeFormatter(item.createdAt):''}
        />
      </List.Item>)}
  />
);

const SurveyComments = ({comments}) => {
  const sortedComments = [...comments].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  return (
    <div>
      <CommentList comments={sortedComments} />
    </div>
  );
};

export default SurveyComments;
