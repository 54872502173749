import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAccessHistory } from '../../redux/actions/template';
import { Col, Pagination, Row, Select, Space, Spin, Table } from 'antd';
import { unixEpochToIST } from '../../utils/dateFormatter';
import "../../styles/main.scss";
import { setTokenAccessHistoryCurrentPage, setTokenAccessHistoryElePerPage } from '../../redux/slices/userSlice';
const {Option} = Select;

const TokenHistoryComponent = () => {
  const user = localStorage.getItem('user');
  const {userTokenHistoryArray,userTokenHistoryArrayLoading,userTokenHistoryCount} = useSelector(state => state.template);
  const { userTokenAccessHistoryCurrentPage,userTokenAccessHistoryElePerPage,userTokenAccessHistoryEleOrderBy } = useSelector((state)=>state.user);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getUserAccessHistory({user,page:userTokenAccessHistoryCurrentPage,limit:userTokenAccessHistoryElePerPage,orderBy:userTokenAccessHistoryEleOrderBy}));
  },[dispatch,user,userTokenAccessHistoryCurrentPage,userTokenAccessHistoryElePerPage,userTokenAccessHistoryEleOrderBy]);

  const columns = [
    {
        title: 'Token Id',
        dataIndex: 'tokenId',
        key: 'tokenId',
        width:'200px'
    },
    // {
    //   title: 'Token Owner',
    //   dataIndex: 'tokenOwner',
    //   key: 'tokenOwner'
    // },
    {
      title: 'Assigned At',
      dataIndex: 'userSetAt',
      key: 'userSetAt',
      width:'200px',
      render: (_, item) => {
        return unixEpochToIST(item.userSetAt && item.userSetAt);
      },    
    },
    {
      title: 'Token Expiry',
      dataIndex: 'userExpires',
      key: 'userExpires',
      width:'200px',
      render: (_, item) => {
        return unixEpochToIST(item.userExpires && item.userExpires);
      }
    },
    {
      title: 'Transaction Hash',
      dataIndex: 'transactionHash',
      key: 'transactionHash',
      render:(transactionHash) => {
        return <span><a href={`https://mumbai.polygonscan.com/tx/${transactionHash}`} target='_blank' rel='noreferrer'>{transactionHash}</a></span>
      },
    }
  ];

  const handleChangePage = (page) => {
    dispatch(setTokenAccessHistoryCurrentPage(page));
    dispatch(getUserAccessHistory({user,page:page,limit:userTokenAccessHistoryElePerPage,orderBy:userTokenAccessHistoryEleOrderBy}));
  };

  const handleChangeElePerPage = (value) => {
    dispatch(setTokenAccessHistoryElePerPage(value));
    dispatch(setTokenAccessHistoryCurrentPage(1));
    dispatch(getUserAccessHistory({user,page:1,limit:value,orderBy:userTokenAccessHistoryEleOrderBy}));
  };

  return (
    <div>
      {
        userTokenHistoryArrayLoading ? <div className='externaluser-historytable-spincontainer flex'><Spin/></div>:
        <>
          <Table pagination={false} columns={columns} dataSource={userTokenHistoryArray && userTokenHistoryArray} rowKey={(record) => record.transactionHash} className='externaluser-historytablestyle' />
          <Row justify='space-between' align='middle' className="mt-1">
            <Col>
              <Space>
                <Select value={userTokenAccessHistoryElePerPage} onChange={handleChangeElePerPage}>
                  <Option value={10}>10 per page</Option>
                  <Option value={20}>20 per page</Option>
                  <Option value={40}>40 per page</Option>
                  <Option value={100}>100 per page</Option>
                </Select>
              </Space>
            </Col>
            <Col>
              <Pagination
                showSizeChanger={false}
                hideOnSinglePage={true}
                current={userTokenAccessHistoryCurrentPage}
                onChange={handleChangePage}
                pageSize={userTokenAccessHistoryElePerPage}
                total={userTokenHistoryCount} 
              />
            </Col>
          </Row>
        </>
      }
    </div>
  )
}

export default TokenHistoryComponent;