import React, { useState } from 'react'
import { Button, Modal, Space ,Form, Pagination, Row, Col, Select} from 'antd'
import { DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';
import InviteAdminComponent from '../../components/InviteAdminComponent/InviteAdminComponent';
import TransferEWarrantOwnership from '../../components/TransferEWarrantOwnership/TransferEWarrantOwnership';
import AdminList from '../../components/AdminList/AdminList';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllAdmins } from '../../redux/actions/template';
import { resetInviteAdminStates } from '../../redux/slices/templateSlice';
import MainLayout from '../../components/MainLayout/MainLayout';
import './SuperAdminDashboard.scss';
import '../../App.css';
import { setSuperAdminDashboardCurrentPage, setSuperAdminDashboardElePerPage } from '../../redux/slices/superAdminSlice';
const {Option} = Select;

const SuperAdminDashboard = () => {
  const [form] = Form.useForm();
  const {isInviteAdminLoading,isInviteAdminSuccess,allAdminArray,isEWarrentOwnershipTransferAPISuccess,xvaultDeploymentSuccess,adminsCount} = useSelector(state => state.template);
  const {superadminDashboardCurrentPage,superadminDashboardElePerPage,adminArrayEleOrderBy,adminArrayEleSortBy} = useSelector(state => state.superAdmin);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    dispatch(resetInviteAdminStates());
  };

  const [isModal1Open, setIsModal1Open] = useState(false);

  const showModal1 = () => {
    setIsModal1Open(true);
  };

  const handleCancel1 = () => {
    form.resetFields();
    setIsModal1Open(false);
  };

  useEffect(()=>{
    isInviteAdminSuccess===true && handleCancel();
  },[isInviteAdminSuccess]);

  useEffect(()=>{
    dispatch(getAllAdmins({page:superadminDashboardCurrentPage,limit:superadminDashboardElePerPage,orderBy:adminArrayEleOrderBy,sortBy:adminArrayEleSortBy}));
  },[dispatch,adminArrayEleOrderBy,adminArrayEleSortBy,superadminDashboardCurrentPage,superadminDashboardElePerPage]);

  useEffect(()=>{
    (isEWarrentOwnershipTransferAPISuccess===true || isInviteAdminSuccess===true) && dispatch(getAllAdmins({page:superadminDashboardCurrentPage,limit:superadminDashboardElePerPage,orderBy:adminArrayEleOrderBy,sortBy:adminArrayEleSortBy}));
  },[dispatch,isInviteAdminSuccess,isEWarrentOwnershipTransferAPISuccess,superadminDashboardCurrentPage,superadminDashboardElePerPage,adminArrayEleOrderBy,adminArrayEleSortBy]);

  useEffect(()=>{
    xvaultDeploymentSuccess && dispatch(getAllAdmins({page:superadminDashboardCurrentPage,limit:superadminDashboardElePerPage,orderBy:adminArrayEleOrderBy,sortBy:adminArrayEleSortBy}));
  },[dispatch,xvaultDeploymentSuccess,superadminDashboardCurrentPage,superadminDashboardElePerPage,adminArrayEleOrderBy,adminArrayEleSortBy]);

  useEffect(()=>{
    isEWarrentOwnershipTransferAPISuccess===true && handleCancel1();
  },[isEWarrentOwnershipTransferAPISuccess]);

  const calculateButtonDirection = () => {
    return window.innerWidth > 480 ? 'horizontal' : 'vertical';
  };
  const [buttonDirection, setButtonDirection] = useState(calculateButtonDirection());

  useEffect(() => {
    const handleResize = () => {
      setButtonDirection(calculateButtonDirection());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChangeSuperadminDashboardCurrentPage = (page) => {
    console.log("page logged :",page);
    dispatch(setSuperAdminDashboardCurrentPage(page));
    dispatch(getAllAdmins({page:superadminDashboardCurrentPage,limit:superadminDashboardElePerPage,orderBy:adminArrayEleOrderBy,sortBy:adminArrayEleSortBy}));
  };

  const handleChangeSuperadminDashboardElePerPage = (value) => {
    console.log("value logged :",value);
    dispatch(setSuperAdminDashboardElePerPage(value));
    dispatch(setSuperAdminDashboardCurrentPage(1));
    dispatch(getAllAdmins({page:1,limit:value,orderBy:adminArrayEleOrderBy,sortBy:adminArrayEleSortBy}));
  };

  return (
    <MainLayout>
          <div className='superadmin-dashboard-main'>
            <h1>Admin List</h1>
              <Space direction={buttonDirection}>
                <Button type='primary' onClick={showModal} icon={<PlusOutlined />}>Invite Admin</Button>
                <Button type='primary' onClick={showModal1} icon={<DoubleRightOutlined />}>Transfer xVault Ownership</Button>
              </Space>
            <Modal title="Invite Admins to xVault" open={isModalOpen} onCancel={handleCancel} footer={false} maskClosable={false}>
              <InviteAdminComponent form={form} isInviteAdminLoading={isInviteAdminLoading}/>
            </Modal>
            <Modal title="Transfer xVault Ownership" open={isModal1Open} onCancel={handleCancel1} footer={false} maskClosable={false}>
              <p>Transfer the ownership of any xVault contract that was deployed for an Admin if you find any malicious activity going on .</p>
              <p><span className='superadmin-dashboard-main-transferownership-note'>Note :</span> The New Admin should not be an Admin of any xVault contract.</p>
              <TransferEWarrantOwnership form={form} allAdminArray={allAdminArray}/>
            </Modal>
          </div>
          <AdminList/>
          <Row justify='space-between' align='middle' className="mt-1">
            <Col>
              <Space>
                <Select value={superadminDashboardElePerPage} onChange={handleChangeSuperadminDashboardElePerPage}>
                  <Option value={10}>10 per page</Option>
                  <Option value={20}>20 per page</Option>
                  <Option value={40}>40 per page</Option>
                  <Option value={100}>100 per page</Option>
                </Select>
              </Space>
            </Col>
            <Col>
              <Pagination
                showSizeChanger={false}
                hideOnSinglePage={true}
                current={superadminDashboardCurrentPage}
                onChange={handleChangeSuperadminDashboardCurrentPage}
                pageSize={superadminDashboardElePerPage}
                total={adminsCount}
              />
            </Col>
          </Row>

    </MainLayout>
  )
}

export default SuperAdminDashboard;