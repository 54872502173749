import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes , Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PrivateRoutes from './utils/PrivateRoutes';
import AdminRoutes from './utils/AdminRoutes';
import UserRoutes from './utils/UserRoutes';
import SuperAdminRoutes from './utils/SuperAdminRoutes';

import Home from './pages/Home/Home';
import AdminManageRolesPage from './pages/AdminManageRolesPage/AdminManageRolesPage';
import SubmitSurvey from './pages/UserDashboard/SubmitSurvey/SubmitSurvey';
import ApproveSurvey from './pages/UserDashboard/ApproveSurvey/ApproveSurvey';
import VerifySurveyPage from './pages/VerifySurveyPage/VerifySurveyPage';
import SubmitSurveyPage from './pages/SubmitSurveyPage/SubmitSurveyPage';
import ApproveSurveyPage from './pages/ApproveSurveyPage/ApproveSurveyPage';
import PDFCodeNew from './components/SubmitSurveyList/PDFCodeNew';
import ViewApprovedSurveys from './pages/ViewApprovedSurveys/ViewApprovedSurveys';
import ViewApprovedSurveyPage from './pages/ViewApprovedSurveyPage/ViewApprovedSurveyPage';
import AllUsers from './pages/AllUsers/AllUsers';
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
import UserDashboard from './pages/UserDashboard/UserDashboard';
import { getEthBalance } from './redux/actions/eWarrant';
import SuperAdminDashboard from './pages/SuperAdminDashboard/SuperAdminDashboard';
import SubmitSurveys from './pages/SubmitSurveys/SubmitSurveys';
import ApproveSurveys from './pages/ApproveSurveys/ApproveSurveys';
import ViewSurveys from './pages/ViewSurveys/ViewSurveys';
import AdminInfoPage from './pages/AdminInfoPage/AdminInfoPage';
import AdminSurveysForSuperAdmin from './pages/AdminSurveysForSuperAdmin/AdminSurveysForSuperAdmin';
import AdminEachSurveyForSuperAdmin from './pages/AdminEachSurveyForSuperAdmin/AdminEachSurveyForSuperAdmin';
import ManageAccess from './pages/ManageAccess/ManageAccess';
import ExternalUserViewSurveys from './pages/ExternalUserViewSurveys/ExternalUserViewSurveys';
import ExternalUserViewSurveysByAdmin from './pages/ExternalUserViewSurveysByAdmin/ExternalUserViewSurveysByAdmin';
import ExternalUserViewASurveyByAdmin from './pages/ExternalUserViewASurveyByAdmin/ExternalUserViewASurveyByAdmin';
import AdminAssignedSurveys from './pages/AdminAssignedSurveys/AdminAssignedSurveys';
import AssignedWorkflowPage from './pages/AssignedWorkflowPage/AssignedWorkflowPage';

const App = () => {
  const sessionId = JSON.parse(localStorage.getItem("openlogin_store"))?.sessionId;
  useEffect(()=> {
    sessionId==="" && localStorage.clear() && window.location.reload()
  },[sessionId]);
  
  const dispatch = useDispatch();
  useEffect(()=>{
    (sessionId && sessionId!=="") && dispatch(getEthBalance())
  },[dispatch,sessionId]);

  return (
    <div className="App">
      <Router> 
        <Routes>
          {/*  3 type routes : SuperAdmin Routes , Admin Routes , User Routes */}
          <Route path="/" element={<Home />}/>  
            <Route path='/pdf-view' element={<PDFCodeNew />} />
            <Route element={<PrivateRoutes />}>
              <Route element={<UserRoutes/>}>
                <Route path="/dashboard" element={<UserDashboard />} />
                <Route path='/manage-access' element={<ManageAccess/>} />
                <Route path="/submit-workflows" element={<SubmitSurveys/>}/>
                <Route path="/submit-workflow/:adminId" element={<SubmitSurvey/>}/>
                <Route path="/submit-workflow/:adminId/:formId" element={<SubmitSurveyPage />} /> 
                <Route path="/approve-workflows" element={<ApproveSurveys/>}/>
                <Route path="/approve-workflow/:adminId" element={<ApproveSurvey/>}/> 
                <Route path="/approve-workflow/:adminId/:formId" element={<ApproveSurveyPage />} /> 
                <Route path="/view-workflows" element={<ViewSurveys/>}/>
                <Route path="/view-workflow/:adminId" element={<ViewApprovedSurveys/>}/> 
                <Route path="/view-workflow/:adminId/:formId" element={<ViewApprovedSurveyPage/>}/> 
                <Route path="/verify-workflow/:adminId/:formId" element={<VerifySurveyPage />} /> 
                <Route path='/view-workflows-externaluser' element={<ExternalUserViewSurveys />} />
                <Route path='/view-workflow-externaluser/:adminId' element={<ExternalUserViewSurveysByAdmin />} />
                <Route path='/view-workflow-externaluser/:adminId/:formId' element={<ExternalUserViewASurveyByAdmin />} />
              </Route>
              <Route element={<AdminRoutes/>}>
                <Route path='/admin-dashboard' element={<AdminDashboard />}/>
                <Route path="/assigned-workflows" element={<AdminAssignedSurveys />}/> 
                <Route path="/assigned-workflow/:formId" element={<AssignedWorkflowPage />} /> 
                <Route path="/manage-roles" element={<AdminManageRolesPage />} />
                <Route path="/all-users" element={<AllUsers />}/>
              </Route>
              <Route element={<SuperAdminRoutes/>}>
                <Route path='/superadmin-dashboard' element={<SuperAdminDashboard />}/>
                <Route path="/superadmin-dashboard/admin" element={<AdminInfoPage />}/>
                <Route path='/superadmin-dashboard/adminWorkflows' element={<AdminSurveysForSuperAdmin />} />
                <Route path='/superadmin-dashboard/adminWorkflow' element={<AdminEachSurveyForSuperAdmin />} />
              </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;