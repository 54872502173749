import React, { useEffect } from 'react'
import Spinner from '../../components/Spinner/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Badge, Card, Col, Divider, Input, Pagination, Row, Select, Space } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../components/MainLayout/MainLayout';
import { getWorkflowsForViewing } from '../../redux/actions/template';
import { setViewWorkflowsCurrentPage, setViewWorkflowsElePerPage } from '../../redux/slices/userSlice';
const { Option } = Select;

const ViewApprovedSurveys = () => {
  const { viewWorkflowsCurrentPage,viewWorkflowsElePerPage,viewWorkflowsEleOrderBy,viewWorkflowsEleSortBy } = useSelector((state) => state.user);
  const {isWorkflowsForViewingLoading,workflowArrayForViewing,totalWorkflowsForViewingCount} = useSelector((state)=>state.template);
  let { adminId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Track initial loading state

  useEffect(() => {
    dispatch(getWorkflowsForViewing({limit:viewWorkflowsElePerPage,orderBy:viewWorkflowsEleOrderBy,sortBy:viewWorkflowsEleSortBy,page:viewWorkflowsCurrentPage,contract_address:location.state.contract_address})).then(() => {
      setIsLoading(false); // Set loading state to false after data is fetched
    });
  }, [dispatch,viewWorkflowsCurrentPage,viewWorkflowsElePerPage,viewWorkflowsEleOrderBy,viewWorkflowsEleSortBy,location.state.contract_address]);

  const surveyDescription = (item) => (
    <div className='flex-center-direction-col'>
      <Row >
        <Col span={10} className='bold-font'>
          Workflow Name
        </Col>
        <Col span={2} className='bold-font'>
        : 
        </Col>
        <Col span={10}>
          {item.name && item.name.length > 15 ? item.name.substring(0, 15) + '...' : item.name}
        </Col>
      </Row>
      <Row>
        <Col span={10} className='bold-font'>
            Workflow Id
          </Col>
          <Col span={2} className='bold-font'>
          : 
          </Col>
          <Col span={10}>
            {item.workflowID}
          </Col>
      </Row>
    </div>
  );

  const toViewApprovedSurvey = ({workflowID,_id}) => {
    navigate(`/view-workflow/${adminId}/${workflowID}`,{state:{contract_address:location.state.contract_address,adminId,_id}});
  };

  const getStatusText = (statusEnum) => {
    switch (statusEnum) {
      case 'ASSIGNED':
        return "Assigned";
      case 'SUBMITTED':
        return "Submitted";
      case 'APPROVED':
        return "Approved";
      case 'REJECTED':
        return "Rejected";
      case 'SIGNED':
        return "Signed";
      default:
        return "";
    }
  };

  const getStatusColor = (statusEnum) => {
    switch (statusEnum) {
      case 'ASSIGNED':
        return "orange";
      case 'SUBMITTED':
        return "blue";
      case 'APPROVED':
        return "#800080";
      case 'REJECTED':
        return "red";
      case 'SIGNED':
        return "green";
      default:
        return "black";
    }
  };
  
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = workflowArrayForViewing?.filter((item) => {
      const surveyName = item.surveyName.toLowerCase();
      const formId = item.formId.toLowerCase();
      return (
        (surveyName.includes(query) || formId.includes(query))
      );
    });
    setFilteredData(filtered);
  };
  
  
  const handleChangePage = (page) => {
    dispatch(setViewWorkflowsCurrentPage(page));
    setSearchQuery(''); // Reset search query when changing pages
    dispatch(getWorkflowsForViewing({limit:viewWorkflowsElePerPage,orderBy:viewWorkflowsEleOrderBy,sortBy:viewWorkflowsEleSortBy,page:page,contract_address:location.state.contract_address}));
  }
  const handleChangeCardsPerPage = (value) => {
    dispatch(setViewWorkflowsElePerPage(value));
    dispatch(setViewWorkflowsCurrentPage(1));
    dispatch(getWorkflowsForViewing({limit:value,orderBy:viewWorkflowsEleOrderBy,sortBy:viewWorkflowsEleSortBy,page:1,contract_address:location.state.contract_address}));
  };
  
  return (
    
    <MainLayout>
            <Row justify={'space-between'}>
              <h1>View Approved Workflows</h1>
              <div className='flex-and-align-center'>
                <Col>
                  <Space>
                    <Input
                      size='large'
                      placeholder='Search For Workflows'
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                    />
                  </Space>
                </Col>
               </div>
            </Row>

              {searchQuery && filteredData.length === 0 && (
                <h1 className='textalign-center'>No matching workflows found.</h1>
              )}
              {(!searchQuery || filteredData.length > 0) && (
              <>
                <Row gutter={[20,20]} className='cards-container'>
                  {isWorkflowsForViewingLoading ? <div className='flex-direction-col width-height-100perc'><Spinner/><h3>Loading...</h3></div> :
                  (searchQuery ? filteredData : workflowArrayForViewing).map((item) => (
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={item.workflowID}>
                      <Badge.Ribbon text={getStatusText(item.status)} color={getStatusColor(item.status)}>
                        <Card
                          cover={<div className='flex mt-3'><img src="/assets/images/submit-survey-icon.png" alt="Survey" className='workflow-card-icon'/></div>}
                          key={item.workflowID}
                          className='workflow-card'
                          hoverable
                          onClick={() => toViewApprovedSurvey({workflowID:item.workflowID,_id:item._id})}
                        >
                          <Divider />
                          {surveyDescription(item)}
                        </Card>
                      </Badge.Ribbon>
                    </Col>
                  ))
                  }
                </Row>
                <Row justify='space-between' align='middle' style={{ marginBottom: '0.5rem' }}>
                  <Col>
                    <Select value={viewWorkflowsElePerPage} onChange={handleChangeCardsPerPage}>
                      <Option value={12}>12 per page</Option>
                      <Option value={20}>20 per page</Option>
                      <Option value={40}>40 per page</Option>
                      <Option value={100}>100 per page</Option>
                    </Select>
                  </Col>
                  <Col>
                    <Pagination
                      showSizeChanger={false}
                      current={viewWorkflowsCurrentPage}
                      onChange={handleChangePage}
                      pageSize={viewWorkflowsElePerPage}
                      total={totalWorkflowsForViewingCount}
                    />
                  </Col>
                </Row>
              </>
              )}
    </MainLayout>
  )
}

export default ViewApprovedSurveys;