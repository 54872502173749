import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { openNotificationWithIcon } from "../../utils/openNotificationWithIcon";
import { approverSignApprovedSurvey, getEthBalance } from "./eWarrant";
import {setLatestWorkflowCreatedId, setSignatureUploadingAndPDFUploadingText} from '../slices/templateSlice';
import { onchainAttestWorkflowTemplate, onchainAttestWorkflowTemplateEdit } from "./attestations";
import { setCurrent } from "../slices/adminSlice";
import { generateBytes32Hash, generateBytes32HashOfString } from "../../utils/attestationUtils";

const BACKENDURL = 'https://backend.xvault.thenftbrewery.com';

export const getTemplates = createAsyncThunk(
  "template/getTemplates",
  async ({adminId,sortBy,orderBy,page,limit}, thunkAPI) => {
    try {
      let res = await axios.get(`${BACKENDURL}/api/templates/templates?admin_id=${adminId}&sortBy=${sortBy}&orderBy=${orderBy}&page=${page}&limit=${limit}`);
      console.log("res :",res)
      return {data :res.data.data,Count:res.data.Count};
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createTemplate = createAsyncThunk(
  "template/createTemplate",
  async ( data , thunkAPI) => {
    try {
      const admin_id = localStorage.getItem('adminId');
      const apiData= {...data,admin_id};
      const res = await axios.post(`${BACKENDURL}/api/templates/create`,apiData);
      console.log("res of creating workflow template :",res);
      const templateId = res.data._id;
      thunkAPI.dispatch(setLatestWorkflowCreatedId(templateId));
      const contentToBeHashed = [data.name,data.description,data.fields];
      const workflowTIdHash = await generateBytes32HashOfString(templateId);
      console.log("workflowTIdHash :",workflowTIdHash);
      const contentHash = await generateBytes32Hash(contentToBeHashed);
      console.log("content hash :",contentHash);
      const attestationData = {workflowTIdHash,contentHash,version:res.data.Version,role:0,templateId,sortBy:data.sortBy,orderBy:data.orderBy,page:data.page,limit:data.limit};
      console.log("attestation data to be sent :",attestationData)
      await thunkAPI.dispatch(onchainAttestWorkflowTemplate(attestationData));
      thunkAPI.dispatch(getEthBalance());
      thunkAPI.dispatch(setCurrent(data.current + 1));
      return res.status===200 ? true : false;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
        return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateTemplate = createAsyncThunk(
  "template/updateTemplate",
  async ( data , thunkAPI) => {
    try {
      console.log("starting to update template")
      // const adminId = localStorage.getItem('adminId');
      const apiData = {name:data.name,description:data.description,fields:data.fields,id:data.id}
      const res = await axios.patch(`${BACKENDURL}/api/templates/update`,apiData);
      const templateId = data.id;
      const contentToBeHashed = [data.name,data.description,data.fields];
      const workflowTIdHash = await generateBytes32HashOfString(templateId);
      console.log("workflowTIdHash :",workflowTIdHash);
      const contentHash = await generateBytes32Hash(contentToBeHashed);
      console.log("content hash :",contentHash);
      const attestationData = {workflowTIdHash,contentHash,version:data.currentLatestVersion+1,role:0,templateId,referencedAttestation:data.referencedAttestation,sortBy:data.sortBy,orderBy:data.orderBy,page:data.page,limit:data.limit};
      console.log("attestation data to be sent :",attestationData)
      await thunkAPI.dispatch(onchainAttestWorkflowTemplateEdit(attestationData));
      thunkAPI.dispatch(getEthBalance());
      return res.status===200 ? true : false;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// we don't delete templates anymore , leaving it for now .
export const deletetemplate = createAsyncThunk(
  "template/deletetemplate",
  async ( data, thunkAPI) => {
    try {
      const adminId = localStorage.getItem('adminId');
      const res = await axios.get(`${BACKENDURL}/api/templates/delete/${data}`);
      thunkAPI.dispatch(getTemplates(adminId));
      return res.payload.status;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const mapSurveyIdToFormId = createAsyncThunk(
  "template/mapSurveyIdToFormId",
  async ( data , thunkAPI) => {
    try {
      const adminId = localStorage.getItem('adminId');
      const apiData = {surveyId:data.surveyId,formId:data.formId,version:data.version,datasetProvider:data.datasetProvider,datasetApprovers:data.datasetApprovers,createdAdmin:adminId};
      await axios.post(`${BACKENDURL}/api/survey/create`,apiData);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
        openNotificationWithIcon('error','api error : mapSurveyIdToFormId action error');
        return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSurveyId = createAsyncThunk(
  "template/getSurveyId",
  async (data, thunkAPI) => {
    console.log("data inside getSurveyId :",data)
    try {
      let res = await axios.get(`${BACKENDURL}/api/survey/getSurvey/?formId=${data}`);
      console.log("res getSurveyId:",res);
      return res.data.data[0];
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
        openNotificationWithIcon('error','cannot render form UI');
        return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTemplateById = createAsyncThunk(
  "template/getTemplateById",
  async (data, thunkAPI) => {
    try {
      let res = await axios.get(`${BACKENDURL}/api/templates/templates/?templateId=${data}`);
      console.log("res getTemplateById :",res.data.data[0]);
      return res.data.data[0];
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllSurveys = createAsyncThunk('template/getAllSurveys',async(data,thunkAPI)=> {
  try {
			let res = await axios.get(`${BACKENDURL}/api/survey/getSurvey`);
      console.log("res getAllSurveys :",res.data);
      return res.data;
  }
  catch(error) {
    openNotificationWithIcon('error','getSurveyIdAndNameAdmin');
  }
})

export const submitCreatedSurvey = createAsyncThunk("template/submitCreatedSurvey",async(data,thunkAPI)=> {
  try {
    const reqData = {formId:data.formId,name:data.name,description:data.description,fields:data.fields}
    let res = await axios.post(`${BACKENDURL}/api/userTemplates/create`,reqData);
    console.log("res submitCreatedSurvey :",res);
    console.log("formId submitCreatedSurvey :",data);
    const statusData = {ID:data._id,Status:"SUBMITTED"}
    res.status===200 && await thunkAPI.dispatch(updateWorkflowStatusAPI(statusData));
    return res.status===200 ? true : false;
  } catch(error) {
    openNotificationWithIcon('error',`${error}`);
  }
})

export const submitRejectedSurveyAgain = createAsyncThunk("template/submitRejectedSurveyAgain",async(data,thunkAPI)=> {
  try {
    let res = await axios.patch(`${BACKENDURL}/api/userTemplates/update`,data);
    console.log("res submitCreatedSurvey :",res);
    console.log("formId submitCreatedSurvey :",data);    
    const statusData = {ID:data._id,Status:"SUBMITTED"}
    res.status===200 && await thunkAPI.dispatch(updateWorkflowStatusAPI(statusData));
    return res.status===200 ? true : false;
  } catch(error) {
    openNotificationWithIcon('error',`${error}`);
  }
})
export const getSubmittedSurveyUI = createAsyncThunk("template/getSubmittedSurveyUI",async(data,thunkAPI)=>{
  try {
    let res = await axios.get(`${BACKENDURL}/api/userTemplates/templates?formId=${data}`);
    console.log("res getSubmittedSurveyUI : ",res);
    return res.data.data[0];
  } catch(error) {
    openNotificationWithIcon('error','Unable to fetch submitted survey');
  }
})

export const setCommentToRejectedSurvey = createAsyncThunk("template/setCommentToRejectedSurvey",async(data,thunkAPI)=>{
  try {
    let res = await axios.post(`${BACKENDURL}/api/userTemplates/reject`,data);
    // res.status === 200 && openNotificationWithIcon('success','Workflow Rejected Successfully');
  } catch(error) {
    openNotificationWithIcon('error','Error in comment api ');
  }
})

export const approverSignatureUpload = createAsyncThunk("template/approverSignatureUpload",async(data,thunkAPI)=>{
  try {
    console.log("signature blob :",data.signatureBlob)
    thunkAPI.dispatch(setSignatureUploadingAndPDFUploadingText('Uploading e-signature ...'));
    console.log("data inside",data);
    console.log("formDAta to be sent to sig api :",data.formData);
    let res = await axios.post(`${BACKENDURL}/api/userTemplates/signature`,data.formData,data.config);
    res.status === 200 && thunkAPI.dispatch(setSignatureUploadingAndPDFUploadingText('Generating workflow PDF ...'));
    const approverPDFURLObj = res.status === 200 && await axios.post(`${BACKENDURL}/api/pdf/pdfList?formId=${data.formId}`);
    console.log("approverPDFURLObj :",approverPDFURLObj);
    console.log("approverPDFURL :",approverPDFURLObj.data.data.data.Location);
    console.log("approverPDF hash :",approverPDFURLObj.data.data.pdfHash);
    await thunkAPI.dispatch(approverSignApprovedSurvey({approverConfirmationCount:data.approverConfirmationCount,formId:data.formId,image:approverPDFURLObj.data.data.data.Location,hash:approverPDFURLObj.data.data.pdfHash,contract_address:data.contract_address,referencedAttestationUId : data.referencedAttestation,signatureBlob:data.signatureBlob,_id:data._id}));
    return res.status;
  } catch(error) {
    openNotificationWithIcon('error','Error in uploading approver signature ');
  }
});

export const allApproversSignedPDFUpload = createAsyncThunk("template/allApproversSignedPDFUpload",async({formId,hash,file},thunkAPI)=>{
  try {
      console.log("allApproversSignedPDFUpload ke andar ...")
      const formData = new FormData();
      formData.append('pdf', file);
      formData.append('name',`${formId}-approved+signed`);
      const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
      };
      const response = await axios.post(`${BACKENDURL}/api/pdf/uplaod`,formData,config);
      response.status===200 && thunkAPI.dispatch(approverSignApprovedSurvey({formId:formId,image:response.data.data.Location,hash:hash}))
  } catch(error) {
    openNotificationWithIcon('error','Axios Error : Approver Signed pdf upload failed !');
  }
});

export const saveEvent = createAsyncThunk("template/saveEvent",async(data,thunkAPI)=> {
  try {
    console.log("data received for saving event :",data)
    let res = await axios.post(`${BACKENDURL}/api/survey/saveEvents`,data);
    console.log("res of saveEvent :",res);
  } catch(error) {
    openNotificationWithIcon('error',`${error}`);
  }
});

export const getEvents = createAsyncThunk("template/getEvents",async(data,thunkAPI)=> {
  try {
    console.log("data received for saving event :",data)
    let res = await axios.get(`${BACKENDURL}/api/survey/getEvents?formId=${data}`);
    return res.data.data;
  } catch(error) {
    openNotificationWithIcon('error',`${error}`);
  }
});

export const inviteUser = createAsyncThunk("template/inviteUser",async(data,thunkAPI)=> {
  try {
    const admin_id = localStorage.getItem('adminId');
    const email = data.email;
    const apiData = {email,admin_id};
    let res = await axios.post(`${BACKENDURL}/api/role/createuser`,apiData);
    console.log("res of inviteUser api call :",res);
    res.status === 200 && openNotificationWithIcon('success','Invitation Sent');
    return res.status;
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});

export const userEmailToWalletAddress = createAsyncThunk("template/userEmailToWalletAddress",async(data,thunkAPI)=> {
  try {
    let res = await axios.patch(`${BACKENDURL}/api/role/update`,data);
    console.log("res of userEmailToWalletAddress api call :",res);
  } catch(error) {
    openNotificationWithIcon('error',`userEmailToWalletAddress error`);
  }
});

export const getUserInvitedRole = createAsyncThunk("template/getUserInvitedRole", async (data, thunkAPI) => {
  try {
    console.log("email :",data.email);
    const email = data.email;
    let res = await axios.get(`${BACKENDURL}/api/role/getRole?email=${email}`);
    console.log("res of getUserInvitedRole api call:", res.data.data[0]);
    let obj = res.data.data[0];
    let userRole = obj.role;
    localStorage.setItem('userrole',userRole);
    if (!obj.hasOwnProperty("address")) {
      thunkAPI.dispatch(userEmailToWalletAddress(data));
    }
    return obj;
  } catch (error) {
    console.log("getUserInvitedRole Error:", error);
    throw error; // Rethrow the error to be caught by the calling code
  }
});

export const getAllInvitedUsers =  createAsyncThunk("template/getAllInvitedUsers", async (data, thunkAPI) => {
  try {
    let res = await axios.get(`${BACKENDURL}/api/role/getRole`);
    console.log("res of getAllInvitedUsers api call:", res.data.data);
    const invitedUsersArray = res.data.data;
    const InvitedSubmittersArray = invitedUsersArray.filter(obj => {
      return obj.role === "submitter" && obj.hasOwnProperty("address");
    });
    const InvitedApproversArray = invitedUsersArray.filter(obj => {
      return obj.role === "approver" && obj.hasOwnProperty("address");
    });
    const InvitedBothRolesArray = invitedUsersArray.filter(obj => {
      return obj.role === "both" && obj.hasOwnProperty("address");
    });
    const finalInvitedSubmittersArray = InvitedSubmittersArray.concat(InvitedBothRolesArray);
    console.log("finalInvitedSubmittersArray :",finalInvitedSubmittersArray);
    const finalInvitedApproversArray = InvitedApproversArray.concat(InvitedBothRolesArray);
    console.log("finalInvitedApproversArray :",finalInvitedApproversArray);
    const returnedObj = {finalInvitedSubmittersArray,finalInvitedApproversArray,invitedUsersArray};
    return returnedObj;
    
  } catch (error) {
    console.log("getAllInvitedUsers Error:", error);
    throw error; // Rethrow the error to be caught by the calling code
  }
});

export const getAllUsersForTimeline =  createAsyncThunk("template/getAllUsersForTimeline", async (data, thunkAPI) => {
  try {
    let res = await axios.get(`${BACKENDURL}/api/role/getUser`);
    console.log("res of getAllInvitedUsers api call:", res.data.data);
    const invitedUsersArray = res.data.data;
    
    // Filter the invitedUsersArray based on the presence of the "address" key
    // const filteredInvitedUsersArray = invitedUsersArray.filter(user => user.hasOwnProperty("address"));
    
    // Create a new array to store filtered user objects
    const filteredInvitedUsersArray = [];

    // Use for...of loop to manually filter the array based on the "address" key
    for (const user of invitedUsersArray) {
      if (Object.keys(user).includes("address")) {
        filteredInvitedUsersArray.push(user);
      }
    }
    return filteredInvitedUsersArray;    
  } catch (error) {
    console.log("getAllUsersForTimeline Error:", error);
    throw error; 
  }
});
export const inviteAdmin = createAsyncThunk("template/inviteAdmin",async(data,thunkAPI)=> {
  try {
    const email = data.email;
    const apiData = {email:email,role:'admin'};
    let res = await axios.post(`${BACKENDURL}/api/role/createadmin`,apiData);
    console.log("res of inviteAdmin api call :",res);
    openNotificationWithIcon('success','Invitation Sent Successfully');
    return res.status;
  } catch(error) {
    console.log("inviteAdmin Error :",error)
    openNotificationWithIcon('error',error.response.data.message); // correct way to return error message from node api
  }
});

export const getAllAdmins = createAsyncThunk("template/getAllAdmins",async({page,limit,orderBy,sortBy},thunkAPI)=> {
  try {
    let res = await axios.get(`${BACKENDURL}/api/role/getadmin?page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`);
    console.log("res of getAllAdmins api call :",res);
    const adminArray = res.data.data;
    const totalAdmins = res.data.Count;
    // const adminArray = myArray.filter((user) => user.role === "admin");
    // console.log("Admin roles array :",adminArray);
    return {adminArray,totalAdmins};
  } catch(error) {
    console.log("getAllAdmins Error :",error)
  }
});

export const adminEmailToWalletAddress = createAsyncThunk("template/adminEmailToWalletAddress",async(data,thunkAPI)=> {
  try {
    let firstApi = await axios.get(`${BACKENDURL}/api/role/getAdmin?email=${data.email}`);
    let obj = firstApi.data.data[0];
    // const adminId = localStorage.getItem('adminId');
    // console.log("adminId earlier :",adminId);
    localStorage.setItem('adminId',obj._id);
    console.log("obj inside adminEmailToWalletAddress action :",obj);
    if (!obj.hasOwnProperty("address")) {
      let res = await axios.patch(`${BACKENDURL}/api/role/updateadmin`,data);
      console.log("res of adminEmailToWalletAddress api call :",res);
    }
    const adminEmail = data.email;
    const newObj = !obj.hasOwnProperty('address') && await axios.get(`${BACKENDURL}/api/role/getAdmin?email=${adminEmail}`);
    return !obj.hasOwnProperty('address') ? newObj : obj;
  } catch(error) {
    openNotificationWithIcon('error',`adminEmailToWalletAddress error`);
  }
});

export const deployedEWarrentForAdmin = createAsyncThunk("template/deployedEWarrentForAdmin",async(data,thunkAPI)=> {
  try {
      let res = await axios.patch(`${BACKENDURL}/api/role/updateadmin`,data);
      console.log("res of deployedEWarrentForAdmin api call :",res);
      openNotificationWithIcon('success','xVault Deployment Successful');
      return res.status;
  } catch(error) {
    openNotificationWithIcon('error',`xVault Deployment Unsuccessful : API error`);
  }
});

export const transferredEWarrentOwnership = createAsyncThunk("template/transferredEWarrentOwnership",async(data,thunkAPI)=> {
  try {
      const arrayLength = data.length;
      let i;
      for(i=0 ; i<arrayLength ; i++) {
        let res = await axios.patch(`${BACKENDURL}/api/role/updateadmin`,data[i]);
        console.log("res of transferredEWarrentOwnership api call :",res);
      }
      openNotificationWithIcon('success','xVault Ownership Transfer Successful');
      return 200;
  } catch(error) {
    openNotificationWithIcon('error',`xVault Ownership Transfer Unsuccessful : API error`);
  }
});

export const getAllUsersInvitedByAdmin = createAsyncThunk("template/getAllUsersInvitedByAdmin",async({page,orderBy,sortBy,limit},thunkAPI)=> {
  try {
    const admin_id = localStorage.getItem('adminId');
    let res = sortBy!=='' ? await axios.get(`${BACKENDURL}/api/role/getAdminUserByid?id=${admin_id}&page=${page}&orderBy=${orderBy}&sortBy=${sortBy}&limit=${limit}`) : await axios.get(`${BACKENDURL}/api/role/getAdminUserByid?id=${admin_id}&page=${page}&orderBy=${orderBy}&limit=${limit}`);
    const myArray = res.data.data;
    const filteredInvitedUsersArray = [];
    for (const user of myArray) {
      if (Object.keys(user).includes("address")) {
        filteredInvitedUsersArray.push(user);
      }
    }
    return {allInvitedUsers:myArray};
  } catch(error) {
    console.log("getAllUsersInvitedByAdmin Error :",error)
  }
});

// temporary fix to get onboarded users when admin create 
export const getAllOnboardedUsersByAdmin = createAsyncThunk("template/getAllOnboardedUsersByAdmin",async(_,thunkAPI)=> {
  try {
    const admin_id = localStorage.getItem('adminId');
    let res = await axios.get(`${BACKENDURL}/api/role/getAdminUserByid?id=${admin_id}`);
    const myArray = res.data.data;
    const filteredInvitedUsersArray = [];
    for (const user of myArray) {
      if (Object.keys(user).includes("address")) {
        filteredInvitedUsersArray.push(user);
      }
    }
    return filteredInvitedUsersArray;
  } catch(error) {
    console.log("getAllOnboardedUsersByAdmin Error :",error)
  }
});

// optimized api ( to be created )
export const getOnboardedUsersByAdminId = createAsyncThunk("template/getOnboardedUsersByAdminId",async(_,thunkAPI)=> {
  try {
    const admin_id = localStorage.getItem('adminId');
    let res = await axios.get(`${BACKENDURL}/api/role/getOnboardedUsersByAdminId?id=${admin_id}`);
    const onboardedUsers = res.data.data;
    return onboardedUsers;
  } catch(error) {
    console.log("getOnboardedUsersByAdminId Error :",error)
  }
});

export const invitedUserEmailToAddress = createAsyncThunk("template/invitedUserEmailToAddress",async(data,thunkAPI)=> {
  try {
    console.log("inside invitedUserEmailToAddress action")
    const userEmail = data.email;
    let res = await axios.get(`${BACKENDURL}/api/role/getUser?email=${userEmail}`);
    let obj = res.data.data[0];
    localStorage.setItem('userId',obj._id);
    console.log("invited user object :",obj);
    const newAPIData = {...data,id:obj._id};
    console.log("patch api data to update invited user's address :",newAPIData);
    if (!obj.hasOwnProperty("address")) {
      await axios.patch(`${BACKENDURL}/api/role/updateuser`,newAPIData);
    }
    let newRes = !obj.hasOwnProperty("address") && await axios.get(`${BACKENDURL}/api/role/getUser?email=${userEmail}`);
    const newObj = !obj.hasOwnProperty("address") ? newRes.data.data[0] :"address already exists";
    return !obj.hasOwnProperty("address") ? newObj : obj;
  } catch(error) {
    console.log("invitedUserEmailToAddress Error :",error)
  }
});

export const getAdminsForUser = createAsyncThunk("template/getAdminsForUser",async(data,thunkAPI)=> {
  try {
    const email = localStorage.getItem('email');
    const res = await axios.get(`${BACKENDURL}/api/role/getUser?email=${email}`);
    console.log("userObject :",res.data.data[0]);
    const adminArray = res.data.data[0].Admin_id;
    thunkAPI.dispatch(getAdminDetailsByAdminId(adminArray));
    return res.data.data[0].Admin_id;
  } catch(error) {
    console.log("getAdminsForUser Error :",error);
  }
});

export const getAdminDetailsByAdminId = createAsyncThunk("template/getAdminDetailsByAdminId",async(data,thunkAPI)=> {
  try {
    const arrayLength = data.length;
    let i = 0;
    const arrayObj = [];
    for(i=0;i<arrayLength;i++){
      const id = data[i];
      let res = await axios.get(`${BACKENDURL}/api/role/getAdminByid?id=${id}`);
      console.log("admin details of this user :",res.data.data[0]);
      arrayObj.push(res.data.data[0]);
    } 
    console.log("array obj:",arrayObj);
    return arrayObj;
  } catch(error) {
    console.log("getAdminDetailsByAdminId Error :",error)
  }
});

export const geteWarrentAddressByAdminId = createAsyncThunk("template/geteWarrentAddressByAdminId",async(data,thunkAPI)=> {
  try {
      let res = await axios.get(`${BACKENDURL}/api/role/getAdminByid?id=${data}`);
      console.log("admin details of this user :",res.data.data[0]);
      const contract_address = res.data.data[0].contract_address;
      return contract_address;
  } catch(error) {
    console.log("geteWarrentAddressByAdminId Error :",error)
  }
});


export const inviteExternalUser = createAsyncThunk("template/inviteExternalUser",async(data,thunkAPI)=> {
  try {
    const userId = localStorage.getItem('userId');
    const email = data.email;
    const apiData = {email,userId};
    let res = await axios.post(`${BACKENDURL}/api/role/createExternalUser`,apiData);
    console.log("res of inviteExternalUser api call :",res);
    res.status === 200 && openNotificationWithIcon('success',`Invitation Sent to ${email}`);
    return res.status;
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});

export const allExternalUsers = createAsyncThunk("template/allExternalUsers",async(data,thunkAPI)=> {
  try {
    const id = localStorage.getItem('userId');
    let res = await axios.get(`${BACKENDURL}/api/role/getexternaluserByid?id=${id}`);
    console.log("res of allExternalUsers api call :",res.data.data);
    const resObj=res.data.data[0];
    const externalUserArray = resObj.External_users;
    const filteredExternalUsers = externalUserArray?.map(user => {
      const filteredUser = {
          email: user.email,
          address:user.address
      };
      // if (user.address) {
      //     filteredUser.address = user.address;
      // }
      return filteredUser;
    });
    console.log("filtered external users :",filteredExternalUsers);
    return filteredExternalUsers;
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});

export const checkExternalUser = createAsyncThunk("template/checkExternalUser",async(data,thunkAPI)=> {
  try {
    let res = await axios.get(`${BACKENDURL}/api/role/checkExternalEmail?email=${data}`);
    console.log("res of checkExternalUser api call :",res.data);
    const isInvited = res.data.invited;
    console.log("is logged user external user :",isInvited);
    isInvited===true && thunkAPI.dispatch(externalUserEmailToAddress(data));
    return isInvited;
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});


export const externalUserEmailToAddress = createAsyncThunk("template/externalUserEmailToAddress",async(data,thunkAPI)=> {
    try {
      const address = localStorage.getItem('user');
      let res = await axios.get(`${BACKENDURL}/api/role/getExternalUser?email=${data}`);
      console.log("res of externalUserEmailToAddress api call :",res.data.data[0]);
      const obj = res.data.data[0];
      const apiData = {address,id:obj._id}
      if (!obj.hasOwnProperty("address")) {
        let res = await axios.patch(`${BACKENDURL}/api/role/updateExternalUser`,apiData);
        console.log("res of adminEmailToWalletAddress api call :",res.data.message);
      }
      const newRes = !obj.hasOwnProperty('address') && await axios.get(`${BACKENDURL}/api/role/getExternalUser?email=${data}`);
      const newObj = newRes.data.data[0];
      console.log("newObj logged :",newObj);
      return !obj.hasOwnProperty('address') ? newObj : obj;
    } catch(error) {
      openNotificationWithIcon('error',error.response.data.message);
    }
});

export const getAdminsOfUserByUserAddress = createAsyncThunk("template/getAdminsOfUserByUserAddress",async(data,thunkAPI)=> {
  try {
    let res = await axios.get(`${BACKENDURL}/api/role/getAdminsbyUserAddress?address=${data}`);
    console.log("res of getAdminsOfUserByUserAddress api call :",res.data);
    const adminArray = res.data.Admins;
    thunkAPI.dispatch(getAdminDetailsByAdminId(adminArray));
    return {adminArray ,adminArrayLength:res.data.length};
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});

export const saveSetUserEvent = createAsyncThunk("template/saveSetUserEvent",async(data,thunkAPI)=> {
  try {
    let res = await axios.post(`${BACKENDURL}/api/nft/saveuser`,data);
    console.log("res of saveSetUserEvent api call :",res);
    return res.status;
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});

export const getUserAccessHistory =  createAsyncThunk("template/getUserAccessHistory",async({user,page,limit,orderBy},thunkAPI)=> {
  try {
    let res = await axios.get(`${BACKENDURL}/api/nft/getuser?search=${user}&limit=${limit}&page=${page}&orderBy=${orderBy}`);
    console.log("res of getUserAccessHistory api call :",res.data.data);
    const userTokenHistoryArray = res.data.data;
    const userTokenHistoryCount = res.data.Count;
    return {userTokenHistoryArray,userTokenHistoryCount};
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});

export const saveExternalUserAccessSignature = createAsyncThunk("template/saveExternalUserAccessSignature",async(data,thunkAPI)=> {
  try {
    let res = await axios.post(`${BACKENDURL}/api/sign/saveExternalUserSignature`,data);
    console.log("res of saveSetUserEvent api call :",res);
    return res.status;
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});

export const getExternalUserAccessSignatureHistory =  createAsyncThunk("template/getExternalUserAccessSignatureHistory",async({user,page,limit,orderBy},thunkAPI)=> {
  try {
    let res = await axios.get(`${BACKENDURL}/api/sign/getExternalUserSignatureHistory?search=${user}&page=${page}&limit=${limit}&orderBy=${orderBy}`);
    console.log("res of getUserAccessHistory api call :",res.data.data);
    const userAccessHistoryArray = res.data.data;
    const userAccessHistoryCount = res.data.Count;
    return {userAccessHistoryArray,userAccessHistoryCount};
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});

export const getUserTokenAccessHistory =  createAsyncThunk("template/getUserTokenAccessHistory",async({user,page,limit,orderBy},thunkAPI)=> {
  try {
    let res = await axios.get(`${BACKENDURL}/api/nft/getuser?search=${user}&page=${page}&limit=${limit}&orderBy=${orderBy}`);
    console.log("res of getUserAccessHistory api call :",res.data.data);
    const userTokenAccessHistoryArray = res.data.data;
    const userTokenAccessHistoryCount = res.data.Count;
    return {userTokenAccessHistoryArray,userTokenAccessHistoryCount};
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});

export const getUserTokenAccessSignatureHistory =  createAsyncThunk("template/getUserTokenAccessSignatureHistory",async({user,page,limit,orderBy},thunkAPI)=> {
  try {
    let res = await axios.get(`${BACKENDURL}/api/sign/getExternalUserSignatureHistory?search=${user}&page=${page}&limit=${limit}&orderBy=${orderBy}`);
    const userTokenAccessSignatureHistoryArray = res.data.data;
    const userTokenAccessSignatureHistoryCount = res.data.Count;
    return {userTokenAccessSignatureHistoryArray,userTokenAccessSignatureHistoryCount};
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});

// api for patching attestation of workflow creation and edit
export const addWorkflowCreationAttestation =  createAsyncThunk("template/addWorkflowCreationAttestation",async(data,thunkAPI)=> {
  try {
    const adminId = localStorage.getItem('adminId');
    const reqData = {location:data.location , attestationUId:data.attestationUId , id:data.id , version:data.version};
    let res = await axios.post(`${BACKENDURL}/api/templates/createAttestation`,reqData);
    // problem !!! why dispatching getTemplates api here ?
    await thunkAPI.dispatch(getTemplates({adminId,sortBy:data.sortBy,orderBy:data.orderBy,page:data.page,limit:data.limit}));
    console.log("res of create template attestation api :",res);
    res.status === 200 && openNotificationWithIcon('success',`Workflow ${data.action} Successfully `);
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});

export const addWorkflowAttestation =  createAsyncThunk("template/addWorkflowAttestation",async(data,thunkAPI)=> {
  try {
    let res = await axios.post(`${BACKENDURL}/api/attestation/create`,data);
    console.log("res of addWorkflowAttestation  api :",res);
    res.status === 200 && openNotificationWithIcon('success',`Workflow ${data.action} Successfully`);
  } catch(error) {
    openNotificationWithIcon('error',error.response.data.message);
  }
});

export const getWorkflowAttestationEvents = createAsyncThunk("template/getWorkflowAttestationEvents",async(data,thunkAPI)=> {
  try {
    console.log("data received for saving event :",data)
    let res = await axios.get(`${BACKENDURL}/api/attestation/list?formId=${data}`);
    console.log("res of get workflow attestation events :",res.data.data.attestations);
    return res.data.data.attestations;
  } catch(error) {
    openNotificationWithIcon('error',`${error}`);
  }
});

export const getWorkflowsForSubmission = createAsyncThunk("template/getWorkflowsForSubmission",async({contract_address,limit,orderBy,sortBy,page},thunkAPI)=> {
  try {
    const ID = localStorage.getItem('userId');
    const request = 'Submission';
    let res = await axios.get(`${BACKENDURL}/api/survey/getWorkflowName?ID=${ID}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&page=${page}&request=${request}`);
    console.log("res of getWorkflowsForSubmission api :",res);
    const totalWorkflowsForSubmission = res.data.Count;
    const workflowArray = res.data.workflowData;
    return {workflowArray,totalWorkflowsForSubmission};
  } catch(error) {
    openNotificationWithIcon('error',`${error}`);
  }
});

export const getWorkflowsForApproval = createAsyncThunk("template/getWorkflowsForApproval",async({contract_address,limit,orderBy,sortBy,page},thunkAPI)=> {
  try {
    const ID = localStorage.getItem('userId');
    const request = 'Approver';
    let res = await axios.get(`${BACKENDURL}/api/survey/getWorkflowName?ID=${ID}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&page=${page}&request=${request}`);
    console.log("res of getWorkflowsForApproval api :",res);
    const totalWorkflowsForApproval = res.data.Count;
    const workflowArray = res.data.workflowData;
    return {workflowArray,totalWorkflowsForApproval};
  } catch(error) {
    openNotificationWithIcon('error',`${error}`);
  }
});

export const adminGetWorkflowsForVerifying = createAsyncThunk("template/adminGetWorkflowsForVerifying",async({limit,orderBy,sortBy,page},thunkAPI)=> {
  try {
    const ID = localStorage.getItem('adminId');
    const request = 'byAdmin';
    let response = await axios.get(`${BACKENDURL}/api/role/getAdminByid?id=${ID}`);
		console.log("admin details of this user :",response.data.data[0]);
		const contract_address = response.data.data[0].contract_address;
    console.log("contract address logged :",contract_address);
    let res = await axios.get(`${BACKENDURL}/api/survey/getWorkflowName?ID=${ID}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&page=${page}&request=${request}`);
    console.log("res of adminGetWorkflowsForVerifying api :",res);
    const totalWorkflowsCreatedByAdmin = res.data.Count;
    const workflowArray = res.data.workflowData;
    return {workflowArray,totalWorkflowsCreatedByAdmin};
  } catch(error) {
    openNotificationWithIcon('error',`${error}`);
  }
});

export const getWorkflowsForViewing = createAsyncThunk("template/getWorkflowsForViewing",async({contract_address,limit,orderBy,sortBy,page},thunkAPI)=> {
  try {
    const ID = localStorage.getItem('userId');
    const request = 'Viewership';
    let res = await axios.get(`${BACKENDURL}/api/survey/getWorkflowName?ID=${ID}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&page=${page}&request=${request}`);
    console.log("res of getWorkflowsForApproval api :",res);
    const totalWorkflowsForViewing = res.data.Count;
    const workflowArray = res.data.workflowData;
    return {workflowArray,totalWorkflowsForViewing};
  } catch(error) {
    openNotificationWithIcon('error',`${error}`);
  }
});

export const superadminGetWorkflowsByAdminId = createAsyncThunk("template/superadminGetWorkflowsByAdminId",async({adminId,limit,orderBy,sortBy,page},thunkAPI)=> {
  try {
    const request = 'byAdmin';
    let res = await axios.get(`${BACKENDURL}/api/survey/getWorkflowName?ID=${adminId}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&page=${page}&request=${request}`);
    console.log("res of adminGetWorkflowsForVerifying api :",res);
    const totalWorkflowsCreatedByAdmin = res.data.Count;
    const workflowArray = res.data.workflowData;
    return {workflowArray,totalWorkflowsCreatedByAdmin};
  } catch(error) {
    openNotificationWithIcon('error',`${error}`);
  }
});

export const updateWorkflowStatusAPI = createAsyncThunk("template/updateWorkflowStatusAPI",async(data,thunkAPI)=> {
  try {
    let res = await axios.patch(`${BACKENDURL}/api/survey/updateStatusSurvey`,data);
    console.log("res updateWorkflowStatusAPI :",res);
    return res.status===200 ? true : false;
  } catch(error) {
    openNotificationWithIcon('error',`${error}`);
  }
})