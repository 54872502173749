import { createSlice } from "@reduxjs/toolkit";
import { externalUserSignAccessSurveyMessage, getERC4907ContractUserData,mintNFTERC4907,setUserERC4907,userOfERC4907Token} from "../actions/erc4907Contract";
import { openNotificationWithIcon } from "../../utils/openNotificationWithIcon";

const erc4907ContractSlice = createSlice({
    name:'erc4907Contract',
    initialState:{
        isERC4907ContractUserDataLoading:false,
        tokenIdsToRespectiveUsers:[],
        isERC4907NFTMinted:false,
        isMintERC4907Loading:false,
        isUserOfERC4907TokenLoading:false,
        ERC4907TokenUser:'',
        ERC4907TokenOwner:'',
        ERC4907TokenUserExpiry:0,
        isAccessTokenAuthorizationFailed:false,
        isERC4907SetUserLoading:false,
        isERC4907UserSetSuccess:false,
        ERC4907TokenId:0,
        isExternalUserSigningAccessSurveyMessage:false,
        externalUserSignedAccessSurveyMessage:false,
    },
    reducers:{
        stopUserOfTokenButtonLoading:(state,{payload})=>{
            state.isUserOfERC4907TokenLoading=false;
        },
        resetTokenUserData:(state,{payload})=>{
            state.isUserOfERC4907TokenLoading = false;
            state.ERC4907TokenUser = '';
            state.ERC4907TokenOwner = '';
            state.ERC4907TokenUserExpiry = 0;
            state.ERC4907TokenId = 0;
            state.externalUserSignedAccessSurveyMessage = false;
            state.isAccessTokenAuthorizationFailed = false;
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getERC4907ContractUserData.pending,(state,action) => {
            state.isERC4907ContractUserDataLoading = true;
            state.tokenIdsToRespectiveUsers = [];
        })
        .addCase(getERC4907ContractUserData.fulfilled,(state,{payload}) => {
            state.isERC4907ContractUserDataLoading = false;
            state.tokenIdsToRespectiveUsers = payload;
        })
        .addCase(getERC4907ContractUserData.rejected,(state,action) => {
            state.isERC4907ContractUserDataLoading = false;
        })
        .addCase(mintNFTERC4907.pending,(state,action) => {
            state.isMintERC4907Loading = true;
            state.isERC4907NFTMinted = false;
        })
        .addCase(mintNFTERC4907.fulfilled,(state,{payload}) => {
            state.isMintERC4907Loading = false;
            state.isERC4907NFTMinted = payload;
            payload===true && openNotificationWithIcon('success','Minted Access NFT');
        })
        .addCase(mintNFTERC4907.rejected,(state,action) => {
            state.isMintERC4907Loading = false;
            state.isERC4907NFTMinted = false;
        })
        .addCase(userOfERC4907Token.pending,(state,{payload}) => {
            state.isUserOfERC4907TokenLoading = true;
            state.ERC4907TokenUser = '';
            state.ERC4907TokenOwner = '';
            state.ERC4907TokenUserExpiry = 0;
            state.ERC4907TokenId = 0;
            state.isAccessTokenAuthorizationFailed = false;
        })
        .addCase(userOfERC4907Token.fulfilled,(state,{payload}) => {
            state.isUserOfERC4907TokenLoading = false;
            state.ERC4907TokenUser = payload && payload.ERC4907TokenUser;
            state.ERC4907TokenOwner = payload && payload.ERC4907TokenOwner;
            state.ERC4907TokenUserExpiry = payload && payload.ERC4907TokenUserExpiry;
            state.ERC4907TokenId = payload && payload.ERC4907TokenId;
            state.isAccessTokenAuthorizationFailed = payload.tokenAuthorizationResult===false ? true : false;
        })
        .addCase(userOfERC4907Token.rejected,(state,{payload}) => {
            state.isUserOfERC4907TokenLoading = false;
            state.isAccessTokenAuthorizationFailed = true;
        })
        .addCase(setUserERC4907.pending,(state,{payload}) => {
            state.isERC4907SetUserLoading = true;
            state.isERC4907UserSetSuccess = false;
        })
        .addCase(setUserERC4907.fulfilled,(state,{payload}) => {
            state.isERC4907SetUserLoading = false;
            state.isERC4907UserSetSuccess = payload;
        })
        .addCase(setUserERC4907.rejected,(state,{payload}) => {
            state.isERC4907SetUserLoading = false;
        })
        .addCase(externalUserSignAccessSurveyMessage.pending,(state,{payload}) => {
            state.isExternalUserSigningAccessSurveyMessage = true;
            state.externalUserSignedAccessSurveyMessage = false;
        })
        .addCase(externalUserSignAccessSurveyMessage.fulfilled,(state,{payload}) => {
            state.isExternalUserSigningAccessSurveyMessage = false;
            state.externalUserSignedAccessSurveyMessage = payload;
        })
        .addCase(externalUserSignAccessSurveyMessage.rejected,(state,{payload}) => {
            state.isExternalUserSigningAccessSurveyMessage = false;
        })
    }
})
export const {stopUserOfTokenButtonLoading,resetTokenUserData,checkValidTokenUserComponentFormReset} = erc4907ContractSlice.actions;
export default erc4907ContractSlice.reducer;