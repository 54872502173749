import { createSlice } from "@reduxjs/toolkit";
const initialState= {
    surveyName:'',
    surveyId:null, // mongoDB _id field
    formId:null, // generated Id from Math.random()
    submitter:"",
    approvers:[],
    approver:'',
    reviewButtonVisible:false,
    approverFormVisible:false,
    submitterFormVisible:false,
    current:0,
    confirmations:null,
    approverAddrArr:[],
    submitterAddr:'',
    surveyNameAssignSurvey:'',
    referencedWorkflowAttestationUId:'',
    dashboardCurrentPage:1,
    dashboardCardsPerPage:12,
    arrayOrderBy:'createdAt',
    arraySortBy:'DESC',
    assignedWorkflowsCurrentPage:1,
    assignedWorkflowsCardsPerPage:12,
    assignedWorkflowsOrderBy:'createdAt',
    assignedWorkflowsSortBy:'DESC'
};
const adminSlice = createSlice({
    name:"adminForm",
    initialState: initialState,
    reducers:{
        setFormId:(state,action)=>{
            state.formId = action.payload.data
            state.submitterFormVisible = action.payload.isVisible;
        },
        setSubmitter:(state,action)=>{
            state.submitter = action.payload.data;
            state.approverFormVisible = action.payload.isVisible;
        },
        setApprover:(state,action)=>{
            state.approver = action.payload.data
            state.reviewButtonVisible = action.payload.isVisible;
        },
        setCurrent:(state,action)=> {
            state.current = action.payload
        },
        setFormDataRedux:(state,{payload}) => {
            console.log("payload of setFormDataRedux :",payload);
            state.surveyId = payload.surveyId;
            state.submitter = payload.submitter; // email of submitter
            state.approvers = payload.approvers; // email array of approvers
            state.submitterAddr = payload.submitterAddr; // address of submitter
            state.approverAddrArr = payload.approverAddressArray; // address array of approvers
            state.confirmations = payload.confirmations;
            state.surveyName = payload.surveyName;
            state.workflowVersion = payload.workflowVersion;
            state.referencedWorkflowAttestationUId = payload.referencedWorkflowAttestationUId;
        },
        assignSurveyData:(state,{payload}) => {
            state.current = payload.current;
            state.surveyNameAssignSurvey = payload.surveyNameAssignSurvey;
        },
        resetFormDataRedux:(state,{payload}) => {
            state.surveyId = null;
            state.submitter = "";
            state.approvers = [];
            state.approverEmails = [];
            state.confirmations = null;
            state.surveyName = '';
        },
        resetWorkflowCreationRelatedStates : () => initialState,
        setDashboardCurrentPage : (state,{payload})=>{
            state.dashboardCurrentPage = payload;
        },
        setDashboardCardsPerPage : (state,{payload})=>{
            state.dashboardCardsPerPage = payload;
        },
        setDashboardCardsArraySortBy : (state,{payload})=>{
            state.arraySortBy = payload;
        },
        setAssignedWorkflowCurrentPage : (state,{payload})=>{
            state.assignedWorkflowsCurrentPage = payload;
        },
        setAssignedWorkflowCardsPerPage : (state,{payload})=>{
            state.assignedWorkflowsCardsPerPage = payload;
        },
        setAssignedWorkflowsSortBy : (state,{payload})=>{
            state.assignedWorkflowsSortBy = payload;
        }
    },
});
export const {setFormId,setApprover,setSubmitter,setCurrent,setFormDataRedux,assignSurveyData,resetFormDataRedux,resetWorkflowCreationRelatedStates,setDashboardCurrentPage,setDashboardCardsPerPage,setDashboardCardsArraySortBy,setAssignedWorkflowCurrentPage,setAssignedWorkflowCardsPerPage,setAssignedWorkflowsSortBy} = adminSlice.actions;
export default adminSlice.reducer;