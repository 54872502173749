import { useDispatch, useSelector } from "react-redux";
import {
    Drawer,
    Input,
    Select,
    Form,
    Button,
    Radio,
    Col,
    Row,
    Checkbox,
    DatePicker,
    Upload
  } from "antd";
  import { useState, useRef } from "react";
import {UploadOutlined} from "@ant-design/icons";
import SignatureCanvas from "react-signature-canvas";
import Editor from "./Editor"
import React from 'react'
import { templateDrawer } from "../../redux/slices/templateSlice";
import "./Drawer.scss"

const { Option } = Select;
const { TextArea } = Input;

const TemplateDrawer = () => {

  const canvasRef = useRef(null);
  const [content, setContent] = useState("");
  const dispatch = useDispatch();
  const {isDrawerVisible,DrawerData} = useSelector((state)=>state.template);
  console.log("DrawerData :",DrawerData);
  const latestWorkflowVersion = DrawerData.templatesVersion[DrawerData.templatesVersion?.length - 1];
  console.log("latestWorkflowVersion :",latestWorkflowVersion);
  const [drawerData,setDrawerData] = useState(latestWorkflowVersion);

  const countryCodeRegex = /^(\+?\d{1,3}|)$/; // Regex for country code (optional, allows + sign followed by 1 to 3 digits)
  const numberRegex = /^\d+$/; // Regex for phone number (only allows digits)
  const emailRegex = /^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
  // const prefixphoneNo = (editrecord) => (
  //   <Form.Item
  //     name="stdCode"
  //     noStyle
  //     initialValue={editrecord.stdCode ? editrecord.stdCode : "2"}
  //   >
  //     <Select
  //       style={{
  //         width: 70,
  //       }}
  //     >
  //       <Option value="1">+1</Option>
  //       <Option value="2">+91</Option>
  //     </Select>
  //   </Form.Item>
  // );
  const templateVersionsArray = DrawerData?.templatesVersion;
  console.log("templateVersionsArray logged here :",templateVersionsArray)
  const items = templateVersionsArray?.map((el)=>{return {label:`version ${el.versions}`,value:el.versions,key:el.versions}})
  console.log("items logged :",items)
  const handleChange = (value) => {
    console.log("clicked handlechange");
    console.log("value logged :",value);
    setDrawerData(templateVersionsArray[value-1]);
  }
  const versionSelect = <Select
  defaultValue={items[templateVersionsArray?.length-1]}
  style={{
    width: 120,
  }}
  onChange={handleChange}
  options={items}
  />

  return (
    <Drawer 
    // style={{backgroundColor:"#f7efbc"}}
    title={drawerData?.name}
    open={isDrawerVisible}
    // width={"50%"}
    width={window.innerWidth > 900 ? 800 : window.innerWidth - 100}
    onClose={()=>{dispatch(templateDrawer({visible:false}));}}
    extra={versionSelect}
    >
    <div className="drawer">
            <Form
              labelAlign="left"
              disabled={true}
              layout="horizontal"
              autoComplete="off"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              {drawerData?.fields.map((field, index) => (
                <div key={index}>
                  <Row key={index}>
                    <Col span={24}>
                      <Form.Item
                        key={index}
                        label={
                          <>
                            <span className="label-h">{`${field.label} `}</span>                            
                          </>
                        }
                        name={field.label}
                        wrapperCol={12}
                        colon={false}
                        hasFeedback
                        rules={[
                          {
                            required: field.disabled,
                            message: `Please Enter ${field.label} `,
                          },
                        ]}
                      >
                        {(field.dataType === 0 && (
                          <Input
                            placeholder={field.placeholder || `Enter A ${field.label}`}
                            size="middle"
                            width={400}
                          />
                        )) ||
                        (field.dataType === 9 && (
                        <Input
                          // addonBefore={prefixphoneNo(editRecord)}
                          controls={false}
                          style={{ width: "100%" }}
                          placeholder={field.placeholder || `Enter A ${field.label}`}
                          maxLength={10}
                          minLength={10}
                        />
                        ))
                        ||
                        (field.dataType === 8 && (
                          <Input
                            placeholder={field.placeholder || `Enter A ${field.label}`}
                            size="middle"
                            rules={[
                              {
                                pattern:emailRegex,
                                message:'Invalid Email'
                              }
                            ]}
                            width={400}
                          />
                        ))
                        ||
                          (field.dataType === 4 && (
                            <Upload>
                              <Button icon={<UploadOutlined />}>
                                Click to Upload
                              </Button>
                            </Upload>
                          )) ||
                          (field.dataType === 1 && (
                            <TextArea
                              placeholder={
                                field.placeholder || `Enter A ${field.label}`
                              }
                              size="small"
                              width={200}
                            />
                          )) ||
                          (field.dataType === 10 && (
                            <>
                              <Editor
                                value={content}
                                onChange={setContent}
                                // placeholder={pointn}
                              />
                            </>
                          )) ||
                          (field.dataType === 11 && (
                            <>
                              <SignatureCanvas
                                penColor="blue"
                                canvasProps={{
                                  style: {
                                    cursor: `url('/assets/images/pen-cursor.png'), auto`,
                                    border: "1px solid black",
                                    width: "100%",
                                    height: "150px",
                                  },

                                  className: "sigCanvas",
                                }}
                                ref={canvasRef}
                              />
                              {/* <Button onClick={handleClear}>Clear</Button>
                              <Button onClick={handelsaves}>save</Button> */}
                            </>
                          )) ||
                          (field.dataType === 7 && (
                            <DatePicker
                              placeholder={`Enter ${field.label}`}
                              style={{ width: "100%" }}
                            ></DatePicker>
                          )) ||
                          (field.dataType === 3 && (
                            <Radio.Group>
                              {field.options.map((item, index) => (
                                <Radio key={index} value={item}>{item}</Radio>
                              ))}
                            </Radio.Group>
                          )) ||
                          (field.dataType === 5 && (
                            <Checkbox.Group>
                              {field.options.map((item, index) => (
                                <Checkbox key={item} value={item}>
                                  {item}
                                </Checkbox>
                              ))}
                            </Checkbox.Group>
                          )) ||
                          (field.dataType === 6 && field.typeOfOptions === "Single" && (
                            <Select placeholder={`Enter ${field.label}`}>
                              {field.options.map((item, index) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          )) ||
                          (field.dataType === 6 && field.typeOfOptions === "Multiple" && (
                            <Select
                              mode="multiple"
                              allowClear
                              placeholder={`Enter ${field.label}`}
                            >
                              {field.options.map((item, index) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          ))}
                      </Form.Item>
                    </Col>
                    {/* <Col span={1}></Col> */}
                    {/* if info is present in the field object , it takes that info , otherwise generates its own info */}
                    {/* <Col span={1}>
                      <Tooltip
                              title={
                                field.info ||
                                `Enter your ${
                                  field.label || field.heading || field.date
                                }`
                              }
                            >
                              <InfoCircleOutlined
                                style={{
                                  color: "#F99417",
                                  fontSize: "15px",
                                  cursor: "pointer",
                                }}
                              />
                      </Tooltip>
                    </Col> */}
                  </Row>
                </div>
              ))}
            </Form>
            </div>
    </Drawer>
  )
}

export default TemplateDrawer;