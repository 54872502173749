import { Button, Row , Col, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import './Home.scss';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/actions/web3Auth';
import Layout from '../../components/Layout/Layout';
const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
    const isSuperAdmin = JSON.parse(localStorage.getItem('isSuperAdmin'));
    const toAdminDashboard = () => {
        navigate("/admin-dashboard");
    }
    const toUserDashboard = () => {
        navigate("/dashboard");
    }
    const toSuperAdminDashboard = () => {
        navigate("/superadmin-dashboard");
    }
    const loginBeforeDashboard = async() => {
        dispatch(login()).then((res) => { 
            res.payload==="superadmin" ?  toSuperAdminDashboard() : ( res.payload==="admin" ? toAdminDashboard() : toUserDashboard() );
        });
    }
    const isLogin = JSON.parse(localStorage.getItem('isLogin'));
    const exploreHandler = () => {
        if(isLogin && isSuperAdmin===true) navigate('/superadmin-dashboard')
        else if(isLogin && (isSuperAdmin===false || isSuperAdmin===null || isSuperAdmin===undefined )&& isAdmin===true) navigate('/admin-dashboard') 
        else if(isLogin && isAdmin===false) navigate('/dashboard')
        else loginBeforeDashboard();
    }

    return (
        <Layout>
            <Row className='homepage'>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className='homepage-left'>
                    <div>
                        <div className='homepage-left-h1-main'>Securing AI vaults with Blockchain</div>
                        <p className='homepage-left-p'>
                            Leveraging the transparency and security of smart contracts is a game-changer when it comes to managing your organization's most critical intellectual property (IP) assets. 
                        </p>
                        <p className='homepage-left-p'>
                            xVault ensures the utmost trust and reliability in safeguarding your AI assets. Through automation and transparency , our platform streamlines IP management, ensuring that your AI assets are protected through strict role based access controls, collaborative controls, audits and anomaly detection, reducing the risk of unauthorized access. 
                        </p>    
                        <p className='homepage-left-p'>Rest assured, your valuable IP assets are protected with the highest level of security and accountability , allowing you to focus on innovation and growth. Make the smart choice for your IP asset management – choose xVault.
                        </p>
                        <Button className='homepage-left-p-btn' onClick={exploreHandler}>Explore</Button>
                        <div className='homepage-svg-container'>
                            <Tooltip title='Ethereum'><img src='./assets/svgs/ethereum-eth-logo.svg' alt='Ethereum logo' width='50' height='50' className='homepage-svg' /></Tooltip>
                            <Tooltip title='Polygon'><img src='./assets/svgs/polygon-matic-logo.svg' alt='Polygon logo' width='50' height='50' className='homepage-svg' /></Tooltip>
                            <Tooltip title='Avalanche'><img src='./assets/svgs/avalanche-avax-logo.svg' alt='Avalanche logo' width='50' height='50' className='homepage-svg' /></Tooltip>
                            <Tooltip title='Binance'><img src='./assets/svgs/binance-logo.svg' alt='Binance logo' width='50' height='50' className='homepage-svg' /></Tooltip>
                            <Tooltip title='Solana'><img src='./assets/svgs/solana-sol-logo.svg' alt='Solana logo' width='50' height='50' className='homepage-svg' /></Tooltip>
                        </div>
                    </div>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12} className='homepage-right'> 
                    <img src='./assets/images/homepage-img.png' alt='homepage contract pic' className='homepage-right-img'/>
                </Col>
            </Row>
        </Layout>
    );
}
export default Home;