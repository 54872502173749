import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Radio, Row, Select, Space, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import {valueaddedonChange2} from "../../redux/slices/templateSlice";
import SurveyComments from '../../components/SurveyComments/SurveyComments';
import { submitRejectedPDF } from '../../redux/actions/eWarrant';
import SurveyTimeline from '../../components/SurveyTimeline/SurveyTimeline';
import dayjs from 'dayjs';
import AttestationTimeline from '../../components/AttestationTimeline/AttestationTimeline';
import "./SubmitSurveyPage.scss";

const { Option } = Select;

const RejectedSurveyPage = ({formId,contract_address,_id}) => {
  const [form] = Form.useForm();
  dayjs.locale('es');
  const {isPDFResubmitLoading} = useSelector((state)=> state.contract);
  const {submittedSurveyUIData,isSurveySubmitButtonClickedRejectedSurvey,attestationTimelineData} = useSelector((state)=>state.template);
  const dispatch = useDispatch();
  const onFinish = (values) => {
    const data = {
      formId:formId,
      name:submittedSurveyUIData.name,
      description:submittedSurveyUIData.description,
      fields:submittedSurveyUIData.fields,
      contract_address:contract_address,
      referencedAttestation : attestationTimelineData ? (attestationTimelineData[attestationTimelineData.length-1].location==="onchain" ? attestationTimelineData[attestationTimelineData.length-1].attestationUId  : "" ) : "",
      _id:_id
    }
    console.log("requiredData to be sent to the update api :",data);
    dispatch(submitRejectedPDF(data));
  };
	const [openCommentModal, setOpenCommentModal] = useState(false);
  const handleCommentCancel = () => {
	  setOpenCommentModal(false);
	};
  const showCommentModal = () => {
    setOpenCommentModal(true);
  };
  const [currentPage, setCurrentPage] = useState(0);
  const fieldsPerPage = 5;
  const totalPages = Math.ceil(submittedSurveyUIData.fields?.length / fieldsPerPage);
  const handleNext = async() => {
    await form.validateFields();
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handleBack = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const startIndex = currentPage * fieldsPerPage;
  const endIndex = startIndex + fieldsPerPage;
  const pageFields = submittedSurveyUIData.fields?.slice(startIndex, endIndex);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [isModal1Open, setIsModal1Open] = useState(false);
  const showModal1 = () => {
    setIsModal1Open(true);
  };
  const handleOk1 = () => {
    setIsModal1Open(false);
  };
  const handleCancel1 = () => {
    setIsModal1Open(false);
  };
  const validateEmail = (_, value) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid email address.'));
  };


  return (
    Object.keys(submittedSurveyUIData).length > 0 &&
    
      <Row justify={'center'} className='height-100perc align-items-center'>
        <Col span={10} className='left-column'>
                      <div className='left-column-title'>Name : <span>{submittedSurveyUIData.name}</span></div>
                      <div className='left-column-desc'>Description : <span>{submittedSurveyUIData.description}</span></div>
                      <br/>
                      <Space direction="vertical" size="large">
                        <Button type='primary' className='brown-black-btn w-220 opacity-point8' onClick={showModal}>Workflow Timeline</Button>
                        <Button type='primary' className='brown-black-btn w-220 opacity-point8' onClick={showModal1}>Attestation Timeline</Button>
                        <Button type="primary" className='brown-black-btn w-220 opacity-point8' onClick={showCommentModal}>View Comments</Button>
                      </Space>
                      <Modal
                          title="Approver Comments"
                          open={openCommentModal}
                          onCancel={handleCommentCancel}
                          footer={false}
                          maskClosable={false}
                        >
                          <SurveyComments comments={submittedSurveyUIData?.reason}/>
                      </Modal>
                      <Modal footer={false} title="Workflow Timeline" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}    bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                          <SurveyTimeline/>
                      </Modal>
                      <Modal footer={false} title="Attestation Timeline" open={isModal1Open} onOk={handleOk1} onCancel={handleCancel1} bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                        <AttestationTimeline formId={formId}/>
                      </Modal>
        </Col>
        <Col span={14} className='right-column survey-form-main'>
          <Form
                        form={form}
                        id="my-form"
                        onFinish={onFinish}
                        labelAlign="left"
                        layout="horizontal"
                        autoComplete="off"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        {pageFields?.map((field, index) => (

                            <Row key={field._id}>
                              <Col span={24}>
                                <Form.Item
                                  initialValue={field.dataType===7 ? dayjs(field.value) : field.value}
                                  // initialValue={field.value}
                                  label={
                                    <>
                                      <span className="label-h">{field.label}</span>                            
                                    </>
                                  }
                                  name={field.label}
                                  wrapperCol={12}
                                  colon={false}
                                  hasFeedback
                                  rules={
                                    field.dataType === 8
                                      ? [
                                          {
                                            required: field.required,
                                            message: `Please Enter ${field.label} `,
                                          },
                                          { validator: validateEmail }
                                        ]
                                      : [
                                          {
                                            required: field.required,
                                            message: `Please Enter ${field.label} `,
                                          },
                                        ]
                                  }
                                >
                                  {(field.dataType === 0 && (
                                    <Input
                                      placeholder={field.placeholder || `Enter A ${field.label}`}
                                      onChange={(e)=>dispatch(valueaddedonChange2({_id:field._id,value:e.target.value}))}
                                      size="middle"
                                      width={400}
                                    />
                                  )) ||
                                  (field.dataType === 8 && (
                                    <Input
                                      placeholder={field.placeholder || `Enter A ${field.label}`}
                                      onChange={(e)=>dispatch(valueaddedonChange2({_id:field._id,value:e.target.value}))}
                                      size="middle"
                                      width={400}
                                    />
                                  ))
                                  ||
                                    (field.dataType === 4 && (
                                      <Upload 
                                      onChange={(e)=>dispatch(valueaddedonChange2({_id:field._id,value:e.file}))}
                                      >
                                        <Button icon={<UploadOutlined />}>
                                          Click to Upload
                                        </Button>
                                      </Upload>
                                    )) ||
                                    (field.dataType === 1 && (
                                      <TextArea
                                        placeholder={
                                          field.placeholder || `Enter A ${field.label}`
                                        }
                                      onChange={(e)=>dispatch(valueaddedonChange2({_id:field._id,value:e.target.value}))}

                                        size="small"
                                        width={200}
                                      />
                                    )) ||
                                    (field.dataType === 9 && (
                                      <InputNumber
                                        onChange={(value)=>dispatch(valueaddedonChange2({_id:field._id,value:value}))}
                                        controls={false}
                                        className='w-100perc'
                                        placeholder={field.placeholder || `Enter A ${field.label}`}
                                        maxLength={10}
                                        minLength={10}
                                      />
                                    ))
                                   ||
                                    (field.dataType === 7 && (
                                      <DatePicker
                                        placeholder={`Enter ${field.label}`}
                                        className='w-100perc'
                                        onChange={(value)=>dispatch(valueaddedonChange2({_id:field._id,value:value}))}
                                      ></DatePicker>
                                    )) ||
                                    (field.dataType === 3 && (
                                      <Radio.Group
                                      onChange={(e)=>dispatch(valueaddedonChange2({_id:field._id,value:e.target.value}))}
                                      >
                                        {field.options.map((item, index) => (
                                          <Radio value={item}>{item}</Radio>
                                        ))}
                                      </Radio.Group>
                                    )) ||
                                    (field.dataType === 5 && (
                                      <Checkbox.Group
                                      onChange={(e)=>dispatch(valueaddedonChange2({_id:field._id,value:e.target.value}))}
                                      >
                                        {field.options.map((item, index) => (
                                          <Checkbox key={item} value={item}>
                                            {item}
                                          </Checkbox>
                                        ))}
                                      </Checkbox.Group>
                                    )) ||
                                    (field.dataType === 6 && field.typeOfOptions === "Single" && (
                                      <Select placeholder={`Enter ${field.label}`} 
                                        onChange={(e)=>dispatch(valueaddedonChange2({_id:field._id,value:e.target.value}))}
                                      >
                                        {field.options.map((item, index) => (
                                          <Option key={item} value={item}>
                                            {item}
                                          </Option>
                                        ))}
                                      </Select>
                                    )) ||
                                    (field.dataType === 6 && field.typeOfOptions === "Multiple" && (
                                      <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder={`Enter ${field.label}`}
                                        onChange={(e)=>dispatch(valueaddedonChange2({_id:field._id,value:e.target.value}))}
                                      >
                                        {field.options.map((item, index) => (
                                          <Option key={item} value={item}>
                                            {item}
                                          </Option>
                                        ))}
                                      </Select>
                                    ))}
                                </Form.Item>
                              </Col>
                              {/* <Col span={1}></Col>                      */}
                            </Row>

                        ))}
                        <Form.Item>
                          {currentPage > 0 && (
                            <Button type="primary" onClick={handleBack} className='mr-1'>
                              Back
                            </Button>
                          )}
                          {currentPage < totalPages - 1 && (
                            <Button type="primary" onClick={handleNext} className='mr-1'>
                              Next
                            </Button>
                          )}
                          {currentPage === totalPages - 1 && (
                            <Button type="primary" htmlType="submit" loading={ isPDFResubmitLoading || isSurveySubmitButtonClickedRejectedSurvey }>
                              Submit
                            </Button>
                          )}
                        </Form.Item>
          </Form>
        </Col>
      </Row> 
  )
}

export default RejectedSurveyPage;