import { createAsyncThunk } from "@reduxjs/toolkit"; 
import { web3auth } from "../../utils/Web3Auth";
import Web3 from "web3";
import RPC from "../../web3RPC";
import { getBalanceOfUser, getContractData, getEthBalance, getPDFHashApprover } from "./eWarrant";
import { openNotificationWithIcon } from "../../utils/openNotificationWithIcon";
import axios from "axios";
// import {contractABI,contractAddress} from '../../utils/contractABIAndAddress';
import { EWarrentDeployerAddress , EWarrentDeployerABI } from '../../utils/EWarrentDeployerABIAndAddress';
import { resetWeb3AuthState } from "../slices/web3AuthSlice";

export const login = createAsyncThunk("auth/login",async(_,thunkAPI)=> {
    try{    
        const web3Auth = await web3auth();
        // console.log("web3auth redux action :",web3auth);
        if(!web3Auth) {
            console.log("web3auth not initialized yet")
            return;
        }
        const res = await web3Auth.connect();
        console.log("res login action homepage",res);
        const web3 = new Web3(res);       
        localStorage.setItem('isLogin',true);
        const fromAddress = (await web3.eth.getAccounts())[0];
        const EWarrentDeployerContract = new web3.eth.Contract(EWarrentDeployerABI,EWarrentDeployerAddress);
        const superAdmin = await EWarrentDeployerContract.methods.superAdmin().call();
        // const isUserAdmin = await EWarrentDeployerContract.methods.eWarrantContractDeployed(fromAddress).call();
        console.log("superAdmin :",superAdmin);
        localStorage.setItem('user',fromAddress);
        localStorage.setItem('isSuperAdmin',fromAddress===superAdmin);
        const userInformation = await web3Auth.getUserInfo();
        localStorage.setItem('name',userInformation.name);
        localStorage.setItem('email',userInformation.email);
        const checkInvitationObj = fromAddress!==superAdmin && await axios.get(`https://backend.xvault.thenftbrewery.com/api/role/checkEmail?email=${userInformation.email}`);
        const reqData = checkInvitationObj.data;
        if(reqData) {
          localStorage.setItem('isUserInvited',reqData.invited);
          localStorage.setItem('isAdmin',reqData.role==="admin" ? true : false);
        }
        console.log("checkInvitation :",checkInvitationObj);
        thunkAPI.dispatch(getEthBalance());
        openNotificationWithIcon("success","Login Successful","You are now logged in to the application.");
        const loggedInUserRole = fromAddress===superAdmin ? "superadmin": reqData.role;
        return loggedInUserRole;
    } catch(error) {
        console.log("login Error :",error);
        window.location.reload();
        thunkAPI.dispatch(resetWeb3AuthState());
        openNotificationWithIcon("error","Login Unsuccessful","Error when logging in.Try again!")
        return thunkAPI.rejectWithValue();
    }
});


export const logout = createAsyncThunk("auth/logout",async(_,thunkAPI)=> {
    try {
        const web3Auth = await web3auth();
        if (!web3Auth) {
          return;
        }
        await web3Auth.logout();
        localStorage.clear();
        // localStorage.removeItem('isLogin');
        // localStorage.removeItem('isAdmin');
        // localStorage.removeItem('user');
        // localStorage.removeItem('userrole');
        // localStorage.removeItem('name');
        // localStorage.removeItem('email');
        // localStorage.removeItem('isSuperAdmin');
        // localStorage.removeItem('adminId');
    }
    catch(error) {
      console.log("error logout appjsx :",error)
      openNotificationWithIcon("error","Logout Unsuccessful","Error in logging out.Try again!");
    }
});

export const user = createAsyncThunk(
  "auth/user",
  async (_, thunkAPI) => {
    try {
      const Web3auth = await web3auth();
      if (!Web3auth) {
        console.log("web3auth not initialized yet");
        return;
      }
      // const rpc = new RPC(Web3auth.provider);
      const user = await Web3auth.getUserInfo();
      localStorage.setItem('name',user.name);
      localStorage.setItem('email',user.email);
      // const chainId = await rpc.getChainId();
      // const address = await rpc.getAccounts();
      // const balance = await rpc.getBalance();
      // return {...user, chainId, address, balance}
      return user;
    } catch (error) {
      const message =
        error.response.data.error || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const getPrivateKey = createAsyncThunk("auth/getPrivateKey",async(data,thunkAPI)=> {
  try{    
    console.log("getPrivateKey started");
    console.log("web3Auth se pehle");
    const web3Auth = await web3auth(); // error !!!!
    console.log("web3Auth returns inside getPrivateKey :",web3Auth)
    if(!web3Auth) {
        console.log("web3auth not initialized yet")
        return;
    }
    const rpc = new RPC(web3Auth.provider);
    const privateKey = await rpc.getPrivateKey();
    console.log("logged user private key : ",privateKey);
    return privateKey;
  } catch(error) {
      console.log("error when getting private key :",error);
      return thunkAPI.rejectWithValue();
  }
}) 