import React, { useState , useRef, useEffect} from 'react'
import { Form, Radio,Button,Modal, Card, Input,Upload } from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import WebFont from 'webfontloader';
import { InboxOutlined } from '@ant-design/icons';
import './ElectronicSignature.scss';
import html2canvas from 'html2canvas';
import { useDispatch, useSelector } from 'react-redux';
import { approverSignatureUpload } from '../../redux/actions/template';
import { hashBlob } from '../../utils/attestationUtils';

const { Dragger } = Upload;

const ElectronicSignature = ({approverConfirmationCount,_id,loggedAccount,formId,contract_address}) => {
    const { isApproverSignatureUploading,attestationTimelineData } = useSelector(state => state.template);
    const {approverSignApprovedSurveyLoading} = useSelector((state)=>state.contract);
    const signatureRef = useRef(null);
    const dispatch = useDispatch();
    const userEmail = localStorage.getItem('email');
    const userName = localStorage.getItem('name');
    function base64ToBlob(base64) {
        const binaryString = window.atob(base64);
        console.log("binaryString :",binaryString);
        const length = binaryString.length;
        const bytes = new Uint8Array(length);
      
        for (let i = 0; i < length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
      
        return new Blob([bytes], { type: 'image/png' });
    }
      
    useEffect(() => {
        WebFont.load({
          google: {
            families: ['Qwitcher Grypen'] // Replace with the fonts you selected
          }
        });
    }, []);
    
    const [modal2Open, setModal2Open] = useState(false);
    const [form] = Form.useForm();
    const [selectedValue, setSelectedValue] = useState("type");
    
    const handleClear = () => {
        console.log("handleClear called ")
        signatureRef?.current.clear();
    };
    
    const handleRadioChange = (e) => {
      setSelectedValue(e.target.value);
    };
    const [typedText, setTypedText] = useState('');
    const handleTextChange = (e) => {
        setTypedText(e.target.value);
    };

    function convertFileToBlob(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onloadend = () => {
            const blob = new Blob([reader.result], { type: file.type });
            resolve(blob);
          };
      
          reader.onerror = () => {
            reject(new Error('Failed to convert file to Blob.'));
          };
      
          reader.readAsArrayBuffer(file);
        });
    }
    const [uploadedSignatureBlob,setUploadedSignatureBlob] = useState(null);

    function handleUploadChange(event) {
        const file = event.file;
      
        convertFileToBlob(file)
          .then(blob => {
            // Do something with the Blob
            console.log('Converted Blob: ' , blob);
            setUploadedSignatureBlob(blob); // setting to get it later , when submit is clicked action is dispatched
          })
          .catch(error => {
            // Handle the error
            console.error('Error converting file to Blob:', error);
          });
    }
      
    const penColorChoices = ['black','red','blue'];
    const [penColor,setPenColor] = useState('black');
    const drawOptionTitle = (
        <div className='flex-between'>
        <p>Draw Your Signature</p>
        <div className='flex-around-center'>
            {penColorChoices.map((color,index)=>(
                <div key={index} style={{width:"20px",height:"20px",borderRadius:'10px',backgroundColor:`${color}`,margin:'5px',cursor:'pointer',border: penColor === color ? '2px solid black' : 'none'}} onClick={()=>setPenColor(color)}></div>
            ))}
        </div>
        </div>
    )
    const convertToPng = async () => { // typedSignatureHandler ka kaam karta hai
        try {
          const typedSignature = document.querySelector('.typed-signature');
          const signatureFile = document.querySelector('.image-signature');
          const formData = new FormData();
      
          if (typedSignature || signatureFile) {
            const canvas = await html2canvas(typedSignature);
            console.log("canvas :",canvas)   
            const image = canvas.toDataURL('image/png').split(';base64,')[1];
            console.log("image is base64 :",image)
            // Do something with the converted image (e.g., save to local storage)
            const blob = base64ToBlob(image);
            console.log("blob :",blob);
            const blobHash = await hashBlob(blob);
            console.log("signature blob hash :",blobHash);
            formData.append('signature',blob);
            formData.append('formId',formId);
            formData.append('approver_address',loggedAccount);
            formData.append('approver_name',userName);
            formData.append('approver_email',userEmail);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            console.log("formData :",formData);
            dispatch(approverSignatureUpload({approverConfirmationCount,_id,formData,config,formId,contract_address,signatureBlob:blobHash,referencedAttestation : attestationTimelineData ? (attestationTimelineData[attestationTimelineData.length-1].location==="onchain" ? attestationTimelineData[attestationTimelineData.length-1].attestationUId  : "" ) : ""}));
            return image;
          } else {
            throw new Error('Typed signature element not found');
          }
        } catch (error) {
          console.error('Error converting to PNG:', error);
          throw error;
        }
    };
    const drawnSignatureHandler = async(unfilteredSignature) => {
        try {
          console.log("unfilteredSignature :",unfilteredSignature);
          const formData = new FormData();
          if (unfilteredSignature!=='') {
            const image = unfilteredSignature.split(';base64,')[1];
            console.log("drawn signature filtered :",image)
            const blob = base64ToBlob(image);
            console.log("blob :",blob)
            formData.append('signature',blob);
            const blobHash = await hashBlob(blob);
            console.log("signature blob hash :",blobHash);
            formData.append('formId',formId);
            formData.append('approver_address',loggedAccount);
            formData.append('approver_name',userName);
            formData.append('approver_email',userEmail);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            console.log("formData :",formData);
            dispatch(approverSignatureUpload({approverConfirmationCount,_id,formData,config,formId,contract_address,signatureBlob:blobHash,referencedAttestation : attestationTimelineData ? (attestationTimelineData[attestationTimelineData.length-1].location==="onchain" ? attestationTimelineData[attestationTimelineData.length-1].attestationUId  : "" ) : ""}));
            return image;
          } else {
            throw new Error('Typed signature element not found');
          }

        } catch (error) {
            console.error('Error converting to PNG:', error);
            throw error;
          }
    }
    const uploadedSignatureHandler = async() => {
        try{
                const blobHash = await hashBlob(uploadedSignatureBlob);
                console.log("signature blob hash :",blobHash);
                // Append image blob to formData
                const formData = new FormData();
                formData.append('signature', uploadedSignatureBlob);
                formData.append('formId', formId);
                formData.append('approver_address', loggedAccount);
                formData.append('approver_name',userName);
                formData.append('approver_email',userEmail);
                // Dispatch the action with formData
                const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
                };
                dispatch(approverSignatureUpload({approverConfirmationCount, _id,formData, config , formId,contract_address,signatureBlob:blobHash ,referencedAttestation : attestationTimelineData ? (attestationTimelineData[attestationTimelineData.length-1].location==="onchain" ? attestationTimelineData[attestationTimelineData.length-1].attestationUId  : "" ) : ""}));
        } catch(error) {
            console.log("Error in uploading signature file")
        }
    }
    const performRemainingActions = () => {
        setModal2Open(false);
        handleClear();
    }
    const onFinish = async() => {
        const values = await form.validateFields(); // consistent
        console.log("values :",values);

        // if signature is typed signature 
        values.radioGroup==='type' && await convertToPng();

        // if signature is drawn signature 
        values.radioGroup==='draw' && await drawnSignatureHandler(values?.canvasSignature);

        // if signature is uploaded signature
        values.radioGroup==='upload' && await uploadedSignatureHandler();

        performRemainingActions();
    } 
    return (
            <div>
                <Button size='large' loading={approverSignApprovedSurveyLoading ||isApproverSignatureUploading} type="primary" className='blue-black-btn w-220' onClick={() => setModal2Open(true)}>
                E-Signature
                </Button>
                <Modal
                    title="E-Signature"
                    centered
                    open={modal2Open}
                    onOk={() => setModal2Open(false)}
                    onCancel={() => setModal2Open(false)}
                    maskClosable={false}
                    footer={false}
                >
                    <Form form={form} onFinish={onFinish} >
                        <Form.Item name="radioGroup" label="Options"  initialValue={selectedValue}>
                            <Radio.Group onChange={handleRadioChange} value={selectedValue}>
                                <Radio value="type">Type</Radio>
                                <Radio value="draw">Draw</Radio>
                                <Radio value="upload">Upload</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {
                            selectedValue === 'draw' && 
                            <Card className='e-signature-card' bordered title={drawOptionTitle} >
                                <Form.Item name='canvasSignature'>
                                    <SignatureCanvas
                                        ref={signatureRef}
                                        penColor={penColor}
                                        canvasProps={{ className: 'signature-canvas' ,border:"1px solid black"}}
                                        onEnd={()=>form.setFieldsValue({ canvasSignature: signatureRef.current.toDataURL() })}
                                    />
                                </Form.Item>
                                <div className='flex-center'><Button onClick={handleClear}>Clear Signature</Button></div>
                            </Card>
                        }
                        {selectedValue === 'type' && (
                            <Card className='e-signature-card' bordered title='Type Your Signature Below'>
                                {/* <Input value={typedText} onChange={handleTextChange} onPressEnter={convertToPng} /> */}
                                <Input value={typedText} onChange={handleTextChange} />
                                <div className='typed-signature' >{typedText}</div> 
                                <Form.Item name='typedSignature'>
                                </Form.Item>
                            </Card>
                        )}
                        {selectedValue === 'upload' && (
                            <Card className='e-signature-card' bordered title='Upload Your Signature Below'>
                                <Form.Item label='Signature Image' name="signatureFile">
                                <Dragger
                                    beforeUpload={() => false} // Prevent automatic upload
                                    name='signature'
                                    // action='/upload' // Replace with your upload endpoint
                                    onChange={handleUploadChange}
                                    accept='.jpg, .jpeg, .png'
                                    maxCount={1}
                                    className='image-signature'
                                >
                                    <p className='ant-upload-drag-icon'>
                                    <InboxOutlined />
                                    </p>
                                    <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                                    <p className='ant-upload-hint'>Support for JPEG and PNG formats only</p>
                                </Dragger>
                                </Form.Item>
                            </Card>
                        )}
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className='mt-1'>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
    )
}

export default ElectronicSignature;