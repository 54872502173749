import { createAsyncThunk } from "@reduxjs/toolkit"; 
import { web3auth } from "../../utils/Web3Auth";
import Web3 from "web3";
import {EWarrentERC4907Address,EWarrentERC4907ABI} from '../../utils/EWarrentERC4907ABIAndAddress';
import { allExternalUsers, getAdminsOfUserByUserAddress, saveExternalUserAccessSignature, saveSetUserEvent } from "./template";
import { openNotificationWithIcon } from "../../utils/openNotificationWithIcon";
import { stopUserOfTokenButtonLoading } from "../slices/erc4907ContractSlice";
import RPC from "../../web3RPC";
import { setSessionCookie } from "./session";

const commonCalls = async()=> {
	try {
    const Web3Auth = await web3auth(); // function for this 
    const web3authProvider = await Web3Auth.connect();
    const web3 = new Web3(web3authProvider);       
    const fromAddress = (await web3.eth.getAccounts())[0];
    const erc4907Contract = new web3.eth.Contract(EWarrentERC4907ABI,EWarrentERC4907Address);
	const balanceInWei = await web3.eth.getBalance(fromAddress);
	const balance = web3.utils.fromWei(balanceInWei, 'ether');
    return {Web3Auth,erc4907Contract,fromAddress,web3,balance};
	}
	catch(error){
		console.log("commonCalls error :",error);
	}
};

export const getERC4907ContractUserData = createAsyncThunk("erc4907Contract/getERC4907ContractUserData",async(_,thunkAPI)=> {
	try {
		const {erc4907Contract,fromAddress} = await commonCalls();
        const totalNFTsMintedToUser = await erc4907Contract.methods.totalNFTsMintedToUser(fromAddress).call();
        console.log("total NFTs minted to user :",totalNFTsMintedToUser);
        const nftOwnerToTokenIds = await erc4907Contract.methods.nftOwnerTokenIdArray(fromAddress).call();
        console.log("nftOwnerToTokenIds :",nftOwnerToTokenIds);
		const promisesTokenIdToUser = Array.from({length: totalNFTsMintedToUser}, async (_, i) => {
			const tokenId = nftOwnerToTokenIds[i];
			const tokenUser = await erc4907Contract.methods.userOf(tokenId).call();
			return {tokenId,tokenUser};
		});
		const tokenIdsToRespectiveUsers = await Promise.all(promisesTokenIdToUser);
		// thunkAPI.dispatch(allExternalUsers());
        return tokenIdsToRespectiveUsers;
	}
	catch(error){
		console.log("getERC4907ContractUserData error :",error);
	}
});

export const mintNFTERC4907 = createAsyncThunk("erc4907Contract/mintNFTERC4907",async(_,thunkAPI)=> {
	try {
		const {erc4907Contract,fromAddress} = await commonCalls();
        const mintERC4907Receipt = await erc4907Contract.methods.nftMint().send({from:fromAddress});
        console.log("mintERC4907Receipt receipt :",mintERC4907Receipt);
		return mintERC4907Receipt.status;
	}
	catch(error){
		console.log("mintNFTERC4907 error :",error);
	}
});

export const setUserERC4907 = createAsyncThunk("erc4907Contract/setUserERC4907",async(data,thunkAPI)=> {
	try {
		const email = localStorage.getItem('email');
		const user = localStorage.getItem('user');
		const {erc4907Contract,fromAddress} = await commonCalls();
		console.log("data logged :",data)
		const dateObj = new Date();
		const nowDateInSeconds = Math.trunc(dateObj/1000);
		console.log("nowDate :",dateObj);
		console.log("nowDateInSeconds :",nowDateInSeconds);
		const nextDay = nowDateInSeconds+86400;
		const next10Minutes = nowDateInSeconds + 600;
        const setUserReceipt = await erc4907Contract.methods.setUser(data.tokenId,data.user,next10Minutes).send({from:fromAddress});
        console.log("setUserERC4907 receipt :",setUserReceipt);
		// const eventData = {tokenOwner:setUserReceipt.from,tokenUser:setUserReceipt.events.UpdateUser.returnValues.user,tokenId:setUserReceipt.events.UpdateUser.returnValues.tokenId,userExpires:setUserReceipt.events.UpdateUser.returnValues.expires,userSetAt:setUserReceipt.events.UserUpdated.returnValues.updatedAt,transactionHash:setUserReceipt.transactionHash,status:setUserReceipt.status,email:email};
		const eventData = {tokenOwner:user,tokenUser:setUserReceipt.events.UpdateUser.returnValues.user,tokenId:setUserReceipt.events.UpdateUser.returnValues.tokenId,userExpires:setUserReceipt.events.UpdateUser.returnValues.expires,userSetAt:setUserReceipt.events.UserUpdated.returnValues.updatedAt,transactionHash:setUserReceipt.transactionHash,status:setUserReceipt.status,email:email};
		console.log("set user event data :",eventData);
		thunkAPI.dispatch(saveSetUserEvent(eventData));
		setUserReceipt.status===true && openNotificationWithIcon('success',`Access given to ${data.userEmail} for Token ${data.tokenId}`);
		return setUserReceipt.status;
	}
	catch(error){
		console.log("setUserERC4907 error :",error);
	}
});


export const userOfERC4907Token = createAsyncThunk("erc4907Contract/userOfERC4907Token",async(data,thunkAPI)=> {
	try {
		const user = localStorage.getItem('user');
		const {erc4907Contract} = await commonCalls();
        const userOfToken = await erc4907Contract.methods.userOf(data).call();
        console.log("userOfToken :",userOfToken);
		if(userOfToken!==user) {
			// alert('You are not user of this tokenId');
			openNotificationWithIcon('error','Token Authorization Failed')
			return {tokenAuthorizationResult : false};
		}
		else {
			openNotificationWithIcon('success','Token Authorization Passed')
			const ownerOfToken = await erc4907Contract.methods.ownerOf(data).call();
			console.log("owner of token :",ownerOfToken);
			const userExpires = await erc4907Contract.methods.userExpires(data).call();
			// thunkAPI.dispatch(getAdminsOfUserByUserAddress(ownerOfToken));
			return {ERC4907TokenUser:userOfToken,ERC4907TokenOwner:ownerOfToken,ERC4907TokenUserExpiry:userExpires,ERC4907TokenId:data,tokenAuthorizationResult : false};
		}
	}
	catch(error){
		console.log("userOfERC4907Token error :",error);
	}
});

export const externalUserSignAccessSurveyMessage = createAsyncThunk("erc4907Contract/externalUserSignAccessSurveyMessage",async(data,thunkAPI)=> {
	try {
		const {web3,Web3Auth,fromAddress} = await commonCalls();
		// const reqObj = {tokenId:data.tokenId,tokenUser:data.tokenUser,userExpiry:data.userExpiry};
		const objectString = JSON.stringify(data);
		console.log("object stringified :",objectString);
		const rpc = new RPC(Web3Auth.provider);
		if(fromAddress	=== data.tokenUser) {
			const privateKey = await rpc.getPrivateKey();
			const {signature} = web3.eth.accounts.sign(objectString, privateKey);
			console.log('Signature:', signature);
			const apiData = {objSigned:data,signature:signature}
			console.log("apiData :",apiData);	
			console.log("dispatching session cookie setter");
			thunkAPI.dispatch(setSessionCookie(data.userExpiry));
			openNotificationWithIcon('success','Signed Message Successfully')
			console.log("dispatched session cookie");
			thunkAPI.dispatch(saveExternalUserAccessSignature(apiData));			
			// const recoveredAddress = web3.eth.accounts.recover(objectString, signature);
			// console.log('Recovered Signer Address:', recoveredAddress);
		} else {
			throw Error('Only the token user can sign this message !');
		}
		return fromAddress===data.tokenUser;
	}
	catch(error){
		console.log("externalUserSignAccessSurveyMessage error :",error);
	}
});

// tokenId , tokenUser, signature , tokenowner