import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router';
import { useSelector , useDispatch} from "react-redux";
import { getApprovedPDFHash} from "../../redux/actions/eWarrant";
import { Button } from 'antd';
import AuthenticatePDF from '../../components/AuthenticatePDF/AuthenticatePDF';
import { DownloadOutlined } from '@ant-design/icons';
import { dateTimeFormatter } from '../../utils/dateFormatter';
import CustomSkeleton from '../../components/CustomSkeleton/CustomSkeleton';
import { useLocation } from 'react-router-dom';
import MainLayout from '../../components/MainLayout/MainLayout';

const VerifySurveyPage = () => {

  let { formId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const {verifySurveyUIObj} = useSelector((state)=>state.contract);
  const [isLoading, setIsLoading] = useState(true); // Track initial loading state

  useEffect(()=>{
    const eWarrentData = {formId,contract_address:location.state.contract_address};
    dispatch(getApprovedPDFHash(eWarrentData)).then(() => {
      setIsLoading(false); // Set loading state to false after data is fetched
    });
  },[dispatch,formId,location.state.contract_address])
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = verifySurveyUIObj.approvedPDFUrl;
    link.target = '_blank';
    link.download = 'approved_survey.pdf';
    link.click();
  };

  return (
    <>  
      {
        <MainLayout>
              <div className='flex-between-center'>
                <h1>Verify Workflow</h1>
                {verifySurveyUIObj.formStatus===4 && <Button icon={<DownloadOutlined />}  type="primary" size='large' className='green-black-btn' onClick={handleDownload}>Download PDF</Button>}
              </div>
              { isLoading ? <CustomSkeleton /> :
              <>
              <div className="workflow-info">
                <div className="workflow-info-row">
                  <div className='workflow-info-key'>Title</div>
                  <div className='workflow-info-value'>{verifySurveyUIObj.surveyName}</div>
                </div>
                <div className="workflow-info-row">
                  <div className='workflow-info-key'>Description</div>
                  <div className='workflow-info-value'>{verifySurveyUIObj.surveyDescription}</div>
                </div>
                <div className="workflow-info-row">
                  <div className='workflow-info-key'>Workflow Id</div>
                  <div className='workflow-info-value'>{formId}</div>
                </div>
                <div className="workflow-info-row">
                <div className='workflow-info-key'>Workflow Status</div>
                <div className='workflow-info-value'>{verifySurveyUIObj.statusString}</div>
              </div>
              <div className="workflow-info-row">
                <div className='workflow-info-key'>Dataset Provider</div>
                <div className='workflow-info-value'>{verifySurveyUIObj.submitter}</div>
              </div>
              {verifySurveyUIObj.formStatus!==4 && 
              <div className="workflow-info-row">
                <div className='workflow-info-key'>Dataset Approver(s)</div>
                <div className='workflow-info-value'>
                  <div className='flex-direction-col-only'>
                    {
                      verifySurveyUIObj.approvers?.map((approver)=>(
                        <div>{approver}</div>
                      ))
                    }
                  </div>
                </div>
              </div>
              }
              {verifySurveyUIObj.formStatus===4 && 
              <div className="workflow-info-row">
                  <div className='workflow-info-key'>Approved By</div>
                  <div className='workflow-info-value'>
                    <div className='flex-direction-col-only'>
                      {
                        verifySurveyUIObj.approverArray?.map((approver)=>(
                          <div>{approver.approver_address} at {dateTimeFormatter(approver.createdAt)}</div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              }
              </div>

              {verifySurveyUIObj.formStatus===4 && <div>
                <AuthenticatePDF storedHash={verifySurveyUIObj.approvedPDFHash} />
              </div>}
              </>
              }                     
        </MainLayout>
      }
    
    </>
  )
}

export default VerifySurveyPage;