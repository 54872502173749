import { Col, Form, Input, Row, Select } from 'antd';
import {useSelector,useDispatch} from "react-redux";
import { useEffect } from 'react';
import { getAllOnboardedUsersByAdmin } from '../../redux/actions/template';
import "./AssignSubmitterApprover.scss";

const AssignSubmitterApprover = ({form}) => {

  const {allOnboardedUsers} = useSelector((state)=>state.template);

  const {surveyNameAssignSurvey} = useSelector(state => state.admin);
  console.log("surveyNameAssignSurvey in the modal component :",surveyNameAssignSurvey);
  const {isFormDataSet} = useSelector(state =>state.contract);
  const dispatch = useDispatch();
  useEffect(()=>{
    isFormDataSet && form.resetFields();
  },[isFormDataSet,form]);

  useEffect(()=>{
    dispatch(getAllOnboardedUsersByAdmin());
  },[dispatch]);
  
  return (
        <div className='assign-submitter-main'>
                <Form
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                >
                    <Form.Item name="surveyName" label="Workflow Name :" initialValue={surveyNameAssignSurvey ? surveyNameAssignSurvey : null}>
                      <Input readOnly />
                    </Form.Item>

                    <Row gutter={[16,0]}>
                      <Col span={12}>
                          <Form.Item label="Dataset Provider :" required={false} name="submitter" rules={[
                                        {
                                        required: true,
                                        whitespace: true,
                                        message: "Please input dataset provider's email",
                                        },
                                    ]}>
                            <Select
                              showSearch
                              placeholder="Select Dataset Provider's Email"
                            >
                              {allOnboardedUsers?.map((obj) => (
                                <Select.Option key={obj._id} value={JSON.stringify({ email: obj.email, address: obj.address })}>
                                  {obj.email}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                      </Col>
                      <Col span={12}>
                      <Form.Item label="Dataset Approver(s) :" required={false} name="approvers" rules={[{
                          required: true,
                          message: "Please select at least one dataset approver!"
                      }, {
                          validator: async (rule, value, callback) => {
                            if (value) {
                                if (value.length > 3) {
                                    return Promise.reject("Cannot have more than 3 dataset approvers")

                                } else if (value.length < 1 ) {
                                    return Promise.reject("Cannot have less than 1 dataset approver")
                                } else {
                                  return Promise.resolve()
                                }
                            }
                            return
                          }
                      }]} >
                        <Select
                          showSearch
                          mode="multiple"
                          placeholder="Select Dataset Approver's Email"
                        >
                              {allOnboardedUsers?.map((obj) => (
                                <Select.Option key={obj._id} value={JSON.stringify({ email: obj.email, address: obj.address })}>
                                  {obj.email}
                                </Select.Option>
                              ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        initialValue={1}
                        name="confirmations"
                        label="Confirmations:"
                        required={false}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the number of confirmations required',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const numApprovers = getFieldValue('approvers').filter(Boolean).length;
                              if (value < 1) {
                                return Promise.reject(new Error('The minimum value is 1'));
                              }
                              if (value > numApprovers) {
                                return Promise.reject(
                                  new Error(`The maximum number of confirmations is ${numApprovers}`)
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input placeholder="Enter the number of confirmations required" type="number" />
                      </Form.Item>

                      </Col>
                    </Row>

                </Form>
        </div>  
    );
};
export default AssignSubmitterApprover;