import React from 'react'
import Layout from '../Layout/Layout'
const MainLayout = ({children}) => {
  return (
    <Layout>
        <div style={{display:"flex",justifyContent:'center',alignItems:'center',margin:' auto',height:'90vh'}}>
            <div className="container-main">
                {children}
            </div>
        </div>
    </Layout>
  )
}

export default MainLayout