export const workflowTemplateSchemaUID = '0x6b4afb13ee13e37628524171d615873c35fe2580996a97932d16d1050438a724';

export const workflowTemplateEditSchemaUID = ''; // same as workflowTemplateSchemaUId

export const workflowAssignmentSchemaUID = '0x7394d738d96a2fb3c3f19b936ba179640fadd46bfb0c7fbe5a253f5f1de1d8bb';

export const workflowSubmissionSchemaUID = '0xdb9c1f8e266e07112fb36d966acf0164db2abbcc240c917da7e3b3ed1e9bb295';

export const workflowApproverSchemaUID = '0x8e065244897b53f0ab6b313e9e46f812eacd6bc1b238824f6df26f4076db5cd3';

export const workflowSignatureSchemaUID = '0xea2ccc2dafd9de74a3553297ec906704f7b4bc517f7e20ac802182531fd43c69';