import { message, Button, Spin, Table, Space, Tag ,Tooltip } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deployEWarrentForAdmin } from "../../redux/actions/eWarrantDeployer";
import { CopyOutlined, LinkOutlined, SendOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {blockExplorers} from '../../utils/Web3Auth';
import copy from "copy-to-clipboard";
import "./AdminList.scss";
const AdminList = () => {
  const { isgetAllAdminsLoading, allAdminArray } = useSelector(
    (state) => state.template
  );
  const dispatch = useDispatch();

  const [itemKey,setItemKey] = useState(null);
  const eWarrentDeploymentHandler = async (admin) => {
      setItemKey(admin._id);
      setButtonLoading(true);
      dispatch(deployEWarrentForAdmin(admin)).then(()=>{
        setButtonLoading(false);
        setItemKey(null);
      });
  };

  const [buttonLoading,setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const toAdminPage = (admin) => {
    navigate(`admin?adminemail=${admin.email}`,{state:{admin:admin}});
  }
  const [isAdminAddressCopying, setIsAdminAddressCopying] = useState(false);

  const handleCopyAdminAddress = (address) => {
    setIsAdminAddressCopying(true);
    copy(address);
    setTimeout(() => setIsAdminAddressCopying(false), 1000); // Reset copying state after 1 second
    message.success('Admin Address copied to clipboard!');
  };
  
  const columns = [
    {
      title: "Admin Email",
      dataIndex: "email",
      key: "email",
      width:100
    },
    {
      title: "Admin Status",
      dataIndex: "status",
      key: "status",
      width:100,
      render: (_, item) => {
        if (item.address) {
          if (item.contract_address==="0x0000000000000000000000000000000000000000") {
            return <Tag color="red">Removed</Tag>;
          } else if (item.contract_address || !item.contract_address){
            return <Tag color="green">Onboarded</Tag>;
          }
        } else if (!item.address) {
          return <Tag color="orange">Invited</Tag>;
        }
      }
    },
    {
      title: "Admin Address",
      dataIndex:"address",
      key: "address",
      width:150,
      render: (address) => (
        <Tooltip title={address}>
          <span>{address && <>{address.slice(0, 6)}...{address.slice(-4)}<CopyOutlined onClick={() => handleCopyAdminAddress(address)}/></>}</span>
        </Tooltip>
      ),
    
    },
    {
      title: "xVault Contract Status",
      key: "actions",
      width:200,
      render: (_, item) => {      
        if (item.address) {
          if (!item.contract_address) {
            return <Tag color="purple">Ready to be Deployed</Tag>
          } else if (item.contract_address !== "0x0000000000000000000000000000000000000000") {
            return <Tag color="green">xVault Deployed</Tag>;
          } else {
            return <Tag color="red">Revoked Access</Tag>;
          }
        } else {
          return <Tag color="blue">Admin not onboarded</Tag>;
        }
      },
    },
    {
      title: "xVault Contract Address",
      dataIndex:"contract_address",
      key: "contract_address",
      width:200,
      render: (contract_address) => (
        contract_address && contract_address!=="0x0000000000000000000000000000000000000000" && <Space align="horizontal">
        <Tooltip title={contract_address}>
          <span>{contract_address.slice(0, 6)}...{contract_address.slice(-4)}</span>
        </Tooltip>
        <a
          // href={`https://mumbai.polygonscan.com/address/${contract_address}`}
          href={`${blockExplorers[1]}/address/${contract_address}#transactions`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkOutlined />
        </a>
        </Space>
      ),
      },
      {
        title: 'Actions',
        key: 'actions',
        fixed: 'right',
        width: 100,
        render: (_,item) => (
          <>
          {item.address && !item.contract_address ?
          <Button
            key={item._id}
            onClick={() => eWarrentDeploymentHandler(item)}
            loading={itemKey === item._id ? buttonLoading : false}
          >
            Deploy xVault
          </Button> :
          item.contract_address && item.contract_address!=="0x0000000000000000000000000000000000000000" && <Button onClick={()=>toAdminPage(item)} icon={<SendOutlined/>}>More</Button>}
          </>
        )
      },
    
  ];  
    
  const calculateContainerHeight = () => {
    if (window.innerWidth > 1400) {
      return 420;
    } else if (window.innerWidth > 980) {
      return 360;
    } else if (window.innerWidth > 680) {
      return 350;
    } else {
      return 320;
    }
  };

  const [containerHeight, setContainerHeight] = useState(calculateContainerHeight());

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(calculateContainerHeight());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    isgetAllAdminsLoading ? (
      <div

        className="flex-direction-col adminlist-spin-container"
        style={{height: containerHeight}}
      >
        <Spin />
        <h3>Loading...</h3>
      </div>
    ) : (
      <Table
        style={{maxWidth:'100%'}}
        dataSource={allAdminArray && allAdminArray}
        columns={columns}
        rowKey={(record) => record._id}
        pagination={false}
        scroll={{ y: containerHeight,x:true }}
      />
    )
  );
};

export default AdminList;