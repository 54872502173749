import { Button } from 'antd';
import React from 'react'
import './SignBeforeAccessingSurveysComponent.scss';
import { useDispatch, useSelector } from 'react-redux';
import { externalUserSignAccessSurveyMessage } from '../../redux/actions/erc4907Contract';

const SignBeforeAccessingSurveysComponent = ({tokenId,userExpiry,tokenUser,tokenOwner}) => {
  const dispatch = useDispatch();
  const {isExternalUserSigningAccessSurveyMessage} = useSelector(state => state.erc4907Contract);
  const signAccessSurveyMessage = () => {
    const reqData = {
        tokenId,
        tokenUser,
        userExpiry,
        tokenOwner
    };
    dispatch(externalUserSignAccessSurveyMessage(reqData));
  }
  return (
    <>
    <div>Sign the message below to get viewing access to workflows</div>
    <div className="sign-message-container">
        <div className="sign-message">Message</div>
        <div>TokenId : {tokenId}</div>
        <div>Token Owner : {tokenOwner}</div>
        <div>Token User : {tokenUser}</div>
        <div>userExpiry : {userExpiry}</div>
    </div>
    <br/>
    <Button onClick={signAccessSurveyMessage} loading={isExternalUserSigningAccessSurveyMessage}>Sign Message</Button>
    </>
  )
}

export default SignBeforeAccessingSurveysComponent;