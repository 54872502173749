import {sha3_256} from "js-sha3"
export const generateBytes32Hash = async(objectsArray)=>{
    try {
      // Convert the array of objects to a JSON string
      const jsonString = JSON.stringify(objectsArray);
  
      // Convert the JSON string to an ArrayBuffer
      const encoder = new TextEncoder();
      const data = encoder.encode(jsonString);
  
      // Calculate the SHA-256 hash
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  
      // Convert the hash to a hexadecimal string
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hexHash = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  
      // Convert the hexadecimal string to bytes32 (64 characters)
      const bytes32Hash = '0x' + hexHash.slice(0, 64);
  
      return bytes32Hash;
    } catch (error) {
      console.error('Error generating hash:', error);
      throw error;
    }
  }
export const generateBytes32HashOfString =async(inputString) => {
  try {
    // Convert the JSON string to an ArrayBuffer
    const encoder = new TextEncoder();
    const data = encoder.encode(inputString);

    // Calculate the SHA-256 hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    // Convert the hash to a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hexHash = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    // Convert the hexadecimal string to bytes32 (64 characters)
    const bytes32Hash = '0x' + hexHash.slice(0, 64);

    return bytes32Hash;
  } catch (error) {
    console.error('Error generating hash:', error);
    throw error;
  }
}

export const hashBlob = async(blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function () {
      const arrayBuffer = reader.result;

      // Convert ArrayBuffer to array of bytes
      const byteArray = Array.from(new Uint8Array(arrayBuffer));

      // Use js-sha3 library to calculate Keccak-256 hash
      const hash = '0x' + sha3_256(byteArray);

      resolve(hash);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsArrayBuffer(blob);
  });
}